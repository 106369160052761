import { makeStyles } from "@material-ui/core";

import { COLORS } from "../../../config/theme/theme";

const clickAndCollectStyles = makeStyles(() => ({
  cards: {
    minWidth: 275,
    marginTop: "16px",
    background: COLORS.WHITE,
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    padding: "8px",
  },
  cardTitle: {
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "22px",
    color: COLORS.TEXT_PRIMARY,
  },
  cardContent: {
    marginTop: "18px",
    display: "flex",
  },
  posDetailLabel: {
    fontSize: "14px",
    fontWeight: 500,
  },
  posDetailData: {
    marginTop: "8px",
    fontSize: "14px",
    fontWeight: 600,
  },
  tabsIndicator: {
    backgroundColor: "#0E5AA7",
  },
  collectionPointAccordionStyle: {
    backgroundColor: "white",
    padding: "24px",
    /* "&.Mui-expanded": {
      // paddingBottom: "0px !important",
    }, */
  },
  accordionStyle: {
    marginTop: "12px",
    backgroundColor: "#F9F9F9",
    boxShadow: "none",
  },
  accordionTitle: {
    fontSize: "18px",
    fontWeight: 600,
  },
  vertical: {
    borderLeft: "1px solid #B4B4B4",
    height: "25px",
    marginLeft: "80px",
    marginTop: "8px",
    marginRight: "80px",
  },
  checkboxLabel: {
    fontSize: "12px",

    fontWeight: "normal",
    lineHeight: "15px",
    paddingLeft: "8px",
  },
  tabLabelIcon: {
    minHeight: "0",
  },
  tabSelected: {
    color: "#0E5AA7",
    fontWeight: "600",
  },
  deleteConfigTabIcon: {
    position: "absolute",
    top: 0,
    height: "15px",
    color: "#0E5AA7",
    right: 0,
  },
  buttons: {
    margin: "24px 0px",
  },
  smallTitle: {
    height: "17px",

    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "17px",
    color: COLORS.TEXT_PRIMARY,
  },
}));

export default clickAndCollectStyles;
