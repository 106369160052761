// Material UI Components
import { Grid, withStyles } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import React from "react";

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 46,
    height: 24,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 3,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(22px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
    "&.Mui-disabled": {
      opacity: "0",
      cursor: "default",
      color: theme.palette.grey[500],
    },
  },
  thumb: {
    width: 18,
    height: 18,
    boxShadow: "none",
    backgroundColor: "white",
  },
  track: {
    borderRadius: 24 / 2,
    opacity: 1,
    backgroundColor: "#E6E6E6",
  },
  sizeSmall: {
    marginTop: "4px",
    height: "13px",
    width: "24px",
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&$checked": {
        transform: "translateX(12px)",
      },
    },
    "& .MuiSwitch-thumb": {
      width: "9px",
      height: "9px",
    },
  },
  checked: {},
}))(Switch);

const SwitchButton = ({ leftLabel, rightLabel, size = "medium", checked, handleChange, disabled, ...props }) => {
  return (
    <Grid container alignItems="center">
      {leftLabel && (
        <Grid item xs={leftLabel.length <= 6 ? 3 : 4} style={{ fontWeight: 600 }}>
          {leftLabel}
        </Grid>
      )}
      <Grid item xs={3}>
        <AntSwitch {...props} checked={checked} onChange={handleChange} size={size} disabled={disabled} />
      </Grid>

      {rightLabel && (
        <Grid item xs={rightLabel.length < 6 ? 3 : 4} style={{ fontWeight: 600 }}>
          {rightLabel}
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(SwitchButton);
