import { makeStyles } from "@material-ui/core";

const productTypeStyles = makeStyles(() => ({
  accordion: {
    marginTop: "12px !important",
  },
  accordionSummary: {
    padding: "8px 24px",
    // "& .MuiAccordionSummary-content": {
    //   flexDirection: 'column'
    // },
  },
  accordionTitle: {
    fontSize: "18px",
    fontWeight: 600,
  },
  accordionSubTitle: {
    fontSize: "12px",
    fontWeight: 400,
    marginBottom: 10
  },
  accordionDetails: {
    display: "block",
    paddingTop: "0px",
    marginLeft: "5px",
  },
  dFlex: { display: "flex" },
  foodSubType: {
    marginRight: "40px",
  },
  foodSubTypeCheckbox: { marginRight: "117px" },
  checkboxLabel: {
    fontSize: "12px",
    fontWeight: "normal",
    lineHeight: "15px",
    paddingLeft: "8px",
  },
  nonFood: { marginTop: "16px" },
  productTypeLabel: {
    fontSize: "14px",
    fontWeight: 500,
  },
  productTypeData: {
    marginTop: "8px",
    fontSize: "14px",
    fontWeight: 600,
  },
  vertical: {
    borderLeft: "1px solid #B4B4B4",
    height: "25px",
    marginLeft: "80px",
    marginTop: "8px",
    marginRight: "80px",
  },
  error: {
    marginTop: "10px",
    fontSize: "12px",
    color: "red",
  },
}));

export default productTypeStyles;
