import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Grid from "@material-ui/core/Grid";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RemoveButton from "components/common/RemoveButton/RemoveButton";
import React from "react";
import { isEmptyArray, isEmptyOrNull } from "utils/DataUtils";
import { ALL_DAYS } from "../../../../config/constants";
import { generateDropdownItems } from "../../../../utils/helperFunctions";
import MultiSelectBox from "../../../common/MultiSelectBox/MultiSelectBox";
import PrimaryButton from "../../../common/PrimaryButton/PrimaryButton";
import SelectBox from "../../../common/SelectBox/SelectBox";
import TextBox from "../../../common/TextBox";
import styles from "./RangeGeneratorFormStyles";
import SlotRange from "./SlotRange";

const HOURS = generateDropdownItems(0, 96, "hours");

const MINIMUM_DAYS = generateDropdownItems(0, 10, "days");
const MAXIMUM_DAYS = generateDropdownItems(1, 20, "days");

interface RangeGeneratorFormProps {
  tabIndex: number;
  formIndex: number;
  days: any;
  remainingDays: any;
  minimumDays: any;
  maximumDays: any;
  bufferTimeHours: any;
  defaultSlotCapacity: any;
  generatedRange: any;
  onDaysChange: any;
  onRangeGeneratorFieldsChange: any;
  onGenerateRangeSubmit: any;
  onIndividualRangeFieldsChange: any;
  handleResetSlotsClick: any;
  objectKey: any;
  deleteConfigureSlotsOption: any;
  generatorFormIndex: any;
  configTabIndex: any;
}

const RangeGeneratorForm = (props: RangeGeneratorFormProps) => {
  const {
    tabIndex,
    formIndex,
    days,
    remainingDays,
    minimumDays,
    maximumDays,
    bufferTimeHours,
    defaultSlotCapacity,
    generatedRange = [],
    onDaysChange,
    onRangeGeneratorFieldsChange,
    onGenerateRangeSubmit,
    onIndividualRangeFieldsChange,
    handleResetSlotsClick,
    objectKey,
    deleteConfigureSlotsOption,
    generatorFormIndex,
    configTabIndex,
  } = props;

  const classes = styles();

  const isButtonDisabled = () => {
    let isDisabled = true;
    isDisabled = isEmptyOrNull(minimumDays) || isEmptyOrNull(maximumDays) || isEmptyOrNull(defaultSlotCapacity) || isEmptyArray(days) || isEmptyOrNull(bufferTimeHours);
    return isDisabled;
  };

  const renderDaysInput = () => {
    return (
      <>
        <div className={classes.fieldLabel}>Days</div>
        <MultiSelectBox menuitems={remainingDays} value={days} onChange={(e: any) => onDaysChange(tabIndex, formIndex, e, objectKey)} />
      </>
    );
  };

  const renderGeneratedRange = () => {
    return (
      <div
      // className={classes.generatedSlotsContainer}
      >
        <div className={classes.separation} />
        <Grid container>
          <Grid className={classes.fontSize16}>
            {`Generated Slots for : `}
            {<span className={classes.slotDays}>{`${days.length >= 7 ? ALL_DAYS : days.join(", ").toLowerCase()}`}</span>}
          </Grid>
        </Grid>

        <Grid container spacing={1} className={classes.generatedSlotsTable}>
          <Grid item xs={3} className={classes.slotsTableLabel}>
            Days Range
          </Grid>
          <Grid item xs={3} className={classes.slotsTableLabel}>
            Capacity
          </Grid>
          <Grid item xs={3} className={classes.slotsTableLabel}>
            Buffer Time (Hrs)
          </Grid>
          <Grid item xs={3} className={`${classes.slotsTableLabel} ${classes.orderCutOffHeader}`}>
            Order Cut-off
          </Grid>
        </Grid>

        <div>
          {generatedRange.map((range: any, index: number) => (
            <SlotRange
              key={range.dateRange}
              tabIndex={tabIndex}
              formIndex={formIndex}
              rangeIndex={index}
              dateRange={range.dateRange}
              capacity={range.capacity}
              bufferTime={range.bufferTime}
              onIndividualRangeFieldsChange={onIndividualRangeFieldsChange}
              minimumDays={minimumDays}
              maximumDays={maximumDays}
            />
          ))}
        </div>
      </div>
    );
  };

  const renderRangeGeneratorForm = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <SelectBox
            fieldLabel="Minimum Days"
            name="minimumDays"
            menuItems={MINIMUM_DAYS}
            value={minimumDays}
            handleChange={(e: any) => onRangeGeneratorFieldsChange(tabIndex, formIndex, e, objectKey)}
          />
        </Grid>
        <Grid item xs={2}>
          <SelectBox
            fieldLabel="Maximum Days"
            name="maximumDays"
            menuItems={MAXIMUM_DAYS}
            value={maximumDays}
            handleChange={(e: any) => onRangeGeneratorFieldsChange(tabIndex, formIndex, e, objectKey)}
          />
        </Grid>
        <Grid item xs={5}>
          <SelectBox
            name="bufferTime"
            fieldLabel="Buffer Time (Day Starts at 8:30)"
            menuItems={HOURS}
            value={bufferTimeHours}
            labelClass={classes.hideShrinkedLabel}
            selectClass={classes.selectClass}
            handleChange={(e: any) => onRangeGeneratorFieldsChange(tabIndex, formIndex, e, objectKey)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextBox
            fieldLabel="Slot Capacity"
            placeholderText="Slot Capacity"
            name="defaultSlotCapacity"
            value={defaultSlotCapacity}
            handleChange={(e: any) => onRangeGeneratorFieldsChange(tabIndex, formIndex, e, objectKey)}
          />
        </Grid>
        <Grid container spacing={3} className={classes.buttons}>
          <Grid item md={4} lg={4}></Grid>

          <Grid item md={8} lg={8}>
            <Grid container justifyContent="flex-end" style={{ textAlign: "right" }}>
              <Grid item xs={6}>
                <PrimaryButton buttonLabel="Reset Range" onClick={() => handleResetSlotsClick(tabIndex, formIndex)} />
              </Grid>
              <Grid item xs={6} className={classes.generateSlotsButton}>
                <PrimaryButton buttonLabel="Generate Range" onClick={() => onGenerateRangeSubmit(tabIndex, formIndex, objectKey)} disabled={isButtonDisabled()} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <div className={classes.slotsGeneratorForm}>
      <Accordion defaultExpanded={true} className={classes.whiteBackground}>
        <AccordionSummary className={classes.slotsGeneratorAccordionSummary} expandIcon={<ExpandMoreIcon />} classes={{ expanded: classes.expandedStyle }}>
          <Grid container>
            <Grid item xs={6} className={classes.daysInput}>
              {renderDaysInput()}
            </Grid>
          </Grid>
          {generatorFormIndex !== 0 && <RemoveButton onClick={() => deleteConfigureSlotsOption(configTabIndex, objectKey, generatorFormIndex)} />}
        </AccordionSummary>
        <AccordionDetails className={classes.slotsGeneratorAccordionDetails}>
          {renderRangeGeneratorForm()}
          {generatedRange.length ? renderGeneratedRange() : null}
        </AccordionDetails>
        {/* {renderResetSlotsModal()} */}
      </Accordion>
    </div>
  );
};

export default RangeGeneratorForm;
