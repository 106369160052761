import React from "react";

import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { capitalizeFirstLetter } from "../../Utils/DeliveryFeeUtils";
import { DELIVERY_FEE_SHIPMENT_LEVEL } from "../../Utils/DeliveryFeeConstants";

import ConfigurationCommonInputView from "./ConfigurationCommonInputView";

interface ShipmentLevelConfigurationInterface {
  classes: any;
  state: any;
  _handle_calculation_toggle_change: any;
  handleInputChange: any;
  _handle_click_add_threshold_range: any;
  _handle_click_delete_threshold_range: any;
  handleRangeInputChange: any;
  _handle_open_configuration_dialog: any;
  openShipmentLevel: any;
  _handle_accordion_open_state: any;
  updateConfigPosId: any;
  updateConfigZoneName: any;
  _handle_calculation_active_toggle_change: any;
  _handle_edit_update_calculation_toggle_change: any;
  removeTimeConfigRecord: any;
  handleAdditionalConfigInputChange: any;
  _handle_edit_toggle_additional_calculation_toggle_change: any;
  _handle_edit_threshold_rane_toggle_change_enable_Edit: any;
  handleServiceType: any;
  handleCapacityUtilisationConfigInputChange: any;
  _handle_edit_toggle_capacity_utilisation_toggle_change: any;
}

const ShipmentLevelConfiguration = (props: ShipmentLevelConfigurationInterface) => {
  const {
    classes,
    state,
    _handle_calculation_toggle_change,
    handleInputChange,
    _handle_click_add_threshold_range,
    _handle_click_delete_threshold_range,
    handleRangeInputChange,
    _handle_open_configuration_dialog,
    openShipmentLevel,
    _handle_accordion_open_state,
    updateConfigPosId,
    updateConfigZoneName,
    _handle_calculation_active_toggle_change,
    _handle_edit_update_calculation_toggle_change,
    removeTimeConfigRecord,
    handleAdditionalConfigInputChange,
    _handle_edit_toggle_additional_calculation_toggle_change,
    _handle_edit_threshold_rane_toggle_change_enable_Edit,
    handleServiceType,
    handleCapacityUtilisationConfigInputChange,
    _handle_edit_toggle_capacity_utilisation_toggle_change,
  } = props;
  return (
    <Accordion expanded={openShipmentLevel} className={classes.accordionStyle} onChange={() => _handle_accordion_open_state("openShipmentLevel")}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummaryStyle}>
        <Grid container alignItems="center">
          <Grid>
            <Typography className={classes.accordionTitle}>{capitalizeFirstLetter(DELIVERY_FEE_SHIPMENT_LEVEL)}</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetailsStyle}>
        <>
          {/* New Configuration Screen */}
          {updateConfigPosId === "" &&
            updateConfigZoneName === "" &&
            state &&
            Object.keys(state).map((stateLevel: any, stateLevelIndex: any) => (
              <ConfigurationCommonInputView
                key={`${stateLevelIndex}-shipmentLevel-stateLevel-state`}
                classes={classes}
                name={stateLevel}
                multiShipmentLevel={false}
                stateObj={state[stateLevel]}
                stateLevelIndex={stateLevelIndex}
                _handle_calculation_toggle_change={_handle_calculation_toggle_change}
                handleInputChange={handleInputChange}
                _handle_click_add_threshold_range={_handle_click_add_threshold_range}
                _handle_click_delete_threshold_range={_handle_click_delete_threshold_range}
                handleRangeInputChange={handleRangeInputChange}
                _handle_open_configuration_dialog={_handle_open_configuration_dialog}
                updateConfigPosId={updateConfigPosId}
                updateConfigZoneName={updateConfigZoneName}
                _handle_calculation_active_toggle_change={_handle_calculation_active_toggle_change}
                _handle_edit_update_calculation_toggle_change={_handle_edit_update_calculation_toggle_change}
                removeTimeConfigRecord={removeTimeConfigRecord}
                handleAdditionalConfigInputChange={handleAdditionalConfigInputChange}
                _handle_edit_toggle_additional_calculation_toggle_change={_handle_edit_toggle_additional_calculation_toggle_change}
                _handle_edit_threshold_rane_toggle_change_enable_Edit={_handle_edit_threshold_rane_toggle_change_enable_Edit}
                handleServiceType={handleServiceType}
                handleCapacityUtilisationConfigInputChange={handleCapacityUtilisationConfigInputChange}
                _handle_edit_toggle_capacity_utilisation_toggle_change={_handle_edit_toggle_capacity_utilisation_toggle_change}
              />
            ))}

          {/* Is Update Screen */}
          {updateConfigPosId !== "" &&
            updateConfigZoneName !== "" &&
            state &&
            Object.keys(state).map((stateLevel: any, stateLevelIndex: any) =>
              state && state[stateLevel] && state[stateLevel].isEditMode === true ? (
                <ConfigurationCommonInputView
                  key={`${stateLevelIndex}-shipmentLevel-stateLevel-state`}
                  classes={classes}
                  name={stateLevel}
                  multiShipmentLevel={false}
                  stateObj={state[stateLevel]}
                  stateLevelIndex={stateLevelIndex}
                  _handle_calculation_toggle_change={_handle_calculation_toggle_change}
                  handleInputChange={handleInputChange}
                  _handle_click_add_threshold_range={_handle_click_add_threshold_range}
                  _handle_click_delete_threshold_range={_handle_click_delete_threshold_range}
                  handleRangeInputChange={handleRangeInputChange}
                  _handle_open_configuration_dialog={_handle_open_configuration_dialog}
                  updateConfigPosId={updateConfigPosId}
                  updateConfigZoneName={updateConfigZoneName}
                  _handle_calculation_active_toggle_change={_handle_calculation_active_toggle_change}
                  _handle_edit_update_calculation_toggle_change={_handle_edit_update_calculation_toggle_change}
                  removeTimeConfigRecord={removeTimeConfigRecord}
                  handleAdditionalConfigInputChange={handleAdditionalConfigInputChange}
                  _handle_edit_toggle_additional_calculation_toggle_change={_handle_edit_toggle_additional_calculation_toggle_change}
                  _handle_edit_threshold_rane_toggle_change_enable_Edit={_handle_edit_threshold_rane_toggle_change_enable_Edit}
                  handleServiceType={handleServiceType}
                  handleCapacityUtilisationConfigInputChange={handleCapacityUtilisationConfigInputChange}
                  _handle_edit_toggle_capacity_utilisation_toggle_change={_handle_edit_toggle_capacity_utilisation_toggle_change}
                />
              ) : (
                <></>
              )
            )}
        </>
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(ShipmentLevelConfiguration);
