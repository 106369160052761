import moment from "moment";

export const HH_MM_SS = "HH:mm:ss";

export function getDateFromData(dateData: any) {
  if (dateData) {
    return moment(dateData).format("DD MMM YYYY");
  }
  return dateData;
}

export function getTimeFormatData(timeData: any) {
  if (timeData) {
    return moment(timeData, HH_MM_SS).format("HH:mm");
  }
  return timeData;
}
