import { makeStyles } from "@material-ui/core";

export default makeStyles({
  fontWeight500: {
    fontWeight: 500,
  },
  switchButton: {
    display: "flex",
    justifyContent: "flex-end",
  },
  inputsContainer: { marginTop: "-6px" },
  error: {
    marginTop: "3px",
    position: "absolute",
    zIndex: 100,
    color: "red",
    fontSize: "12px",
  },
});
