import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TabContext from "@material-ui/lab/TabContext";
import TabPanel from "@material-ui/lab/TabPanel";
import produce from "immer";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import CustomAlert from "../../../components/common/CustomAlert/CustomAlert";
import Loader from "../../../components/common/Loader/Loader";
import { ALERT_TYPES, APP_ROUTES, SERVICE_TYPES, STATUS_OK } from "../../../config/constants";
import { selectServiceTypes } from "../../../config/redux/configurationsSlice";
import ConfirmModal from "../../common/ConfirmModal/ConfirmModal";
import CustomBreadcrumbs from "../../common/CustomBreadcrumbs/CustomBreadcrumbs";
import LinkButton from "../../common/LinkButton/LinkButton";
import PageTitle from "../../common/PageTitle/PageTitle";
import PrimaryButton from "../../common/PrimaryButton/PrimaryButton";
import RemoveButton from "../../common/RemoveButton/RemoveButton";
import { fetchPOSDetailsByPOSNo } from "../../CreatePOS/redux/posSlice";
import AreaTransferList from "../AreaTransferList/AreaTransferList";
import BasicServiceDetails from "../common/BasicServiceDetails/BasicServiceDetails";
import { deleteZoneConfig, fetchSlotsInformationByServiceType, makeDefaultTemplateSlotEditable, makeTemplateSlotEditable, resetSlotsInformation } from "../redux/templateSlotsSlice";
import { ALL_DAYS_VALUES } from "../utils/constants";
import { calculateAvailableDaysForSelection, calculateAvailableDaysForSelectionDefaultService, createNextZoneName } from "../utils/serviceUtils";
import { getPOSBreadCum } from "../utils/SlotServicesUtils";
import {
  createPayloadForSavedefaultConfig,
  createPayloadForUpdatedefaultConfig,
  executeScroll,
  getMissingDeliveryTypeFromObject,
  isAddOtherDaysButtonDisabled,
  saveDefaultZoneConfigurations,
  updateTemplateSlotHandler,
  updateZoneConfigurations,
  validateDefaultZoneConfig,
  validateDefaultZoneConfigBothEmpty,
  validateSaveDefaultConfigObject,
  validateUpdateSlotConfigObject,
} from "./base/DefaultUtils";
import {
  ConfigTabsState,
  currentAllowedDeliveryTypes,
  DefaultServiceProps,
  DEFAULT_CONFIG_TAB,
  DEFAULT_RANGE_GENERATOR_FORM_VALUES,
  slotsGeneratorFormsnterface,
  tabInitValues,
} from "./DefaultInterface";
import defaultServiceStyles from "./DefaultServiceStyles";
import EditSlotRange from "./RangeGeneratorForm/EditSlotRange";
import RangeGeneratorForm from "./RangeGeneratorForm/RangeGeneratorForm";

const DefaultService = (props: DefaultServiceProps) => {
  const { serviceTypeId, serviceTypeName, isPOSMappingAreaBased } = props;
  const { posNo } = useParams<any>();
  const reg = new RegExp("^[0-9]+$");
  const classes = defaultServiceStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const rangeGeneratorFormRef = React.useRef(null);

  const { posDetails, loading: fetchingDetails } = useSelector((state: any) => state.pos);
  const { fetchingSlots, default: defaultService } = useSelector((state: any) => state.slots);
  const serviceTypes = useSelector(selectServiceTypes);

  const [selectedZoneTab, setSelectedZoneTab] = useState(0);
  const [showDeleteZCModal, setShowDeleteZCModal] = useState(false);
  const [configTabs, setConfigTabs] = useState<Array<ConfigTabsState>>(tabInitValues);

  const resetConfigTab = (tabIndexToBeReset: number) => {
    let defaultConfig: any = {
      ...DEFAULT_CONFIG_TAB,
    };

    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndexToBeReset] = { ...defaultConfig, zoneName: `Zone01` };
    });

    setConfigTabs(newTabs);
  };

  const removeZoneTabAfterDeletion = (tabIndexToBeDeleted: number) => {
    if (configTabs.length > 1) {
      let newTabs = produce(configTabs, (draft) => {
        draft.splice(tabIndexToBeDeleted, 1);
      });
      setSelectedZoneTab(configTabs.length - 2);
      setConfigTabs(newTabs);
    } else {
      resetConfigTab(tabIndexToBeDeleted);
    }
  };

  /**
   * Method to delete the zone config
   */
  const deleteConfigTab = async (tabIndexToBeDeleted: number) => {
    let zoneId: any = configTabs[tabIndexToBeDeleted].zoneId;

    // If the tabs config is being saved at the backend, call the API to delete
    if (zoneId) {
      const {
        payload: { data, message: deleteError },
      } = await dispatch(
        deleteZoneConfig({
          serviceTypeName,
          zoneConfigId: configTabs[tabIndexToBeDeleted].zoneId,
          posNo: posNo,
        })
      );
      setShowDeleteZCModal(false);

      if (data === true) {
        CustomAlert(ALERT_TYPES.SUCCESS, "Zone configuration deleted successfully!");
        removeZoneTabAfterDeletion(tabIndexToBeDeleted);
      } else {
        if (deleteError) {
          CustomAlert(ALERT_TYPES.ERROR, deleteError);
          return;
        }
      }
    } else if (!zoneId) {
      // If the tab data is not yet saved at the backend, no need to call backend API
      removeZoneTabAfterDeletion(tabIndexToBeDeleted);
      setShowDeleteZCModal(false);
    }
  };

  const handleAddZoneTabClick = () => {
    let newConfigTab = {
      ...DEFAULT_CONFIG_TAB,
      zoneName: createNextZoneName(configTabs),
    };
    let newTabs: any = [...configTabs, newConfigTab];

    setConfigTabs(newTabs);
    setSelectedZoneTab(configTabs.length);
  };

  const handleAddOtherDaysClick = (tabIndex: number, e: any, objectKey: any) => {
    e.stopPropagation();

    let newForm: any = {
      ...DEFAULT_RANGE_GENERATOR_FORM_VALUES,
    };

    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].slotsGeneratorForms[objectKey as keyof slotsGeneratorFormsnterface].push(newForm);
    });
    setConfigTabs(newTabs);

    setTimeout(() => {
      executeScroll(rangeGeneratorFormRef);
    }, 100);
  };

  const handleDaysChange = (tabIndex: number, generatorFormIndex: number, value: any, objectKey: any) => {
    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].slotsGeneratorForms[objectKey as keyof slotsGeneratorFormsnterface][generatorFormIndex].days = value;
    });
    setConfigTabs(newTabs);
  };

  const handleRangeGeneratorFieldsChange = (tabIndex: number, generatorFormIndex: number, event: any, objectKey: any) => {
    const { name, value } = event.target;
    if (value !== "" && name === "defaultSlotCapacity" && !reg.test(value)) {
      return;
    }
    let newTabs: any = produce(configTabs, (draft: any) => {
      draft[tabIndex].slotsGeneratorForms[objectKey as keyof slotsGeneratorFormsnterface][generatorFormIndex][name] = value;
    });
    setConfigTabs(newTabs);
  };

  const handleGenerateRangeSubmit = (tabIndex: number, formIndex: number, objectKey: any) => {
    let { minimumDays, maximumDays, defaultSlotCapacity, bufferTime } = configTabs[tabIndex].slotsGeneratorForms[objectKey as keyof slotsGeneratorFormsnterface][formIndex];
    if (parseInt(minimumDays) >= parseInt(maximumDays)) {
      CustomAlert(ALERT_TYPES.ERROR, "Minimum days cannot be same/greater than maximum days");
    } else {
      let range: Array<any> = [];

      range.push({
        dateRange: `${minimumDays} - ${maximumDays}`,
        capacity: defaultSlotCapacity,
        bufferTime: bufferTime,
      });

      let newTabs = produce(configTabs, (draft) => {
        draft[tabIndex].slotsGeneratorForms[objectKey as keyof slotsGeneratorFormsnterface][formIndex].generatedRange = range;
      });

      setConfigTabs(newTabs);
    }
  };

  const handleAssignedAreas = (configTabIndex: number, assignedAreas: any) => {
    let newTabs = produce(configTabs, (draft) => {
      draft[configTabIndex].selectedAreas = assignedAreas.map((area: any) => area.id);
    });
    setConfigTabs(newTabs);
  };

  const renderAssignedAreas = (configTab: any, configTabIndex: number) => {
    return <AreaTransferList selectedAreas={configTab.selectedAreas} handleAssignedAreaListChange={(assignedAreas: any) => handleAssignedAreas(configTabIndex, assignedAreas)} />;
  };

  const cancelEditingASlot = (tabIndex: number, generatorFormIndex: number, index: number, slot: any, objectKey: any) => {
    if (!slot || !slot.id) return;
    dispatch(makeDefaultTemplateSlotEditable({ serviceTypeName, slotData: slot, canBeUpdated: false, objectKey }));

    let unEditedSlotFromStore =
      defaultService.length &&
      defaultService[tabIndex] &&
      defaultService[tabIndex].supplierTypes &&
      defaultService[tabIndex].supplierTypes[objectKey] &&
      defaultService[tabIndex].supplierTypes[objectKey].dayWiseSlots &&
      defaultService[tabIndex].supplierTypes[objectKey].dayWiseSlots[slot.day].filter((item: any) => item.id === slot.id)[0];

    let newTabs = produce(configTabs, (draft: any) => {
      draft[tabIndex].slotsGeneratorForms[objectKey as keyof slotsGeneratorFormsnterface][unEditedSlotFromStore.day] = [{ ...unEditedSlotFromStore }];
    });

    setConfigTabs(newTabs);
  };

  const deleteConfigureSlotsOption = (tabIndex: any, objectKey: any, slotGenertorFormIndex: any) => {
    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].slotsGeneratorForms[objectKey as keyof slotsGeneratorFormsnterface].splice(slotGenertorFormIndex, 1);
    });
    setConfigTabs(newTabs);
  };
  const renderRangeGeneratorForm = (configTab: any, configTabIndex: number, generatorFormIndex: number, objectKey: any) => {
    let slotDetailsData = configTabs[configTabIndex].slotsGeneratorForms[objectKey as keyof slotsGeneratorFormsnterface][generatorFormIndex];
    let remainingDays = calculateAvailableDaysForSelection(generatorFormIndex, configTab, objectKey);

    return (
      <RangeGeneratorForm
        tabIndex={configTabIndex}
        formIndex={generatorFormIndex}
        remainingDays={remainingDays}
        days={slotDetailsData.days}
        minimumDays={slotDetailsData.minimumDays}
        maximumDays={slotDetailsData.maximumDays}
        bufferTimeHours={slotDetailsData.bufferTime}
        defaultSlotCapacity={slotDetailsData.defaultSlotCapacity}
        generatedRange={slotDetailsData.generatedRange}
        onDaysChange={handleDaysChange}
        onGenerateRangeSubmit={handleGenerateRangeSubmit}
        onRangeGeneratorFieldsChange={handleRangeGeneratorFieldsChange}
        handleResetSlotsClick={(tabIndex: number, formIndex: number) => {
          let newTabs: any = produce(configTabs, (draft: any) => {
            draft[tabIndex].slotsGeneratorForms[objectKey as keyof slotsGeneratorFormsnterface][formIndex] = DEFAULT_RANGE_GENERATOR_FORM_VALUES;
          });

          setConfigTabs(newTabs);
        }}
        onIndividualRangeFieldsChange={(tabIndex: number, formIndex: number, rangeIndex: number, event: any) => {
          const { name, value } = event.target;
          if (value !== "" && name === "capacity" && !reg.test(value)) {
            return;
          }
          let newTabs: any = produce(configTabs, (draft: any) => {
            draft[tabIndex].slotsGeneratorForms[objectKey as keyof slotsGeneratorFormsnterface][formIndex].generatedRange[rangeIndex][name] = value;
            if (name === "capacity") {
              draft[tabIndex].slotsGeneratorForms[objectKey as keyof slotsGeneratorFormsnterface][formIndex].defaultSlotCapacity = value;
            }
          });
          setConfigTabs(newTabs);
        }}
        objectKey={objectKey}
        deleteConfigureSlotsOption={deleteConfigureSlotsOption}
        generatorFormIndex={generatorFormIndex}
        configTabIndex={configTabIndex}
      />
    );
  };

  const renderRangeGeneratorFormForUpdate = (configTab: any, configTabIndex: number, objectKey: any) => {
    const slotDetailsData = configTab.slotsGeneratorForms[objectKey as keyof slotsGeneratorFormsnterface];
    const isEditMode = !!configTab.zoneId;
    let dayWiseSlots = isEditMode
      ? defaultService &&
        defaultService.length &&
        defaultService.length > 0 &&
        defaultService[configTabIndex] &&
        defaultService[configTabIndex].supplierTypes &&
        defaultService[configTabIndex].supplierTypes[objectKey] &&
        defaultService[configTabIndex].supplierTypes[objectKey].dayWiseSlots
      : null;
    return (
      <EditSlotRange
        tabIndex={configTabIndex}
        formIndex={0}
        generatedSlots={slotDetailsData}
        dayWiseSlots={dayWiseSlots}
        makeSlotEditable={(slot: any) => {
          if (!slot || !slot.id) return;
          dispatch(makeDefaultTemplateSlotEditable({ serviceTypeName, slotData: slot, canBeUpdated: true, objectKey }));
        }}
        cancelEditingASlot={(slotIndex: number, slot: any) => {
          cancelEditingASlot(configTabIndex, 0, slotIndex, slot, objectKey);
        }}
        onIndividualRangeFieldsChange={(tabIndex: number, formIndex: number, generatedSlots: any) => {
          let newTabs: any = produce(configTabs, (draft: any) => {
            draft[tabIndex].slotsGeneratorForms[objectKey as keyof slotsGeneratorFormsnterface] = generatedSlots;
          });
          setConfigTabs(newTabs);
        }}
        handleUpadteConfig={async (slotIndex: number, slot: any) => {
          const responseValue = validateUpdateSlotConfigObject(slot);
          if (!responseValue) {
            let data = await updateTemplateSlotHandler(configTabIndex, 0, slotIndex, slot, serviceTypeName, serviceTypeId, dispatch, objectKey);
            if (
              data &&
              data.day &&
              configTabs &&
              configTabs[configTabIndex] &&
              configTabs[configTabIndex].slotsGeneratorForms &&
              configTabs[configTabIndex].slotsGeneratorForms[objectKey as keyof slotsGeneratorFormsnterface] &&
              configTabs[configTabIndex].slotsGeneratorForms[objectKey as keyof slotsGeneratorFormsnterface][data.day] &&
              configTabs[configTabIndex].slotsGeneratorForms[objectKey as keyof slotsGeneratorFormsnterface][data.day]
            ) {
              let newTabs = produce(configTabs, (draft: any) => {
                // draft[configTabIndex].slotsGeneratorForms[objectKey as keyof slotsGeneratorFormsnterface][0].generatedSlots[slot.day] = [
                draft[configTabIndex].slotsGeneratorForms[objectKey as keyof slotsGeneratorFormsnterface][data.day] = [
                  {
                    ...data,
                  },
                ];
              });
              setConfigTabs(newTabs);
            }
          }
        }}
      />
    );
  };

  const capitalizeFirstLetter = (nameString: any) => {
    return nameString.charAt(0).toUpperCase() + nameString.slice(1);
  };

  /* *****
  Rendering Delivery Type
  name could be "Carrefour Delivery"
  and objectKey could be "carrefour"
  */
  const renderConfigureSlotsAccordion = (configTab: any, configTabIndex: number, objectKey: any) => {
    const name = capitalizeFirstLetter(objectKey) + " Delivery";
    return (
      <Accordion defaultExpanded={false} className={classes.accordionStyle}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummaryStyle}>
          <Grid container alignItems="center">
            <Grid>
              <Typography className={classes.accordionTitle}>{capitalizeFirstLetter(name)}</Typography>
            </Grid>
          </Grid>
          {configTab &&
            configTab.slotsGeneratorForms &&
            configTab.slotsGeneratorForms[objectKey as keyof slotsGeneratorFormsnterface] &&
            Array.isArray(configTab.slotsGeneratorForms[objectKey as keyof slotsGeneratorFormsnterface]) && (
              <Grid item xs={6} className={classes.addOtherDays}>
                <LinkButton
                  buttonLabel={"Add Other Days"}
                  disabled={isAddOtherDaysButtonDisabled(configTabs, configTabIndex, objectKey)}
                  onClick={(e: any) => handleAddOtherDaysClick(configTabIndex, e, objectKey)}
                />
              </Grid>
            )}
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetailsStyle}>
          {configTab &&
          configTab.slotsGeneratorForms &&
          configTab.slotsGeneratorForms[objectKey as keyof slotsGeneratorFormsnterface] &&
          Array.isArray(configTab.slotsGeneratorForms[objectKey as keyof slotsGeneratorFormsnterface])
            ? Object.keys(configTab.slotsGeneratorForms[objectKey as keyof slotsGeneratorFormsnterface]).map((slotGeneratorForm: any, generatorFormIndex: number) => (
                <div key={`renderConfigureSlotsAccordion-slotGeneratorForm-${generatorFormIndex}`} ref={rangeGeneratorFormRef}>
                  {configTabs[configTabIndex] && configTabs[configTabIndex].slotsGeneratorForms && configTabs[configTabIndex].slotsGeneratorForms[objectKey as keyof slotsGeneratorFormsnterface] ? (
                    renderRangeGeneratorForm(configTab, configTabIndex, generatorFormIndex, objectKey)
                  ) : (
                    <></>
                  )}
                </div>
              ))
            : renderRangeGeneratorFormForUpdate(configTab, configTabIndex, objectKey)}
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderZoneConfigurationsCard = () => {
    return (
      <Card className={classes.cards}>
        <CardContent className={classes.zoneConfigContainer}>
          <TabContext value={selectedZoneTab.toString()}>
            <Grid container alignItems="center" className={classes.borderBelowTabs}>
              <Grid item xs={9}>
                <Tabs
                  variant="scrollable"
                  value={selectedZoneTab}
                  onChange={(e: any, tabIndex: number) => {
                    setSelectedZoneTab(tabIndex);
                  }}
                >
                  {configTabs.map((configTab, configTabIndex) => (
                    <Tab
                      id={`config-tab-${configTabIndex}`}
                      value={configTabIndex}
                      key={configTabIndex}
                      disableRipple
                      classes={{
                        labelIcon: classes.tabLabelIcon,
                      }}
                      label={configTab.zoneName}
                      icon={
                        (configTabs.length === 1 && configTabs[selectedZoneTab].zoneId) || configTabs.length > 1 ? (
                          <RemoveButton
                            className={classes.deleteConfigTabIcon}
                            onClick={(e: any) => {
                              e.stopPropagation();
                              setSelectedZoneTab(configTabIndex);
                              setShowDeleteZCModal(true);
                            }}
                          />
                        ) : (
                          ""
                        )
                      }
                    ></Tab>
                  ))}
                </Tabs>
              </Grid>
              <Grid item xs={3} className={classes.textAlignRight}>
                <LinkButton buttonLabel="Add Zone Configuration" onClick={handleAddZoneTabClick} disabled={!configTabs[selectedZoneTab]?.zoneId} />
              </Grid>
            </Grid>

            {configTabs.map((configTab, configTabIndex) => (
              <TabPanel key={`renderZoneConfigurationsCard-configTab=${configTabIndex}`} className={classes.tabPanel} value={configTabIndex.toString()}>
                {configTab && configTab.slotsGeneratorForms ? (
                  Object.keys(configTab.slotsGeneratorForms).map((deliveyTypeKey: any) => renderConfigureSlotsAccordion(configTab, configTabIndex, deliveyTypeKey))
                ) : (
                  <></>
                )}
                {isPOSMappingAreaBased && renderAssignedAreas(configTab, configTabIndex)}

                <Grid container className={classes.buttonsContainer}>
                  <Grid item xs={6}></Grid>
                  {configTab.zoneId ? (
                    <Grid item xs={6} className={classes.buttonsStyle}>
                      <PrimaryButton
                        buttonLabel={defaultService[selectedZoneTab] && defaultService[selectedZoneTab].isUpdating ? "Updating..." : `Update ${configTabs[selectedZoneTab].zoneName} Configurations`}
                        disabled={!!(defaultService[selectedZoneTab] && defaultService[selectedZoneTab].isUpdating)}
                        onClick={() => handleSaveOrUpdateZoneConfigurationsSubmit(selectedZoneTab, true)}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={6} className={classes.buttonsStyle}>
                      <PrimaryButton
                        buttonLabel={defaultService[selectedZoneTab] && defaultService[selectedZoneTab].isCreating ? "Creating..." : `Save ${configTabs[selectedZoneTab].zoneName} Configurations`}
                        disabled={!!(defaultService[selectedZoneTab] && (defaultService[selectedZoneTab].isCreating || defaultService[selectedZoneTab].isCreated))}
                        onClick={() => handleSaveOrUpdateZoneConfigurationsSubmit(selectedZoneTab)}
                      />
                    </Grid>
                  )}
                </Grid>
              </TabPanel>
            ))}
          </TabContext>
        </CardContent>
      </Card>
    );
  };

  async function handleSaveOrUpdateZoneConfigurationsSubmit(selectedZoneTab: any, isUpdate?: boolean) {
    const responseValue = validateSaveDefaultConfigObject(configTabs, selectedZoneTab, isUpdate);
    if (!responseValue.isValid) {
      let tab = configTabs[selectedZoneTab];

      if (isUpdate) {
        let newTabs = produce(configTabs, (draft) => {
          draft[selectedZoneTab].isUpdating = true;
          draft[selectedZoneTab].zoneId = "empty";
        });
        setConfigTabs(newTabs);
        let configurationsPayload = createPayloadForUpdatedefaultConfig(tab, posDetails.id, serviceTypeId, responseValue.allowedDeliveryTypes);
        updateZoneConfigurations(posNo, serviceTypeName, selectedZoneTab, configurationsPayload, dispatch);
      } else {
        let newTabs = produce(configTabs, (draft) => {
          draft[selectedZoneTab].isCreating = true;
        });
        setConfigTabs(newTabs);
        let configurationsPayload = createPayloadForSavedefaultConfig(tab, posDetails.id, serviceTypeId, posDetails, responseValue.allowedDeliveryTypes);
        saveDefaultZoneConfigurations(posNo, serviceTypeName, selectedZoneTab, configurationsPayload, dispatch);
      }
    }
  }

  const generateSlotForm = useCallback((supplierType: any) => {
    let slotsGeneratorForms: any = {};

    if (supplierType) {
      Object.keys(supplierType).forEach((type: any) => {
        slotsGeneratorForms = {
          ...slotsGeneratorForms,
          [type]: supplierType[type].dayWiseSlots,
        };
      });
    }

    if (slotsGeneratorForms && Object.keys(slotsGeneratorForms) && Object.keys.length !== currentAllowedDeliveryTypes.length) {
      const missingKey = getMissingDeliveryTypeFromObject(slotsGeneratorForms);
      if (missingKey && missingKey.length > 0) {
        missingKey.forEach((element: any) => {
          slotsGeneratorForms = {
            ...slotsGeneratorForms,
            [element]: [
              {
                ...DEFAULT_RANGE_GENERATOR_FORM_VALUES,
                days: ALL_DAYS_VALUES,
              },
            ],
          };
        });
      }
    }

    return slotsGeneratorForms;
  }, []);

  const savedConfigurationsHandler = useCallback(
    (slots: any) => {
      let newConfigTabs = slots.map((slot: any) => ({
        ...slot,
        zoneId: slot.zoneConfig.id,
        zoneName: slot.zoneConfig.zoneName,
        selectedAreas: slot.selectedAreas || [],
        foodTypes: ["NON_FOOD"],
        slotsGeneratorForms: generateSlotForm(slot.supplierTypes),
      }));
      setConfigTabs(newConfigTabs);
    },
    [generateSlotForm]
  );

  useEffect(() => {
    if (
      defaultService.length &&
      defaultService[selectedZoneTab] &&
      !defaultService[selectedZoneTab].createError &&
      !defaultService[selectedZoneTab].isCreating &&
      !defaultService[selectedZoneTab].isUpdating &&
      !defaultService[selectedZoneTab].updateError &&
      configTabs[selectedZoneTab].zoneId !== defaultService[selectedZoneTab].zoneConfig.id
    ) {
      savedConfigurationsHandler(defaultService);
    }
  }, [defaultService, configTabs, selectedZoneTab, savedConfigurationsHandler]);

  useEffect(() => {
    if (posNo && !posDetails.posNo && serviceTypes.length) {
      dispatch(fetchPOSDetailsByPOSNo(posNo));
    }
  }, [dispatch, posNo, serviceTypeId, serviceTypeName, posDetails.posNo, serviceTypes.length]);

  useEffect(() => {
    const fetchSlots = () => {
      dispatch(fetchSlotsInformationByServiceType({ posNo, serviceType: serviceTypeId, serviceTypeName }));
    };
    if (posNo && serviceTypeId) {
      fetchSlots();
    }
  }, [dispatch, posNo, serviceTypeId, serviceTypeName]);

  const renderBasicServiceDtails = () => (
    <BasicServiceDetails
      serviceTypeId={serviceTypeId}
      serviceTypeName={serviceTypeName}
      country={posDetails && posDetails.posAddress && posDetails.posAddress.country}
      region={posDetails && posDetails.posAddress && posDetails.posAddress.region}
    />
  );

  const renderFooterButtons = () => {
    return (
      <Grid container direction="row" className={classes.buttons}>
        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item style={{ display: "flex", justifyContent: "flex-end" }}>
              <PrimaryButton buttonLabel="VIEW SETUP" buttonVariant="outlined" disabled={!configTabs.length} onClick={() => history.push(`${APP_ROUTES.COMPLETE_SETUP}/${posDetails.posNo}`)} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  /**
   * Empty redux state on unmount
   */
  useEffect(() => {
    return () => {
      dispatch(resetSlotsInformation());
    };
  }, [dispatch]);

  return (
    <div>
      <CustomBreadcrumbs links={getPOSBreadCum(SERVICE_TYPES.DEFAULT)} />
      {!fetchingDetails && !fetchingSlots ? (
        <>
          <PageTitle title={`POS - ${posNo}`} />
          {renderBasicServiceDtails()}
          {renderZoneConfigurationsCard()}
          {renderFooterButtons()}
          {
            <ConfirmModal
              show={showDeleteZCModal}
              title="Delete Zone Config"
              description="All the data for the zone configuration would be permanently lost. Do you wish to proceed?"
              confirmButtonText="Approve"
              onClose={() => setShowDeleteZCModal(false)}
              onProceed={() => deleteConfigTab(selectedZoneTab)}
            />
          }
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default DefaultService;
