import React from 'react';

import { IconButton, InputAdornment } from '@material-ui/core';

import searchIcon from '../../assets/svg/SearchIcon.svg';
import TextBox from 'components/common/TextBox';

import { userStyles } from '../Styles/DeliveryFeePageStyles';
import { DeliveryFeeInitialStateInterface } from '../Utils/DeliveryFeeInterface';

interface DeliveryFeeSearchSectionInterface {
    deliveryFeeState : DeliveryFeeInitialStateInterface;
    handleSearchchange : any;
}
const DeliveryFeeSearchSection = (props: DeliveryFeeSearchSectionInterface) => {
    const classes = userStyles();
    const { deliveryFeeState, handleSearchchange } = props;

    return(
        <div className={classes.headerRoot}>
            <div className={classes.header}>
                <TextBox
                    textBoxId="searchTextBox"
                    placeholderText="Quick search by POS No, POS Name"
                    startAdornment={
                        <InputAdornment position="start">
                            <IconButton edge="start" size="small">
                                <img src={searchIcon} alt="search" />
                            </IconButton>
                        </InputAdornment>
                    }
                    value={deliveryFeeState.searchText}
                    handleChange={handleSearchchange}
                    className={classes.searchBoxStyle}
                ></TextBox>
            </div>

            <></>
    </div>
    )
}

export default React.memo(DeliveryFeeSearchSection);