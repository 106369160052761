import { Table, TableContainer, TableFooter, TablePagination, TableRow } from "@material-ui/core";
import React from "react";

import { userStyles } from "../Styles/DeliveryFeePageStyles";

import DeliveryFeeCardHeader from "./DeliveryFeeCardHeader";
import DeliveryFeeCardBody from "./DeliveryFeeCardBody";

interface DeliveryFeeCardSectionInterface {
  deliveryFeeHeaderData: any;
  deliveryFeeListData: any;
  pageSize: any;
  page: any;
  rowCount: any;
  fetchNextRecord: any;
  userInfo: any;
}

const DeliveryFeeCardSection = (props: DeliveryFeeCardSectionInterface) => {
  const classes = userStyles();
  const { deliveryFeeHeaderData, deliveryFeeListData, pageSize, page, rowCount, fetchNextRecord, userInfo } = props;
  return (
    <div className={classes.cardRoot}>
      <TableContainer className={classes.tableContainerStyle}>
        <Table padding="none" size="small" stickyHeader={true} className={classes.root}>
          <DeliveryFeeCardHeader deliveryFeeHeaderData={deliveryFeeHeaderData} />
          <DeliveryFeeCardBody deliveryFeeListData={deliveryFeeListData} deliveryFeeHeaderData={deliveryFeeHeaderData} userInfo={userInfo} />
        </Table>
        <TableFooter>
          <TableRow>
            {deliveryFeeListData && deliveryFeeListData.length > 0 && (
              <TablePagination
                className={classes.tablePaginationStyle}
                colSpan={
                  deliveryFeeHeaderData && deliveryFeeHeaderData.headerRowsValues && deliveryFeeHeaderData.headerRowsValues.length > 0 ? 4 + deliveryFeeHeaderData.headerRowsValues.length * 3 : 4
                }
                rowsPerPageOptions={[pageSize]}
                count={rowCount}
                rowsPerPage={pageSize}
                page={page}
                onPageChange={(event, pageNumber) => {
                  fetchNextRecord && fetchNextRecord(event, pageNumber);
                }}
              />
            )}
          </TableRow>
        </TableFooter>
      </TableContainer>
    </div>
  );
};

export default React.memo(DeliveryFeeCardSection);
