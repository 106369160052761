import React from "react";

import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { SHIPMENT_LEVEL_DELIVERY_FEE } from "../../Utils/DeliveryFeeConstants";
import AdditionalDeliveryFeeConfigList from "./AdditionalDeliveryFeeConfigList";
import AdditionalCapacityUtilisationConfigList from "./AdditionalCapacityUtilisationConfigList";

interface ShipmentLevelPreviewConfigurationInterface {
  classes: any;
  state: any;
}

const ShipmentLevelPreviewConfiguration = (props: ShipmentLevelPreviewConfigurationInterface) => {
  const { classes, state } = props;

  return (
    <Accordion defaultExpanded={true} className={classes.accordionStyle}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummaryStyle}>
        <Grid container alignItems="center">
          <Grid>
            <Typography className={classes.accordionTitle}>{SHIPMENT_LEVEL_DELIVERY_FEE}</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetailsStyle}>
        {state && state.shipmentLevel && Object.keys(state.shipmentLevel).length > 0 ? (
          Object.keys(state.shipmentLevel).map(
            (ele: any, index) =>
              state.shipmentLevel[ele].isEditMode && (
                <Grid container className={classes.previewServiceDivStyle}>
                  <Grid container className={classes.ServiceTypeMainDivStyle}>
                    <Grid>
                      <Typography className={classes.dlvFeeServiceTypeTextStyle}>{ele}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={3} className={classes.dlvFeeValueDisplayDivStyle}>
                      <Typography className={classes.dlvFeeValueDisplayTitleStyle}>Less than (Threshold Value)</Typography>
                      <Typography className={classes.dlvFeeValueDisplayDataStyle}>{state.shipmentLevel[ele].orderThreshold}</Typography>
                    </Grid>

                    <Grid item xs={3} className={classes.dlvFeeValueDisplayDivStyle}>
                      <Typography className={classes.dlvFeeValueDisplayTitleStyle}>Delivery Fees Below Threshold</Typography>
                      <Typography className={classes.dlvFeeValueDisplayDataStyle}>{state.shipmentLevel[ele].belowThreshold}</Typography>
                    </Grid>

                    <Grid item xs={3} className={classes.dlvFeeValueDisplayDivStyle}>
                      {""}
                    </Grid>

                    <Grid item xs={3} className={classes.dlvFeeValueDisplayDivStyle}>
                      <Typography className={classes.dlvFeeValueDisplayTitleStyle}>Status</Typography>
                      <Typography className={classes.dlvFeeValueDisplayDataStyle}>{state.shipmentLevel[ele].active === true ? "Active" : "In-Active"}</Typography>
                    </Grid>
                  </Grid>

                  {state.shipmentLevel[ele] &&
                  state.shipmentLevel[ele].deliveryFeeRangeConfigDTOList &&
                  Array.isArray(state.shipmentLevel[ele].deliveryFeeRangeConfigDTOList) &&
                  state.shipmentLevel[ele].deliveryFeeRangeConfigDTOList.length > 0 ? (
                    state.shipmentLevel[ele].deliveryFeeRangeConfigDTOList.map((rangeObject: any, rangeObjectIndex: any) => (
                      <Grid container key={`${rangeObjectIndex}-deliveryFeeRangeConfigDTOList-multiShipmentLevel-${ele}`}>
                        <Grid item xs={3} className={classes.dlvFeeValueDisplayDivStyle}>
                          <Typography className={classes.dlvFeeValueDisplayTitleStyle}>Greater than or Equals to (Threshold Value)</Typography>
                          <Typography className={classes.dlvFeeValueDisplayDataStyle}>{rangeObject.previousOrderThreshold}</Typography>
                        </Grid>

                        <Grid item xs={3} className={classes.dlvFeeValueDisplayDivStyle}>
                          <Typography className={classes.dlvFeeValueDisplayTitleStyle}>Less than (Threshold Value)</Typography>
                          <Typography className={classes.dlvFeeValueDisplayDataStyle}>{rangeObject.newOrderThreshold}</Typography>
                        </Grid>

                        <Grid item xs={3} className={classes.dlvFeeValueDisplayDivStyle}>
                          <Typography className={classes.dlvFeeValueDisplayTitleStyle}>Delivery Fees</Typography>
                          <Typography className={classes.dlvFeeValueDisplayDataStyle}>{rangeObject.deliveryFee}</Typography>
                        </Grid>
                      </Grid>
                    ))
                  ) : (
                    <></>
                  )}

                  <Grid container>
                    <Grid item xs={3} className={classes.dlvFeeValueDisplayDivStyle}>
                      <Typography className={classes.dlvFeeValueDisplayTitleStyle}>Greater than or Equals to (Threshold Value)</Typography>
                      <Typography className={classes.dlvFeeValueDisplayDataStyle}>{state.shipmentLevel[ele].greaterOrderThresold}</Typography>
                    </Grid>

                    <Grid item xs={3} className={classes.dlvFeeValueDisplayDivStyle}>
                      <Typography className={classes.dlvFeeValueDisplayTitleStyle}>Delivery Fees Above Threshold</Typography>
                      <Typography className={classes.dlvFeeValueDisplayDataStyle}>{state.shipmentLevel[ele].aboveThreshold}</Typography>
                    </Grid>
                  </Grid>

                  {state.shipmentLevel[ele] &&
                    state.shipmentLevel[ele].additionalDeliveryFeeConfigDTOList &&
                    Array.isArray(state.shipmentLevel[ele].additionalDeliveryFeeConfigDTOList) &&
                    state.shipmentLevel[ele].additionalDeliveryFeeConfigDTOList.length > 0 && (
                      <AdditionalDeliveryFeeConfigList feeConfigList={state.shipmentLevel[ele].additionalDeliveryFeeConfigDTOList} classes={classes} />
                    )}

                  {state.shipmentLevel[ele] &&
                    state.shipmentLevel[ele].deliveryFeeSlotCapacityConfigDTOList &&
                    Array.isArray(state.shipmentLevel[ele].deliveryFeeSlotCapacityConfigDTOList) &&
                    state.shipmentLevel[ele].deliveryFeeSlotCapacityConfigDTOList.length > 0 && (
                      <AdditionalCapacityUtilisationConfigList feeConfigList={state.shipmentLevel[ele].deliveryFeeSlotCapacityConfigDTOList} classes={classes} />
                    )}
                </Grid>
              )
          )
        ) : (
          <div> No Data Available.</div>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
export default React.memo(ShipmentLevelPreviewConfiguration);
