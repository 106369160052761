import React from "react";

import { Checkbox, FormControlLabel, Grid, Typography } from "@material-ui/core";

import TextBoxWithLabel from "../../../common/TextBoxWithLabel/TextBoxWithLabel";
import SwitchButton from "../../../common/SwitchButton/SwitchButton";
import Pencil from "../../../assets/svg/Pencil.svg";
import { SERVICE_TYPE, STANDARD_SERVICE_TYPE } from "../../Utils/DeliveryFeeConstants";

import { AddConfigurationsText, AddThresoldRangeText } from "../utils/DeliveryFeeConfigViewUtils";
import AdditionalDeliveryFeeConfigList from "./AdditionalDeliveryFeeConfigList";
import ThresholdRangeDeliveryFeeView from "./ThresholdRangeDeliveryFeeView";
import AdditionalCapacityUtilisationConfigList from "./AdditionalCapacityUtilisationConfigList";

interface ConfigurationCommonInputViewInterface {
  classes: any;
  name: any;
  multiShipmentLevel: any;
  stateObj: any;
  stateLevelIndex: any;
  _handle_calculation_toggle_change: any;
  handleInputChange: any;
  _handle_click_add_threshold_range: any;
  _handle_click_delete_threshold_range: any;
  handleRangeInputChange: any;
  _handle_open_configuration_dialog: any;
  updateConfigPosId: any;
  updateConfigZoneName: any;
  _handle_calculation_active_toggle_change: any;
  _handle_edit_update_calculation_toggle_change: any;
  removeTimeConfigRecord: any;
  handleAdditionalConfigInputChange: any;
  _handle_edit_toggle_additional_calculation_toggle_change: any;
  _handle_edit_threshold_rane_toggle_change_enable_Edit: any;
  handleServiceType: any;
  handleCapacityUtilisationConfigInputChange: any;
  _handle_edit_toggle_capacity_utilisation_toggle_change: any;
}

const ConfigurationCommonInputView = (props: ConfigurationCommonInputViewInterface) => {
  const {
    classes,
    name,
    multiShipmentLevel,
    stateObj,
    stateLevelIndex,
    _handle_calculation_toggle_change,
    handleInputChange,
    _handle_click_add_threshold_range,
    _handle_click_delete_threshold_range,
    handleRangeInputChange,
    _handle_open_configuration_dialog,
    updateConfigPosId,
    updateConfigZoneName,
    _handle_calculation_active_toggle_change,
    _handle_edit_update_calculation_toggle_change,
    removeTimeConfigRecord,
    handleAdditionalConfigInputChange,
    _handle_edit_toggle_additional_calculation_toggle_change,
    _handle_edit_threshold_rane_toggle_change_enable_Edit,
    handleServiceType,
    handleCapacityUtilisationConfigInputChange,
    _handle_edit_toggle_capacity_utilisation_toggle_change,
  } = props;

  return (
    <Grid container className={classes.dlvFeeServiceTypeDiv} key={`${stateLevelIndex}-inner-object`}>
      <Grid container>
        <Grid item xs={2} className={classes.dlvFeeServiceTypeTextDivStyle}>
          <Typography className={classes.dlvFeeServiceTypeTextStyle}>{name || SERVICE_TYPE}</Typography>
        </Grid>
        <Grid item xs={10} className={classes.marginAuto}>
          <Grid container justifyContent="flex-end">
            {updateConfigPosId === "" && updateConfigZoneName === "" ? (
              <>
                <Grid item xs={5} className={classes.useCalculationDivStyle}>
                  <Typography className={classes.dlvFeeCalculationTextStyle}>Use this value in calculation</Typography>
                </Grid>
                <Grid item xs={3}>
                  <SwitchButton
                    className={stateObj.isEditMode ? classes.switchStyle : classes.disableSwitch}
                    size="small"
                    checked={stateObj.isEditMode}
                    disabled={false}
                    handleChange={(e: any) => _handle_calculation_toggle_change(e, multiShipmentLevel, name)}
                    leftLabel={undefined}
                    rightLabel={undefined}
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={5} className={classes.useCalculationDivStyle}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography className={classes.dlvFeeCalculationTextStyle}>Inactive</Typography>
                    </Grid>
                    <Grid item xs={1} className={classes.marginAuto}>
                      <SwitchButton
                        leftLabel=""
                        rightLabel=""
                        size="small"
                        checked={stateObj.active}
                        disabled={false}
                        handleChange={(e: any) => _handle_calculation_active_toggle_change(e, multiShipmentLevel, name)}
                      />
                    </Grid>
                    <Grid item xs={5} className={classes.textAlignLeft}>
                      <Typography className={classes.dlvFeeCalculationTextStyle}>Active</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  {""}
                </Grid>
              </>
            )}
            {stateObj && stateObj.isEditMode === true && name && name !== "" ? (
              <Grid item xs={4}>
                <AddConfigurationsText
                  classes={classes}
                  name={name}
                  multiShipmentLevel={multiShipmentLevel}
                  orderThreshold={stateObj.orderThreshold}
                  belowThreshold={stateObj.belowThreshold}
                  aboveThreshold={stateObj.aboveThreshold}
                  _handle_open_configuration_dialog={_handle_open_configuration_dialog}
                />
              </Grid>
            ) : (
              <Grid item xs={4}>
                {""}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {stateObj.isEditMode && (
        <>
          {name !== undefined && name === STANDARD_SERVICE_TYPE && stateObj.updateDrawerConfig !== undefined && stateObj.updateDrawerConfig === false && (
            <Grid container className={classes.drawerCheckBoxInputMainDiv}>
              <Grid item xs={3} className={classes.thresholdInputDiv}>
                <FormControlLabel
                  className={classes.serviceTypeItem}
                  key={name}
                  control={<Checkbox checked={stateObj.showDrawerConfig} value={stateObj.showDrawerConfig} onChange={(e) => handleServiceType(e, "showDrawerConfig", multiShipmentLevel, name)} />}
                  label={<Typography className={classes.checkboxLabel}>{"Show Drawer"}</Typography>}
                />
              </Grid>
            </Grid>
          )}

          <Grid container className={classes.thresholdInputMainDiv}>
            <Grid item xs={3} className={classes.thresholdInputDiv}>
              <TextBoxWithLabel
                label={"Less than (Threshold Value)"}
                name="orderThreshold"
                value={stateObj.orderThreshold}
                placeholderText="Less than (Threshold Value) "
                handleChange={(e: any) => handleInputChange(e, multiShipmentLevel, name)}
                type="number"
                className={classes.rightPaddingStyle}
                disabled={
                  stateObj.enableBelowThresholdEdit === false
                    ? stateObj && stateObj.deliveryFeeRangeConfigDTOList && Array.isArray(stateObj.deliveryFeeRangeConfigDTOList) && stateObj.deliveryFeeRangeConfigDTOList.length > 0
                      ? true
                      : false
                    : true
                }
              ></TextBoxWithLabel>
            </Grid>

            <Grid item xs={3} className={classes.thresholdInputDiv}>
              <TextBoxWithLabel
                label={"Delivery Fees Below Threshold"}
                name="belowThreshold"
                value={stateObj.belowThreshold}
                placeholderText="Delivery Fees Below Threshold"
                handleChange={(e: any) => handleInputChange(e, multiShipmentLevel, name)}
                type="number"
                className={classes.rightPaddingStyle}
                disabled={stateObj.enableBelowThresholdEdit}
              ></TextBoxWithLabel>
            </Grid>

            <Grid item xs={1} className={classes.actionIconDivStyle}>
              {!multiShipmentLevel && updateConfigPosId !== "" && updateConfigZoneName !== "" && stateObj.enableBelowThresholdEdit === true ? (
                <img
                  src={Pencil}
                  alt="Edit"
                  className={classes.actionIconStyle}
                  onClick={(e: any) => _handle_edit_update_calculation_toggle_change(e, multiShipmentLevel, name, "enableBelowThresholdEdit")}
                />
              ) : (
                ""
              )}
            </Grid>

            <Grid item xs={2}>
              {stateObj && stateObj.deliveryFeeRangeConfigDTOList && Array.isArray(stateObj.deliveryFeeRangeConfigDTOList) && stateObj.deliveryFeeRangeConfigDTOList.length === 0 ? (
                <AddThresoldRangeText
                  classes={classes}
                  name={name}
                  multiShipmentLevel={multiShipmentLevel}
                  orderThreshold={stateObj.orderThreshold}
                  belowThreshold={stateObj.belowThreshold}
                  aboveThreshold={stateObj.aboveThreshold}
                  _handle_click_add_threshold_range={_handle_click_add_threshold_range}
                />
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={3} className={classes.drawerCheckBoxDiv}>
              {name !== undefined && name === STANDARD_SERVICE_TYPE ? (
                <Grid item xs={12} className={classes.thresholdInputDiv}>
                  <FormControlLabel
                    className={classes.serviceTypeItem}
                    key={name}
                    control={
                      <Checkbox checked={stateObj.updateDrawerConfig} value={stateObj.updateDrawerConfig} onChange={(e) => handleServiceType(e, "updateDrawerConfig", multiShipmentLevel, name)} />
                    }
                    label={<Typography className={classes.checkboxLabel}>{"Use existing drawer configuration"}</Typography>}
                  />
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </Grid>

          {stateObj && stateObj.deliveryFeeRangeConfigDTOList && Array.isArray(stateObj.deliveryFeeRangeConfigDTOList) && stateObj.deliveryFeeRangeConfigDTOList.length > 0 ? (
            <ThresholdRangeDeliveryFeeView
              classes={classes}
              name={name}
              multiShipmentLevel={multiShipmentLevel}
              deliveryFeeRangeConfigDTOList={stateObj.deliveryFeeRangeConfigDTOList}
              handleRangeInputChange={handleRangeInputChange}
              _handle_click_add_threshold_range={_handle_click_add_threshold_range}
              _handle_click_delete_threshold_range={_handle_click_delete_threshold_range}
              updateConfigPosId={updateConfigPosId}
              updateConfigZoneName={updateConfigZoneName}
              _handle_edit_threshold_rane_toggle_change_enable_Edit={_handle_edit_threshold_rane_toggle_change_enable_Edit}
            />
          ) : (
            <></>
          )}

          <Grid container className={classes.thresholdInputMainDiv}>
            <Grid item xs={3} className={classes.thresholdInputDiv}>
              <TextBoxWithLabel
                label={"Greater than or Equals to (Threshold Value)"}
                name="greaterOrderThresold"
                value={stateObj.greaterOrderThresold}
                placeholderText="Greater than or Equals to (Threshold Value)"
                handleChange={(e: any) => handleInputChange(e, multiShipmentLevel, name)}
                type="number"
                className={classes.rightPaddingStyle}
                disabled={true}
              ></TextBoxWithLabel>
            </Grid>

            <Grid item xs={3} className={classes.thresholdInputDiv}>
              <TextBoxWithLabel
                label={"Delivery Fees Above Threshold"}
                name="aboveThreshold"
                value={stateObj.aboveThreshold}
                placeholderText="Delivery Fees Above Threshold"
                handleChange={(e: any) => handleInputChange(e, multiShipmentLevel, name)}
                type="number"
                className={classes.rightPaddingStyle}
                disabled={stateObj.enableAboveThresholdEdit}
              ></TextBoxWithLabel>
            </Grid>

            <Grid item xs={1} className={classes.actionIconDivStyle}>
              {!multiShipmentLevel && updateConfigPosId !== "" && updateConfigZoneName !== "" ? (
                stateObj.enableAboveThresholdEdit === true ? (
                  <img
                    src={Pencil}
                    alt="Edit"
                    className={classes.actionIconStyle}
                    onClick={(e: any) => _handle_edit_update_calculation_toggle_change(e, multiShipmentLevel, name, "enableAboveThresholdEdit")}
                  />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </Grid>

            <Grid item xs={5}>
              {""}
            </Grid>
          </Grid>

          <Grid container className={classes.addConfigurationInputDivStyle}>
            {stateObj && stateObj.additionalDeliveryFeeConfigDTOList && Array.isArray(stateObj.additionalDeliveryFeeConfigDTOList) && stateObj.additionalDeliveryFeeConfigDTOList.length > 0 && (
              <AdditionalDeliveryFeeConfigList
                feeConfigList={stateObj.additionalDeliveryFeeConfigDTOList}
                classes={classes}
                stateObj={stateObj}
                shipment_name={name}
                multiShipmentLevel={multiShipmentLevel}
                removeTimeConfigRecord={removeTimeConfigRecord}
                handleAdditionalConfigInputChange={handleAdditionalConfigInputChange}
                _handle_edit_toggle_additional_calculation_toggle_change={_handle_edit_toggle_additional_calculation_toggle_change}
              />
            )}
          </Grid>

          <Grid container className={classes.addConfigurationInputDivStyle}>
            {stateObj && stateObj.deliveryFeeSlotCapacityConfigDTOList && Array.isArray(stateObj.deliveryFeeSlotCapacityConfigDTOList) && stateObj.deliveryFeeSlotCapacityConfigDTOList.length > 0 && (
              <AdditionalCapacityUtilisationConfigList
                feeConfigList={stateObj.deliveryFeeSlotCapacityConfigDTOList}
                classes={classes}
                shipment_name={name}
                multiShipmentLevel={multiShipmentLevel}
                handleCapacityUtilisationConfigInputChange={handleCapacityUtilisationConfigInputChange}
                _handle_edit_toggle_capacity_utilisation_toggle_change={_handle_edit_toggle_capacity_utilisation_toggle_change}
              />
            )}
          </Grid>
        </>
      )}
      {stateObj && stateObj.configuration && Array.isArray(stateObj.configuration) && stateObj.configuration.length > 0 ? <Grid container className={classes.dlvFeeServiceTypeDiv}></Grid> : <></>}
    </Grid>
  );
};

export default React.memo(ConfigurationCommonInputView);
