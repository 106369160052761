import { Button, Card, CardContent, Divider, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import { DAYS_DROPDOWN_OPTIONS, SERVICE_TYPES, SERVICE_TYPES_KEYS } from "../../config/constants";
import Loader from "../common/Loader/Loader";
import MultiSelectBox from "../common/MultiSelectBox/MultiSelectBox";
import CountryDefaultTypeDatePicker from "./CountryDefaultTypeDatePicker";
import { ReactComponent as DeleteSlotIcon } from "../assets/svg/DeleteIcon.svg";

interface DefaultServiceTypeDetailsProps {
  classes: any;
  defaultDateState: any;
  handleDateChangeEvent: any;
  handleCancel: any;
  handleDetailsChange: any;
  defaultDetails: any;
  handleCalendarHoliday: any;
  calendarHoliday: any;
  displayHolidays: any;
  displayElementCount: any;
}

export const DefaultServiceTypeDetails = (props: DefaultServiceTypeDetailsProps) => {
  const { classes, defaultDateState, handleDateChangeEvent, handleCancel, handleDetailsChange, defaultDetails, handleCalendarHoliday, calendarHoliday, displayHolidays, displayElementCount } = props;

  return (
    <>
      <Grid container>
        <Grid xs={4}>
          <Grid item className={`${classes.serviceTypeContentTitle} ${classes.advanceSlotDaysContainer}`}>
            Weekly Holidays
          </Grid>
          <Grid item xs={4} style={{ marginTop: "17px" }}>
            <MultiSelectBox name="holidays" menuitems={DAYS_DROPDOWN_OPTIONS} value={defaultDetails.holidays} onChange={handleDetailsChange} />
          </Grid>
        </Grid>
        <Grid xs={6}>
          <Grid item className={`${classes.serviceTypeContentTitle} ${classes.advanceSlotDaysContainer}`}>
            Calendar Holidays
          </Grid>
          <Grid className={`${classes.advanceSlotDaysContainer} ${classes.calendarMarginTop}`}>
            {/* <Grid container className.advanceSlotDaysContainer={classes.advanceSlotDaysInput}>
              <Grid item xs={3}>
              <MultiSelectBox name="holidays" menuitems={DAYS_DROPDOWN_OPTIONS} value={defaultDetails.holidays} onChange={handleDetailsChange} />
              </Grid>
                </Grid>
                </Grid> */}
            <CountryDefaultTypeDatePicker classes={classes} defaultDateState={defaultDateState} handleDateChangeEvent={handleDateChangeEvent} handleCancel={handleCancel} />

            <Button
              style={{
                marginLeft: "10px",
                color: "#fff",
                backgroundColor: "#28a745",
                borderColor: "#28a745",
              }}
              onClick={(e) => handleCalendarHoliday(e, "ADD", defaultDateState.dateLabel)}
            >
              {" "}
              <Add></Add>{" "}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {displayElementCount && displayElementCount.length > 0 && <Divider style={{ margin: "12px", backgroundColor: "black" }} light={false}></Divider>}
      {displayElementCount &&
        displayElementCount.length > 0 &&
        displayElementCount.map((item: any, index: any) => (
          <Grid container>
            <Grid style={{ padding: "4px" }} xs={4}>
              {displayHolidays.length > index ? displayHolidays[index] : ""}
            </Grid>
            <Grid style={{ padding: "4px" }} xs={2}>
              {calendarHoliday.length > index ? calendarHoliday[index].dateLabel : ""}
            </Grid>
            {calendarHoliday.length > index && (
              <Grid xs={1}>
                <DeleteSlotIcon className={classes.iconButton} onClick={(e: any) => handleCalendarHoliday(e, "REMOVE", calendarHoliday[index].dateLabel)} />
              </Grid>
            )}
          </Grid>
        ))}
    </>
  );
};

interface PosTabInfoProps {
  handleServiceTypeDetails: (type: SERVICE_TYPES_KEYS) => {};
  selectedServiceType: SERVICE_TYPES_KEYS;
  serviceType: SERVICE_TYPES_KEYS;
  classes: any;
  // DropdownIcon: any,
  fetchingPosCount: boolean;
  hideDivisonView: boolean;
  posCount: any;
  posCountError: any;
  countStyle: any;
  serviceTypeLabel: string;
}

export function PosTabInfo(props: PosTabInfoProps) {
  const { handleServiceTypeDetails, selectedServiceType, classes, fetchingPosCount, posCount, posCountError, serviceType, countStyle, hideDivisonView, serviceTypeLabel } = props;
  return (
    <Grid item onClick={() => handleServiceTypeDetails(serviceType)} className={`${classes.serviceTab} ${selectedServiceType === serviceType ? classes.serviceTypeDetailsSectionActive : ""}`}>
      <Grid container justify="space-between">
        <Grid item className={classes.posTitle}>
          <Typography className={classes.serviceTypeStyle}>{serviceTypeLabel}</Typography>
        </Grid>
        <Grid item className={classes.posValue}>
          {fetchingPosCount && <Loader />}
          {!fetchingPosCount && (
            <Typography>
              <div className={countStyle}>
                <div className={classes.serviceTypeCountData}>{posCount}</div>
              </div>
            </Typography>
          )}
          {!fetchingPosCount && !posCount && posCountError && <div className={classes.error}>{posCountError.toString()}</div>}
          {!hideDivisonView && <div className={classes.serviceTypeDivision}></div>}
        </Grid>
      </Grid>
    </Grid>
  );
}

interface PlasticBagconfigurationViewUtilsInterface {
  classes: any;
  bagChargeEnabled: any;
  handleBagChargeChange: any;
  name: any;
}

export const PlasticBagconfigurationViewUtils = (props: PlasticBagconfigurationViewUtilsInterface) => {
  const { classes, bagChargeEnabled, handleBagChargeChange, name } = props;
  return (
    <FormControl component="fieldset" classes={{ root: classes.formControlLabelRoot }}>
      <FormLabel component="legend" className={classes.serviceTypeContentTitle}>
        Plastic Bag Charge Enabled
      </FormLabel>
      <RadioGroup name={name} value={bagChargeEnabled} onChange={handleBagChargeChange}>
        <FormControlLabel value={true} control={<Radio color="primary" />} label="On" classes={{ label: classes.formControlLabel }} />
        <FormControlLabel value={false} control={<Radio color="primary" />} label="Off" classes={{ label: classes.formControlLabel }} />
      </RadioGroup>
    </FormControl>
  );
};
