import { API_HOST_URL, APP_DLV_PROMISE_URL } from "../config/constants";
import { handleError, handleResponse } from "./ApiUtils";
import Fetch from "./Fetch";

export const getPOSZoneMappingList = (body) => {
  return Fetch.get(`${API_HOST_URL}/delivery-promise/pos-zone-mapping`, body).then(handleResponse).catch(handleError);
};

export const saveRuleEngineConfigurations = (body) => {
  return Fetch.create(`${APP_DLV_PROMISE_URL}/delivery-promise-config`, body).then(handleResponse).catch(handleError);
};

export const getRuleEngineConfigurations = (query) => {
  return Fetch.get(`${APP_DLV_PROMISE_URL}/delivery-promise-config/${query.countryId}/${query.serviceType}/${query.posId}/${query.zoneId}`).then(handleResponse).catch(handleError);
};

export const fetchCurrentConfigurationService = ({ countryId, serviceTypeName }, queryParams) => {
  return Fetch.get(`${APP_DLV_PROMISE_URL}/delivery-promise-config/${countryId}/${serviceTypeName}`, queryParams).then(handleResponse).catch(handleError);
};
