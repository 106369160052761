import { useCallback } from "react";

import { Grid, Typography } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import AddPlusIcon from "../../../assets/svg/AddPlusIcon.svg";
import SubtractMinusIcon from "../../../assets/svg/SubtractMinusIcon.svg";
import { STANDARD_SERVICE_TYPE } from "../../Utils/DeliveryFeeConstants";

interface AddConfigurationsCommonTextUIViewInterface {
  name: any;
  classes: any;
}

export const AddConfigurationsCommonTextUIView = (props: AddConfigurationsCommonTextUIViewInterface) => {
  const { name, classes } = props;
  return (
    <>
      <AddCircleOutlineIcon className={classes.addConfigIconStyle} />
      {name !== undefined && name === STANDARD_SERVICE_TYPE ? (
        <Typography className={classes.addConfigTextStyle}>Add Subconfigurations</Typography>
      ) : (
        <Typography className={classes.addConfigTextStyle}>Add Configurations</Typography>
      )}
    </>
  );
};

interface AddConfigurationsTextInterface {
  classes: any;
  name: any;
  multiShipmentLevel: any;
  orderThreshold: any;
  belowThreshold: any;
  aboveThreshold: any;
  _handle_open_configuration_dialog: any;
}

export const AddConfigurationsText = (props: AddConfigurationsTextInterface) => {
  const { classes, name, multiShipmentLevel, orderThreshold, belowThreshold, aboveThreshold, _handle_open_configuration_dialog } = props;

  const _check_if_parent_configuratopn_present = useCallback(() => {
    if (multiShipmentLevel === false) {
      if (orderThreshold !== "" && belowThreshold !== "" && aboveThreshold !== "") {
        return true;
      }
    }
    return false;
  }, [multiShipmentLevel, orderThreshold, belowThreshold, aboveThreshold]);

  return (
    <Grid container className={classes.addConfigIconMainDivStyle}>
      {_check_if_parent_configuratopn_present() === true ? (
        <Grid item xs={10} className={classes.addConfigIconDivStyle} onClick={(e) => _handle_open_configuration_dialog(name, multiShipmentLevel)}>
          <AddConfigurationsCommonTextUIView name={name} classes={classes} />
        </Grid>
      ) : (
        <Grid item xs={10} className={classes.addConfigIconDivStyleDisabled}>
          <AddConfigurationsCommonTextUIView name={name} classes={classes} />
        </Grid>
      )}
    </Grid>
  );
};

interface AddThresoldRangeTextInterface {
  classes: any;
  name: any;
  multiShipmentLevel: any;
  orderThreshold: any;
  belowThreshold: any;
  aboveThreshold: any;
  _handle_click_add_threshold_range: any;
}

export const AddThresoldRangeText = (props: AddThresoldRangeTextInterface) => {
  const { classes, name, multiShipmentLevel, orderThreshold, belowThreshold, aboveThreshold, _handle_click_add_threshold_range } = props;

  const _check_if_parent_configuratopn_present = () => {
    if (multiShipmentLevel === false) {
      if (orderThreshold !== "" && belowThreshold !== "" && aboveThreshold !== "") {
        return true;
      }
    }
    return false;
  };
  return (
    <Grid container className={classes.addConfigIconMainDivStyle}>
      {_check_if_parent_configuratopn_present() === true ? (
        <Grid item xs={10} className={classes.addConfigIconDivStyle} onClick={(e) => _handle_click_add_threshold_range(name, multiShipmentLevel)}>
          <AddCircleOutlineIcon className={classes.addConfigIconStyle} />
          <Typography className={classes.addConfigTextStyle}>Add Range</Typography>
        </Grid>
      ) : (
        <Grid item xs={10} className={classes.addConfigIconDivStyleDisabled}>
          <AddCircleOutlineIcon className={classes.addConfigIconStyle} />
          <Typography className={classes.addConfigTextStyle}>Add Range</Typography>
        </Grid>
      )}
    </Grid>
  );
};

interface AddSubtractThresholdRangeObjectButtonsInterface {
  classes: any;
  name: any;
  multiShipmentLevel: any;
  rangeObject: any;
  _handle_click_delete_threshold_range: any;
  _handle_click_add_threshold_range: any;
}

export const AddSubtractThresholdRangeObjectButtons = (props: AddSubtractThresholdRangeObjectButtonsInterface) => {
  const { classes, name, multiShipmentLevel, rangeObject, _handle_click_delete_threshold_range, _handle_click_add_threshold_range } = props;
  return (
    <>
      <img src={SubtractMinusIcon} alt="SubtractMinusIcon" className={classes.actionIconStyle} onClick={(e) => _handle_click_delete_threshold_range(name, multiShipmentLevel)} />
      {rangeObject.newOrderThreshold !== "" && Number(rangeObject.newOrderThreshold) > Number(rangeObject.previousOrderThreshold) && (
        <img src={AddPlusIcon} alt="AddPlusIcon" className={classes.actionIconStyle} onClick={(e) => _handle_click_add_threshold_range(name, multiShipmentLevel)} />
      )}
    </>
  );
};

interface AddCapacityUtilisationRangeTextInterface {
  classes: any;
  _handle_click_add_capacity_range: any;
  capacityHigh: any;
  capacityLow: any;
  deliveryFee: any;
}

export const AddCapacityUtilisationRangeText = (props: AddCapacityUtilisationRangeTextInterface) => {
  const { classes, _handle_click_add_capacity_range, capacityHigh, capacityLow, deliveryFee } = props;

  const _check_if_parent_configuratopn_present = () => {
    if (capacityLow !== "" && capacityHigh !== "" && Number(capacityHigh) < 100 && deliveryFee !== "") {
      return true;
    }
    return false;
  };

  return (
    <Grid container className={classes.addConfigIconMainDivStyle}>
      {_check_if_parent_configuratopn_present() === true ? (
        <Grid item xs={12} className={classes.addConfigIconDivStyle} onClick={(e) => _handle_click_add_capacity_range()}>
          <AddCircleOutlineIcon className={classes.addConfigIconStyle} />
          <Typography className={classes.addConfigTextStyle}>Add Range</Typography>
        </Grid>
      ) : (
        <Grid item xs={12} className={classes.addConfigIconDivStyleDisabled}>
          <AddCircleOutlineIcon className={classes.addConfigIconStyle} />
          <Typography className={classes.addConfigTextStyle}>Add Range</Typography>
        </Grid>
      )}
    </Grid>
  );
};

interface AddSubtractCapacityRangeObjectButtonsInterface {
  classes: any;
  _handle_click_add_capacity_range: any;
  capacityHigh: any;
  capacityLow: any;
  deliveryFee: any;
  state_index: any;
  _handle_click_remove_capacity_range: any;
}

export const AddSubtractCapacityRangeObjectButtons = (props: AddSubtractCapacityRangeObjectButtonsInterface) => {
  const { classes, _handle_click_add_capacity_range, capacityHigh, capacityLow, deliveryFee, _handle_click_remove_capacity_range, state_index } = props;
  return (
    <>
      <img src={SubtractMinusIcon} alt="SubtractMinusIcon" className={classes.actionIconStyle} onClick={(e) => _handle_click_remove_capacity_range(state_index)} />
      {capacityHigh !== "" && capacityLow !== "" && Number(capacityLow) < 100 && deliveryFee !== "" && (
        <img src={AddPlusIcon} alt="AddPlusIcon" className={classes.actionIconStyle} onClick={_handle_click_add_capacity_range} />
      )}
    </>
  );
};
