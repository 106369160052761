import { Grid } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "../../../config/constants";
import { convertMinutesToHoursAndMinutes, convertSecondsIntoMinutes } from "../../../utils/helperFunctions";
import settingsIcon from "../../assets/svg/Settings.svg";
import { canChangeRuleEngineConfig } from "../RuleEngineAccessControl";

export const convertWeightInGramsToKiloGrams = (weightInGrams: any) => {
  return weightInGrams ? Math.floor(weightInGrams / 1000) : weightInGrams;
};

// export const getCurrentConfigTableColumn = (classes: any, userInfo: any) => {
//     let coulums: any = [
//         { field: "posNo", headerName: "POS No", width: 100, },
//         {
//             field: "posName", headerName: "POS Name", width: 200, sortable: true, renderCell: (params: any) => (
//                 <Tooltip title={params.row.posName} >
//                     <span className={classes.tableCellTrucate}>{params.row.posName}</span>
//                 </Tooltip>
//             ),
//         },
//         { field: "zoneName", headerName: "Zone Name", width: 100, sortable: false },
//         {
//             field: "dynamicPromise",
//             headerName: "Type of Promise",
//             width: 150,
//             renderCell: (params: any) => <div>{params.row.dynamicPromise ? "Dynamic" : "Static"}</div>,
//             sortable: false
//         },
//         {
//             field: "defaultOrFixedTime",
//             headerName: "Default Time",
//             width: 150,
//             sortable: false,
//             renderCell: (params: any) => (
//                 <div
//                     style={{
//                         alignSelf: 'center'
//                     }}
//                 >{params.row.defaultOrFixedTime ?
//                     `${convertMinutesToHoursAndMinutes(convertSecondsIntoMinutes(params.row.defaultOrFixedTime))}` : '---'
//                     }</div>
//             ),
//         },
//         {
//             field: "weightThreshold",
//             headerName: "Weight Threshold",
//             width: 120,
//             sortable: false,
//             renderCell: (params: any) => (
//                 <div>{params.row.rules_data.weightRule.thresholdWeight ? convertWeightInGramsToKiloGrams(params.row.rules_data.weightRule.thresholdWeight) : "---"}</div>
//             ),
//         },
//         {
//             field: "weightIncrementalTime",
//             headerName: "Weight Incremental Time",
//             width: 120,
//             sortable: false,
//             renderCell: (params: any) => (
//                 <div>{params.row.rules_data.weightRule.incrementalTime ? `${convertSecondsIntoMinutes(params.row.rules_data.weightRule.incrementalTime)} mins` : "---"}</div>
//             ),
//         },
//         {
//             field: "countThreshold",
//             headerName: "Count Threshold",
//             width: 120,
//             sortable: false,
//             renderCell: (params: any) => <div>{params.row.rules_data.countRule.thresholdCount ? params.row.rules_data.countRule.thresholdCount : "---"}</div>,
//         },
//         {
//             field: "countIncrementalTime",
//             headerName: "Count Incremental Time",
//             width: 150,
//             sortable: false,
//             renderCell: (params: any) => (
//                 <div>
//                     {params.row.rules_data.countRule.incrementalTime
//                         ? `${params.row.rules_data.countRule.incrementalTime} seconds`
//                         : "---"}
//                 </div>
//             ),
//         },
//         {
//             field: "scalableItems",
//             headerName: "Incremental Time for Scalable Items",
//             width: 200,
//             sortable: false,
//             renderCell: (params: any) => (
//                 <Tooltip
//                     title={
//                         params.row.rules_data.scalableTypeRule.itemList ? (
//                             <Grid container style={{ lineHeight: 1.5 }}>
//                                 {params.row.rules_data.scalableTypeRule.itemList.map((item: any) => (
//                                     <Grid item xs={12} key={item.section}>{`${item.section} - ${convertSecondsIntoMinutes(
//                                         item.incrementalTime
//                                     )} mins`}</Grid>
//                                 ))}
//                             </Grid>
//                         ) : '---'
//                     }
//                 >
//                     <div>
//                         {params.row.rules_data.scalableTypeRule.itemList ? (
//                             <Grid container style={{ lineHeight: 1.5 }}>
//                                 {params.row.rules_data.scalableTypeRule.itemList.map((item: any) => (
//                                     <Grid item xs={12} key={item.section}>{`${item.section} - ${convertSecondsIntoMinutes(
//                                         item.incrementalTime
//                                     )} mins`}</Grid>
//                                 ))}
//                             </Grid>
//                         ) : '---'}
//                     </div>
//                 </Tooltip>
//             ),
//         },
//     ];

//     if (canChangeRuleEngineConfig(userInfo)) {
//         coulums.push({
//             field: "",
//             headerName: "Actions",
//             width: 120,
//             renderCell: (params: any) => {
//                 const {
//                     row: { countryId, posNo, serviceType, zoneId, },
//                 } = params;
//                 return (
//                     <Link to={{
//                         pathname: `${APP_ROUTES.RULE_ENGINE}`,
//                         search: `?countryId=${countryId}&serviceType=${serviceType}&posNo=${posNo}&zoneId=${zoneId}`
//                     }} className={classes.completeSetupSettingsButton}>
//                         <img src={settingsIcon} alt="settings" />
//                     </Link>
//                 );
//             },
//         });
//     }
//     return coulums;
// }
export const getCurrentConfigTableColumn = (classes: any, userInfo: any) => {
  let coulums: any = [
    { field: "posNo", headerName: "POS No", width: 90, sortable: false, headerAlign: "left", headerClassName: classes.headerStyle },
    {
      field: "posName",
      headerName: "POS Name",
      flex: 1,
      sortable: true,
      headerAlign: "left",
      headerClassName: classes.headerStyle,
      renderCell: (params: any) => (
        <Tooltip title={params.row.posName}>
          <span className={classes.tableCellTrucate}>{params.row.posName}</span>
        </Tooltip>
      ),
    },
    { field: "zoneName", headerName: "Zone Name", flex: 1, sortable: false, headerClassName: classes.headerStyle },
    {
      field: "dynamicPromise",
      headerName: "Type of Promise",
      flex: 1,
      headerAlign: "left",
      renderCell: (params: any) => <div>{params.row.dynamicPromise ? "Dynamic" : "Static"}</div>,
      sortable: false,
      headerClassName: classes.headerStyle,
    },
    {
      field: "defaultOrFixedTime",
      headerName: "Default Time",
      flex: 1,
      sortable: false,
      headerClassName: classes.headerStyle,
      headerAlign: "left",
      renderCell: (params: any) => (
        <div
          style={{
            alignSelf: "center",
          }}
        >
          {params.row.defaultOrFixedTime ? `${convertMinutesToHoursAndMinutes(convertSecondsIntoMinutes(params.row.defaultOrFixedTime))}` : "---"}
        </div>
      ),
    },
    {
      field: "weightThreshold",
      headerName: "Weight Threshold",
      flex: 1,
      sortable: false,
      headerClassName: classes.headerStyle,
      headerAlign: "left",
      renderCell: (params: any) => <div>{params.row.rules_data.weightRule.thresholdWeight ? convertWeightInGramsToKiloGrams(params.row.rules_data.weightRule.thresholdWeight) : "---"}</div>,
    },
    {
      field: "weightIncrementalTime",
      headerName: "Weight Incremental Time",
      flex: 1,
      sortable: false,
      headerClassName: classes.headerStyle,
      headerAlign: "left",
      renderCell: (params: any) => <div>{params.row.rules_data.weightRule.incrementalTime ? `${convertSecondsIntoMinutes(params.row.rules_data.weightRule.incrementalTime)} mins` : "---"}</div>,
    },
    {
      field: "countThreshold",
      headerName: "Count Threshold",
      flex: 1,
      sortable: false,
      headerClassName: classes.headerStyle,
      headerAlign: "left",
      renderCell: (params: any) => <div>{params.row.rules_data.countRule.thresholdCount ? params.row.rules_data.countRule.thresholdCount : "---"}</div>,
    },
    {
      field: "countIncrementalTime",
      headerName: "Count Incremental Time",
      flex: 1,
      sortable: false,
      headerClassName: classes.headerStyle,
      headerAlign: "left",
      renderCell: (params: any) => <div>{params.row.rules_data.countRule.incrementalTime ? `${params.row.rules_data.countRule.incrementalTime} seconds` : "---"}</div>,
    },
    {
      field: "scalableItems",
      headerName: "Incremental Time for Scalable Items",
      flex: 1.5,
      sortable: false,
      headerClassName: classes.headerStyle,
      headerAlign: "left",
      renderCell: (params: any) => (
        <Tooltip
          title={
            params?.row?.rules_data?.scalableTypeRule?.itemList ? (
              <Grid container style={{ lineHeight: 1.5 }}>
                {params.row.rules_data.scalableTypeRule.itemList.map((item: any) => (
                  <Grid item xs={12} key={item.section}>{`${item.section} - ${convertSecondsIntoMinutes(item.incrementalTime)} mins`}</Grid>
                ))}
              </Grid>
            ) : (
              "---"
            )
          }
        >
          <div>
            {params?.row.rules_data?.scalableTypeRule?.itemList ? (
              <>
                <Grid container style={{ lineHeight: 1.5 }}>
                  {params.row.rules_data.scalableTypeRule.itemList.slice(0, 1).map((item: any) => (
                    <Grid item xs={12} key={item.section}>{`${item.section} - ${convertSecondsIntoMinutes(item.incrementalTime)} mins`}</Grid>
                  ))}
                </Grid>
                {params.row.rules_data.scalableTypeRule.itemList.length > 1 && (
                  <Grid container style={{ lineHeight: 1.5 }}>
                    <span className={classes.ScalableItemsSpanStyle}>{`+ ${params.row.rules_data.scalableTypeRule.itemList.length - 1}  Scalable Items`}</span>
                  </Grid>
                )}
              </>
            ) : (
              "---"
            )}
          </div>
        </Tooltip>
      ),
    },
  ];

  if (userInfo && canChangeRuleEngineConfig(userInfo) === true) {
    coulums.push({
      field: "actions",
      headerName: "Actions",
      width: 80,
      sortable: false,
      headerClassName: classes.headerStyle,
      headerAlign: "left",
      renderCell: (params: any) => {
        const {
          row: { countryId, posNo, serviceType, zoneId },
        } = params;
        return (
          <Link
            to={{
              pathname: `${APP_ROUTES.RULE_ENGINE_CURRENT_CONFIGURATION}`,
              search: `?countryId=${countryId}&serviceType=${serviceType}&posNo=${posNo}&zoneId=${zoneId}`,
            }}
            className={classes.completeSetupSettingsButton}
          >
            <img src={settingsIcon} alt="settings" />
          </Link>
        );
      },
    });
  }
  return coulums;
};
