import React from "react";

import { Card, Grid, Typography } from "@material-ui/core";

import { getDateFromData, getTimeFormatData } from "../../../../utils/DateUtils";
import { ReactComponent as DeleteIcon } from "../../../assets/svg/DeleteIcon.svg";
import TextBox from "../../../common/TextBox/index";
import Pencil from "../../../assets/svg/Pencil.svg";

interface AdditionalDeliveryFeeConfigListInterface {
  feeConfigList: any;
  classes: any;
  stateObj?: any;
  multiShipmentLevel?: any;
  shipment_name?: any;
  removeTimeConfigRecord?: any;
  handleAdditionalConfigInputChange?: any;
  _handle_edit_toggle_additional_calculation_toggle_change?: any;
}

const AdditionalDeliveryFeeConfigList = (props: AdditionalDeliveryFeeConfigListInterface) => {
  const { feeConfigList, classes, multiShipmentLevel, shipment_name, handleAdditionalConfigInputChange, removeTimeConfigRecord, _handle_edit_toggle_additional_calculation_toggle_change } = props;

  return (
    <Card className={classes.additionDlvFeeMainCard}>
      <Grid container className={classes.additionDlvFeeMainTitleDivStyle}>
        <Grid item xs={12}>
          <Typography className={classes.additionDlvFeeMainTitleStyle}>Additional delivery fee configuration</Typography>
        </Grid>
      </Grid>
      {feeConfigList && Array.isArray(feeConfigList) && feeConfigList.length > 0 ? (
        feeConfigList.map((config: any, configIndex: any) => (
          <Grid container key={`${configIndex}-feeConfigList-config`}>
            {config &&
              config.daysConfiguration &&
              Array.isArray(config.daysConfiguration) &&
              config.daysConfiguration.length > 0 &&
              config.daysConfiguration.map((daysConfig: any, daysConfigIndex: any) => (
                <Card key={`${daysConfigIndex}-daysConfiguration-config`} className={classes.additionDlvFeeInnerCardStyle}>
                  <Grid container className={classes.additionDlvFeeInnerRowHeaderStyle}>
                    <Grid item xs={12} className={classes.dlvFeeValueDisplayDivStyle}>
                      <Typography className={classes.dlvFeeValueDisplayDataStyle}>{`Generated range for : ${
                        daysConfig && daysConfig.days && Array.isArray(daysConfig.days) && daysConfig.days.length > 0 ? daysConfig.days.join(", ") : ""
                      }, (${config.startDate ? getDateFromData(config.startDate) : ""} - ${config.endDate ? getDateFromData(config.endDate) : ""})`}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container className={classes.additionDlvFeeInnerRowHeaderStyle}>
                    <Grid item xs={2} className={classes.dlvFeeValueDisplayDivStyle}>
                      <Typography className={classes.dlvFeeValueDisplayTitleStyle}>Start - End Time</Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.dlvFeeValueDisplayDivStyle}>
                      <Typography className={classes.dlvFeeValueDisplayTitleStyle}>Threshold</Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.dlvFeeValueDisplayDivStyle}>
                      <Typography className={classes.dlvFeeValueDisplayTitleStyle}>Below Threshold Fee</Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.dlvFeeValueDisplayDivStyle}>
                      <Typography className={classes.dlvFeeValueDisplayTitleStyle}>Above Threshold Fee</Typography>
                    </Grid>
                  </Grid>
                  {daysConfig.timeZoneConfiguration &&
                    Array.isArray(daysConfig.timeZoneConfiguration) &&
                    daysConfig.timeZoneConfiguration.length > 0 &&
                    daysConfig.timeZoneConfiguration.map((timeConfig: any, timeConfigIndex: any) => (
                      <Grid container className={classes.additionDlvFeeInnerRowHeaderStyle}>
                        <Grid item xs={2} className={classes.additionDlvFeeInnerRowDivStyle}>
                          <Typography className={classes.dlvFeeValueDisplayDataStyle}>{`${timeConfig.startTime ? getTimeFormatData(timeConfig.startTime) : ""} - ${
                            timeConfig.endTime ? getTimeFormatData(timeConfig.endTime) : ""
                          }`}</Typography>
                        </Grid>
                        <Grid item xs={2} className={classes.additionDlvFeeInnerRowDivStyle}>
                          {handleAdditionalConfigInputChange !== undefined ? (
                            <TextBox
                              name="thresholdAmount"
                              type="number"
                              textBoxId="thresholdAmount"
                              placeholderText="Threshold Fee"
                              value={timeConfig.thresholdAmount}
                              handleChange={(e: any) => handleAdditionalConfigInputChange(e, multiShipmentLevel, shipment_name, configIndex, daysConfigIndex, timeConfigIndex)}
                              className={classes.rightPaddingStyle}
                              disabled={timeConfig.enableEdit}
                            ></TextBox>
                          ) : (
                            <Typography className={classes.dlvFeeValueDisplayDataStyle}>{`${timeConfig.thresholdAmount}`}</Typography>
                          )}
                        </Grid>
                        <Grid item xs={2} className={classes.additionDlvFeeInnerRowDivStyle}>
                          {handleAdditionalConfigInputChange !== undefined ? (
                            <TextBox
                              name="thresholdBelowAmount"
                              type="number"
                              textBoxId="thresholdBelowAmount"
                              placeholderText="Fees Below Threshold"
                              value={timeConfig.thresholdBelowAmount}
                              handleChange={(e: any) => handleAdditionalConfigInputChange(e, multiShipmentLevel, shipment_name, configIndex, daysConfigIndex, timeConfigIndex)}
                              className={classes.rightPaddingStyle}
                              disabled={timeConfig.enableEdit}
                            ></TextBox>
                          ) : (
                            <Typography className={classes.dlvFeeValueDisplayDataStyle}>{`${timeConfig.thresholdBelowAmount}`}</Typography>
                          )}
                        </Grid>
                        <Grid item xs={2} className={classes.additionDlvFeeInnerRowDivStyle}>
                          {handleAdditionalConfigInputChange !== undefined ? (
                            <TextBox
                              name="thresholdAboveAmount"
                              type="number"
                              textBoxId="thresholdAboveAmount"
                              placeholderText="Fees Above Threshold"
                              value={timeConfig.thresholdAboveAmount}
                              handleChange={(e: any) => handleAdditionalConfigInputChange(e, multiShipmentLevel, shipment_name, configIndex, daysConfigIndex, timeConfigIndex)}
                              className={classes.rightPaddingStyle}
                              disabled={timeConfig.enableEdit}
                            ></TextBox>
                          ) : (
                            <Typography className={classes.dlvFeeValueDisplayDataStyle}>{`${timeConfig.thresholdAboveAmount}`}</Typography>
                          )}
                        </Grid>

                        {removeTimeConfigRecord !== undefined && _handle_edit_toggle_additional_calculation_toggle_change !== undefined && timeConfig.enableEdit && (
                          <Grid item xs={1} className={classes.actionIconDivStyle}>
                            <img
                              src={Pencil}
                              alt="Edit"
                              className={classes.actionIconStyle}
                              onClick={(e: any) => _handle_edit_toggle_additional_calculation_toggle_change(multiShipmentLevel, shipment_name, configIndex, daysConfigIndex, timeConfigIndex)}
                              style={{ cursor: "pointer" }}
                            />
                          </Grid>
                        )}
                        {!timeConfig.enableEdit && removeTimeConfigRecord !== undefined && (
                          <Grid item xs={1} className={classes.additionDlvFeeInnerRowDivStyle}>
                            <DeleteIcon onClick={() => removeTimeConfigRecord(multiShipmentLevel, shipment_name, configIndex, daysConfigIndex, timeConfigIndex)} style={{ cursor: "pointer" }} />
                          </Grid>
                        )}
                      </Grid>
                    ))}
                </Card>
              ))}
          </Grid>
        ))
      ) : (
        <></>
      )}
    </Card>
  );
};

export default React.memo(AdditionalDeliveryFeeConfigList);
