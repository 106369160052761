export interface DeliveryFeeInitialStateInterface {
  loading: boolean;
  refresh: boolean;
  searchText: string;
  deliveryFeeListData: any;
  page: any;
  size: any;
  maxRecordPresent: any;
  deliveryFeeHeaderData: any;
}

export const DeliveryFeeInitialState: DeliveryFeeInitialStateInterface = {
  loading: false,
  refresh: false,
  searchText: "",
  deliveryFeeListData: [],
  page: 0,
  size: 20,
  maxRecordPresent: 0,
  deliveryFeeHeaderData: {},
};

export interface thresholdshipmentLevelValueObjectInterface {
  isEditMode: boolean;
  thresholdAmount: any;
  feesBelowThreshold: any;
  feesAboveThreshold: any;
}

export const thresholdshipmentLevelValueObject: thresholdshipmentLevelValueObjectInterface = {
  isEditMode: false,
  thresholdAmount: "",
  feesBelowThreshold: "",
  feesAboveThreshold: "",
};

export interface thresholdmultiShipmentLevelValueObjectInterface {
  isEditMode: boolean;
  thresholdAmount: any;
  feesAboveThreshold: any;
}

export const thresholdmultiShipmentLevelValueObject: thresholdmultiShipmentLevelValueObjectInterface = {
  isEditMode: true,
  thresholdAmount: "",
  feesAboveThreshold: "",
};

export interface DlvFeeConfigObjStateInterface {
  loading: boolean;
  shipmentLevel: {
    express: thresholdshipmentLevelValueObjectInterface;
    eFood: thresholdshipmentLevelValueObjectInterface;
    nonFood: thresholdshipmentLevelValueObjectInterface;
  };
  multiShipmentLevel: {
    expressEFood: thresholdmultiShipmentLevelValueObjectInterface;
    expressNonFood: thresholdmultiShipmentLevelValueObjectInterface;
    expressEfoodNonFood: thresholdmultiShipmentLevelValueObjectInterface;
    eFoodNonFood: thresholdmultiShipmentLevelValueObjectInterface;
  };
}

export const DlvFeeConfigObjState: DlvFeeConfigObjStateInterface = {
  loading: false,
  shipmentLevel: {
    express: { ...thresholdshipmentLevelValueObject },
    eFood: { ...thresholdshipmentLevelValueObject },
    nonFood: { ...thresholdshipmentLevelValueObject },
  },
  multiShipmentLevel: {
    expressEFood: { ...thresholdmultiShipmentLevelValueObject },
    expressNonFood: { ...thresholdmultiShipmentLevelValueObject },
    expressEfoodNonFood: { ...thresholdmultiShipmentLevelValueObject },
    eFoodNonFood: { ...thresholdmultiShipmentLevelValueObject },
  },
};
