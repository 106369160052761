export function isEmptyOrNull(value: any) {
    return value === undefined || value === null || ((((typeof value) === 'string') ? value.trim() : value) === "");
}

export function isEmptyArray(array: Array<any> | undefined | null) {
    return array === undefined || array === null || array.length === 0;
}

export function isObjectEmptyOrNull(obj: any) {
    return obj === undefined || obj === null || Object.keys(obj).length === 0;
}