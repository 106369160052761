import { Grid } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
//Material UI Components
import Typography from "@material-ui/core/Typography";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// Constants, utils
import { APP_ROUTES } from "../../config/constants";
import { selectPosTypes, selectServiceTypes } from "../../config/redux/configurationsSlice";
import { downloadCSVFile } from "../../utils/helperFunctions";
import searchIcon from "../assets/svg/SearchIcon.svg";
import settingsIcon from "../assets/svg/Settings.svg";
import CustomAlert from "../common/CustomAlert/CustomAlert";
import Loader from "../common/Loader/Loader";
import MultiSelectBox from "../common/MultiSelectBox/MultiSelectBox";
import PrimaryButton from "../common/PrimaryButton/PrimaryButton";
//Common Components
import Table from "../common/Table/Table";
import TextBox from "../common/TextBox";
import { downloadPOSDetailsReport, resetPOSDetails } from "../CreatePOS/redux/posSlice";
import { selectUserDetails } from "../Login/redux/userSlice";
// Styles
import { posListStyles } from "./POSListStyles";
import { CreatePosButton } from "./posUtils/PosAccessUtils";
import { a11yProps, TabPanel } from "./posUtils/PosListViewUtils";
import { fetchPOSListByCountryId } from "./redux/posListSlice";

const POSList = () => {
  const classes = posListStyles();

  const [currentTab, setCurrentTab] = React.useState(0);
  const [posList, setPosList] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState({
    serviceType: [],
    posType: [],
  });
  const [searchText, setSearchText] = useState("");
  const [activePosList, setActivePosList] = useState([]);
  const [inactivePosList, setInactivePosList] = useState([]);

  const [isExporting, setIsExporting] = useState(false);

  const dispatch = useDispatch();

  const {
    currentCountry: { isoCode },
  } = useSelector((state) => state.appConfig);

  const serviceTypes = useSelector(selectServiceTypes);
  const posTypes = useSelector(selectPosTypes);

  const { data: responsePOSList, loading: fetchingPosList, error: posListError } = useSelector((state) => state.posList);

  const userInfo = useSelector(selectUserDetails);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
    resetAllFilters();
  };

  const resetAllFilters = () => {
    setSearchText("");
    setAppliedFilters({ ...appliedFilters, serviceType: [], posType: [] });
  };

  //Fetching an API
  const fetchPOSLists = useCallback(async () => {
    dispatch(fetchPOSListByCountryId({ country: isoCode }));
  }, [dispatch, isoCode]);

  const columns = [
    { field: "posNo", headerName: "POS No.", flex: 0.55 },
    { field: "storeName", headerName: "Store Name", flex: 0.75 },
    { field: "address", headerName: "Address", flex: 1.25, sortable: false },
    {
      field: "posType",
      headerName: "POS Type",
      flex: 0.75,
      renderCell: (params) => <div>{params.row.posType}</div>,
    },
    {
      sortable: false,
      field: "serviceTypeList",
      headerName: "Service Type",
      flex: 2.5,
      scroll: false,
      renderCell: (params) => {
        return (
          <Grid container spacing={2} className={classes.serviceTypeContainer}>
            {params.row.serviceTypeList.map((element, index) => {
              if (element.status) {
                return (
                  <div key={`${element.type} - ${index}`} className={`${classes.activeServiceTypes} ${index !== 0 ? classes.leftMargin8 : ""}`}>
                    <div className={`${classes.serviceType}`}>{element.type}</div>
                  </div>
                );
              } else {
                return (
                  <div key={`${element.type} - ${index}`} className={`${classes.inactiveServiceTypes} ${index !== 0 ? classes.leftMargin8 : ""}`}>
                    <div className={`${classes.serviceType}`}>{element.type}</div>
                  </div>
                );
              }
            })}
          </Grid>
        );
      },
    },
    {
      field: "",
      headerName: "Actions",
      flex: 0.4,
      sortable: false,
      renderCell: (params) => {
        const {
          row: { posNo },
        } = params;
        return (
          <Link to={`${APP_ROUTES.COMPLETE_SETUP}/${posNo}`} className={classes.completeSetupSettingsButton}>
            <img src={settingsIcon} alt="settings" />
          </Link>
        );
      },
    },
  ];

  const getFilteredList = React.useMemo(() => {
    const { posType, serviceType } = appliedFilters;

    let filteredList = currentTab === 0 ? [...activePosList] : [...inactivePosList];

    if (serviceType.length) {
      let serviceTypesWithNames = serviceType.reduce((acc, val) => {
        let obj1 = serviceTypes.filter((service) => service.id === val)[0];
        return [...acc, obj1.name];
      }, []);
      filteredList = serviceTypesWithNames.reduce((acc, st) => [...acc, ...filteredList.filter((row) => row.serviceTypes && row.serviceTypes.includes(st))], []);
    }

    if (posType.length) {
      filteredList = posType.reduce((acc, pt) => [...acc, ...filteredList.filter((row) => row.posType && row.posType.toLowerCase().includes(pt.toLowerCase()))], []);
    }

    if (searchText) {
      filteredList = filteredList.filter(
        (list) => (list.posNo && list.posNo.toLowerCase().includes(searchText.toLowerCase())) || (list.storeName && list.storeName.toLowerCase().includes(searchText.toLowerCase()))
      );
    }

    return [...new Set(filteredList)];
  }, [activePosList, appliedFilters, currentTab, inactivePosList, searchText, serviceTypes]);

  /**
   * Method to handle POS Type Filter
   * @param {object} event
   */
  const handlePosTypeFilter = (name, selectedValues) => {
    setAppliedFilters({
      ...appliedFilters,
      [name]: selectedValues,
    });
  };

  /**
   * Method to handle Service Type Filter
   * @param {object} event
   */
  const handleServiceTypeFilter = (name, selectedValues) => {
    setAppliedFilters({
      ...appliedFilters,
      [name]: selectedValues,
    });
  };

  /**
   * Method to handle search
   * @param {object} event
   */
  const handleSearch = (e) => {
    let { value } = e.target;
    setSearchText(value);
  };

  const handleExportCSVClick = async () => {
    setIsExporting(true);
    const {
      payload: { message: reportDownloadError, csvData, fileName },
    } = await dispatch(downloadPOSDetailsReport(isoCode));
    if (reportDownloadError) {
      CustomAlert("error", reportDownloadError);
    }

    if (csvData && fileName) {
      downloadCSVFile(fileName, csvData);
    }
    setIsExporting(false);
  };

  // clear last selected POS details
  useEffect(() => {
    dispatch(resetPOSDetails());
  }, [dispatch]);

  useEffect(() => {
    fetchPOSLists();
  }, [fetchPOSLists]);

  useEffect(() => {
    if (responsePOSList) {
      setPosList(responsePOSList);
      setActivePosList(responsePOSList.filter((row) => row.active));
      setInactivePosList(
        responsePOSList.filter(function (row) {
          return row.active !== true;
        })
      );
    }
  }, [responsePOSList]);

  return (
    <div className={classes.posListSection}>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={6} className={classes.root}>
          <Typography className={classes.heading}>POS List</Typography>
          <div className={classes.posListCount}>{posList.length}</div>
        </Grid>
        <Grid item xs={6}>
          <Grid container justifyContent="flex-end" spacing={1}>
            <Grid item xs={6}>
              <PrimaryButton
                buttonLabel={isExporting ? "exporting..." : "Export CSV"}
                buttonVariant="outlined"
                disabled={isExporting}
                onClick={handleExportCSVClick}
                className={classes.fullWidthButton}
              />
            </Grid>
            <Grid item xs={6}>
              <CreatePosButton classes={classes} userInfo={userInfo} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Card className={classes.cardSection}>
        <CardContent className={classes.tabsContainer}>
          <Tabs className={classes.tabs} value={currentTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
            <Tab label={`Active POS (${currentTab === 0 ? getFilteredList.length : activePosList.length})`} {...a11yProps(0)} className={classes.tabLabel} />
            <Tab label={`Inactive POS (${currentTab === 1 ? getFilteredList.length : inactivePosList.length})`} {...a11yProps(1)} className={classes.tabLabel} />
          </Tabs>
        </CardContent>
      </Card>
      <Grid container className={classes.searchAndFilterContainer}>
        <Grid item xs={3} className={classes.searchFilter}>
          <TextBox
            // className={classes.textBox}
            textBoxId="searchTextBox"
            placeholderText="Quick search by POS No., Store Name"
            startAdornment={
              <InputAdornment position="start">
                <IconButton edge="start" size="small">
                  <img src={searchIcon} alt="search" />
                </IconButton>
              </InputAdornment>
            }
            value={searchText}
            handleChange={handleSearch}
          ></TextBox>
        </Grid>
        <Grid item xs={3}>
          {""}
        </Grid>
        <Grid item xs={6}>
          <Grid container alignItems="center">
            <Grid item xs={4} className={classes.filterByTextStyle}>
              <Typography className={classes.filterText}>Filter By</Typography>
            </Grid>

            <Grid item xs={4}>
              <MultiSelectBox
                name="posType"
                label="POS Type"
                menuitems={posTypes}
                value={appliedFilters.posType}
                onChange={(e) => handlePosTypeFilter("posType", e)}
                className={classes.posTypeFilter}
              />
            </Grid>
            <Grid item xs={4}>
              <MultiSelectBox
                name="serviceType"
                label="Service Type"
                menuitems={serviceTypes}
                value={appliedFilters.serviceType}
                onChange={(e) => handleServiceTypeFilter("serviceType", e)}
                className={classes.serviceTypeFilter}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <TabPanel value={currentTab} index={0}>
        <div className={classes.tableStyle}>
          {fetchingPosList && <Loader />}
          {!fetchingPosList && activePosList && <Table columnsData={columns} rowsData={getFilteredList} />}
          {!fetchingPosList && !activePosList && posListError && <div className={classes.error}>{posListError.toString()}</div>}
        </div>
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <div className={classes.tableStyle}>
          {fetchingPosList && <Loader />}
          {!fetchingPosList && inactivePosList && <Table columnsData={columns} rowsData={getFilteredList} />}
          {!fetchingPosList && !inactivePosList && posListError && <div className={classes.error}>{posListError.toString()}</div>}
        </div>
      </TabPanel>
    </div>
  );
};
export default POSList;
