import produce from "immer";
import CustomAlert from "../../../components/common/CustomAlert/CustomAlert";
import { ALERT_TYPES } from '../../../config/constants';

export const validateStandardZoneConfig = (configTabs: any, selectedConfigTab: any, isPOSMappingAreaBased: boolean, errors: any) => {
    let currentTab = configTabs[selectedConfigTab];
    let newErrors: any = {};

    // Remove as we implemented exclusion logic zone may not exclusion any type
    // if (!currentTab.foodTypes) {
    //     let errorMsg = "Please select product type";
    //     newErrors.foodTypes = errorMsg;
    //     CustomAlert(ALERT_TYPES.ERROR, errorMsg);
    // }

    if (currentTab.slotOptimizationEnabled) {
        if (!currentTab.capacityThreshold) {
            let errorMsg = "Please enter capacity threshold";
            newErrors.capacityThreshold = errorMsg;
            CustomAlert(ALERT_TYPES.ERROR, errorMsg);
        }

        if (currentTab.capacityThreshold && (currentTab.capacityThreshold < 50 || currentTab.capacityThreshold > 100)) {
            let errorMsg = "Accepted capacity threshold values are 50 to 100";
            newErrors.capacityThreshold = errorMsg;
            CustomAlert(ALERT_TYPES.ERROR, errorMsg);
        }
        if (!currentTab.timeThreshold) {
            let errorMsg = "Please enter time threshold";
            newErrors.timeThreshold = errorMsg;
            CustomAlert(ALERT_TYPES.ERROR, errorMsg);
        }
    }

    if (isPOSMappingAreaBased) {
        if (!currentTab.selectedAreas.length) {
            let errorMsg = "Please assign areas to the zone configurations";
            newErrors.selectedAreas = errorMsg;
            CustomAlert(ALERT_TYPES.ERROR, errorMsg);
        }
    }

    let currentTabErrors = produce(errors, (draft: any) => {
        draft[selectedConfigTab] = { ...newErrors };
    });


    return { isError: (Object.keys(newErrors).filter((err) => newErrors[err]).length > 0), currentTabErrors }
};