import { makeStyles } from "@material-ui/core";
// Material UI Components
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";


const useStyles = makeStyles(() => ({
    root: {
        position: "absolute",
        top: "50%",
        left: "50%",
        // transform: "translate(-50%, -50%)",
    },
    container: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 999,
        top: 0,
        left: 0
    }
}));

interface OverlayLoaderProps {
    loading: boolean
}

const OverlayLoader = (props: OverlayLoaderProps) => {
    const { loading } = props;
    const classes = useStyles();
    return loading && <div className={classes.container} ><CircularProgress className={classes.root} size={50} color="primary" /></div>;
};

export default OverlayLoader;
