export const CREATE_NEW_CONFIGURATION_BUTTON = "Create New Configuration";
export const HEADER_TEXT = "Delivery Fees Configurations";

export const TABLE_BODY_NO_DATA_AVAILABLE = "No Delivery Fee Record found.";

export const DELIVERY_FEE_SHIPMENT_LEVEL = "Delivery Fee - Shipment Level ";
export const SHIPMENT_LEVEL_DELIVERY_FEE = "Shipment Level Delivery Fee";
export const DELIVERY_FEE_FOR_MULTI_SHIPMENT_LEVEL = "Delivery Fee For Multishipment";

export const DELIVERY_FEE_MULTI_SHIPMENT_LEVEL = "Delivery Fee - Multishipment";
export const POS_ZONE_MAPPING = "POS-Zone Mapping";

export const SERVICE_TYPE = "Service Type";

export const SHIPMENT_LEVEL_DUMMY_DATA = [
  { id: 1, shipment: "STANDARD", multi_shipment: false },
  { id: 2, shipment: "EXPRESS", multi_shipment: false },
  { id: 3, shipment: "DEFAULT", multi_shipment: false },
  { id: 4, shipment: "EXPRESS-STANDARD", multi_shipment: true },
  { id: 5, shipment: "DEFAULT-EXPRESS", multi_shipment: true },
  { id: 6, shipment: "DEFAULT-STANDARD", multi_shipment: true },
  { id: 7, shipment: "DEFAULT-EXPRESS-STANDARD", multi_shipment: true },
];

export const TOTAL_AREAS = "Total Pos";
export const ASSIGNED_AREAS = "Assigned Pos";
export const ERROR_CREATE_CONFIG = "Error while creating configuration";
export const NO_MESSAGE_AVAILABLE = "No message available";

export const dlvFeeConfigurationRecordServiceDummyObject = [
  {
    shipmentLevelDeliveryFeeConfigs: {
      "2": {
        cncReferenceId: "",
        orderThreshold: 20,
        belowThreshold: 2,
        aboveThreshold: 1,
        additionalDeliveryFeeConfigDTOList: [],
      },
      "3": {
        cncReferenceId: "",
        orderThreshold: 25,
        belowThreshold: 2.7,
        aboveThreshold: 0.5,
        additionalDeliveryFeeConfigDTOList: [],
      },
    },
    posNo: "072",
    storeName: "city centre",
    zoneId: 121,
    zone: "zone01",
  },
  {
    shipmentLevelDeliveryFeeConfigs: {
      "1": {
        cncReferenceId: "",
        orderThreshold: 20,
        belowThreshold: 2,
        aboveThreshold: 1,
        additionalDeliveryFeeConfigDTOList: [],
      },
      "3": {
        cncReferenceId: "",
        orderThreshold: 25,
        belowThreshold: 2.7,
        aboveThreshold: 0.5,
        additionalDeliveryFeeConfigDTOList: [],
      },
    },
    posNo: "072",
    storeName: "city centre",
    zoneId: 121,
    zone: "zone01",
  },
];

export const STANDARD_SERVICE_TYPE = "STANDARD";
export const ADD_CONFIGURATION_TEXT = "Sub Configurations for Delivery Fee";
export const CAPACITY_CONFIGURATION_TEXT = "Capacity Configurations";
