import { makeStyles } from "@material-ui/core";

const completeSetupStyles = makeStyles(() => ({
  completeSetup: {
    marginTop: "80px",
    padding: "24px 12px 24px 24px",
  },
  title: {
    fontWeight: 600,
  },
  footerButton: {
    marginTop: "16px",
  },
  linkstyle: {
    textDecoration: "none",
  },
}));

export default completeSetupStyles;
