export interface UserManagementState {
  searchText: string;
  isExporting: boolean;
  page: any;
  size: any;
  maxRecordPresent: any;
}

export const userManagementInitState: UserManagementState = {
  searchText: "",
  isExporting: false,
  page: 0,
  size: 20,
  maxRecordPresent: 0,
};
