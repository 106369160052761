import { Typography } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Grid from "@material-ui/core/Grid";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TabContext from "@material-ui/lab/TabContext";
import TabPanel from "@material-ui/lab/TabPanel";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ALERT_TYPES, APP_ROUTES, EXPORT_STANDARD_AND_EXPRESS_ENABLE_FLAG, EXPRESS_TYPES, HH_MM_SS, SERVICE_TYPES, STATUS_OK, USER_TYPES, ZONE_CHANGE_STATUS } from "../../../config/constants";
import { selectAllAreas, selectServiceTypes } from "../../../config/redux/configurationsSlice";
import { downloadCSVFile, getAreaNameFromAreaId, getFullAddress, getServiceTypeDetailsFromServiceName } from "../../../utils/helperFunctions";
import { exclusionSlotMessage } from "../../../utils/MessageUtils";
import EmailIcon from "../../assets/svg/email.svg";
import ExpandMoreIcon from "../../assets/svg/ExpandAccordionIcon.svg";
import CustomAlert from "../../common/CustomAlert/CustomAlert";
import LinkButton from "../../common/LinkButton/LinkButton";
import Loader from "../../common/Loader/Loader";
import { selectCountrySettings } from "../../CountrySettings/redux/countrySettingsSlice";
import { ClickAndCollectStatuSwitch, EditPosButton, PosStatusToggle } from "../../POSList/posUtils/PosAccessUtils";
import { activateDeactivateCollectionPoint, activateOrDeactivateAServiceOfPOS, downloadServiceConfigCSV, activateOrDeactivateAServiceZoneOfPOS } from "../redux/completeSetupSlice";
import { RenderDefaultServiceSlots } from "./DefaultServiceSlot";
// Styles
import slotsInformationStyles from "./SlotsInformationStyles";

const SlotsInformation = ({ posNo, serviceTypeName, serviceTypeId, slotsInformation = {}, userInfo = {} }) => {
  const classes = slotsInformationStyles();
  const dispatch = useDispatch();

  const allAreas = useSelector(selectAllAreas);
  const serviceTypes = useSelector(selectServiceTypes);
  const { countryDetails } = useSelector(selectCountrySettings);

  const { loading, error, active, slots, toggling } = slotsInformation;

  const [selectedTab, setSelectedTab] = useState(slots && slots.length ? slots[0].zoneConfig.id.toString() : 0);
  const [expanded, setExpanded] = useState(false);

  const [isExporting, setIsExporting] = useState(false);

  /* Temporary disabling standard and express service export */
  const isExportCSVDisabled = (serviceName = "") => {
    let isDisabled = true;

    if (serviceName === SERVICE_TYPES.CLICK_N_COLLECT) return false;

    if (EXPORT_STANDARD_AND_EXPRESS_ENABLE_FLAG) return false;

    return isDisabled;
  };

  const getCollectionPointDetailsByCollectionName = (cpName, allCollectionPoints) => {
    let allCollectionPointsObj = { ...allCollectionPoints };

    let cpDetails = allCollectionPointsObj[cpName] && allCollectionPointsObj[cpName].collectionPointDetails;
    return cpDetails || {};
  };

  const handleTabChange = (tabIndex) => {
    setSelectedTab(tabIndex);
  };

  const handleServiceActiveInactiveSwitch = async (e, selectedService) => {
    const { checked } = e.target;
    const {
      payload: { statusCode, message: error },
    } = await dispatch(
      activateOrDeactivateAServiceOfPOS({
        posNo,
        serviceType: serviceTypeId,
        serviceTypeName: serviceTypeName.toUpperCase(),
        active: checked,
      })
    );
    if (statusCode === STATUS_OK) {
      // let message = checked
      //   ? `${selectedService} service activated Successfully`
      //   : `${selectedService} service de-activated Successfully`;
      let message = checked ? `Service activated Successfully` : `Service de-activated Successfully`;
      CustomAlert(ALERT_TYPES.SUCCESS, message);
    }

    if (error) {
      CustomAlert(ALERT_TYPES.SUCCESS, error);
    }
  };

  const handleCollectionPointToggleSwitch = async (e, cpName, allCollectionPoints) => {
    const { checked } = e.target;
    const { collectionPointId: cpID, zoneId } = getCollectionPointDetailsByCollectionName(cpName, allCollectionPoints);

    const {
      payload: { status, message: error },
    } = await dispatch(
      activateDeactivateCollectionPoint({
        serviceType: serviceTypeId,
        serviceTypeName: serviceTypeName.toUpperCase(),
        zoneId,
        collectionPointId: cpID,
        active: checked,
      })
    );
    if (status === STATUS_OK) {
      let message = checked ? `Collection point - ${cpName} activated Successfully` : `Collection point - ${cpName} de-activated Successfully`;
      CustomAlert(ALERT_TYPES.SUCCESS, message);
    }

    if (error) {
      CustomAlert(ALERT_TYPES.SUCCESS, error);
    }
  };

  const handleExportCSVClick = async (e) => {
    e.stopPropagation();
    setIsExporting(true);
    const {
      payload: { message: reportDownloadError, csvData, fileName },
    } = await dispatch(downloadServiceConfigCSV({ serviceTypeName, posNo }));

    setIsExporting(false);
    if (reportDownloadError) CustomAlert(ALERT_TYPES.ERROR, reportDownloadError);

    if (csvData && fileName) {
      downloadCSVFile(fileName, csvData);
    }
  };

  const getRegionWiseAreas = (selectedAreaIds = []) => {
    let regionWiseAreas = []; // { regionName: 'Dubai', areas: [] }
    let allAreasNameArr = [];
    let allRegionsArr = [];

    selectedAreaIds.forEach((aId) => allAreasNameArr.push(getAreaNameFromAreaId(aId, allAreas, true)));
    allRegionsArr = allAreasNameArr.map((sa) => sa.regionName);
    allRegionsArr = [...new Set(allRegionsArr)];

    regionWiseAreas = allRegionsArr.reduce((acc, item) => [...acc, { regionName: item, areas: allAreasNameArr.filter((a) => a.regionName === item) }], []);
    return regionWiseAreas;
  };

  const calculateOrderCutOff = (slot) => {
    const { bufferTime, startTime } = slot;
    let orderCutOff = moment(startTime, HH_MM_SS).subtract(bufferTime, "m").format("HH:mm");

    return orderCutOff || "";
  };

  const getExpressType = React.useMemo(() => {
    let expressType = EXPRESS_TYPES.SLOT_BASED.value;

    if (countryDetails && countryDetails.status === STATUS_OK && serviceTypes.length) {
      let {
        data: { serviceTypes: countrySettingServices },
      } = countryDetails;

      let expressId = getServiceTypeDetailsFromServiceName(serviceTypes, SERVICE_TYPES.EXPRESS).id;

      expressType = countrySettingServices.find((s) => s.serviceTypeID === expressId).expressType;
    }

    return expressType;
  }, [countryDetails, serviceTypes]);

  useEffect(() => {
    if (!error && !loading && slots) {
      const tabId = slots[0].zoneConfig.id.toString();
      setSelectedTab(tabId);
    }
  }, [error, loading, slots]);

  const renderExpressOnDemandSlots = (dayWiseSlots) => {
    return (
      <div>
        {Object.keys(dayWiseSlots).map((dayWiseSlot) => (
          <Accordion key={dayWiseSlot} className={classes.slotsAccordion} onClick={(e) => e.stopPropagation()}>
            <AccordionSummary expandIcon={<img src={ExpandMoreIcon} alt="Expand Accordion Icon" />} className={classes.slotsAccordionSummary}>
              <div className={classes.fieldTitle}>
                <span className={classes.fontWeight500}>Slots for : </span>
                <span className={classes.dayLabel}>{`${dayWiseSlot.toLowerCase()} (Capacity - ${dayWiseSlots[dayWiseSlot][0].maximum_slot_capacity || ""})`}</span>
              </div>
            </AccordionSummary>
            <AccordionDetails className={classes.dBlock}>
              <Grid container>
                <Grid item xs={8}>
                  <Grid container spacing={1} className={classes.slotsContainer}>
                    <Grid item xs={4} className={classes.slotsTableHeader}>
                      First customer order time
                    </Grid>
                    <Grid item xs={4} className={classes.slotsTableHeader}>
                      Last order Cut-off Time
                    </Grid>
                    <Grid item xs={4} className={classes.slotsTableHeader}>
                      Capacity
                    </Grid>
                  </Grid>

                  {dayWiseSlots[dayWiseSlot].map((slot, index) => (
                    <div key={slot.id} className={classes.slotDivider} style={{ fontSize: "12px" }}>
                      <Grid container spacing={1} className={classes.slotsContainer}>
                        <Grid item xs={4}>{`${slot.startTime.substr(0, 5)}`}</Grid>
                        <Grid item xs={4}>{`${slot.endTime.substr(0, 5)}`}</Grid>
                        <Grid item xs={4}>
                          {slot.slotCapacity}
                        </Grid>
                      </Grid>
                    </div>
                  ))}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    );
  };

  const renderTemplateSlots = (dayWiseSlots) => {
    let isExpressTypeOnDemand = false;

    if (serviceTypeName === SERVICE_TYPES.EXPRESS) {
      isExpressTypeOnDemand = getExpressType === EXPRESS_TYPES.ON_DEMAND.value;
    }

    return (
      <>
        {Object.keys(dayWiseSlots).map((dayWiseSlot) => (
          <Accordion key={dayWiseSlot} className={classes.slotsAccordion} onClick={(e) => e.stopPropagation()}>
            <AccordionSummary expandIcon={<img src={ExpandMoreIcon} alt="Expand Accordion Icon" />} className={classes.slotsAccordionSummary}>
              <div className={classes.fieldTitle}>
                <span className={classes.fontWeight500}>Slots for : </span>
                <span className={classes.dayLabel}>{`${dayWiseSlot.toLowerCase()} (Capacity - ${dayWiseSlots[dayWiseSlot][0].maximum_slot_capacity || ""})`}</span>
              </div>
            </AccordionSummary>
            <AccordionDetails className={classes.dBlock}>
              <Grid container>
                <Grid item xs={8}>
                  <Grid container spacing={1} className={classes.slotsContainer}>
                    <Grid item xs={3} className={classes.slotsTableHeader}>
                      Slots
                    </Grid>
                    <Grid item xs={3} className={classes.slotsTableHeader}>
                      Capacity
                    </Grid>
                    {!isExpressTypeOnDemand && (
                      <Grid item xs={3} className={classes.slotsTableHeader}>
                        Buffer Time
                      </Grid>
                    )}
                    <Grid item xs={3} className={classes.slotsTableHeader}>
                      Order Cut-Off
                    </Grid>
                  </Grid>

                  {dayWiseSlots[dayWiseSlot].map((slot, index) => (
                    <div key={slot.id} className={classes.slotDivider}>
                      <Grid container spacing={1} className={classes.slotsContainer} style={{ fontSize: "12px" }}>
                        <Grid item xs={3}>
                          {`${slot.startTime.substr(0, 5)} - ${slot.endTime.substr(0, 5)}`}
                        </Grid>
                        <Grid item xs={3}>
                          {slot.slotCapacity}
                        </Grid>
                        {!isExpressTypeOnDemand && (
                          <Grid item xs={3}>
                            {`${slot.bufferTime} mins`}
                          </Grid>
                        )}
                        <Grid item xs={3}>
                          {calculateOrderCutOff(slot)}
                        </Grid>
                      </Grid>
                    </div>
                  ))}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </>
    );
  };

  const renderRegionWiseAreas = (index, regionName = "", regionWiseAreas = []) => {
    return (
      <div className={classes.regionWiseAreas}>
        {index !== 0 && <div className={`${classes.horizontalLine} ${classes.regionSeparator}`}></div>}
        <div className={classes.regionName}>{`Region - ${regionName} (${regionWiseAreas.length})`}</div>
        <Grid container>
          {regionWiseAreas.map((a) => (
            <Grid key={a.areaName} item xs={2} className={classes.fontWeight500}>
              {a.areaName}
            </Grid>
          ))}
        </Grid>
      </div>
    );
  };

  const renderAssignedAreas = (areas) => {
    return (
      <div className={classes.assignedAreas}>
        {getRegionWiseAreas(areas).map((regionWiseArea, index) => (
          <div key={regionWiseArea.regionName}>{renderRegionWiseAreas(index, regionWiseArea.regionName, regionWiseArea.areas)}</div>
        ))}
      </div>
    );
  };

  const renderServiceAllInformation = (tab, cp) => {
    const {
      foodTypeMapping: { foodTypes },
      zoneConfig: { serviceCutOffTime, showFirstSlotAt },
      dayWiseSlots,
      collectionPoints,
      // selectedAreas = [],
    } = tab;
    return (
      <>
        <div className={classes.productTypeInfo}>
          <div className={classes.prodTypeFieldTitle}>{exclusionSlotMessage}</div>
          <div className={`${classes.fieldData} ${classes.fontWeight600}`}>{foodTypes && foodTypes.length > 0 ? foodTypes?.join(", ") : "None"}</div>
        </div>
        <div className={classes.slotsInformation}>
          {showFirstSlotAt || serviceCutOffTime ? (
            <Grid container className={classes.showFirstSlotAtContainer}>
              {showFirstSlotAt && (
                <Grid item xs={3}>
                  <div className={classes.fieldTitle}>Show First Slot At</div>
                  <div className={classes.fieldData}>{moment(showFirstSlotAt, HH_MM_SS).format("HH:mm")}</div>
                </Grid>
              )}
              {serviceCutOffTime && (
                <Grid item xs={3}>
                  <div className={classes.fieldTitle}>Service Cut-Off Time</div>
                  <div className={classes.fieldData}>{moment(serviceCutOffTime, HH_MM_SS).format("HH:mm")}</div>
                </Grid>
              )}
            </Grid>
          ) : null}

          <div className={classes.horizontalLine} />
          <Grid container>
            <Grid item className={classes.slotsAllDaysText}>
              Slots (All Days)
            </Grid>
            <Grid item xs={12} className={classes.dayWiseSlotsContainer}>
              {serviceTypeName === SERVICE_TYPES.EXPRESS && renderExpressOnDemandSlots(dayWiseSlots)}
              {serviceTypeName === SERVICE_TYPES.STANDARD && renderTemplateSlots(dayWiseSlots)}
              {serviceTypeName === SERVICE_TYPES.DEFAULT && <RenderDefaultServiceSlots dayWiseSlots={dayWiseSlots} classes={classes} />}
              {serviceTypeName === SERVICE_TYPES.CLICK_N_COLLECT && renderTemplateSlots(collectionPoints[cp].dayWiseSlots)}
            </Grid>
          </Grid>
        </div>
        {/* {serviceTypeName !== SERVICE_TYPES.CLICK_N_COLLECT && (
          <>
            <div className={classes.horizontalLine} />
            <div className={classes.productTypeInfo}>
              <div className={classes.areasContainer}>{`Mapped Areas (${selectedAreas.length})`}</div>
              <div className={classes.fieldData}>{renderAssignedAreas(selectedAreas)}</div>
            </div>
          </>
        )} */}
      </>
    );
  };

  const renderCollectionPointDetails = (tab, cp) => {
    const { collectionPoints } = tab;

    const collectionPointObj = collectionPoints[cp] && collectionPoints[cp].collectionPointDetails;
    return (
      <Grid container spacing={1}>
        <Grid item xs={2}>
          <div className={classes.fieldTitle}>Contact Name</div>
          <div className={classes.fieldData}>{collectionPointObj.contactName}</div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.fieldTitle}>Email</div>
          <div className={`${classes.fieldData} ${classes.dFlex}`}>
            <img src={EmailIcon} alt="Email" className={classes.emailIcon} />
            {collectionPointObj.contactEmail}
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className={classes.fieldTitle}>Contact No.</div>
          <div className={classes.fieldData}>{collectionPointObj.contactNumber}</div>
        </Grid>
        <Grid item xs={2}>
          <div className={classes.fieldTitle}>Coordinates</div>
          <div className={classes.fieldData}>{`${collectionPointObj.latitude}, ${collectionPointObj.longitude}`}</div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.fieldTitle}>Address</div>
          <div className={classes.fieldData}>{getFullAddress(collectionPointObj)}</div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.fieldTitle}>Reference Id</div>
          <div className={classes.fieldData}>{collectionPointObj.referenceId}</div>
        </Grid>
      </Grid>
    );
  };

  const renderCollectionPoints = (tab) => {
    const { collectionPoints } = tab;
    return (
      <div className={classes.collectionPoints}>
        {Object.keys(collectionPoints).map((cp, cpIndex) => (
          <Accordion key={cp} className={classes.accordion} onClick={(e) => e.stopPropagation()}>
            <AccordionSummary
              expandIcon={<img src={ExpandMoreIcon} alt="Expand Accordion Icon" />}
              className={`${classes.accordionSummary} ${classes.collectionPointAccSummary}`}
              onClick={(e) => e.stopPropagation()}
            >
              <Grid container>
                <Grid item xs={6}>
                  {`${cp} (Capacity - ${collectionPoints[cp].capacityAmountAtCollectionPointLevel || ""})`}
                </Grid>
                <Grid item xs={6}>
                  <Grid container justifyContent="flex-end">
                    <ClickAndCollectStatuSwitch
                      userInfo={userInfo}
                      checked={getCollectionPointDetailsByCollectionName(cp, collectionPoints).active}
                      handleCollectionPointToggleSwitch={(e) => handleCollectionPointToggleSwitch(e, cp, collectionPoints)}
                      classes={classes}
                      disabled={false}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails className={classes.collectionPointAccDetails}>
              {renderCollectionPointDetails(tab, cp)}
              <div className={classes.horizontalLine} />
              {renderServiceAllInformation(tab, cp)}
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    );
  };

  const capitalizeFirstLetter = (nameString) => {
    return nameString.charAt(0).toUpperCase() + nameString.slice(1);
  };

  const renderSupplierAllInformation = (tab) => {
    const { supplierTypes } = tab;
    return (
      <>
        {supplierTypes ? (
          <div>
            {Object.keys(supplierTypes).map((supplier, supplierIndex) => (
              <Accordion key={supplierIndex} className={classes.accordion} onClick={(e) => e.stopPropagation()}>
                <AccordionSummary
                  expandIcon={<img src={ExpandMoreIcon} alt="Expand Accordion Icon" />}
                  className={`${classes.accordionSummary} ${classes.collectionPointAccSummary}`}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Grid container alignItems="center">
                    <Grid item xs={10}>
                      {`${capitalizeFirstLetter(supplier)} ${
                        supplierTypes !== undefined &&
                        supplierTypes[supplier] !== undefined &&
                        supplierTypes[supplier][0] !== undefined &&
                        supplierTypes[supplier][0].total_slot_capacity_at_service_level !== undefined &&
                        "(Capacity - " + supplierTypes[supplier][0].total_slot_capacity_at_service_level + ")"
                      }`}
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails className={classes.collectionPointAccDetails}>
                  <Grid container>
                    <Grid item className={classes.slotsAllDaysText}>
                      Slots (All Days)
                    </Grid>
                    <Grid item xs={12} className={classes.dayWiseSlotsContainer}>
                      <RenderDefaultServiceSlots dayWiseSlots={supplierTypes[supplier].dayWiseSlots} classes={classes} />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        ) : (
          <></>
        )}
      </>
    );
  };

  const handleActivateDeactivateZoneConfigClick = async (event, tabIndex, id) => {
    const { checked } = event.target;
    event.stopPropagation();
    const requestObject = {
      posNo,
      serviceType: serviceTypeId,
      serviceTypeName: serviceTypeName.toUpperCase(),
      activstatus: checked === true ? ZONE_CHANGE_STATUS.ACTIVATED : ZONE_CHANGE_STATUS.DEACTIVATED,
      tabIndex: tabIndex,
      zoneId: id,
      checked: checked,
    };
    const { payload } = await dispatch(activateOrDeactivateAServiceZoneOfPOS(requestObject));
    if (payload && payload.status !== undefined && payload.status === "OK") {
      let message = checked ? `Zone activated Successfully` : `Zone de-activated Successfully`;
      CustomAlert(ALERT_TYPES.SUCCESS, message);
    } else {
      CustomAlert(ALERT_TYPES.ERROR, payload.message);
    }
  };

  const is_all_pos_active_disable_status = (slotsRecord) => {
    if (slotsRecord !== undefined && Array.isArray(slotsRecord) && slotsRecord.length > 0) {
      let count = 0;
      slotsRecord.forEach((slot) => {
        if (slot !== undefined && slot.zoneConfig !== undefined && slot.zoneConfig.status !== undefined && slot.zoneConfig.status === "DEACTIVATED") {
          count++;
        }
      });
      if (count === slotsRecord.length) {
        return true;
      }
    }
    return false;
  };

  return (
    <div className={classes.slotsInfoContainer}>
      {loading && <Loader />}
      {!loading && (
        <Accordion className={!slots ? classes.disableAccordion : classes.activeAccordion} expanded={expanded} onClick={!slots ? () => {} : () => setExpanded(!expanded)}>
          <AccordionSummary expandIcon={slots ? <img src={ExpandMoreIcon} alt="Expand Accordion Icon" /> : null} className={`${classes.accordionSummary} ${classes.serviceAccSummary}`}>
            <Grid container alignItems="center">
              <Grid item xs={4} className={!slots ? classes.disabledServiceType : classes.activeServiceType}>
                {slots ? (
                  <div className={classes.textCapitalize}>{`${serviceTypes.find((s) => s.name === serviceTypeName).displayName}`}</div>
                ) : userInfo?.userProfile?.role !== USER_TYPES.READONLY_USER && userInfo?.userProfile?.role !== USER_TYPES.ADMIN ? (
                  <Link to={`${APP_ROUTES.CREATE_POS}${APP_ROUTES.SERVICE_AND_SLOTS}/${serviceTypeName.toLowerCase()}/${posNo}`} className={classes.linkstyle}>
                    <LinkButton className={classes.configureServiceButton} buttonLabel={`Configure ${serviceTypeName.toLowerCase()}`} />
                  </Link>
                ) : (
                  <LinkButton className={classes.configureServiceButton} buttonLabel={`Configure ${serviceTypeName.toLowerCase()}`} />
                )}
              </Grid>
              <Grid item xs={2}></Grid>

              <Grid item xs={6}>
                {slots ? (
                  <Grid container justifyContent="flex-end">
                    {toggling ? (
                      <Grid item xs={5} className={classes.posRelative}>
                        <Loader />
                      </Grid>
                    ) : userInfo?.userProfile?.role !== USER_TYPES.READONLY_USER && userInfo?.userProfile?.role !== USER_TYPES.ADMIN ? (
                      <PosStatusToggle
                        userInfo={userInfo}
                        serviceTypeName={serviceTypeName}
                        classes={classes}
                        checked={Boolean(active)}
                        handleServiceActiveInactiveSwitch={(e) => handleServiceActiveInactiveSwitch(e, serviceTypeName)}
                        disabled={is_all_pos_active_disable_status(slots)}
                      />
                    ) : (
                      <></>
                    )}
                    <Grid item xs={3}>
                      <LinkButton buttonLabel="Export CSV" onClick={handleExportCSVClick} disabled={isExporting || isExportCSVDisabled(serviceTypeName)} />
                    </Grid>
                    <EditPosButton userInfo={userInfo} classes={classes} serviceTypeName={serviceTypeName} posNo={posNo} />
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </AccordionSummary>

          {slots && selectedTab && (
            <AccordionDetails className={classes.serviceAccDetails} onClick={(e) => e.stopPropagation()}>
              {slots.map((tab, tabIndex) => (
                <Accordion className={classes.zoneAccordionStyle}>
                  <AccordionSummary expandIcon={slots ? <img src={ExpandMoreIcon} alt="Expand Accordion Icon" /> : null} className={`${classes.accordionSummary} ${classes.serviceAccSummary}`}>
                    <Grid container alignItems="center">
                      <Grid item xs={6}>
                        <Typography className={classes.zoneNameTextStyle}>{`${tab.zoneConfig.zoneName.toString()} (Capacity - ${tab.capacityAmountAtZoneLevel || ""})`}</Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Grid container justifyContent="flex-end">
                          {toggling ? (
                            <Grid item xs={5} className={classes.posRelative}>
                              <Loader />
                            </Grid>
                          ) : userInfo?.userProfile?.role !== USER_TYPES.READONLY_USER && userInfo?.userProfile?.role !== USER_TYPES.ADMIN ? (
                            <PosStatusToggle
                              userInfo={userInfo}
                              serviceTypeName={serviceTypeName}
                              classes={classes}
                              checked={tab.zoneConfig.status === ZONE_CHANGE_STATUS.ACTIVATED ? true : false}
                              handleServiceActiveInactiveSwitch={(e) => handleActivateDeactivateZoneConfigClick(e, tabIndex, tab.zoneConfig.id)}
                              disabled={toggling}
                            />
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </AccordionSummary>

                  <AccordionDetails className={classes.serviceAccDetails} onClick={(e) => e.stopPropagation()}>
                    {serviceTypeName !== SERVICE_TYPES.CLICK_N_COLLECT && serviceTypeName !== SERVICE_TYPES.DEFAULT && renderServiceAllInformation(tab)}
                    {serviceTypeName === SERVICE_TYPES.CLICK_N_COLLECT && serviceTypeName !== SERVICE_TYPES.DEFAULT && renderCollectionPoints(tab)}
                    {serviceTypeName === SERVICE_TYPES.DEFAULT && renderSupplierAllInformation(tab)}
                  </AccordionDetails>
                </Accordion>
              ))}

              {/* <div>
                <TabContext value={selectedTab.toString()}>
                  <Tabs value={selectedTab} onChange={(e, tabIndex) => handleTabChange(tabIndex)} onClick={(e) => e.stopPropagation()}>
                    {slots.map((tab) => (
                      <Tab id={`config-tab-${tab.zoneConfig.id}`} key={tab.zoneConfig.id} value={tab.zoneConfig.id.toString()} label={tab.zoneConfig.zoneName} />
                    ))}
                  </Tabs>
                  <div className={classes.zoneTabBorder}></div>

                  {serviceTypeName !== SERVICE_TYPES.CLICK_N_COLLECT &&
                    serviceTypeName !== SERVICE_TYPES.DEFAULT &&
                    slots.map((tab) => (
                      <TabPanel key={tab.zoneConfig.id} value={tab.zoneConfig.id.toString()} className={classes.tabPanelStyle}>
                        {renderServiceAllInformation(tab)}
                      </TabPanel>
                    ))}

                  {serviceTypeName === SERVICE_TYPES.CLICK_N_COLLECT &&
                    serviceTypeName !== SERVICE_TYPES.DEFAULT &&
                    slots.map((tab) => (
                      <TabPanel key={tab.zoneConfig.id} value={tab.zoneConfig.id.toString()} className={classes.tabPanelStyle}>
                        {renderCollectionPoints(tab)}
                      </TabPanel>
                    ))}

                  {serviceTypeName === SERVICE_TYPES.DEFAULT &&
                    slots.map((tab) => (
                      <TabPanel key={tab.zoneConfig.id} value={tab.zoneConfig.id.toString()} className={classes.tabPanelStyle}>
                        {renderSupplierAllInformation(tab)}
                      </TabPanel>
                    ))}
                </TabContext>
              </div> */}
            </AccordionDetails>
          )}
        </Accordion>
      )}
    </div>
  );
};

export default SlotsInformation;
