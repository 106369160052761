import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_RESPONSE_STATUS } from "../../../config/constants";
import * as SingleUsePlasticService from "../../../api/SingleUsePlasticService";

export const fetchPOSZoneServiceMapping = createAsyncThunk("singleUsePlastic/fetchZoneMapping", async (country: any, { rejectWithValue }) => {
  try {
    const { response }: any = await SingleUsePlasticService.fetchPOSZoneServiceMapping({ countryId: country.countryId });
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const fetchPOSBagMapping = createAsyncThunk("singleUsePlastic/fetchPosBagMapping", async ({ posId, countryId }: any, { rejectWithValue }) => {
  try {
    const { response }: any = await SingleUsePlasticService.fetchPOSBagMapping(posId, countryId);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updatePosBagMapping = createAsyncThunk("singleUsePlastic/updatePosBagMapping", async ({ posBagMappingDetail, posId, countryId }: any, { rejectWithValue }) => {
  try {
    const { response }: any = await SingleUsePlasticService.updatePOSBagMapping(posBagMappingDetail, posId, countryId);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const fetchPlasticBagList = createAsyncThunk("singleUsePlastic/fetchPlasticBagRecordList", async (data: any, { rejectWithValue }) => {
  try {
    const { response }: any = await SingleUsePlasticService.fetchPlasticBagRecordList(data);
    if (response && response.success && response.data && response.data.bagChargesList && Array.isArray(response.data.bagChargesList)) {
      return response.data.bagChargesList;
    }
    return [];
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const fetchPosZoneServiceTypeListByCountryId = createAsyncThunk("singleUsePlastic/fetchPosZoneServiceTypeList", async (data: any, { rejectWithValue }) => {
  try {
    const { response }: any = await SingleUsePlasticService.fetchPosZoneServiceTypeList(data);
    if (response && response.data && response.data.content && Array.isArray(response.data.content)) {
      return response.data;
    }
    return [];
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createNewPlasticBagConfig = createAsyncThunk("singleUsePlastic/postNewPlasticBagConfig", async (data: any, { rejectWithValue }) => {
  try {
    const { response }: any = await SingleUsePlasticService.postNewPlasticBagConfig(data);
    if (response && response.success) {
      return response;
    }
    return [];
  } catch (error) {
    return rejectWithValue(error);
  }
});

const commonFetchInitialState = {
  fetchStatus: API_RESPONSE_STATUS.IDLE,
  error: "",
  data: [] as any,
  loading: false,
};

const singleUsePlasticSlice = createSlice({
  name: "singleUsePlastic",
  initialState: {
    posZoneServiceMapping: {},
    posBagMappingDetail: {},
    updatePosBagMapping: {},
    plasticBagDetailsService: {
      ...commonFetchInitialState,
    },
    posZoneListDetailsService: {
      ...commonFetchInitialState,
    },
    createNewConfigService: {
      ...commonFetchInitialState,
    },
  },
  reducers: {
    resetUpdatePosBagMappingState: (state: any, action: any) => {
      state.updatePosBagMapping = {};
    },
  },
  extraReducers: (builders) => {
    builders
      .addCase(fetchPOSZoneServiceMapping.fulfilled, (state: any, action: any) => {
        state.posZoneServiceMapping = action.payload;
      })
      .addCase(fetchPOSBagMapping.fulfilled, (state: any, action: any) => {
        // Add user to the state array
        state.posBagMappingDetail = action.payload;
      })
      .addCase(fetchPOSBagMapping.pending, (state: any, action: any) => {
        // Add user to the state array
        state.posBagMappingDetail = action.payload;
      })
      .addCase(fetchPOSBagMapping.rejected, (state: any, action: any) => {
        // Add user to the state array
        state.posBagMappingDetail = action.payload;
      })
      .addCase(updatePosBagMapping.fulfilled, (state: any, action: any) => {
        // Add user to the state array
        state.updatePosBagMapping = action.payload;
      })
      .addCase(updatePosBagMapping.pending, (state: any, action: any) => {
        // Add user to the state array
        state.updatePosBagMapping = action.payload;
      })
      .addCase(updatePosBagMapping.rejected, (state: any, action: any) => {
        // Add user to the state array
        state.updatePosBagMapping = action.payload;
      })
      /* fetchUserRecordList */
      .addCase(fetchPlasticBagList.pending, (state: any) => {
        state.plasticBagDetailsService.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.plasticBagDetailsService.loading = true;
        state.plasticBagDetailsService.data = [];
        state.plasticBagDetailsService.error = "";
      })
      .addCase(fetchPlasticBagList.fulfilled, (state: any, action: any) => {
        state.plasticBagDetailsService.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.plasticBagDetailsService.loading = false;
        state.plasticBagDetailsService.data = action.payload;
      })
      .addCase(fetchPlasticBagList.rejected, (state: any, action: any) => {
        state.plasticBagDetailsService.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.plasticBagDetailsService.loading = false;
        state.plasticBagDetailsService.error = action.error.message;
      })
      /* fetchPosZoneServiceTypeListByCountryId */
      .addCase(fetchPosZoneServiceTypeListByCountryId.pending, (state: any) => {
        state.posZoneListDetailsService.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.posZoneListDetailsService.loading = true;
        state.posZoneListDetailsService.data = [];
        state.posZoneListDetailsService.error = "";
      })
      .addCase(fetchPosZoneServiceTypeListByCountryId.fulfilled, (state: any, action: any) => {
        state.posZoneListDetailsService.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.posZoneListDetailsService.loading = false;
        state.posZoneListDetailsService.data = action.payload;
      })
      .addCase(fetchPosZoneServiceTypeListByCountryId.rejected, (state: any, action: any) => {
        state.createNewConfigService.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.createNewConfigService.loading = false;
        state.createNewConfigService.error = action.error.message;
      })
      /* postNewPlasticBagConfig */
      .addCase(createNewPlasticBagConfig.pending, (state: any) => {
        state.createNewConfigService.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.createNewConfigService.loading = true;
        state.createNewConfigService.data = [];
        state.createNewConfigService.error = "";
      })
      .addCase(createNewPlasticBagConfig.fulfilled, (state: any, action: any) => {
        state.createNewConfigService.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.createNewConfigService.loading = false;
        state.createNewConfigService.data = action.payload;
      })
      .addCase(createNewPlasticBagConfig.rejected, (state: any, action: any) => {
        state.createNewConfigService.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.createNewConfigService.loading = false;
        state.createNewConfigService.error = action.error.message;
      });
  },
});

export default singleUsePlasticSlice.reducer;
export const { resetUpdatePosBagMappingState } = singleUsePlasticSlice.actions;
export const selectAllPlasticBagRecord = (state: any) => state.singleUsePlastic.plasticBagDetailsService;
export const selectAllPosZoneMapRecord = (state: any) => state.singleUsePlastic.posZoneListDetailsService;
