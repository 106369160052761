import React, { useState } from "react";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import { ReactComponent as EditIcon } from "../../../../assets/svg/Pencil.svg";
import { ReactComponent as DeleteSlotIcon } from "../../../../assets/svg/DeleteIcon.svg";

import TextBox from "../../../../common/TextBox";
import TimePicker from "../../../../common/TimePicker/TimePicker";
import LinkButton from "../../../../common/LinkButton/LinkButton";
import { ReactComponent as CheckedIcon } from "../../../../assets/svg/CheckedIcon.svg";

// Styles
import styles from "./OnDemandSlotInEditModeStyles";
import CustomAlert from "../../../../common/CustomAlert/CustomAlert";
import { ALERT_TYPES } from "../../../../../config/constants";

const OnDemandSlotInEditMode = ({
  tabIndex,
  formIndex,
  slotIndex,
  slot,
  canSlotBeDeleted,
  handleSlotsModified,
  updateOnDemandSlot,
  deleteOnDemandSlot,
  saveNewOnDemandSlot,
}) => {
  const classes = styles();
  const isNewSlot = !slot.id;

  const [localSlot, setLocalSlot] = useState({ ...slot });

  const [isEditable, setIsEditable] = useState(isNewSlot ? true : false);
  const [loading, setLoading] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);

  const handleOnDemandServiceTimeChange = (time, field) => {
    setLocalSlot({ ...localSlot, [field]: time });
  };

  const handleOnDemandCapacityChange = (e) => {
    const { name, value } = e.target;
    setLocalSlot({ ...localSlot, [name]: value });
  };

  const handleCancelEditingASlot = () => {
    if (isNewSlot) {
      deleteOnDemandSlot(tabIndex, formIndex, slot);
      return;
    }
    setIsEditable(false);
    setLocalSlot({ ...localSlot, ...slot });
  };

  const handleSaveSlotSubmit = async () => {
    if (localSlot.slotCapacity === "") {
      CustomAlert(ALERT_TYPES.ERROR, "Please enter capacity for the selected slot");
      return;
    }
    setLoading(true);

    if (isNewSlot) {
      await saveNewOnDemandSlot(tabIndex, formIndex, slotIndex, localSlot);
    } else {
      await updateOnDemandSlot(tabIndex, formIndex, slotIndex, localSlot);
      setIsEditable(false);
      setIsUpdated(true);
    }
    setLoading(false);
    handleSlotsModified(true);
  };

  const handleDeleteSlot = async () => {
    setLoading(true);
    await deleteOnDemandSlot(tabIndex, formIndex, slot);
    setLoading(false);
    handleSlotsModified(true);
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={3}>
        <TimePicker
          value={localSlot.startTime}
          handleChange={(date) => handleOnDemandServiceTimeChange(date, "startTime")}
          disabled={!isEditable}
        />
      </Grid>
      <Grid item xs={3}>
        <TimePicker
          value={localSlot.endTime}
          handleChange={(date) => handleOnDemandServiceTimeChange(date, "endTime")}
          disabled={!isEditable}
        />
      </Grid>

      <Grid item xs={3}>
        <TextBox
          type="number"
          name="slotCapacity"
          value={localSlot.slotCapacity}
          handleChange={handleOnDemandCapacityChange}
          disabled={!isEditable && !isNewSlot}
          placeholderText="Enter Capacity"
          isNegativeAllowed={false}
        />
      </Grid>
      {/* <Grid item xs={1}></Grid> */}

      {isEditable || isNewSlot ? (
        <Grid item xs={2}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <LinkButton
                className={classes.updateSlotButton}
                buttonLabel={loading ? "Updating..." : "Save"}
                onClick={handleSaveSlotSubmit}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={4}>
              <LinkButton
                className={classes.updateSlotButton}
                buttonLabel="Cancel"
                onClick={handleCancelEditingASlot}
              />
              <Grid item xs={3}></Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={2}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={3}>
              <EditIcon className={classes.iconButton} onClick={() => setIsEditable(true)} />
            </Grid>
            {canSlotBeDeleted && (
              <Grid item xs={3}>
                {loading ? "deleting..." : <DeleteSlotIcon className={classes.iconButton} onClick={handleDeleteSlot} />}
              </Grid>
            )}
            <Grid item xs={3}></Grid>
          </Grid>
        </Grid>
      )}

      <Grid item xs={1}>
        {isUpdated && <CheckedIcon />}
      </Grid>
    </Grid>
  );
};

export default OnDemandSlotInEditMode;
