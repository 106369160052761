import { makeStyles } from "@material-ui/core/styles";

import { COLORS } from "../../config/theme/theme";

export const posListStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-center",
    alignItems: "center",
  },
  posListSection: {
    marginTop: "80px",
    padding: "24px 12px 24px 24px",
  },
  cardSection: {
    marginTop: "24px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.04)",
    borderRadius: "8px",
    background: "#F5F5F5",
  },
  tabsContainer: {
    padding: "0px",
    paddingBottom: "0px !important",
  },
  tabs: {
    padding: "0px 16px",
  },
  heading: {
    fontWeight: 600,
    fontSize: "24px",
    color: COLORS.DARK_GRAY,
  },
  posListCount: {
    width: "40px",
    height: "32px",
    background: "#E6E6E6",
    borderRadius: "4px",
    marginLeft: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 500,
  },
  tabLabel: {
    fontWeight: 600,
    fontSize: "14px",
    color: COLORS.TEXT_PRIMARY,
    "&.MuiTabs-indicator": {
      height: "2px",
    },
    marginRight: "24px",
  },
  createButton: {
    height: "40px",
    width: "100%",
  },
  searchAndFilterContainer: {
    marginTop: "24px",
    marginBottom: "24px",
  },
  searchFilter: {
    width: "364px",
    background: COLORS.WHITE,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E6E6E6",
      top: "0px",
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#E6E6E6",
        top: "0px",
      },
    },
  },
  textBox: {},
  filterText: {
    fontWeight: 500,
    fontSize: "12px",
    color: COLORS.DARK_GRAY,
    padding: "10px",
  },
  completeSetupSettingsButton: {
    display: "flex",
    background: "none",
    "& img": {
      height: "18px",
    },
  },
  serviceTypeContainer: {
    margin: 0,
    padding: 0,
  },
  activeServiceTypes: {
    height: "20px",
    background: "#BFE4E1",
    borderRadius: "4px",
    display: "inline-block",
    color: "#007F73",
    fontWeight: 600,
  },
  leftMargin8: {
    margin: "0px 0px 8px 8px",
  },
  serviceType: {
    height: "20px",
    fontSize: "12px",
    padding: "0px 8px",
    display: "flex",
    alignItems: "center",
  },
  inactiveServiceTypes: {
    height: "23px",
    background: "#E6E6E6",
    borderRadius: "4px",
    display: "inline-block",
    color: "#B2B2B2",
    fontWeight: 600,
  },
  posTypeFilter: {
    backgroundColor: COLORS.WHITE,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px 0px 0px 8px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E6E6E6",
      top: "0px",
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#E6E6E6",
        top: "0px",
      },
    },
  },
  serviceTypeFilter: {
    backgroundColor: COLORS.WHITE,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "0px 8px 8px 0px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E6E6E6",
      borderLeft: "none",
      top: "0px",
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#E6E6E6",
        top: "0px",
      },
    },
  },
  fullWidthButton: {
    width: "100%",
  },
  linkstyle: {
    textDecoration: "none",
  },
  filterByTextStyle: {
    textAlign: "right",
  },
  tableStyle: {
    margin: 0,
    padding: 0,
    minHeight: "500px",
    maxHeight: "calc(100vh - 330px)",
    width: "100%",
    overflow: "auto",
  },
  buttonStyles: {
    width: "100%",
    height: "40px",
    background: "#DD2326",
    color: "#FFFFFF",
    border: "1px solid #DD2326",
    borderRadius: "4px",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    "&:hover": {
      backgroundColor: "#DD2326",
      color: "#FFFFFF",
      border: "1px solid #DD2326",
      borderRadius: "8px",
    },
    "&:disabled": {
      border: "1px solid rgba(0, 0, 0, 0.12)",
    },
  },
}));
