import { ADDITIONAL_SERVICES, APP_ROUTES, USER_TYPES } from "./../../../config/constants";

export function getTopBarMenuItems(userProfile: any) {
  let userOption: any = [
    {
      label: "DASHBOARD",
      value: "DASHBOARD",
      to: APP_ROUTES.DASHBOARD,
      isDisabled: true,
    },
    {
      label: "POS",
      value: "POS",
      to: APP_ROUTES.POS_LIST,
      isDefault: true,
      isDisabled: false,
    },
  ];

  if (userProfile?.role === USER_TYPES.MAF_ADMIN || userProfile?.role === USER_TYPES.SUPER_ADMIN) {
    userOption.push({
      label: "APPROVAL REQUESTS",
      value: "APPROVAL REQUESTS",
      to: APP_ROUTES.APPROVAL_REQUESTS,
      isDisabled: true,
    });
  }

  if (userProfile?.role === USER_TYPES.MAF_ADMIN || userProfile?.role === USER_TYPES.SUPER_ADMIN) {
    userOption.push({
      label: "USER MANAGEMENT",
      value: "USER MANAGEMENT",
      to: APP_ROUTES.USER_MANAGEMENT,
      isDisabled: false,
    });
  }

  userOption.push({
    label: "RULE ENGINE",
    value: "RULE ENGINE",
    to: APP_ROUTES.RULE_ENGINE,
    isDisabled: false,
  });

  userOption.push({
    label: "SINGLE USE PLASTIC",
    value: "SINGLE USE PLASTIC",
    to: APP_ROUTES.SINGLE_USE_PLASTIC,
    isDisabled: false,
  });

  if (
    userProfile !== undefined &&
    userProfile.featureId !== undefined &&
    userProfile.featureId !== "" &&
    Array.isArray(userProfile.featureId) &&
    userProfile.featureId.length > 0 &&
    userProfile.featureId.includes(ADDITIONAL_SERVICES.DELIVERY_FEE)
  ) {
    userOption.push({
      label: "DELIVERY FEE",
      value: "DELIVERY FEE",
      to: APP_ROUTES.DELIVERY_FEE,
      isDisabled: false,
    });
  }

  return userOption;
}
