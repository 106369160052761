import { Grid, Typography } from "@material-ui/core";
//Material UI Components
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { APP_ROUTES, USER_TYPES } from "../../../config/constants";
import { selectAllAreas, selectAllRegions } from "../../../config/redux/configurationsSlice";
// Utils
import { getFullAddress, getRegionNameFromRegionId } from "../../../utils/helperFunctions";
// SVG's
import ExpandMoreIcon from "../../assets/svg/ExpandAccordionIcon.svg";
import EditIcon from "../../assets/svg/Pencil.svg";
import Loader from "../../common/Loader/Loader";
// Styles
import basicInformationStyles from "./BasicInformationStyles";

const BasicInformation = ({ loading, posDetails, isEditable = true, userInfo = {} }) => {
  const { storeName, posType, posNo, services, posContacts, posAddress } = posDetails;

  const classes = basicInformationStyles();

  const regions = useSelector(selectAllRegions);
  const areas = useSelector(selectAllAreas);

  const getAreaNameFromAreaId = (regionId, areaId) => {
    let areaObj = areas.find((a) => a.id === parseInt(areaId));
    return areaObj ? areaObj.name : "";
  };

  const renderServices = () => {
    return services.length ? services.map((s) => s.name).join(", ") : "";
  };

  return (
    <Accordion defaultExpanded className={classes.accordion}>
      <AccordionSummary expandIcon={<img src={ExpandMoreIcon} alt="Expand Accordion Icon" />} className={classes.accordionSummary}>
        <Grid container alignItems="center">
          <Grid item xs={10}>
            Basic Details
          </Grid>
          {isEditable && userInfo?.userProfile?.role !== USER_TYPES.READONLY_USER && userInfo?.userProfile?.role !== USER_TYPES.ADMIN && (
            <Grid item xs={2}>
              <Grid container>
                <Grid item xs={10}></Grid>
                <Grid item xs={2}>
                  <Link to={`${APP_ROUTES.CREATE_POS}${APP_ROUTES.BASIC_DETAILS}/${posNo}`} className={classes.dFlex}>
                    <img src={EditIcon} alt="Expand Accordion Icon" />
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Grid container>
              <Grid item>
                <Typography className={classes.primaryInfoTitle}>Primary Information</Typography>
              </Grid>
              <Grid container className={classes.informationContainer}>
                <Grid item xs={2}>
                  <div className={classes.fieldTitle}>Store Name</div>
                  <div className={classes.fieldData}>{storeName}</div>
                </Grid>
                <Grid item xs={2}>
                  <div className={classes.fieldTitle}>POS Type</div>
                  <div className={classes.fieldData}>{posType}</div>
                </Grid>
                <Grid item xs={2}>
                  <div className={classes.fieldTitle}>POS Number</div>
                  <div className={classes.fieldData}>{posNo}</div>
                </Grid>
                <Grid item xs={3}>
                  <div className={classes.fieldTitle}>Service Types</div>
                  <div className={classes.fieldData}>{services && renderServices()}</div>
                </Grid>
                <Grid item xs={3}></Grid>
              </Grid>
              <div className={classes.horizontalLine} />
              <Grid container>
                <Grid item>
                  <Typography className={classes.primaryInfoTitle}>Address Information</Typography>
                </Grid>
                <Grid container className={classes.informationContainer}>
                  <Grid item xs={2}>
                    <div className={classes.fieldTitle}>Name</div>
                    <div className={classes.fieldData}>{posContacts && posContacts[0].name}</div>
                  </Grid>
                  <Grid item xs={2}>
                    <div className={classes.fieldTitle}>Email</div>
                    <div className={`${classes.fieldData} ${classes.dFlex}`}>{posContacts && posContacts[0].email}</div>
                  </Grid>
                  <Grid item xs={2}>
                    <div className={classes.fieldTitle}>Phone Number</div>
                    <div className={`${classes.fieldData} ${classes.dFlex}`}>{posContacts && posContacts[0].phoneNumber}</div>
                  </Grid>
                  <Grid item xs={2}>
                    <div className={classes.fieldTitle}>Coordinates</div>
                    <div className={classes.fieldData}>{posAddress && `${posAddress.latitude}, ${posAddress.longitude}`}</div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className={classes.fieldTitle}>Address</div>
                    <div className={classes.fieldData}>
                      {posAddress &&
                        getFullAddress({
                          ...posAddress,
                          region: getRegionNameFromRegionId(posAddress.region, regions),
                          area: getAreaNameFromAreaId(posAddress.region, posAddress.area),
                        })}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default BasicInformation;
