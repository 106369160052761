import { Grid, IconButton, InputAdornment } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TextBox from "components/common/TextBox";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_RESPONSE_STATUS, SERVICE_TYPES } from "../../../config/constants";
import searchIcon from "../../assets/svg/SearchIcon.svg";
import PageTitle from "../../common/PageTitle/PageTitle";
import Table from "../../common/Table/Table";
import { selectUserDetails } from "../../Login/redux/userSlice";
import { fetchCurrentConfigurationAction, selectDeliveryPromiseServiceCurrConfiguration } from "../redux/ruleEngineSlice";
import commonStyles from "../RuleEngineCommonStyles";
import { RuleEngineCreateConfigButtonAccess } from "../Utils/RuleEngineAccessUtils";
import currentConfigStyle from "./CurrentConfigurationStyles";
import { getCurrentConfigTableColumn } from "./CurrentConfigUtils";

// const BREADCRUMB_LINKS = [{ name: "Rule Engine", to: APP_ROUTES.RULE_ENGINE, isCurrentRoute: true }];
interface DeliveryPromiseServiceCurrentConfigurationsInterface {
  serviceName: any;
  userInfo: any;
}
const DeliveryPromiseServiceCurrentConfigurations = (props: DeliveryPromiseServiceCurrentConfigurationsInterface) => {
  const { serviceName, userInfo } = props;

  const commonClasses = commonStyles();
  const classes = currentConfigStyle();
  const [searchedText, setSearchedText] = useState<string>("");
  const [tableColumns, setTableColumns] = useState<any>([]);

  const dispatch = useDispatch();

  const [apiParams, setApiParams] = useState({
    query: searchedText,
    currentPage: 0,
    size: 20,
    maxRecordPresent: 0,
  });

  const {
    currentCountry: { countryId },
  } = useSelector((state: any) => state.appConfig);
  const { fetchStatus: deliveryPromiseServiceCurrConfigStatus, data: deliveryPromiseServiceCurrConfiguration } = useSelector((state) =>
    selectDeliveryPromiseServiceCurrConfiguration(state, serviceName)
  );

  const getTableRows = () => {
    let tableRows = [];
    const data = deliveryPromiseServiceCurrConfiguration?.data;

    if (data && data.content) {
      tableRows = data.content;
    }
    return tableRows;
  };

  const fetchServiceCurrentConfigurations = useCallback(
    (pageNumber: any, size: any, query: any) => {
      dispatch(
        fetchCurrentConfigurationAction({
          serviceTypeName: SERVICE_TYPES.EXPRESS,
          countryId: countryId,
          page: pageNumber,
          size: size,
          // query: query,
        })
      );
    },
    [countryId, dispatch]
  );

  // used to handle serach filter value change
  const handleSearchInputChange = (e: any) => {
    if (e.target.value === "") {
      setApiParams({
        ...apiParams,
        currentPage: 0,
        query: "",
        maxRecordPresent: 0,
      });
      fetchServiceCurrentConfigurations(0, apiParams.size, "");
    }
    setSearchedText(e.target.value);
  };

  // used to handle serach filter enter key change
  const handleKeyUp = (e: any) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      setApiParams({
        ...apiParams,
        currentPage: 0,
        query: searchedText,
        maxRecordPresent: 0,
      });
      fetchServiceCurrentConfigurations(0, apiParams.size, searchedText);
    }
  };

  const handleSearch = () => {
    setApiParams({
      ...apiParams,
      currentPage: 0,
      query: searchedText,
      maxRecordPresent: 0,
    });
    fetchServiceCurrentConfigurations(0, apiParams.size, searchedText);
  };

  const fetchNextRecord = useCallback(
    (pageNumber: any) => {
      setApiParams({
        ...apiParams,
        currentPage: pageNumber,
      });
      fetchServiceCurrentConfigurations(pageNumber, apiParams.size, "");
    },
    [apiParams, fetchServiceCurrentConfigurations]
  );

  const getPageNumber = (userDataRecord: any) => {
    if (userDataRecord && userDataRecord.number && userDataRecord.number >= 0) {
      return userDataRecord.number;
    }
    return 0;
  };

  const getMaxRecordPresent = (userDataRecord: any) => {
    if (userDataRecord && userDataRecord.totalElements) {
      return userDataRecord.totalElements;
    }
    return 0;
  };

  useEffect(() => {
    function updateTableFooterData() {
      if (deliveryPromiseServiceCurrConfiguration?.data?.content) {
        const tableRows = deliveryPromiseServiceCurrConfiguration.data;
        setApiParams({ ...apiParams, currentPage: getPageNumber(tableRows), maxRecordPresent: getMaxRecordPresent(tableRows) });
      }
    }
    updateTableFooterData();
  }, [
    deliveryPromiseServiceCurrConfiguration,
    deliveryPromiseServiceCurrConfiguration?.data,
    deliveryPromiseServiceCurrConfiguration?.data?.content,
    deliveryPromiseServiceCurrConfiguration?.data?.pageNumber,
  ]);

  useEffect(() => {
    userInfo && userInfo.userProfile && userInfo.userProfile.role && setTableColumns(getCurrentConfigTableColumn(classes, userInfo));
  }, [userInfo]);

  useEffect(() => {
    if (countryId !== "") {
      fetchServiceCurrentConfigurations(apiParams.currentPage, apiParams.size, apiParams.query);
    }
  }, [dispatch, countryId]);

  return (
    <Accordion defaultExpanded className={commonClasses.accordion}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} className={commonClasses.accordionSummary}>
        {serviceName}
      </AccordionSummary>
      <AccordionDetails className={commonClasses.accordionDetailsDeliveryPromiseTable}>
        <div className={commonClasses.fullScreenStyle}>
          <Grid container>
            <Grid item xs={3}>
              <TextBox
                label="Search Pos Code"
                placeholderText="Search"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton edge="end" size="small">
                      <img src={searchIcon} alt="search" onClick={handleSearch} />
                    </IconButton>
                  </InputAdornment>
                }
                value={searchedText}
                handleChange={handleSearchInputChange}
                onKeyUp={handleKeyUp}
                className={classes.searchBoxDivStyle}
              />
            </Grid>
            <Grid item xs={8} />
            <Grid item xs={12}>
              <div className={classes.tableStyle}>
                <Table
                  columnsData={tableColumns}
                  rowsData={getTableRows()}
                  loading={deliveryPromiseServiceCurrConfigStatus === API_RESPONSE_STATUS.LOADING}
                  hideFooter={false}
                  pageSize={apiParams.size}
                  rowCount={apiParams.maxRecordPresent}
                  pageNumber={apiParams.currentPage}
                  fetchNextRecord={fetchNextRecord}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const CurrentConfiguration = () => {
  const classes = currentConfigStyle();
  const userInfo = useSelector(selectUserDetails);

  return (
    <div className={classes.root}>
      <div className={classes.headerRoot}>
        <div className={classes.header}>
          <span className={classes.heading}>{`Delivery Promise Rule Engine Current Configuration`}</span>
        </div>
        <RuleEngineCreateConfigButtonAccess userInfo={userInfo} classes={classes} />
      </div>
      {userInfo && <DeliveryPromiseServiceCurrentConfigurations serviceName={SERVICE_TYPES.EXPRESS} userInfo={userInfo} />}
    </div>
  );
};

export default React.memo(CurrentConfiguration);
