import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React from "react";
import { calculateOrderCutOff } from "../../../../components/ServiceAndSlots/utils/SlotServicesUtils";
import { generateDropdownItems } from "../../../../utils/helperFunctions";
import SelectBox from "../../../common/SelectBox/SelectBox";
import TextBox from "../../../common/TextBox";
import { DefaultServiceStartTime } from "../base/DefaultUtils";
import styles from "./SlotRangeStyles";



const HOURS = generateDropdownItems(0, 95, "hours");

interface SlotRangeProps {
  tabIndex: number,
  formIndex: number,
  rangeIndex: number,
  dateRange: any,
  minimumDays: any,
  maximumDays: any,
  bufferTime: any,
  capacity: any,
  onIndividualRangeFieldsChange: any,
}

const SlotRange = (props: SlotRangeProps) => {
  const { dateRange, onIndividualRangeFieldsChange, capacity, bufferTime, tabIndex, formIndex, rangeIndex } = props;
  const classes = styles();
  return (
    <Grid container spacing={2} style={{ marginBottom: "24px" }}>
      <Grid item xs={3}>
        <FormControlLabel
          control={<Checkbox checked value={dateRange} />}
          label={<Typography className={classes.checkboxLabel}>{dateRange}</Typography>}
        />
      </Grid>
      <Grid item xs={3}>
        <TextBox
          placeholderText="Slot Capacity"
          name="capacity"
          value={capacity}
          handleChange={(e: any) => onIndividualRangeFieldsChange(tabIndex, formIndex, rangeIndex, e)}
        />
      </Grid>
      <Grid item xs={3}>
        <SelectBox
          name="bufferTime"
          menuItems={HOURS}
          value={bufferTime}
          handleChange={(e: any) => onIndividualRangeFieldsChange(tabIndex, formIndex, rangeIndex, e)}
        />
      </Grid>
      <Grid item xs={3} style={{
        alignSelf: 'center'
      }}>
        {calculateOrderCutOff({ bufferTimeHours: bufferTime, bufferTimeMinutes: 0, startTime: DefaultServiceStartTime })}
      </Grid>
    </Grid >
  );
};

export default SlotRange;
