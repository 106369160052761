import { APP_ROUTES, USER_TYPES } from "config/constants";
import { Link } from "react-router-dom";
import Pencil from "../../assets/svg/Pencil.svg";
import { userStyles } from "../SingleUsePlasticStyles";

export const getSingleUsePlasticListColumns = (userInfo: any) => {
  const classes = userStyles();
  return [
    { sortable: false, flex: 1, field: "posNo", headerName: "POS Number", headerClassName: classes.headerStyle },
    { sortable: false, flex: 2, field: "storeName", headerName: "Pos Name", headerClassName: classes.headerStyle },
    { sortable: false, flex: 1, field: "totalConfigs", headerName: "Total Configs", headerClassName: classes.headerStyle },
    { sortable: false, flex: 1, field: "activeConfigs", headerName: "Active Configs", headerClassName: classes.headerStyle },
    {
      sortable: false,
      flex: 0.5,
      field: "actions",
      headerName: "Actions",
      headerClassName: classes.headerStyle,
      renderCell: (params: any) => {
        const {
          row: { posId, posNo },
        } = params;
        return userInfo?.userProfile?.role !== USER_TYPES.READONLY_USER && userInfo?.userProfile?.role !== USER_TYPES.ADMIN ? (
          <Link to={{ pathname: `${APP_ROUTES.EDIT_SINGLE_USE_PLASTIC_CURRENT_CONFIGURATION}`, search: `?posId=${posId}&posNo=${posNo}` }}>
            <div className={classes.editIconStyle}>
              <img src={Pencil} alt="Edit config" />
            </div>
          </Link>
        ) : (
          <div className={classes.disabledEditIconStyle}>
            <img src={Pencil} alt="Edit config" />
          </div>
        );
      },
    },
  ];
};
