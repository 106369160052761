import Grid from "@material-ui/core/Grid";
import { produce } from "immer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { ALERT_TYPES, APP_ROUTES, SERVICE_TYPES, STATUS_OK } from "../../config/constants";
import CustomAlert from "../common/CustomAlert/CustomAlert";
import CustomBreadcrumbs from "../common/CustomBreadcrumbs/CustomBreadcrumbs";
import PageTitle from "../common/PageTitle/PageTitle";
import PrimaryButton from "../common/PrimaryButton/PrimaryButton";
import PreviewDeliveryPromise from "./PreviewDeliveryPromise/PreviewDeliveryPromise";
import { getDeliveryRuleEngineConfig, saveDeliveryRuleEngineConfig } from "./redux/ruleEngineSlice";
import ruleEngineStyles from "./RuleEnginePageStyles.js";
import {
  BREADCRUMB_LINKS,
  convertHoursOrMinutesToSeconds,
  convertWeightInKiloGramsToGrams,
  DEFAULT_RULE_CONFIG,
  DEFAULT_RULE_CONFIG_STATE,
  getConfiguredData,
  getRuleConfig,
  valiadteRuleEngineData,
} from "./RuleEngineUtils";
import ServiceRuleEngine from "./ServiceRuleEngine/ServiceRuleEngine";
import { selectUserDetails } from "../Login/redux/userSlice";
import { RuleEngineCurrentConfigButtonAccess } from "./Utils/RuleEngineAccessUtils";

const RuleEnginePage = () => {
  const classes = ruleEngineStyles();
  let location = useLocation<any>();
  const userInfo: any = useSelector(selectUserDetails);

  const [expressRuleConfig, setExpressRuleConfig] = useState<DEFAULT_RULE_CONFIG>({
    ...DEFAULT_RULE_CONFIG_STATE,
    serviceType: SERVICE_TYPES.EXPRESS,
  });

  const [showPreviewModal, setShowPreviewModal] = useState(false);

  const [isSaving, setIsSaving] = useState(false);
  const [resetTransferList, setResetTransferList] = useState(false);

  // Used to map difference between current and updated state
  const [currentState, setCurrentConfig] = useState<DEFAULT_RULE_CONFIG>({
    ...DEFAULT_RULE_CONFIG_STATE,
    serviceType: SERVICE_TYPES.EXPRESS,
  });

  useEffect(() => {
    const getCurrentConfig = async () => {
      let params = new URLSearchParams(location.search);
      // check search params available or not
      if (location.search) {
        setIsSaving(true);
        let requestPayload = {
          countryId: params.get("countryId"),
          serviceType: params.get("serviceType"),
          posId: params.get("posNo"),
          zoneId: params.get("zoneId"),
        };
        // call api if all params area availble
        if (requestPayload.countryId && requestPayload.serviceType && requestPayload.posId && requestPayload.zoneId) {
          const { payload }: any = await dispatch(getDeliveryRuleEngineConfig({ requestPayload }));
          if (payload && payload.data) {
            setIsSaving(false);
            let Info = getConfiguredData(payload.data);
            setExpressRuleConfig({
              ...expressRuleConfig,
              ...Info,
            });
            setCurrentConfig({
              ...currentState,
              ...Info,
            });
          }
        } else {
          // Removed all saved Config
          setExpressRuleConfig({
            ...DEFAULT_RULE_CONFIG_STATE,
            serviceType: SERVICE_TYPES.EXPRESS,
          });
          setCurrentConfig({
            ...DEFAULT_RULE_CONFIG_STATE,
            serviceType: SERVICE_TYPES.EXPRESS,
          });
        }
      }
    };
    getCurrentConfig();
    // eslint-disable-next-line
  }, [location.search]);

  const {
    currentCountry: { countryId },
  } = useSelector((state: any) => state.appConfig);
  const dispatch = useDispatch();

  const handleDynamicPromiseChange = (serviceName: string, e: any) => {
    const { checked } = e.target;
    // reset various value on edit mode
    if (expressRuleConfig.isEditMode) {
      let isPromiseChnaged = currentState.dynamicPromise !== checked;
      // reset all rule on promise change
      let ruleConfig: any = getRuleConfig(isPromiseChnaged, checked, currentState);
      setExpressRuleConfig({
        ...expressRuleConfig,
        dynamicPromise: checked,
        defaultOrFixedTimeSameAsEarlier: isPromiseChnaged ? true : currentState.defaultOrFixedTimeSameAsEarlier,
        defaultOrFixedTimeHours: isPromiseChnaged ? "" : currentState.defaultOrFixedTimeHours,
        defaultOrFixedTimeMinutes: isPromiseChnaged ? "" : currentState.defaultOrFixedTimeMinutes,
        defaultOrFixedTime: isPromiseChnaged ? 0 : currentState.defaultOrFixedTime,
        enableDefaultTimeEdit: isPromiseChnaged,
        ...ruleConfig,
      });
    } else
      setExpressRuleConfig({
        ...expressRuleConfig,
        dynamicPromise: checked,
        defaultOrFixedTimeSameAsEarlier: true,
        defaultOrFixedTimeHours: "",
        defaultOrFixedTimeMinutes: "",
        defaultOrFixedTime: 0,
      });
  };

  const handleDefaultOrFixedTimeChange = (serviceName: string, fieldName: string, e: any) => {
    const { value } = e.target;
    setExpressRuleConfig({
      ...expressRuleConfig,
      [fieldName]: value,
    });
  };

  const handleDefaultTimeIsSameAsEarlierChange = (serviceName: string, e: any) => {
    const { checked } = e.target;
    setExpressRuleConfig({
      ...expressRuleConfig,
      defaultOrFixedTimeSameAsEarlier: checked,
      defaultOrFixedTimeHours: "",
      defaultOrFixedTimeMinutes: "",
      defaultOrFixedTime: 0,
    });
  };

  const handleUseForConfigurationSwitchChange = (serviceName: string, ruleName: string, e: any) => {
    const { checked } = e.target;

    let valuesSet: any = DEFAULT_RULE_CONFIG_STATE[ruleName as keyof DEFAULT_RULE_CONFIG];
    setExpressRuleConfig({
      ...expressRuleConfig,
      [ruleName]: {
        ...valuesSet,
        active: checked,
        sameAsEarlier: false,
      },
    });
  };

  const handleIsSameAsEarlierCheckbox = (serviceName: string, ruleName: string, e: any) => {
    const { checked } = e.target;
    let valuesSet: any = DEFAULT_RULE_CONFIG_STATE[ruleName as keyof DEFAULT_RULE_CONFIG];
    setExpressRuleConfig({
      ...expressRuleConfig,
      [ruleName]: {
        ...valuesSet,
        sameAsEarlier: checked,
      },
    });
  };

  const handleWeightRuleFieldsChange = (serviceName: string, e: any) => {
    const { name, value } = e.target;
    setExpressRuleConfig({ ...expressRuleConfig, weightRule: { ...expressRuleConfig.weightRule, [name]: value } });
  };

  const handleCountRuleFieldsChange = (serviceName: string, e: any) => {
    const { name, value } = e.target;
    setExpressRuleConfig({ ...expressRuleConfig, countRule: { ...expressRuleConfig.countRule, [name]: value } });
  };

  const handleAddScalableItems = (serviceName: string) => {
    let newItem: any = { section: "", incrementalTime: "" };

    let newExpressConfig = produce(expressRuleConfig, (draft: any) => {
      draft.scalableTypeRule.itemList.push(newItem);
    });
    setExpressRuleConfig(newExpressConfig);
  };

  const handleRemoveScalableItems = (serviceName: string, itemIndex: number) => {
    let newExpressConfig = produce(expressRuleConfig, (draft: any) => {
      draft.scalableTypeRule.itemList.splice(itemIndex, 1);
    });
    setExpressRuleConfig(newExpressConfig);
  };

  const handleScalableItemsFieldChange = (serviceName: string, itemIndex: number, e: any) => {
    const { name, value } = e.target;
    let newExpressConfig = produce(expressRuleConfig, (draft: any) => {
      draft.scalableTypeRule.itemList[itemIndex][name] = value;
    });
    setExpressRuleConfig(newExpressConfig);
  };

  const handlePOSZonesSelectChange = (serviceName: string, selectedZonesList = []) => {
    let newExpressConfig = produce(expressRuleConfig, (draft: any) => {
      draft.posZoneIdentifierList = selectedZonesList;
    });
    setExpressRuleConfig(newExpressConfig);
  };

  const handleSaveDeliveryRuleEngineConfig = async () => {
    let requestPayload = [];
    let expressConfigPayload = {
      serviceType: expressRuleConfig.serviceType,
      dynamicPromise: expressRuleConfig.dynamicPromise,
      defaultOrFixedTimeSameAsEarlier: expressRuleConfig.defaultOrFixedTimeSameAsEarlier,
      defaultOrFixedTimeHours: expressRuleConfig.defaultOrFixedTimeHours,
      defaultOrFixedTimeMinutes: expressRuleConfig.defaultOrFixedTimeMinutes,
      posZoneIdentifierList: expressRuleConfig.posZoneIdentifierList,
      countryId,
      defaultOrFixedTime: convertHoursOrMinutesToSeconds(expressRuleConfig.defaultOrFixedTimeHours, "hours") + convertHoursOrMinutesToSeconds(expressRuleConfig.defaultOrFixedTimeMinutes, "minutes"),
      weightRule: {
        ...expressRuleConfig.weightRule,
        thresholdWeight: convertWeightInKiloGramsToGrams(expressRuleConfig.weightRule.thresholdWeight),
        incrementalTime: convertHoursOrMinutesToSeconds(expressRuleConfig.weightRule.incrementalTime, "minutes"),
      },
      countRule: {
        ...expressRuleConfig.countRule,
        thresholdCount: expressRuleConfig.countRule.thresholdCount || 0,
        incrementalTime: expressRuleConfig.countRule.incrementalTime || 0,
      },
      scalableTypeRule: {
        ...expressRuleConfig.scalableTypeRule,
        itemList: expressRuleConfig.scalableTypeRule.itemList.map((item: any) => ({
          ...item,
          incrementalTime: convertHoursOrMinutesToSeconds(item.incrementalTime, "minutes"),
        })),
      },
      status: expressRuleConfig.status,
    };

    requestPayload.push(expressConfigPayload);

    setIsSaving(true);
    const { payload }: any = await dispatch(saveDeliveryRuleEngineConfig({ requestPayload }));
    setIsSaving(false);

    if (payload.message) {
      CustomAlert(ALERT_TYPES.ERROR, payload.message);
    }

    if (payload.status === STATUS_OK) {
      if (expressRuleConfig.isEditMode) {
        // Upadte Pervious save state with new cofig state
        setCurrentConfig({
          ...expressRuleConfig,
        });
        CustomAlert(ALERT_TYPES.SUCCESS, "Delivery Rule Engine configurations updated successfully");
      } else {
        CustomAlert(ALERT_TYPES.SUCCESS, "Delivery Rule Engine configurations saved successfully");
        // Upadte Express config as init blanck config
        setExpressRuleConfig({
          ...currentState,
        });
        setResetTransferList((prev) => !prev);
        // window.location.reload();
      }
    }

    setShowPreviewModal(false);
  };

  const renderButtons = () => {
    return (
      <Grid container className={classes.footerDivStyle}>
        <Grid item xs={7}></Grid>
        <Grid item xs={5}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <PrimaryButton
                buttonVariant="outlined"
                buttonLabel="Reset"
                onClick={() => {
                  if (!expressRuleConfig.isEditMode) {
                    setResetTransferList((prev) => !prev);
                  }
                  setExpressRuleConfig({
                    ...currentState,
                  });
                }}
                className={classes.buttonStyle}
              />
            </Grid>
            <Grid item xs={6}>
              <PrimaryButton
                buttonLabel="PREVIEW AND UPDATE"
                onClick={() => {
                  if (valiadteRuleEngineData(expressRuleConfig)) {
                    setShowPreviewModal(true);
                  }
                }}
                className={classes.buttonStyle}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const handleDefaultTimeConfigEdit = (e: any) => {
    const { checked } = e.target;
    let configChanges: any = checked
      ? {
          enableDefaultTimeEdit: true,
          defaultOrFixedTimeSameAsEarlier: false,
        }
      : {
          enableDefaultTimeEdit: false,
          defaultOrFixedTimeHours: currentState.defaultOrFixedTimeHours,
          defaultOrFixedTimeMinutes: currentState.defaultOrFixedTimeMinutes,
          defaultOrFixedTime: currentState.defaultOrFixedTime,
          defaultOrFixedTimeSameAsEarlier: true,
        };

    setExpressRuleConfig({
      ...expressRuleConfig,
      ...configChanges,
    });
  };

  const handleWeightRuleConfigEdit = (e: any) => {
    const { checked } = e.target;
    let configChanges: any = checked
      ? {
          enableWeightRuleEdit: true,
        }
      : {
          enableWeightRuleEdit: false,
          weightRule: currentState.weightRule,
        };
    setExpressRuleConfig({
      ...expressRuleConfig,
      ...configChanges,
    });
  };

  const handleCountRuleConfigEdit = (e: any) => {
    const { checked } = e.target;
    let configChanges: any = checked
      ? {
          enableCountRuleEdit: true,
        }
      : {
          enableCountRuleEdit: false,
          countRule: currentState.countRule,
        };
    setExpressRuleConfig({
      ...expressRuleConfig,
      ...configChanges,
    });
  };

  const handleScaleRuleConfigEdit = (e: any) => {
    const { checked } = e.target;
    let configChanges: any = checked
      ? {
          enableScaleRuleEdit: true,
        }
      : {
          enableScaleRuleEdit: false,
          scalableTypeRule: currentState.scalableTypeRule,
        };
    setExpressRuleConfig({
      ...expressRuleConfig,
      ...configChanges,
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.headerRoot}>
        <div className={classes.header}>
          <span className={classes.heading}>{`Delivery Promise Rule Engine Set up`}</span>
        </div>
        <RuleEngineCurrentConfigButtonAccess userInfo={userInfo} classes={classes} />
      </div>
      <ServiceRuleEngine
        serviceName={expressRuleConfig.serviceType}
        config={expressRuleConfig}
        onDynamicPromiseChange={handleDynamicPromiseChange}
        onDefaultOrFixedTimeChange={handleDefaultOrFixedTimeChange}
        onDefaultTimeIsSameAsEarlierChange={handleDefaultTimeIsSameAsEarlierChange}
        onUseForConfigurationSwitchChange={handleUseForConfigurationSwitchChange}
        onIsSameAsEarlierCheckboxChange={handleIsSameAsEarlierCheckbox}
        onWeightRuleFieldsChange={handleWeightRuleFieldsChange}
        onCountRuleFieldsChange={handleCountRuleFieldsChange}
        onAddScalableItems={handleAddScalableItems}
        onRemoveScalableItems={handleRemoveScalableItems}
        onScalableItemsFieldChange={handleScalableItemsFieldChange}
        onPOSZonesSelected={handlePOSZonesSelectChange}
        onDefaultTimeConfigEdit={handleDefaultTimeConfigEdit}
        onWeightRuleConfigEdit={handleWeightRuleConfigEdit}
        onCountRuleConfigEdit={handleCountRuleConfigEdit}
        onScaleRuleConfigEdit={handleScaleRuleConfigEdit}
        resetTransferList={resetTransferList}
      />
      {renderButtons()}
      <PreviewDeliveryPromise
        show={showPreviewModal}
        onClose={() => setShowPreviewModal(false)}
        configDetails={expressRuleConfig}
        isLoading={isSaving}
        onProceed={handleSaveDeliveryRuleEngineConfig}
        updatedConfigInfo={currentState}
      />
    </div>
  );
};

export default RuleEnginePage;
