import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Grid from "@material-ui/core/Grid";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import lodashGet from "lodash.get";
import { React, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_RESPONSE_STATUS, SERVICE_TYPES } from "../../../config/constants";
import { selectServiceTypes } from "../../../config/redux/configurationsSlice";
import { generateDropdownItems } from "../../../utils/helperFunctions";
import PrimaryButton from "../../common/PrimaryButton/PrimaryButton";
import RemoveButton from "../../common/RemoveButton/RemoveButton";
import SelectBox from "../../common/SelectBox/SelectBox";
import SwitchButton from "../../common/SwitchButton/SwitchButton";
import TextBox from "../../common/TextBox/index";
import TransferList from "../../common/TransferList/TransferList";
import { fetchPOSZoneMappingList, resetPOSZoneMappingList, selectServicesPOSZoneMappingList } from "../redux/ruleEngineSlice";
import commonStyles from "../RuleEngineCommonStyles";
import { getUniqueList } from "../RuleEngineUtils";
import serviceRuleEngineStyles from "./ServiceRuleEngineStyles";
import { ChangeConfigOtion, DefaultTimeRule } from "./ServiceRuleEngineUtils";

export const DEFAULT_TIME_HOURS = generateDropdownItems(0, 3, "hours");
export const DEFAULT_TIME_MINUTES = generateDropdownItems(15, 50, "minutes");

export const ITEM_COUNT_INCREMENTAL_SECONDS = generateDropdownItems(15, 150, "seconds");
export const ITEMS_INCREMENTAL_TIME_RANGE = generateDropdownItems(1, 30, "minutes");

const ServiceRuleEngine = ({
  serviceName = "",
  config,
  onDynamicPromiseChange,
  onDefaultOrFixedTimeChange,
  onDefaultTimeIsSameAsEarlierChange,
  onUseForConfigurationSwitchChange,
  onIsSameAsEarlierCheckboxChange,
  onWeightRuleFieldsChange,
  onCountRuleFieldsChange,
  onAddScalableItems,
  onRemoveScalableItems,
  onScalableItemsFieldChange,
  onPOSZonesSelected,
  onDefaultTimeConfigEdit,
  onWeightRuleConfigEdit,
  onCountRuleConfigEdit,
  onScaleRuleConfigEdit,
  resetTransferList,
}) => {
  const isServiceExpress = !!(serviceName.toUpperCase() === SERVICE_TYPES.EXPRESS);
  const commonClasses = commonStyles();
  const classes = serviceRuleEngineStyles();
  const [resetZoneList, setResetZoneList] = useState(false);
  const [apiParams, setAPIParams] = useState({
    currentPage: 0,
    searchedText: "",
  });

  const dispatch = useDispatch();

  const {
    currentCountry: { countryId },
  } = useSelector((state) => state.appConfig);
  const serviceTypes = useSelector(selectServiceTypes);

  const { fetchStatus: posZoneMappingFetchStatus, data: posZoneMappingData } = useSelector((state) => selectServicesPOSZoneMappingList(state, serviceName));

  const handleItemsAssignedChange = (zonesSelected) => {
    onPOSZonesSelected(serviceName, zonesSelected);
  };

  const handleDynamicPromiseChange = (serviceName, e) => {
    handlePOSZoneMappingSearch("");
    setResetZoneList((prev) => true);
    onDynamicPromiseChange(serviceName, e);
  };

  const getServiceTypeId = useCallback(() => {
    let id = 0;

    if (serviceTypes && serviceTypes.length) {
      id = serviceTypes.find((st) => st.name === serviceName).id;
    }

    return id;
  }, [serviceTypes, serviceName]);

  const callPOSZoneMappingListAPI = useCallback(() => {
    dispatch(
      fetchPOSZoneMappingList({
        countryId,
        serviceTypeId: getServiceTypeId(),
        serviceTypeName: serviceName,
        query: apiParams.searchedText,
        pageNumber: apiParams.currentPage,
        dynamicPromiseType: config.dynamicPromise,
      })
    );
    setResetZoneList((prev) => false);
  }, [dispatch, countryId, getServiceTypeId, serviceName, apiParams.currentPage, apiParams.searchedText, config.dynamicPromise]);

  const handlePOSZoneMappingSearch = (query) => {
    if (query !== apiParams.searchedText) {
      setAPIParams((prev) => ({ ...prev, searchedText: query, currentPage: 0 }));
      dispatch(resetPOSZoneMappingList({ serviceTypeName: serviceName }));
    }
  };

  function fetchMoreData() {
    if (posZoneMappingData && posZoneMappingData.list && posZoneMappingData.list.length > 0) {
      setAPIParams((prev) => ({ ...prev, currentPage: prev.currentPage + 1 }));
    }
  }

  useEffect(() => {
    if (serviceTypes.length) {
      dispatch(resetPOSZoneMappingList({ serviceTypeName: serviceName }));
      callPOSZoneMappingListAPI();
    }
    // eslint-disable-next-line
  }, [serviceName, serviceTypes, callPOSZoneMappingListAPI]);

  useEffect(() => {
    return () => {
      dispatch(resetPOSZoneMappingList({ serviceTypeName: serviceName }));
    };
  }, [dispatch, serviceName]);

  const renderTypeOfPromiseCard = () => {
    return (
      <Grid className={classes.typeOfPromiseContainer} container spacing={1}>
        <Grid item xs={9} className={classes.typeOfPromiseText}>
          Type of Promise
        </Grid>
        <Grid item xs={3}>
          <SwitchButton leftLabel="Static" rightLabel="Dynamic" checked={config.dynamicPromise} handleChange={(e) => handleDynamicPromiseChange(serviceName, e)} />
        </Grid>
      </Grid>
    );
  };

  const renderDynamicPromiseOptions = () => {
    return (
      <div>
        {renderWeightRule()}
        {renderItemCountRule()}
        {renderScalableItems()}
        {renderMaximumDeliveryPromise()}
        {renderTypeOfVehicle()}
      </div>
    );
  };

  const renderWeightRule = () => {
    return (
      <Grid container className={classes.ruleContainer} spacing={2}>
        <Grid item xs={8}>
          <Grid container spacing={1}>
            <Grid item xs={7} className={classes.ruleLabel}>
              Weight of Consignment
            </Grid>
            {!config.weightRule.sameAsEarlier && (
              <>
                <Grid item xs={4} className={classes.dFlexEnd}>
                  Use this value in calculation
                </Grid>
                <Grid item xs={1}>
                  <SwitchButton
                    className={config.isEditMode && !config.enableWeightRuleEdit ? classes.disableSwitch : ""}
                    size="small"
                    checked={config.weightRule.active}
                    disabled={config.isEditMode && !config.enableWeightRuleEdit}
                    handleChange={(e) => onUseForConfigurationSwitchChange(serviceName, "weightRule", e)}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <ChangeConfigOtion
          config={config}
          checked={config.weightRule.sameAsEarlier}
          changeExisting={config.enableWeightRuleEdit}
          classes={classes}
          checkChangeHandler={(e) => onIsSameAsEarlierCheckboxChange(serviceName, "weightRule", e)}
          onConfigEdit={onWeightRuleConfigEdit}
          xsValue={4}
        />

        {config.weightRule.active && (
          <Grid item xs={8}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextBox
                  fieldLabel="Threshold Weight Limit (Kgs)"
                  name="thresholdWeight"
                  type="number"
                  isNegativeAllowed={false}
                  value={config.weightRule.thresholdWeight}
                  handleChange={(e) => onWeightRuleFieldsChange(serviceName, e)}
                  disabled={config.isEditMode && !config.enableWeightRuleEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectBox
                  fieldLabel="Incremental Time Above Threshold"
                  name="incrementalTime"
                  label="Minutes"
                  menuItems={DEFAULT_TIME_MINUTES}
                  value={config.weightRule.incrementalTime}
                  handleChange={(e) => onWeightRuleFieldsChange(serviceName, e)}
                  disabled={config.isEditMode && !config.enableWeightRuleEdit}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };

  const renderItemCountRule = () => {
    return (
      <Grid container className={classes.ruleContainer} spacing={2}>
        <Grid item xs={8}>
          <Grid container spacing={1}>
            <Grid item xs={7} className={classes.ruleLabel}>
              Item Count
            </Grid>
            {!config.countRule.sameAsEarlier && (
              <>
                <Grid item xs={4} className={classes.dFlexEnd}>
                  Use this value in calculation
                </Grid>
                <Grid item xs={1}>
                  <SwitchButton
                    className={config.isEditMode && !config.enableCountRuleEdit ? classes.disableSwitch : ""}
                    size="small"
                    checked={config.countRule.active}
                    changeExisting={config.enableCountRuleEdit}
                    disabled={config.isEditMode && !config.enableCountRuleEdit}
                    handleChange={(e) => onUseForConfigurationSwitchChange(serviceName, "countRule", e)}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <ChangeConfigOtion
          config={config}
          checked={config.countRule.sameAsEarlier}
          classes={classes}
          xsValue={4}
          checkChangeHandler={(e) => onIsSameAsEarlierCheckboxChange(serviceName, "countRule", e)}
          onConfigEdit={onCountRuleConfigEdit}
          changeExisting={config.enableCountRuleEdit}
        />
        {config.countRule.active && (
          <>
            <Grid item xs={8}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextBox
                    fieldLabel="Threshold Limit"
                    name="thresholdCount"
                    type="number"
                    isNegativeAllowed={false}
                    value={lodashGet(config, "countRule.thresholdCount", "")}
                    handleChange={(e) => onCountRuleFieldsChange(serviceName, e)}
                    disabled={config.isEditMode && !config.enableCountRuleEdit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SelectBox
                    fieldLabel="Incremental Time Above Threshold"
                    name="incrementalTime"
                    label="Seconds"
                    value={lodashGet(config, "countRule.incrementalTime", "")}
                    handleChange={(e) => onCountRuleFieldsChange(serviceName, e)}
                    menuItems={ITEM_COUNT_INCREMENTAL_SECONDS}
                    disabled={config.isEditMode && !config.enableCountRuleEdit}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    );
  };

  const renderScalableItems = () => {
    return (
      <Grid container className={classes.ruleContainer} spacing={2}>
        <Grid item xs={8}>
          <Grid container spacing={1}>
            <Grid item xs={7} className={classes.ruleLabel}>
              Type of Items (Scalable/ non-Scalable)
            </Grid>
            {!config.scalableTypeRule.sameAsEarlier && (
              <>
                <Grid item xs={4} className={classes.dFlexEnd}>
                  Use this value in calculation
                </Grid>
                <Grid item xs={1}>
                  <SwitchButton
                    className={config.isEditMode && !config.enableScaleRuleEdit ? classes.disableSwitch : ""}
                    size="small"
                    checked={config.scalableTypeRule.active}
                    disabled={config.isEditMode && !config.enableScaleRuleEdit}
                    handleChange={(e) => onUseForConfigurationSwitchChange(serviceName, "scalableTypeRule", e)}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <ChangeConfigOtion
          config={config}
          checked={config.scalableTypeRule.sameAsEarlier}
          classes={classes}
          checkChangeHandler={(e) => onIsSameAsEarlierCheckboxChange(serviceName, "scalableTypeRule", e)}
          onConfigEdit={onScaleRuleConfigEdit}
          changeExisting={config.enableScaleRuleEdit}
          xsValue={4}
        />

        {/* Render dynamic type of items */}
        {config.scalableTypeRule.itemList !== undefined &&
          Array.isArray(config.scalableTypeRule.itemList) &&
          config.scalableTypeRule.itemList.length !== 0 &&
          config.scalableTypeRule.itemList.length > 0 && (
            <Grid item xs={8}>
              {config.scalableTypeRule.itemList.map((item, index) => (
                <Grid key={index} container spacing={1} alignItems="center">
                  <Grid item xs={7}>
                    <SelectBox
                      fieldLabel={index === 0 ? "Select a products from catalog" : ""}
                      name="section"
                      label="Product"
                      value={item.section}
                      handleChange={(e) => onScalableItemsFieldChange(serviceName, index, e)}
                      menuItems={getUniqueList(config.scalableTypeRule.itemList, item)}
                      disabled={config.isEditMode && !config.enableScaleRuleEdit}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <SelectBox
                      fieldLabel={index === 0 ? "Incremental time" : ""}
                      name="incrementalTime"
                      label="Minutes"
                      value={item.incrementalTime}
                      handleChange={(e) => onScalableItemsFieldChange(serviceName, index, e)}
                      menuItems={ITEMS_INCREMENTAL_TIME_RANGE}
                      disabled={config.isEditMode && !config.enableScaleRuleEdit}
                    />
                  </Grid>
                  <Grid item xs={2} style={index === 0 ? { marginTop: "30px" } : {}}>
                    <RemoveButton
                      className={config.isEditMode && !config.enableScaleRuleEdit ? classes.disableCloseBtn : ""}
                      onClick={() => onRemoveScalableItems(serviceName, index)}
                      disabled={config.isEditMode && !config.enableScaleRuleEdit}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          )}
        {config.scalableTypeRule.active && (
          <Grid item xs={12}>
            <PrimaryButton buttonVariant="outlined" buttonLabel="Add Another Product" disabled={config.isEditMode && !config.enableScaleRuleEdit} onClick={() => onAddScalableItems(serviceName)} />
          </Grid>
        )}
      </Grid>
    );
  };

  const renderMaximumDeliveryPromise = () => {
    return (
      <Grid container className={classes.ruleContainer} spacing={2} style={{ opacity: "0.4" }}>
        <Grid item xs={8}>
          <Grid container spacing={1}>
            <Grid item xs={7} className={classes.ruleLabel}>
              Maximum Delivery Promise
            </Grid>
            {config !== undefined && config.vehicleTypeRule !== undefined && config.vehicleTypeRule.sameAsEarlier !== undefined && !config.vehicleTypeRule.sameAsEarlier && (
              <>
                <Grid item xs={4} className={classes.dFlexEnd}>
                  Use this value in calculation
                </Grid>
                <Grid item xs={1}>
                  <SwitchButton
                    className={config.isEditMode && !config.enableScaleRuleEdit ? classes.disableSwitch : ""}
                    size="small"
                    checked={config.vehicleTypeRule.active}
                    disabled={config.isEditMode && !config.enableScaleRuleEdit}
                    // handleChange={(e) => onUseForConfigurationSwitchChange(serviceName, "vehicleTypeRule", e)}
                    handleChange={undefined}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <ChangeConfigOtion
          config={config}
          checked={false}
          classes={classes}
          // checkChangeHandler={(e) => onIsSameAsEarlierCheckboxChange(serviceName, "vehicleTypeRule", e)}
          checkChangeHandler={undefined}
          onConfigEdit={undefined}
          changeExisting={false}
          xsValue={4}
        />
      </Grid>
    );
  };

  const renderTypeOfVehicle = () => {
    return (
      <Grid container className={classes.ruleContainer} spacing={2} style={{ opacity: "0.4" }}>
        <Grid item xs={8}>
          <Grid container spacing={1}>
            <Grid item xs={7} className={classes.ruleLabel}>
              Type of Vehicle
            </Grid>
            {config !== undefined && config.vehicleTypeRule !== undefined && config.vehicleTypeRule.sameAsEarlier !== undefined && !config.vehicleTypeRule.sameAsEarlier && (
              <>
                <Grid item xs={4} className={classes.dFlexEnd}>
                  Use this value in calculation
                </Grid>
                <Grid item xs={1}>
                  <SwitchButton
                    className={config.isEditMode && !config.enableScaleRuleEdit ? classes.disableSwitch : ""}
                    size="small"
                    checked={config.vehicleTypeRule.active}
                    disabled={config.isEditMode && !config.enableScaleRuleEdit}
                    // handleChange={(e) => onUseForConfigurationSwitchChange(serviceName, "vehicleTypeRule", e)}
                    handleChange={undefined}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <ChangeConfigOtion
          config={config}
          checked={false}
          classes={classes}
          // checkChangeHandler={(e) => onIsSameAsEarlierCheckboxChange(serviceName, "vehicleTypeRule", e)}
          checkChangeHandler={undefined}
          onConfigEdit={undefined}
          changeExisting={false}
          xsValue={4}
        />
      </Grid>
    );
  };

  return (
    <div className={classes.root}>
      <Accordion defaultExpanded={isServiceExpress} className={commonClasses.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={commonClasses.accordionSummary}>
          {serviceName}
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          {renderTypeOfPromiseCard()}
          <DefaultTimeRule
            config={config}
            classes={classes}
            onDefaultTimeIsSameAsEarlierChange={(e) => onDefaultTimeIsSameAsEarlierChange(serviceName, e)}
            onDefaultOrFixedHourhange={(e) => onDefaultOrFixedTimeChange(serviceName, "defaultOrFixedTimeHours", e)}
            onDefaultOrFixedMinuteChange={(e) => onDefaultOrFixedTimeChange(serviceName, "defaultOrFixedTimeMinutes", e)}
            onDefaultTimeConfigEdit={onDefaultTimeConfigEdit}
          />
          {config.dynamicPromise && renderDynamicPromiseOptions()}
          {!config.isEditMode && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TransferList
                  title="POS-Zone Mapping"
                  fetchingList={posZoneMappingFetchStatus === API_RESPONSE_STATUS.LOADING}
                  onLeftItemsSearch={handlePOSZoneMappingSearch}
                  leftSideItems={lodashGet(posZoneMappingData, "list", [])}
                  hasMoreData={!lodashGet(posZoneMappingData, "posAndZoneDetails.empty", false)}
                  onFetchMore={fetchMoreData}
                  onItemsAssigned={handleItemsAssignedChange}
                  resetItem={resetTransferList || resetZoneList}
                />
              </Grid>
            </Grid>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ServiceRuleEngine;
