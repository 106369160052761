import { makeStyles } from "@material-ui/core/styles";
import { COLORS } from "../../config/theme/theme";

export const countrySettingsStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
  },
  countrySection: {
    marginTop: "80px",
    padding: "24px",
  },
  lists: {
    // margin: "24px",
  },
  title: {
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "36px",
    color: COLORS.TEXT_PRIMARY,
  },
  serviceTypeCard: {
    borderRadius: "0px 0px 8px 8px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  },
  posDetailsPaperStyle: {
    borderRadius: "8px 8px 0px 0px",
    background: COLORS.WHITE,
    marginTop: "24px",
    height: "96px",
  },
  posDetails: {
    // width: "294px",
    height: "96px",
    minWidth: "20%",
    background: "linear-gradient(180deg, #1B96C3 0%, #41BCEA 100%)",
    borderRadius: "8px 0px 0px 0px",
  },
  activePosCount: {
    color: COLORS.WHITE,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "35px",
    fontWeight: 600,
    lineHeight: "20px",
    fontSize: "16px",
  },
  posCountNo: {
    color: COLORS.WHITE,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    marginTop: "35px",
    fontWeight: 700,
    lineHeight: "39px",
    fontSize: "32px",
  },
  posTitle: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "70%",
  },
  serviceTypeStyle: {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "20px",
    color: theme.palette.primary.main,
    marginLeft: "12px",
  },
  serviceTypeTitle: {
    display: "flex",
    justifyContent: "center",
    marginTop: "45px",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "17px",
    color: COLORS.TEXT_PRIMARY,
  },
  standardServiceTypeCount: {
    background: "rgba(59, 59, 152, 0.2);",
    width: "66px",
    height: "48px",
    borderRadius: "100px",
    display: "flex",
    justifyContent: "center",
    color: "#3B3B98",
  },
  expressServiceTypeCount: {
    background: "rgba(227, 135, 15, 0.2)",
    width: "66px",
    height: "48px",
    borderRadius: "100px",
    display: "flex",
    justifyContent: "center",
    color: "#E3870F",
  },
  cncServiceTypeCount: {
    background: "rgba(132, 61, 119, 0.2)",
    width: "66px",
    height: "48px",
    borderRadius: "100px",
    marginRight: "40px",
    display: "flex",
    justifyContent: "center",
    color: "#843D77",
  },
  defaultServiceTypeCount: {
    background: "rgba(58, 186, 113, 0.2);",
    width: "66px",
    height: "48px",
    borderRadius: "100px",
    display: "flex",
    justifyContent: "center",
    color: "#3B3B98",
  },
  activeServiceDdnIcon: { marginLeft: "8px" },
  nextDaySlotVisibility: { marginTop: "12px" },
  serviceTypeDivision: {
    borderLeft: "2px solid #EFEFEF",
    // height: "96px",
    marginLeft: "40px",
  },
  serviceTypeCountData: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "20px",
    fontWeight: 700,
  },
  collectionPtCount: {
    background: "#B7D5D2",
    width: "34px",
    height: "34px",
    marginTop: "0px",
    marginLeft: "8px",
    borderRadius: "100px",
    display: "flex",
    justifyContent: "center",
    color: "#037E73",
    lineHeight: "17px",
    "& >div": {
      fontSize: "14px",
    },
  },
  serviceTab: {
    padding: "20px 0px",
    borderBottom: "2px solid #E6E6E6",
    cursor: "pointer",
    borderLeft: "2px solid #EFEFEF",
    width: "16%",
  },
  serviceTypeDetailsSectionActive: {
    background: "#F3F6F8",
    borderBottom: "none",
  },
  divButton: {
    marginTop: "16px",
  },
  buttonsSection: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  formControlLabel: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "17px",
    color: COLORS.TEXT_PRIMARY,
  },
  serviceTypeContentTitle: {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "20px",
    color: COLORS.TEXT_PRIMARY,
  },
  editDaysButton: { padding: "8px 0px 0px 0px" },
  advanceSlotDaysContainer: { marginTop: "16px" },
  calendarMarginTop: { marginTop: "10px !important" },
  advanceSlotDaysInput: { marginTop: "16px" },
  collectionPointStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  applyChangesModalBody: {
    border: "none",
    maxWidth: "600px",
    maxHeight: "80%",
    backgroundColor: "white",
    position: "relative",
    top: "80px",
    left: "25%",
    transform: "none",
    padding: "24px",
    textAlign: "center",
    borderRadius: "8px",
    "&:focus": {
      outline: "none",
    },
    overflow: "auto",
    "&:focus": {
      outline: "none",
    },
  },
  closeModalButton: {
    position: "absolute",
    right: "24px",
    top: "24px",
    cursor: "pointer",
  },
  closeModalIcon: { fill: theme.palette.primary.main },
  modalTitle: {
    fontSize: "24px",
    lineHeight: "24px",
    fontWeight: 600,
    padding: "0px",
    margin: "0px",
  },
  modalBody: {
    paddingLeft: "24px",
    paddingRight: "24px",
  },
  applyModalBodyTitle: {
    display: "flex",
    justifyContent: "flex-start",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "20px",
    paddingTop: "16px",
  },
  applyModalBodyContent: {
    paddingTop: "8px",
    fontSize: "14px",
    lineHeight: "17px",
    display: "flex",
    justifyContent: "flex-start",
  },
  modalButtons: {
    padding: "24px",
  },
  buttonStyles: {
    minWidth: "100%",
  },
  selectedValuesData: {
    fontWeight: 600,
    paddingLeft: "8px",
  },
  applyChangesButton: {
    display: "flex",
    justifyContent: "flex-end",
  },
  uploadFileDescription: {
    color: COLORS.DARK_GRAY,
    fontSize: "12px",
    lineHeight: "15px",
    fontWeight: 500,
    marginTop: "9px",
  },
  dividerStyle: {
    marginTop: "24px",
  },
  areaMappingContainer: {
    marginTop: "16px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  },
  posValue: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "20%",
  },
  dateSearchBoxOpen: {
    margin: 0,
    padding: "12px",
    width: "212px",
    maxWidth: "212px",
    height: "40px",
    border: "1px solid #adb5bd",
    borderRadius: "4px",
    background: "#FFFFFF",
    cursor: "pointer",
  },
  fbcServiceTypeCount: {
    background: "#FFFFFF",
    width: "66px",
    height: "48px",
    borderRadius: "100px",
    display: "flex",
    justifyContent: "center",
    color: "#FFFFFF",
  },
}));
