import { createStyles, makeStyles } from "@material-ui/core";
import { COLORS } from "../../config/theme/theme";

export const userStyles = makeStyles(() =>
  createStyles({
    containerBody: {
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "24px",
      gap: "24px",

      position: "absolute",
      width: "1350px",
      height: "1034px",
      left: "90px",
      top: "86px",
      background: "#FFFFFF",
    },
    container: {
      marginTop: "80px",
      padding: "24px 12px 24px 24px",
    },
    root: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "24px",
    },
    createNewConfigStyle: {
      width: "300px",
      background: "#DD2326",
      border: "1px solid #DD2326",
      borderRadius: "4px",
      color: "#FFFFFF",
      textDecoration: "none",
      "&:hover": {
        background: "#DD2326",
      },
      "&.MuiButton-label": {
        background: "#DD2326",
        "&:hover": {
          background: "#DD2326",
        },
      },
    },
    cardRoot: {
      width: "100%",
      height: "calc(100vh - 195px)",
      background: "#FFFFFF",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px 8px 0px 0px",
      overflow: "auto",
    },
    accessDiv: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    topHeaderDiv: {
      position: "fixed",
      zIndex: 1,
      width: "calc(100vw - 113px)",
      height: "64px",
      background: "#FFFFFF",
      borderRadius: "8px 8px 0px 0px",
    },
    searchAndFilterContainer: {
      width: "calc(100vw - 161px)",
      margin: "24px 24px 0px 24px",
      borderRadius: "8px",
    },
    searchFilter: {
      width: "364px",
      background: COLORS.WHITE,
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#E6E6E6",
        top: "0px",
      },
      "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#E6E6E6",
          top: "0px",
        },
      },
    },
    tableDivStyle: {
      marginTop: "88px",
    },

    // PlasticBagCurrentCofiguration
    //
    //
    //
    // PlasticBagCurrentCofiguration
    configDetailsDiv: {
      padding: 0,
      margin: 0,
      marginTop: "24px",
    },
    bagConfigTextBoxDiv: {
      display: "flex",
      justifyContent: "flex-start",
      width: "70%",
    },
    rightPaddingStyle: {
      paddingRight: "12px",
    },
    leftPaddingStyle: {
      paddingLeft: "12px",
    },
    cancelConfigStyle: {
      width: "212px",
      background: "#FFFFFF",
      border: "1px solid #0E5AA7",
      borderRadius: "4px",
      color: "#0E5AA7",
      textDecoration: "none",
      marginRight: "24px",
      "&:hover": {
        background: "#FFFFFF",
        border: "1px solid #0E5AA7",
      },
      "&.MuiButton-label": {
        background: "#FFFFFF",
        border: "1px solid #0E5AA7",
        "&:hover": {
          background: "#FFFFFF",
          border: "1px solid #0E5AA7",
        },
      },
    },
    previewAndUpdateConfigStyle: {
      width: "300px",
      background: "#0E5AA7",
      border: "1px solid #0E5AA7",
      borderRadius: "4px",
      color: "#FFFFFF",
      textDecoration: "none",
      "&:hover": {
        background: "#0E5AA7",
        border: "1px solid #0E5AA7",
      },
      "&.MuiButton-label": {
        background: "#0E5AA7",
        border: "1px solid #0E5AA7",
        "&:hover": {
          background: "#0E5AA7",
          border: "1px solid #0E5AA7",
        },
      },
    },
    accordionStyle: {
      background: COLORS.WHITE,
      border: "1px solid #C3C3C3",
      borderRadius: "4px",
      "&:last-child": {
        padding: 0,
        margin: 0,
        marginTop: "12px",
      },
    },
    accordionSummaryStyle: {
      padding: "8px 24px",
    },
    accordionTitle: {
      fontSize: "18px",
      fontWeight: 600,
    },
    accordionDetailsStyle: {
      borderTop: "1px solid #C3C3C3",
      display: "block",
      padding: "24px",
    },
    configButtonDiv: {
      display: "flex",
      justifyContent: "flex-end",
      padding: "24px 0px",
    },
    posZoneConfigDiv: {
      margin: 0,
      padding: 0,
      display: "flex",
      justifyContent: "space-between",
    },
    editIconStyle: {
      width: "100%",
      height: "100%",
      cursor: "pointer",
      textAlign: "center",
    },
    textBoxpaddingStyle: {
      padding: "12px 12px 0px 0px",
    },
    iconButton: {
      cursor: "pointer",
    },
    editLableNames: {
      fontWeight: 600,
      marginLeft: "0px",
    },
    tableStyle: {
      margin: 0,
      marginTop: "90px",
      padding: 0,
      minHeight: "500px",
      maxHeight: "calc(100vh - 285px)",
      width: "100%",
      overflow: "auto",
    },
    headerRoot: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "24px",
    },
    header: {
      display: "flex",
      justifyContent: "flex-start",
    },
    heading: {
      fontWeight: 600,
      fontSize: "24px",
      color: COLORS.DARK_GRAY,
    },
    buttonStyles: {
      width: "364px",
      height: "40px",
      background: "#DD2326",
      color: "#FFFFFF",
      border: "1px solid #DD2326",
      borderRadius: "4px",
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "20px",
      "&:hover": {
        backgroundColor: "#DD2326",
        color: "#FFFFFF",
        border: "1px solid #DD2326",
        borderRadius: "8px",
      },
    },
    headerStyle: {
      background: "rgba(0, 0, 0, 0.04)",
      margin: 0,
      padding: 0,
      minHeight: "60px",
      "& .MuiDataGrid-root": {
        margin: 0,
        padding: 0,
      },
      "& .MuiDataGrid-columnHeaderTitleContainer": {
        margin: 0,
        padding: 0,
      },
      "& .MuiDataGrid-columnHeaderTitle": {
        lineHeight: "normal",
        textOverflow: "clip",
        whiteSpace: "normal",
        fontWeight: 600,
      },
    },
    disabledEditIconStyle: {
      width: "100%",
      height: "100%",
      cursor: "none",
      textAlign: "center",
      opacity: "0.4",
    },
  })
);
