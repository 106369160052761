import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { validateToken } from "../../../api/LoginApi";
import { CURRENT_COUNTRY, NEW_PATH_SELECTED, USER_INFO, USER_INFO_DOS } from "../../../config/constants";

export const validateUserToken: any = createAsyncThunk("/auth/login", async (token: any, { rejectWithValue }) => {
  try {
    const requestURL = { token };
    localStorage.setItem(USER_INFO_DOS, JSON.stringify(requestURL));
    const { response }: any = await validateToken(requestURL);
    if (response && response.success) {
      const { data } = response;
      const user = { token };
      if (data && data.token) {
        user.token = data.token;
      }
      localStorage.setItem(USER_INFO, JSON.stringify(user));
      return { ...data };
    } else {
      const { errors }: any = response;
      if (Array.isArray(errors) && errors.length > 0) {
        const error = errors[0];
        return rejectWithValue({ message: error.errorMessage });
      }
    }
    return {};
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const logOutUser: any = createAsyncThunk("user/logout", () => {
  localStorage.removeItem(USER_INFO_DOS);
  localStorage.removeItem(USER_INFO);
  localStorage.removeItem(CURRENT_COUNTRY);
  localStorage.removeItem(NEW_PATH_SELECTED);
  return false;
});

const initialState = {
  isLoggedIn: false,
  loading: false,
  userDetails: {},
  loginError: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: {
    [logOutUser.fulfilled]: (state) => {
      state.isLoggedIn = false;
      state.userDetails = {};
      state.loginError = "";
    },
    [validateUserToken.pending]: (state) => {
      state.loading = true;
      state.loginError = "";
      state.userDetails = {};
    },
    [validateUserToken.rejected]: (state, action) => {
      state.loading = false;
      state.loginError = action.payload.message;
      state.isLoggedIn = false;
      state.userDetails = {};
    },
    [validateUserToken.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.loading = false;
      state.userDetails = action.payload;
    },
  },
});

export default userSlice.reducer;

export const selectUserDetails = (state: any) => state.user.userDetails;
