import { createStyles, makeStyles } from "@material-ui/core";
import { COLORS } from "../../../config/theme/theme";

export const userStyles = makeStyles(() =>
  createStyles({
    root: {
      background: COLORS.WHITE,
      borderRadius: "3px 3px 0px 0px",
      borderColor: "transparent",
    },
    container: {
      marginTop: "80px",
      padding: "24px 12px 24px 24px",
    },
    headerRoot: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "24px",
    },
    header: {
      display: "flex",
      justifyContent: "flex-start",
    },
    heading: {
      fontWeight: 600,
      fontSize: "24px",
      color: COLORS.DARK_GRAY,
    },
    buttonStyles: {
      width: "364px",
      height: "40px",
      background: "#DD2326",
      color: "#FFFFFF",
      border: "1px solid #DD2326",
      borderRadius: "4px",
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "20px",
      "&:hover": {
        backgroundColor: "#DD2326",
        color: "#FFFFFF",
        border: "1px solid #DD2326",
        borderRadius: "8px",
      },
    },
    searchBoxStyle: {
      width: "364px",
      background: "#FFFFFF",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px",
    },
    cardRoot: {
      width: "100%",
      height: "calc(100vh - 256px)",
      background: "#FFFFFF",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px",
      overflow: "auto",
    },
    tableHeaderStyle: {
      width: "auto",
      height: "auto",
    },
    cellHeaderStyle: {
      position: "sticky",
      top: 0,
      background: "#F4F4F4",
      padding: "12px 0px 0px 12px",
      margin: 0,
      color: "#252525",
      fontWeight: 600,
      fontSize: "12px",
      lineHeight: "21px",
      border: "none",
      verticalAlign: "text-top",
      "&.MuiTableCell-paddingNone:last-child": {
        padding: "12px 12px 0px 12px",
      },
    },
    cellSubHeaderStyle: {
      position: "sticky",
      top: "33px",
      background: "#F4F4F4",
      padding: "0px 6px 0px 6px",
      margin: 0,
      color: "#505050",
      fontWeight: 400,
      fontSize: "10px",
      lineHeight: "24px",
      border: "none",
      borderRight: "1px solid #FFFFFF",
      borderCollapse: "separate",
      verticalAlign: "middle",
      "&.MuiTableCell-paddingNone:nth-child(3n)": {
        padding: "0px 6px 0px 6px",
        borderRight: "none",
      },
    },
    noDataRowStyle: {
      width: "auto",
      height: "calc(100vh - 400px)",
      verticalAlign: "middle",
      textAlign: "center",
      borderRadius: "8px",
      border: "none",
      boxShadow: "none",
    },
    cellDataStyle: {
      width: "auto",
      height: "40px",
      verticalAlign: "middle",
      color: "#2D2D2D",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "15px",
      border: "none",
      borderBottom: "1px solid #F4F4F4",
      padding: "12px 12px 0px 12px",
      "&.MuiTableCell-paddingNone:last-child": {
        padding: "12px 12px 0px 12px",
      },
    },
    cellActionStyle: {
      width: "auto",
      height: "40px",
      verticalAlign: "middle",
      border: "none",
      borderBottom: "1px solid #F4F4F4",
      padding: "12px 0px 0px 12px",
    },
    actionDiv: {
      display: "flex",
      justifyContent: "space-between",
    },
    actionIconStyle: {
      padding: 0,
      margin: "0px 8px 0px 8px",
      cursor: "pointer",
    },
    tablePaginationStyle: {
      position: "fixed",
      bottom: 0,
      justifyContent: "flex-end",
      width: "calc(100vw - 110px)",
      marginBottom: "24px",
      marginLeft: "-2px",
      background: "#E6E6E6",
      borderRadius: "0px 0px 8px 8px",
      minHeight: "54px",
      maxHeight: "54px",
    },
    tableContainerStyle: {
      height: "calc(100vh - 310px)",
    },
    thresholdValueTextStyle: {
      color: "#2D2D2D",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "15px",
    },
    rangeConfiguredTextStyle: {
      color: "#0E5AA7",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "15px",
      cursor: "pointer",
    },
  })
);
