import Grid from "@material-ui/core/Grid";
import React from "react";
import RemoveButton from "../../../../common/RemoveButton/RemoveButton";
import TextBox from "../../../../common/TextBox";
import TimePicker from "../../../../common/TimePicker/TimePicker";




const OnDemandSlot = ({
  tabIndex,
  formIndex,
  slotIndex,
  slotCapacity,
  serviceStartTime,
  serviceEndTime,
  handleRemoveOnDemandSlot,
  handleIndividualSlotFieldsChange,
  handleOnDemandServiceTimeChange,
}) => {

  const renderCapacityInput = () => {
    return (
      <TextBox
        type="number"
        name="slotCapacity"
        fieldLabel={slotIndex === 0 ? "Capacity" : ""}
        value={slotCapacity}
        handleChange={(e) => handleIndividualSlotFieldsChange(tabIndex, formIndex, slotIndex, e)}
        placeholderText="Enter Capacity"
        isNegativeAllowed={false}
      />
    );
  };

  const renderServiceStartTimeInput = () => {
    return (
      <>
        <TimePicker
          fieldLabel={slotIndex === 0 ? "First customer order time" : ""}
          value={serviceStartTime}
          handleChange={(date) => handleOnDemandServiceTimeChange(tabIndex, formIndex, slotIndex, date, "startTime")}
        />
      </>
    );
  };

  const renderServiceEndTimeInput = () => {
    return (
      <>
        <TimePicker
          fieldLabel={slotIndex === 0 ? "Last order Cut-off Time" : ""}
          value={serviceEndTime}
          handleChange={(date) => handleOnDemandServiceTimeChange(tabIndex, formIndex, slotIndex, date, "endTime")}
        />
      </>
    );
  };

  const renderServiceTimeInputs = () => {
    return (
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={4}>
          {renderServiceStartTimeInput()}
        </Grid>
        <Grid item xs={4}>
          {renderServiceEndTimeInput()}
        </Grid>
        <Grid item xs={3}>
          {renderCapacityInput()}
        </Grid>
        {slotIndex !== 0 ? (
          <Grid item xs={1}>
            <RemoveButton onClick={() => handleRemoveOnDemandSlot(tabIndex, formIndex, slotIndex)} />
          </Grid>
        ) : null}
      </Grid>
    );
  };

  return <div>{renderServiceTimeInputs()}</div>;
};

export default OnDemandSlot;
