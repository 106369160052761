// import "date-fns";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
// Material UI Components
import Grid from "@material-ui/core/Grid";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TabContext from "@material-ui/lab/TabContext";
import TabPanel from "@material-ui/lab/TabPanel";
import { unwrapResult } from "@reduxjs/toolkit";
import produce from "immer";
import lodashGet from "lodash.get";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import ConfirmModal from "../../../components/common/ConfirmModal/ConfirmModal";
import CustomAlert from "../../../components/common/CustomAlert/CustomAlert";
// Constants
import { ALERT_TYPES, APP_ROUTES, EXPRESS_TYPES, HH_MM_SS, PHARMACY, SERVICE_TYPES, STATUS_OK } from "../../../config/constants";
import { selectServiceTypes } from "../../../config/redux/configurationsSlice";
import { convertMinutesToHours } from "../../../utils/helperFunctions";
import CustomBreadcrumbs from "../../common/CustomBreadcrumbs/CustomBreadcrumbs";
// Assets
// Components
import LinkButton from "../../common/LinkButton/LinkButton";
import OverlayLoader from "../../common/Loader/OverlayLoader";
import PageTitle from "../../common/PageTitle/PageTitle";
import PrimaryButton from "../../common/PrimaryButton/PrimaryButton";
import RemoveButton from "../../common/RemoveButton/RemoveButton";
import { fetchPOSDetailsByPOSNo } from "../../CreatePOS/redux/posSlice";
import AreaTransferList from "../AreaTransferList/AreaTransferList";
import BasicServiceDetails from "../common/BasicServiceDetails/BasicServiceDetails";
import ProductType from "../common/ProductType/ProductType";
import ShowFirstSlotAndServiceCutOffTime from "../common/ShowFirstSlotAndServiceCuttOff/ShowFirstSlotAndServiceCuttOff";
import CreateTemplateSlotsForm from "../common/Slots/CreateTemplateSlots";
import EditTemplateSlots from "../common/Slots/EditTemplateSlots";
import {
  createNewTemplateSlot,
  createTemplateSlots,
  deleteATemplateSlot,
  deleteZoneConfig,
  fetchSlotsInformationByServiceType,
  makeTemplateSlotEditable,
  resetSlotsInformation,
  updateTemplateSlotBySlotId,
  updateTemplateSlots,
} from "../redux/templateSlotsSlice";
import { ALL_DAYS_VALUES, DEFAULT_SLOTS_GENERATOR_FORMS_VALUES, DEFAULT_TAB_CONFIGURATIONS, DEFAULT_TEMPLATE_SLOT, foodSplitRegex, PRE_SELECTED_FOOD_SUB_TYPES } from "../utils/constants";
import { validateExpressZoneConfig } from "../utils/ExpressUtils";
import { calculateAvailableDaysForSelection, createNextZoneName, createServiceConfigPayload, generateSlots } from "../utils/serviceUtils";
import { getFoodTypesFromFoodSubTypes, getPOSBreadCum, handleFoodSubType, handleProductType } from "../utils/SlotServicesUtils";
import expressServiceStyles from "./ExpressStyles";
import CreateOnDemandSlots from "./OnDemand/CreateOnDemandSlots";
import EditOnDemandSlots from "./OnDemand/EditOnDemandSlots";

const DEFAULT_ON_DEMAND_SLOT = {
  slotCapacity: "",
  startTime: null,
  endTime: null,
};

const DEFAULT_ON_DEMAND_SLOT_GENERATOR_FORM = {
  days: [],
  generatedSlots: [{ ...DEFAULT_ON_DEMAND_SLOT }],
};

const Express = ({ serviceTypeId, serviceTypeName, isPOSMappingAreaBased, expressType }) => {
  const { posNo } = useParams();
  const history = useHistory();

  const isExpressTypeOnDemand = expressType === EXPRESS_TYPES.ON_DEMAND.value;

  const classes = expressServiceStyles();

  const slotsGeneratorFormRef = React.useRef(null);

  // New state from here
  const [selectedConfigTab, setSelectedConfigTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [refetchConfig, setRefetchConfig] = useState(false);

  const [configTabs, setConfigTabs] = useState([
    {
      zoneName: "Zone01",
      foodTypes: "",
      foodSubType: PRE_SELECTED_FOOD_SUB_TYPES,
      slotsGeneratorForms: isExpressTypeOnDemand ? [{ days: ALL_DAYS_VALUES, generatedSlots: [{ ...DEFAULT_ON_DEMAND_SLOT }] }] : [{ ...DEFAULT_SLOTS_GENERATOR_FORMS_VALUES, days: ALL_DAYS_VALUES }],
      selectedAreas: [],
    },
  ]);

  const [errors, setErrors] = useState([
    {
      foodTypes: "",
      selectedAreas: "",
    },
  ]);

  const [showDeleteZCModal, setShowDeleteZCModal] = useState(false);

  const dispatch = useDispatch();
  const { posDetails, loading: fetchingDetails } = useSelector((state) => state.pos);
  const { fetchingSlots, express, isDeleting } = useSelector((state) => state.slots);

  const isPosTypePharmacy = lodashGet(posDetails, "posType", "") === PHARMACY;

  const serviceTypes = useSelector(selectServiceTypes);

  const addConfigTabs = () => {
    let newConfigTab = {
      ...DEFAULT_TAB_CONFIGURATIONS,
      foodTypes: "",
      slotsGeneratorForms: isExpressTypeOnDemand ? [{ days: ALL_DAYS_VALUES, generatedSlots: [{ ...DEFAULT_ON_DEMAND_SLOT }] }] : [{ ...DEFAULT_SLOTS_GENERATOR_FORMS_VALUES, days: ALL_DAYS_VALUES }],
      zoneName: createNextZoneName(configTabs),
    };
    let newTabs = [...configTabs, newConfigTab];

    setConfigTabs(newTabs);
    setSelectedConfigTab(configTabs.length);

    let newError = {
      foodTypes: "",
    };
    let newErrors = produce(errors, (draft) => {
      draft.push(newError);
    });

    setErrors(newErrors);
  };

  /**
   *
   * @param {number} tabIndexToBeDeleted
   * @param {eventObj} e
   * Handler to show the delete confirm modal while deleting a zone config
   */
  const handleDeleteZoneConfigClick = (tabIndexToBeDeleted, e) => {
    e.stopPropagation();

    setSelectedConfigTab(tabIndexToBeDeleted);
    setShowDeleteZCModal(true);
  };

  const resetConfigTab = (tabIndexToBeReset) => {
    let defaultConfig = {
      ...DEFAULT_TAB_CONFIGURATIONS,
      foodTypes: "",
      slotsGeneratorForms: isExpressTypeOnDemand ? [{ days: ALL_DAYS_VALUES, generatedSlots: [{ ...DEFAULT_ON_DEMAND_SLOT }] }] : [{ ...DEFAULT_SLOTS_GENERATOR_FORMS_VALUES, days: ALL_DAYS_VALUES }],
    };

    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndexToBeReset] = { ...defaultConfig, zoneName: `Zone01` };
    });

    setConfigTabs(newTabs);
  };

  const removeZoneTabAfterDeletion = (tabIndexToBeDeleted) => {
    if (configTabs.length > 1) {
      let newTabs = produce(configTabs, (draft) => {
        draft.splice(tabIndexToBeDeleted, 1);
      });
      setSelectedConfigTab(configTabs.length - 2);
      setConfigTabs(newTabs);
      let newErrors = produce(errors, (draft) => {
        draft.splice(tabIndexToBeDeleted, 1);
      });
      setErrors(newErrors);
    } else {
      resetConfigTab(tabIndexToBeDeleted);
    }
  };

  /**
   * Method to delete the zone config
   */
  const deleteConfigTab = async (tabIndexToBeDeleted) => {
    let zoneId = configTabs[tabIndexToBeDeleted].zoneId;

    // If the tabs config is being saved at the backend, call the API to delete
    if (zoneId) {
      setLoading(true);
      const {
        payload: { data, message: deleteError },
      } = await dispatch(
        deleteZoneConfig({
          serviceTypeName,
          zoneConfigId: configTabs[tabIndexToBeDeleted].zoneId,
          posNo: posNo,
        })
      );
      setShowDeleteZCModal(false);

      if (data === true) {
        CustomAlert(ALERT_TYPES.SUCCESS, "Zone configuration deleted successfully!");
        removeZoneTabAfterDeletion(tabIndexToBeDeleted);
        // After every transaction related to service type config changes refetch all slot details from server for sync.
        setRefetchConfig((prev) => !prev);
      } else {
        if (deleteError) {
          CustomAlert(ALERT_TYPES.ERROR, deleteError);
          setLoading(false);
          return;
        }
      }
      return;
    }

    // If the tab data is not yet saved at the backend, no need to call backend API
    if (!zoneId) {
      removeZoneTabAfterDeletion(tabIndexToBeDeleted);
      setShowDeleteZCModal(false);
    }
  };

  const handleDaysChange = (tabIndex, generatorFormIndex, value) => {
    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].slotsGeneratorForms[generatorFormIndex].days = value;
    });
    setConfigTabs(newTabs);
  };

  const handleAddOnDemandSlot = (tabIndex, generatorFormIndex) => {
    let newTabs = produce(configTabs, (draft) => {
      let newOnDemandSlot = { ...DEFAULT_ON_DEMAND_SLOT };
      draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots.push(newOnDemandSlot);
    });

    setConfigTabs(newTabs);
  };

  const handleRemoveOnDemandSlot = (tabIndex, generatorFormIndex, slotIndex) => {
    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots.splice(slotIndex, 1);
    });

    setConfigTabs(newTabs);
  };

  const handleOnDemandServiceTimeChange = (tabIndex, generatorFormIndex, slotIndex, date, fieldName) => {
    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[slotIndex][fieldName] = date;
    });

    setConfigTabs(newTabs);
  };

  const handleSlotsGeneratorFormFields = (tabIndex, generatorFormIndex, event) => {
    const { name, value } = event.target;

    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].slotsGeneratorForms[generatorFormIndex][name] = value;
    });

    setConfigTabs(newTabs);
  };

  const handleServiceTimeChange = (tabIndex, generatorFormIndex, time, element) => {
    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].slotsGeneratorForms[generatorFormIndex][element] = time;
    });
    setConfigTabs(newTabs);
  };

  const handleSwitchButton = (tabIndex, event) => {
    const { name, checked } = event.target;
    const newTabs = produce(configTabs, (draft) => {
      draft[tabIndex][name] = checked;

      if (!checked) {
        switch (name) {
          case "showFirstSlotAtOption":
            draft[tabIndex].showFirstSlotAt = null;
            break;

          case "showCutOffTimeOfDayOption":
            draft[tabIndex].cutOffTimeOfDay = null;
            break;

          default:
            break;
        }
      }
    });

    setConfigTabs(newTabs);
  };

  const handleServiceCutOffAndShowSlotAtTime = (tabIndex, time, element) => {
    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex][element] = time;
    });
    setConfigTabs(newTabs);
  };

  const handleIndividualSlotFieldsChange = (tabIndex, generatorFormIndex, index, event) => {
    const { name, value } = event.target;

    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[index][name] = value;
    });

    setConfigTabs(newTabs);
  };

  const handleIndividualSlotCheckbox = (tabIndex, generatorFormIndex, index, status) => {
    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[index].status = status;
      draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[index].slotCapacity = "";
      draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[index].bufferTimeHours = "";
      draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[index].bufferTimeMinutes = 0;
      draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[index].orderCutOff = "";
    });

    setConfigTabs(newTabs);
  };

  /* Handling of edit flow for On Demand Slot */
  const updateOnDemandSlot = async (tabIndex, generatorFormIndex, index, slot) => {
    let onDemandSlotPayload = {
      ...DEFAULT_ON_DEMAND_SLOT,
      ...slot,
      startTime: moment(slot.startTime).format(HH_MM_SS),
      endTime: moment(slot.endTime).format(HH_MM_SS),
    };

    const {
      payload: { message, data },
    } = await dispatch(updateTemplateSlotBySlotId({ serviceTypeName, slotData: onDemandSlotPayload }));

    if (message) {
      CustomAlert(ALERT_TYPES.ERROR, message);
    }

    if (data) {
      let newTabs = produce(configTabs, (draft) => {
        draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[index] = {
          ...data,
          startTime: moment(data.startTime, HH_MM_SS),
          endTime: moment(data.endTime, HH_MM_SS),
          time: `${moment(data.startTime, HH_MM_SS).format("HH:mm")} - ${moment(data.endTime, HH_MM_SS).format("HH:mm")}`,
        };
      });

      setConfigTabs(newTabs);
    }
  };

  const handleSaveNewOnDemandSlot = async (tabIndex, generatorFormIndex, index, slot) => {
    let newTemplateSlotBody = {
      ...slot,
      posId: slot.posId,
      zoneId: slot.zoneId,
      day: slot.day,
      bufferTime: 0,
      startTime: moment(slot.startTime).format(HH_MM_SS),
      endTime: moment(slot.endTime).format(HH_MM_SS),
      serviceTypeId,
    };

    let newTabs = {};
    setLoading(true);
    const { payload } = await dispatch(createNewTemplateSlot({ serviceTypeName, slotData: newTemplateSlotBody }));
    if (payload && payload.data) {
      // After every transaction related to service type config changes refetch all slot details from server for sync.
      setRefetchConfig((prev) => !prev);

      // TODO remove commented code after testing as we are fetching all configuration anfter action result

      // if (data) {
      //   newTabs = produce(configTabs, (draft) => {
      //     draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[index] = {
      //       ...data,
      //       startTime: moment(data.startTime, HH_MM_SS),
      //       endTime: moment(data.endTime, HH_MM_SS),
      //       time: `${moment(data.startTime, HH_MM_SS).format("HH:mm")} - ${moment(data.endTime, HH_MM_SS).format(
      //         "HH:mm"
      //       )}`,
      //     };
      //   });

      //   setConfigTabs(newTabs);
    }

    if (payload && payload.message) {
      setLoading(false);
      CustomAlert(ALERT_TYPES.ERROR, payload.message);
    }
  };

  /** Handling of edit flow - Start */
  const makeSlotEditable = (slot) => {
    if (!slot || !slot.id) return;

    dispatch(makeTemplateSlotEditable({ serviceTypeName, slotData: slot, canBeUpdated: true }));
  };

  const cancelEditingASlot = (tabIndex, generatorFormIndex, index, slot) => {
    if (!slot || !slot.id) return;
    dispatch(makeTemplateSlotEditable({ serviceTypeName, slotData: slot, canBeUpdated: false }));

    let unEditedSlotFromStore = express.length && express[tabIndex].dayWiseSlots[slot.day].filter((item) => item.id === slot.id)[0];

    let { minutes: bufferTimeMinutes, hours: bufferTimeHours } = convertMinutesToHours(unEditedSlotFromStore.bufferTime);

    let unEditedSlot = {
      ...unEditedSlotFromStore,
      time: `${moment(unEditedSlotFromStore.startTime, HH_MM_SS).format("HH:mm")} - ${moment(unEditedSlotFromStore.endTime, HH_MM_SS).format("HH:mm")}`,
      startTime: moment(unEditedSlotFromStore.startTime, HH_MM_SS),
      endTime: moment(unEditedSlotFromStore.endTime, HH_MM_SS),
      bufferTimeHours,
      bufferTimeMinutes,
    };

    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[index] = { ...unEditedSlot };
    });

    setConfigTabs(newTabs);
  };

  const editSlotTime = (tabIndex, generatorFormIndex, index, time, elem) => {
    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[index][elem] = time;
    });

    setConfigTabs(newTabs);
  };

  const updateTemplateSlotHandler = async (tabIndex, generatorFormIndex, index, slot) => {
    setLoading(true);
    let requestBody = {
      id: slot.id,
      posId: slot.posId,
      zoneId: slot.zoneId,
      day: slot.day,
      startTime: moment(slot.startTime).format(HH_MM_SS),
      endTime: moment(slot.endTime).format(HH_MM_SS),
      slotCapacity: slot.slotCapacity,
      bufferTime: slot.bufferTimeHours * 60 + slot.bufferTimeMinutes,
      serviceTypeId,
    };

    const { payload } = await dispatch(updateTemplateSlotBySlotId({ serviceTypeName, slotData: requestBody }));

    if (payload && payload.message) {
      setLoading(false);
      CustomAlert(ALERT_TYPES.ERROR, payload.message);
    }

    if (payload && payload.data && payload.data.id) {
      // After every transaction related to service type config changes refetch all slot details from server for sync.
      setRefetchConfig((prev) => !prev);
    }

    // TODO remove commented code after testing as we are fetching all configuration anfter action result
    // if (data) {
    //   let newTabs = produce(configTabs, (draft) => {
    //     const { hours, minutes } = convertMinutesToHours(data.bufferTime);

    //     draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[index] = {
    //       ...data,
    //       bufferTimeHours: hours,
    //       bufferTimeMinutes: minutes,
    //       startTime: moment(data.startTime, HH_MM_SS),
    //       endTime: moment(data.endTime, HH_MM_SS),
    //       time: `${moment(data.startTime, HH_MM_SS).format("HH:mm")} - ${moment(data.endTime, HH_MM_SS).format(
    //         "HH:mm"
    //       )}`,
    //     };
    //   });

    //   setConfigTabs(newTabs);
    // }
  };

  const handleDeleteTemplateSlot = async (tabIndex, generatorFormIndex, slot) => {
    if (!slot || !slot.id) {
      let newTabs = produce(configTabs, (draft) => {
        let allSlots = draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots;
        let filteredSlots = allSlots.filter((item) => !!item.id);
        draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots = [...filteredSlots];
      });

      setConfigTabs(newTabs);
      return;
    }
    setLoading(true);
    const payload = await dispatch(deleteATemplateSlot({ serviceTypeName, slotData: slot }));
    let deletedSlot = unwrapResult(payload);
    if (deletedSlot && deletedSlot.data && deletedSlot.data.id) {
      // After every transaction related to service type config changes refetch all slot details from server for sync.
      setRefetchConfig((prev) => !prev);
    } else {
      setLoading(false);
    }
    // TODO remove commented code after testing as we are fetching all configuration after action result
    // let newTabs = produce(configTabs, (draft) => {
    //   let allSlots = draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots;
    //   let filteredSlots = allSlots.filter((item) => item.id !== deletedSlot.id);
    //   draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots = [...filteredSlots];
    // });

    // setConfigTabs(newTabs);
  };

  const addNewSlot = (tabIndex, generatorFormIndex, day) => {
    let newTabs = produce(configTabs, (draft) => {
      let newSlot = isExpressTypeOnDemand
        ? {
            ...DEFAULT_ON_DEMAND_SLOT,
            posId: posDetails.id,
            day: day.join(","),
            zoneId: draft[tabIndex].zoneId,
            isNewSlot: true,
          }
        : {
            ...DEFAULT_TEMPLATE_SLOT,
            bufferTimeHours: 0,
            bufferTimeMinutes: 0,
            posId: posDetails.id,
            day: day.join(","),
            zoneId: draft[tabIndex].zoneId,
            isNewSlot: true,
          };

      draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots.push(newSlot);
    });

    setConfigTabs(newTabs);
  };

  const handleSaveNewTemplateSlot = async (tabIndex, generatorFormIndex, index, slot) => {
    let newTemplateSlotBody = {
      posId: slot.posId,
      zoneId: slot.zoneId,
      day: slot.day,
      startTime: moment(slot.startTime).format(HH_MM_SS),
      endTime: moment(slot.endTime).format(HH_MM_SS),
      bufferTime: parseInt(slot.bufferTimeHours * 60 + slot.bufferTimeMinutes),
      slotCapacity: parseInt(slot.slotCapacity),
      serviceTypeId,
    };

    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[index].isCreating = true;
    });

    setConfigTabs(newTabs);
    setLoading(true);
    const { payload } = await dispatch(createNewTemplateSlot({ serviceTypeName, slotData: newTemplateSlotBody }));

    if (payload && payload.data) {
      // After every transaction related to service type config changes refetch all slot details from server for sync.
      setRefetchConfig((prev) => !prev);

      // TODO remove commented code after testing as we are fetching all configuration anfter action result
    }
    // if (data) {
    //   newTabs = produce(configTabs, (draft) => {
    //     const { hours, minutes } = convertMinutesToHours(data.bufferTime);

    //     draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[index] = {
    //       ...data,
    //       bufferTimeHours: hours,
    //       bufferTimeMinutes: minutes,
    //       startTime: moment(data.startTime, HH_MM_SS),
    //       endTime: moment(data.endTime, HH_MM_SS),
    //       time: `${moment(data.startTime, HH_MM_SS).format("HH:mm")} - ${moment(data.endTime, HH_MM_SS).format(
    //         "HH:mm"
    //       )}`,
    //       isCreating: false,
    //       isCreated: true,
    //     };
    //   });

    //   setConfigTabs(newTabs);
    // }

    if (payload && payload.message) {
      newTabs = produce(configTabs, (draft) => {
        draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[index] = {
          ...draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[index],
          isCreating: false,
          isCreated: false,
        };
      });
      setConfigTabs(newTabs);
      setLoading(false);
      CustomAlert(ALERT_TYPES.ERROR, payload.message);
    }
  };
  /** Handling of edit flow - end */

  const handleAssignedAreas = (tabIndex, selectedAreaList) => {
    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].selectedAreas = selectedAreaList.map((area) => area.id);
    });

    setConfigTabs(newTabs);
  };

  const isAddOtherDaysButtonDisabled = (tabIndex) => {
    let isButtonDisabled = false;
    let daysSelectedSoFar = configTabs[tabIndex].slotsGeneratorForms.reduce((days, value) => [...days, ...value.days], []);
    let remainingDays = ALL_DAYS_VALUES.filter((day) => !daysSelectedSoFar.includes(day));

    if (remainingDays.length === 0) isButtonDisabled = true;

    return isButtonDisabled;
  };

  const executeScroll = (ref) => ref.current.scrollIntoView({ behavior: "smooth" });

  const addOtherDays = (tabIndex, e) => {
    e.stopPropagation();

    let newForm = isExpressTypeOnDemand
      ? { ...DEFAULT_ON_DEMAND_SLOT_GENERATOR_FORM }
      : {
          ...DEFAULT_SLOTS_GENERATOR_FORMS_VALUES,
        };

    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].slotsGeneratorForms.push(newForm);
    });
    setConfigTabs(newTabs);

    setTimeout(() => {
      executeScroll(slotsGeneratorFormRef);
    }, 100);
  };

  const deleteConfigureSlotsOption = (tabIndex, slotGenertorFormIndex) => {
    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].slotsGeneratorForms.splice(slotGenertorFormIndex, 1);
    });
    setConfigTabs(newTabs);
  };

  const getGeneratedSlots = (tabIndex, slotsGeneratorFormIndex) => {
    const slotsGeneratorFormFields = configTabs[tabIndex].slotsGeneratorForms[slotsGeneratorFormIndex];

    const calculatedSlots = generateSlots({ ...slotsGeneratorFormFields });

    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].slotsGeneratorForms[slotsGeneratorFormIndex].generatedSlots = [...calculatedSlots];
    });

    setConfigTabs(newTabs);
  };

  const resetSlotsGeneratorForm = (tabIndex, formIndex) => {
    const newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].slotsGeneratorForms[formIndex] = { ...DEFAULT_SLOTS_GENERATOR_FORMS_VALUES };
    });

    setConfigTabs(newTabs);
  };

  const resetSlots = (tabIndex, generatorFormIndex) => {
    resetSlotsGeneratorForm(tabIndex, generatorFormIndex);
  };

  /**
   *
   * @param {object} configurations This object has all the template slots and zone configurations
   * Function to make a call to the backend API for creating template slots
   */
  const saveZoneConfigurations = async ({ quotaSetting, ...configs }) => {
    setLoading(true);
    const {
      payload: { zoneConfig, message },
    } = await dispatch(
      createTemplateSlots({
        posNo,
        zoneConfigurations: configs,
        service: serviceTypeName,
        zoneConfigIndex: selectedConfigTab,
      })
    );

    if (message) {
      setLoading(false);
      CustomAlert(ALERT_TYPES.ERROR, message);
    }

    if (zoneConfig && zoneConfig.id) {
      // After every transaction related to service type config changes refetch all slot details from server for sync.
      setRefetchConfig((prev) => !prev);
      CustomAlert(ALERT_TYPES.SUCCESS, "Zone Configurations saved successfully");
    }
  };

  /**
   *
   * @param {object} configurations This object has all the template slots and zone configurations
   * Function to make a call to the backend API for updating conig or updating template slots
   */
  const updateZoneConfigurations = async ({ quotaSetting, ...configs }) => {
    setLoading(true);
    const {
      payload: { zoneConfig, message },
    } = await dispatch(
      updateTemplateSlots({
        posNo,
        zoneConfigurations: configs,
        service: serviceTypeName,
        zoneConfigIndex: selectedConfigTab,
      })
    );

    if (message) {
      setLoading(false);
      CustomAlert(ALERT_TYPES.ERROR, message);
    }

    if (zoneConfig && zoneConfig.id) {
      // After every transaction related to service type config changes refetch all slot details from server for sync.
      setRefetchConfig((prev) => !prev);
      CustomAlert(ALERT_TYPES.SUCCESS, "Zone Configurations updated successfully");
    }
  };

  /**
   *
   * @param {number} configTabIndex Current index of the selected tab
   * Method to handle the submit of service configurations, it creates the requested payload
   * and then makes an API call to create template slots in the backend
   */
  const handleSaveOrUpdateZoneConfigurationsSubmit = (configTabIndex = selectedConfigTab, isUpdate = false) => {
    const { isError, currentTabErrors } = validateExpressZoneConfig(configTabs, selectedConfigTab, isPOSMappingAreaBased, errors);
    setErrors(currentTabErrors);
    if (!isError) {
      let tab = configTabs[configTabIndex];

      let configurationsPayload = createServiceConfigPayload(tab, serviceTypeId, posDetails);

      if (isUpdate) {
        updateZoneConfigurations(configurationsPayload);
      } else {
        saveZoneConfigurations(configurationsPayload);
      }
    }
  };

  // TODO need to set as common method for all

  const transformConfigForUI = useCallback((slots) => {
    let newConfigTabs = slots.map((slot) => ({
      zoneId: slot.zoneConfig.id,
      zoneName: slot.zoneConfig.zoneName,
      foodTypes: getFoodTypesFromFoodSubTypes(slot.foodTypeMapping.foodTypes),
      foodSubType: slot.foodTypeMapping.foodTypes?.filter((fst) => fst !== "NON_FOOD").join(foodSplitRegex),
      showFirstSlotAtOption: !!slot.zoneConfig.showFirstSlotAt,
      showFirstSlotAt: slot.zoneConfig.showFirstSlotAt ? moment(slot.zoneConfig.showFirstSlotAt, HH_MM_SS) : null,
      showServiceCutOffTimeOption: !!slot.zoneConfig.serviceCutOffTime,
      serviceCutOffTime: slot.zoneConfig.serviceCutOffTime ? moment(slot.zoneConfig.serviceCutOffTime, HH_MM_SS) : null,
      selectedAreas: slot.selectedAreas || [],
      slotsGeneratorForms: Object.keys(slot.dayWiseSlots).map((dayWiseSlot) => ({
        isEdit: true,
        days: [dayWiseSlot],
        generatedSlots:
          slot.dayWiseSlots[dayWiseSlot] !== undefined && Array.isArray(slot.dayWiseSlots[dayWiseSlot])
            ? slot.dayWiseSlots[dayWiseSlot].map((slot) => ({
                ...slot,
                startTime: moment(slot.startTime, HH_MM_SS),
                endTime: moment(slot.endTime, HH_MM_SS),
                status: slot.status,
                time: `${moment(slot.startTime, HH_MM_SS).format("HH:mm")} - ${moment(slot.endTime, HH_MM_SS).format("HH:mm")}`,
                bufferTimeHours: convertMinutesToHours(slot.bufferTime).hours,
                bufferTimeMinutes: convertMinutesToHours(slot.bufferTime).minutes,
                orderCutOff: moment(slot.startTime, HH_MM_SS).subtract(convertMinutesToHours(slot.bufferTime).minutes, "m").format("HH:mm"),
              }))
            : {},
      })),
    }));

    setConfigTabs(newConfigTabs);
  }, []);

  useEffect(() => {
    if (posNo && !posDetails.posNo && serviceTypes.length) {
      dispatch(fetchPOSDetailsByPOSNo(posNo));
    }
  }, [dispatch, posNo, serviceTypeId, serviceTypeName, posDetails.posNo, serviceTypes.length]);

  useEffect(() => {
    const fetchSlots = () => {
      dispatch(fetchSlotsInformationByServiceType({ posNo, serviceType: serviceTypeId, serviceTypeName }));
      setLoading(false);
    };
    if (posNo && serviceTypeId) {
      fetchSlots();
    }
  }, [dispatch, posNo, serviceTypeId, serviceTypeName, refetchConfig]);

  /**
   * Empty redux state on unmount
   */
  useEffect(() => {
    return () => {
      dispatch(resetSlotsInformation());
    };
  }, [dispatch]);

  /**
   * Fetch all the config information once and set it in the configTabs state
   */
  useEffect(() => {
    if (
      express &&
      express.length &&
      !loading
      // &&express[selectedConfigTab] && (!express[selectedConfigTab] ||
      //   (!express[selectedConfigTab].createError &&
      //     !express[selectedConfigTab].isCreating &&
      //     !express[selectedConfigTab].isUpdating &&
      //     !express[selectedConfigTab].updateError))
      // configTabs[selectedConfigTab].zoneId !== express[selectedConfigTab].zoneConfig.id
    ) {
      transformConfigForUI(express);
    }
  }, [express]);

  useEffect(() => {
    if (isPosTypePharmacy) {
      let newConfigTabs = produce(configTabs, (draft) => {
        draft[selectedConfigTab].foodTypes = "";
      });

      setConfigTabs(newConfigTabs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPosTypePharmacy, selectedConfigTab]);

  const renderTitle = () => <PageTitle title={`POS - ${posNo}`} />;

  const renderBasicServiceDtails = () => (
    <BasicServiceDetails
      serviceTypeId={serviceTypeId}
      serviceTypeName={serviceTypeName}
      expressType={EXPRESS_TYPES[expressType].label}
      country={posDetails && posDetails.posAddress && posDetails.posAddress.country}
      region={posDetails && posDetails.posAddress && posDetails.posAddress.region}
    />
  );

  const renderOnDemandSlotsGeneratorForm = (configTab, configTabIndex, generatorFormIndex) => {
    let remainingDays = calculateAvailableDaysForSelection(generatorFormIndex, configTab);
    const { days, generatedSlots } = configTabs[configTabIndex].slotsGeneratorForms[generatorFormIndex];

    const isEditMode = !!configTab.zoneId;

    return (
      <div style={{ position: "relative", marginTop: "12px" }}>
        {generatorFormIndex !== 0 && !isEditMode && (
          <RemoveButton
            style={{
              position: "absolute",
              right: "25px",
              top: "40px",
            }}
            onClick={() => deleteConfigureSlotsOption(configTabIndex, generatorFormIndex)}
          />
        )}
        {isEditMode ? (
          <EditOnDemandSlots
            tabIndex={configTabIndex}
            formIndex={generatorFormIndex}
            days={days}
            generatedSlots={generatedSlots}
            addNewOnDemandSlot={addNewSlot}
            updateOnDemandSlot={updateOnDemandSlot}
            deleteOnDemandSlot={handleDeleteTemplateSlot}
            handleSaveNewOnDemandSlot={handleSaveNewOnDemandSlot}
          />
        ) : (
          <CreateOnDemandSlots
            tabIndex={configTabIndex}
            formIndex={generatorFormIndex}
            days={days}
            remainingDays={remainingDays}
            generatedSlots={generatedSlots}
            handleDaysChange={handleDaysChange}
            handleAddOnDemandSlot={handleAddOnDemandSlot}
            handleRemoveOnDemandSlot={handleRemoveOnDemandSlot}
            handleIndividualSlotFieldsChange={handleIndividualSlotFieldsChange}
            handleOnDemandServiceTimeChange={handleOnDemandServiceTimeChange}
          />
        )}
      </div>
    );
  };

  const renderServiceCutOffAndShowSlotTimeOption = (configTab, configTabIndex) => {
    const { showFirstSlotAtOption, showFirstSlotAt, showServiceCutOffTimeOption, serviceCutOffTime } = configTab;

    return (
      <ShowFirstSlotAndServiceCutOffTime
        showFirstSlotAtOption={showFirstSlotAtOption}
        showFirstSlotAt={showFirstSlotAt}
        showServiceCutOffTimeOption={showServiceCutOffTimeOption}
        serviceCutOffTime={serviceCutOffTime}
        handleSwitchButton={(e) => handleSwitchButton(configTabIndex, e)}
        handleShowFirstSlotOrServiceCutOffTime={(date, elem) => handleServiceCutOffAndShowSlotAtTime(configTabIndex, date, elem)}
      />
    );
  };

  const renderSlotsGeneratorForm = (configTab, configTabIndex, generatorFormIndex) => {
    const isEditMode = !!configTab.zoneId;

    const { days, slotDurationHours, slotDurationMinutes, bufferTimeHours, bufferTimeMinutes, serviceStartTime, serviceEndTime, defaultSlotCapacity, generatedSlots } =
      configTabs[configTabIndex].slotsGeneratorForms[generatorFormIndex];

    let remainingDays = calculateAvailableDaysForSelection(generatorFormIndex, configTab);

    const dayWiseSlots = isEditMode ? express && express.length && express[configTabIndex] && express[configTabIndex].dayWiseSlots && express[configTabIndex].dayWiseSlots[days[0]] : null;

    return (
      <div style={{ position: "relative", marginTop: "12px" }}>
        {generatorFormIndex !== 0 && !isEditMode ? (
          <RemoveButton
            style={{
              position: "absolute",
              right: "24px",
              top: "10px",
              zIndex: "100",
            }}
            onClick={() => deleteConfigureSlotsOption(configTabIndex, generatorFormIndex)}
          />
        ) : null}

        {isEditMode ? (
          <EditTemplateSlots
            days={days}
            generatedSlots={generatedSlots}
            dayWiseSlots={dayWiseSlots}
            handleIndividualSlotCheckbox={(slotIndex, e) => handleIndividualSlotCheckbox(configTabIndex, generatorFormIndex, slotIndex, e.target.checked)}
            handleIndividualSlotFieldsChange={(slotIndex, e) => handleIndividualSlotFieldsChange(configTabIndex, generatorFormIndex, slotIndex, e)}
            handleIndividualSlotTimeChange={(slotIndex, date, elem) => editSlotTime(configTabIndex, generatorFormIndex, slotIndex, date, elem)}
            makeSlotEditable={(slotIndex, slot) => makeSlotEditable(slot)}
            cancelEditingASlot={(slotIndex, slot) => cancelEditingASlot(configTabIndex, generatorFormIndex, slotIndex, slot)}
            updateTemplateSlot={(slotIndex, slot) => updateTemplateSlotHandler(configTabIndex, generatorFormIndex, slotIndex, slot)}
            deleteTemplateSlot={(slotIndex, slot) => handleDeleteTemplateSlot(configTabIndex, generatorFormIndex, slot)}
            addNewTemplateSlot={() => addNewSlot(configTabIndex, generatorFormIndex, days)}
            saveNewTemplateSlot={(slotIndex, slot) => handleSaveNewTemplateSlot(configTabIndex, generatorFormIndex, slotIndex, slot)}
          />
        ) : (
          <CreateTemplateSlotsForm
            days={days}
            remainingDays={remainingDays}
            slotDurationHours={slotDurationHours}
            slotDurationMinutes={slotDurationMinutes}
            bufferTimeHours={bufferTimeHours}
            bufferTimeMinutes={bufferTimeMinutes}
            serviceStartTime={serviceStartTime}
            serviceEndTime={serviceEndTime}
            defaultSlotCapacity={defaultSlotCapacity}
            generatedSlots={generatedSlots}
            handleDaysChange={(e) => handleDaysChange(configTabIndex, generatorFormIndex, e)}
            handleSlotsGeneratorFormFields={(e) => handleSlotsGeneratorFormFields(configTabIndex, generatorFormIndex, e)}
            handleServiceTimeChange={(date, elem) => handleServiceTimeChange(configTabIndex, generatorFormIndex, date, elem)}
            handleGenerateSlots={() => getGeneratedSlots(configTabIndex, generatorFormIndex)}
            handleResetSlots={() => resetSlots(configTabIndex, generatorFormIndex)}
            handleIndividualSlotFieldsChange={(slotIndex, e) => handleIndividualSlotFieldsChange(configTabIndex, generatorFormIndex, slotIndex, e)}
            handleIndividualSlotCheckbox={(slotIndex, e) => handleIndividualSlotCheckbox(configTabIndex, generatorFormIndex, slotIndex, e.target.checked)}
          />
        )}
      </div>
    );
  };

  // always as per exclusion logic discussion
  const renderConfigureSlotsAccordion = (configTab, configTabIndex) => {
    // let isNonFood = isNonFoodType(configTab);
    return (
      <Accordion className={classes.accordionStyle}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ padding: "8px 24px" }}>
          <Grid container style={{ alignItems: "center" }}>
            <Grid>
              <Typography className={classes.accordionTitle}>Slots</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails style={{ display: "block", padding: "0px 24px 24px 24px" }}>
          <Grid container alignItems="center">
            {/* {isNonFood ? (
              <Grid item xs={6}></Grid>
            ) : ( */}
            <Grid item xs={6}>
              {renderServiceCutOffAndShowSlotTimeOption(configTab, configTabIndex)}
            </Grid>
            {/* )} */}
            {!configTab.zoneId && (
              <Grid item xs={6} className={classes.addOtherDays}>
                <LinkButton buttonLabel={"Add Other Days"} disabled={isAddOtherDaysButtonDisabled(configTabIndex)} onClick={(e) => addOtherDays(configTabIndex, e)} />
              </Grid>
            )}
          </Grid>
          <>
            {configTab.slotsGeneratorForms.map((slotGeneratorForm, generatorFormIndex) => (
              <div key={generatorFormIndex} ref={slotsGeneratorFormRef}>
                {isExpressTypeOnDemand ? renderOnDemandSlotsGeneratorForm(configTab, configTabIndex, generatorFormIndex) : renderSlotsGeneratorForm(configTab, configTabIndex, generatorFormIndex)}
              </div>
            ))}
          </>
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderAssignedAreas = (configTab, configTabIndex) => {
    return <AreaTransferList selectedAreas={configTab.selectedAreas} handleAssignedAreaListChange={(assignedAreas) => handleAssignedAreas(configTabIndex, assignedAreas)} />;
  };

  const renderZoneConfigurationsCard = () => {
    return (
      <Card className={classes.cards}>
        <CardContent style={{ padding: "0px 16px 16px 16px" }}>
          <TabContext value={selectedConfigTab.toString()}>
            <Grid container style={{ alignItems: "center", borderBottom: "1px solid #e8e8e8" }}>
              <Grid item xs={9}>
                <Tabs
                  classes={{
                    root: classes.tabsRoot,
                    indicator: classes.tabsIndicator,
                  }}
                  variant="scrollable"
                  value={selectedConfigTab}
                  onChange={(e, tabIndex) => setSelectedConfigTab(tabIndex)}
                >
                  {configTabs.map((configTab, configTabIndex) => (
                    <Tab
                      id={`config-tab-${configTabIndex}`}
                      value={configTabIndex}
                      key={configTabIndex}
                      disableRipple
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.tabSelected,
                        labelIcon: classes.tabLabelIcon,
                      }}
                      label={configTab.zoneName}
                      icon={
                        (configTabs.length === 1 && configTabs[selectedConfigTab]?.zoneId) || configTabs.length > 1 ? (
                          <RemoveButton className={classes.deleteConfigTabIcon} onClick={(e) => handleDeleteZoneConfigClick(configTabIndex, e)} />
                        ) : null
                      }
                    ></Tab>
                  ))}
                </Tabs>
              </Grid>
              <Grid item xs={3} style={{ textAlign: "right" }}>
                <LinkButton buttonLabel="Add Zone Configuration" onClick={addConfigTabs} disabled={!configTabs[selectedConfigTab]?.zoneId} />
              </Grid>
            </Grid>

            {configTabs.map((configTab, configTabIndex) => (
              <TabPanel key={configTabIndex} value={configTabIndex.toString()} index={configTabIndex} style={{ padding: "0px" }}>
                {!isPosTypePharmacy && (
                  <ProductType
                    isProductTypeDisabled={configTab.zoneId}
                    foodType={configTab.foodTypes}
                    foodSubType={configTab.foodSubType}
                    error={errors[configTabIndex] && errors[configTabIndex].foodTypes}
                    onFoodTypeChange={(e, checked) => {
                      setConfigTabs(handleProductType(configTabs, configTabIndex, e, checked));
                    }}
                    onSubFoodTypeChange={(e) => {
                      setConfigTabs(handleFoodSubType(configTabs, configTabIndex, e));
                    }}
                  />
                )}

                {/* Configure slots */}
                {renderConfigureSlotsAccordion(configTab, configTabIndex)}
                {isPOSMappingAreaBased && renderAssignedAreas(configTab, configTabIndex)}
                <Grid container style={{ marginTop: "12px" }}>
                  <Grid item xs={6}></Grid>
                  {configTab.zoneId ? (
                    <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                      <PrimaryButton
                        buttonLabel={express[selectedConfigTab] && express[selectedConfigTab].isUpdating ? "updating..." : `Update ${configTabs[selectedConfigTab]?.zoneName} Configurations`}
                        disabled={express[selectedConfigTab] && express[selectedConfigTab].isUpdating}
                        onClick={() => handleSaveOrUpdateZoneConfigurationsSubmit(selectedConfigTab, true)}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                      <PrimaryButton
                        buttonLabel={express[selectedConfigTab] && express[selectedConfigTab].isCreating ? "creating..." : `Save ${configTabs[selectedConfigTab]?.zoneName} Configurations`}
                        disabled={express[selectedConfigTab] && (express[selectedConfigTab].isCreating || express[selectedConfigTab].isCreated)}
                        onClick={() => handleSaveOrUpdateZoneConfigurationsSubmit(selectedConfigTab)}
                      />
                    </Grid>
                  )}
                </Grid>
              </TabPanel>
            ))}
          </TabContext>
        </CardContent>
      </Card>
    );
  };

  const renderFooterButtons = () => {
    return (
      <Grid container direction="row" className={classes.buttons}>
        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item style={{ display: "flex", justifyContent: "flex-end" }}>
              <PrimaryButton buttonLabel="VIEW SETUP" buttonVariant="outlined" disabled={!express.length} onClick={() => history.push(`${APP_ROUTES.COMPLETE_SETUP}/${posDetails.posNo}`)} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <div className={classes.mainContainer}>
      {/* {(fetchingDetails || fetchingSlots) ? <OverlayLoader />
        : (
          <> */}
      <OverlayLoader loading={fetchingDetails || fetchingSlots || loading} />
      <CustomBreadcrumbs links={getPOSBreadCum(SERVICE_TYPES.EXPRESS)} />
      {renderTitle()}

      {renderBasicServiceDtails()}

      {renderZoneConfigurationsCard()}

      {renderFooterButtons()}

      <ConfirmModal
        show={showDeleteZCModal}
        title="Delete Zone Config"
        description="All the data for the zone configuration would be permanently lost. Do you wish to proceed?"
        confirmButtonText="Approve"
        isLoading={isDeleting}
        onClose={() => setShowDeleteZCModal(false)}
        onProceed={() => deleteConfigTab(selectedConfigTab)}
      />
      {/* </>
        )} */}
    </div>
  );
};

export default Express;
