function handleUnauthroizedRequest() {
  localStorage.clear();
  // eslint-disable-next-line no-restricted-globals
  // location.reload();
}

function constructErrorMsg(errorBody) {
  let errorObj = new Error("Error occured while processing your request.");
  if (errorBody && errorBody.message) {
    throw errorBody;
  }
  else if (errorBody && errorBody.errors) {
    if (Array.isArray(errorBody.errors) && errorBody.errors.length > 0) {
      const error = errorBody.errors[0];
      if (error && error.errorMessage) {
        errorObj = new Error(error.errorMessage);
      }
    }
  }
  return errorObj;
}

export async function handleResponse(response) {
  if (response.ok) {
    try{
      let result = await response.json();
      return {response : result };
    }catch(error){
      return { response: { status : response.status}   };
    }
    
  } else {
    if (response.status === 401 || response.status === 403) {
      handleUnauthroizedRequest();
    }
    const errorBody = await response.json();
    throw constructErrorMsg(errorBody);
  }
}

export async function handleResponseLogin(response) {
  if (response.ok) {
    let result = await response.json();
    return { response: result };
  } else {
    const errorBody = await response.json();
    throw constructErrorMsg(errorBody);
  }
}

export function handleError(error) {
  console.error("API call failed. " + error);
  throw error;
}

export async function handleCSVResponse(response) {
  if (response.ok) {
    const contentDispositionHeader = response.headers.get("Content-disposition");
    const fileName = contentDispositionHeader ? contentDispositionHeader.split(`"`)[1] : "Download.csv";
    let result = await response.text();
    return { response: result, fileName };
  } else {
    if (response.status === 401) {
      handleUnauthroizedRequest();
    }
    const errorBody = await response.json();
    throw constructErrorMsg(errorBody);
  }
}
