import { makeStyles } from "@material-ui/core";

const styles = makeStyles({
  editMode: {},
  slotCheckboxStyle: {
    marginRight: "8px",
  },
  updateSlotButton: {
    justifyContent: "left",
    padding: "0px",
    "&:hover": { backgroundColor: "transparent" },
  },
  isSlotUpdatedIconStyle: { marginLeft: "8px" },
});

export default styles;
