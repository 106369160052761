import produce from "immer";
import CustomAlert from "../../../components/common/CustomAlert/CustomAlert";
import { ALERT_TYPES } from '../../../config/constants';

export const validateExpressZoneConfig = (configTabs: any, selectedConfigTab: any, isPOSMappingAreaBased: boolean, errors: any) => {
    let currentTab = configTabs[selectedConfigTab];
    let newErrors: any = {};
    let isAnyError = false;

    // Remove as we implemented exclusion logic zone may not exclusion any type
    // if (!currentTab.foodTypes) {
    //     let errorMsg = "Please select product type";
    //     newErrors.foodTypes = errorMsg;
    //     CustomAlert(ALERT_TYPES.ERROR, errorMsg);
    // }

    if (isPOSMappingAreaBased) {
        if (!currentTab.selectedAreas.length) {
            let errorMsg = "Please assign areas to the zone configurations";
            newErrors.selectedAreas = errorMsg;
            CustomAlert(ALERT_TYPES.ERROR, errorMsg);
        }
    }

    currentTab.slotsGeneratorForms.forEach((sgf: any, index: number) => {
        sgf.generatedSlots.forEach((slot: any, slotIndex: number) => {
            if (slot.slotCapacity === "") {
                isAnyError = true;
                CustomAlert(ALERT_TYPES.ERROR, `Please enter capactiy for a slot: ${slotIndex + 1}`);
                return !isAnyError;
            }
        });
    });

    let currentTabErrors = produce(errors, (draft: any) => {
        draft[selectedConfigTab] = { ...newErrors };
    });

    return { isError: (Object.keys(newErrors).filter((err) => newErrors[err]).length > 0 || isAnyError), currentTabErrors }
};