import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as POSService from "../../../api/POSService";
import { addNewTemplateSlot, deleteTemplateSlot, updateTemplateSlot } from "../../../api/TemplateSlotsService";
import { ALL_DAYS_VALUES, SERVICE_TYPES } from "../../../config/constants";

const designDefaultServiceDayWiseSlot = (allSupplierTypes: any, configResponse: any) => {
  let allSupplierTypesData: any = allSupplierTypes.reduce(
    (acc: any, cp: any) => ({
      ...acc,
      [cp]: {
        // collectionPointDetails: configResponse.templateSlots.find((s: any) => s.clickAndCollect.displayName.toLowerCase() === cp.toLowerCase()).clickAndCollect,
        // templateSlots: configResponse.templateSlots.filter((s: any) => s.clickAndCollect.displayName.toLowerCase() === cp.toLowerCase()),
      },
    }),
    {}
  );
  allSupplierTypesData = allSupplierTypes.reduce((acc: any, cp: any) => {
    let allSupplierTypesDataObj: any = configResponse.defaultServiceTemplateSlots[cp];
    return {
      ...acc,
      [cp]: {
        ...allSupplierTypesDataObj,
        dayWiseSlots: ALL_DAYS_VALUES.reduce(
          (daysAcc, day) => ({
            ...daysAcc,
            [day]: allSupplierTypesDataObj.filter((cpo: any) => cpo.day.toLowerCase() === day.toLowerCase()),
          }),
          {}
        ),
      },
    };
  }, {});

  if (allSupplierTypesData !== undefined && Object.keys(allSupplierTypesData).length > 0) {
    Object.keys(allSupplierTypesData).forEach((supplierType: any) => {
      let total_slot_capacity_at_service_level = 0;
      if (
        supplierType !== undefined &&
        allSupplierTypesData[supplierType] !== undefined &&
        allSupplierTypesData[supplierType].dayWiseSlots !== undefined &&
        Object.keys(allSupplierTypesData[supplierType].dayWiseSlots).length > 0
      ) {
        Object.keys(allSupplierTypesData[supplierType].dayWiseSlots).forEach((dayValue: any) => {
          if (
            dayValue !== undefined &&
            allSupplierTypesData[supplierType].dayWiseSlots[dayValue] !== undefined &&
            Array.isArray(allSupplierTypesData[supplierType].dayWiseSlots[dayValue]) &&
            allSupplierTypesData[supplierType].dayWiseSlots[dayValue].length > 0
          ) {
            let maximum_service_type_slot_capacity = allSupplierTypesData[supplierType].dayWiseSlots[dayValue].reduce((a: any, b: any) => a + b.slotCapacity, 0);
            total_slot_capacity_at_service_level = total_slot_capacity_at_service_level + maximum_service_type_slot_capacity;
            allSupplierTypesData[supplierType].dayWiseSlots[dayValue].forEach((daydata: any) => {
              daydata.maximum_slot_capacity = maximum_service_type_slot_capacity;
            });
          }
        });
        allSupplierTypesData[supplierType][0].total_slot_capacity_at_service_level = total_slot_capacity_at_service_level;
      }
    });
  }

  return allSupplierTypesData;
};

// Method used to transform result for all service rather than default
const groupTemplateSlotsByDay = (configsResponse: any, serviceName: string) => {
  if (serviceName.toUpperCase() !== SERVICE_TYPES.DEFAULT) {
    let newResponse = {
      ...configsResponse,
      dayWiseSlots: ALL_DAYS_VALUES.reduce(
        (acc, day) => ({
          ...acc,
          [day]: configsResponse.templateSlots?.filter((slot: any) => slot.day.toLowerCase() === day.toLowerCase()),
        }),
        {}
      ),
    };
    return newResponse;
  } else {
    let allSupplierTypes: any = Object.keys(configsResponse.defaultServiceTemplateSlots);
    let allSupplierTypesData: any = designDefaultServiceDayWiseSlot(allSupplierTypes, configsResponse);

    let newResponse = {
      ...configsResponse,
      supplierTypes: allSupplierTypesData,
    };

    return newResponse;
  }
};

export const groupClickAndCollectResponseByCollectionPoints: any = (configResponse: any) => {
  let allCollectionPoints: any = [...new Set(configResponse.templateSlots.map((ts: any) => ts.clickAndCollect.displayName))];
  let collectionPointsData: any = allCollectionPoints.reduce(
    (acc: any, cp: any) => ({
      ...acc,
      [cp]: {
        collectionPointDetails: configResponse.templateSlots.find((s: any) => s.clickAndCollect.displayName.toLowerCase() === cp.toLowerCase()).clickAndCollect,
        templateSlots: configResponse.templateSlots.filter((s: any) => s.clickAndCollect.displayName.toLowerCase() === cp.toLowerCase()),
        capacityAmountAtCollectionPointLevel:
          configResponse.templateSlots !== undefined
            ? configResponse.templateSlots.filter((s: any) => s.clickAndCollect.displayName.toLowerCase() === cp.toLowerCase()).reduce((a: any, b: any) => a + b.slotCapacity, 0)
            : 0,
      },
    }),
    {}
  );
  collectionPointsData = allCollectionPoints.reduce((acc: any, cp: any) => {
    let collectionPointObj: any = collectionPointsData[cp];

    return {
      ...acc,
      [cp]: {
        ...collectionPointObj,
        dayWiseSlots: ALL_DAYS_VALUES.reduce(
          (daysAcc, day) => ({
            ...daysAcc,
            [day]: collectionPointObj.templateSlots.filter((cpo: any) => cpo.day.toLowerCase() === day.toLowerCase()),
          }),
          {}
        ),
      },
    };
  }, {});

  if (collectionPointsData !== undefined && Object.keys(collectionPointsData).length > 0) {
    Object.keys(collectionPointsData).forEach((collPoint: any) => {
      if (collectionPointsData[collPoint] !== undefined && collectionPointsData[collPoint].dayWiseSlots !== undefined) {
        Object.keys(collectionPointsData[collPoint].dayWiseSlots).forEach((dayValue) => {
          if (
            collectionPointsData[collPoint].dayWiseSlots[dayValue] !== undefined &&
            Array.isArray(collectionPointsData[collPoint].dayWiseSlots[dayValue]) !== undefined &&
            collectionPointsData[collPoint].dayWiseSlots[dayValue].length > 0
          ) {
            let maximum_slot_capacity = collectionPointsData[collPoint].dayWiseSlots[dayValue].reduce((a: any, b: any) => a + b.slotCapacity, 0);
            collectionPointsData[collPoint].dayWiseSlots[dayValue].forEach((daydata: any) => {
              daydata.maximum_slot_capacity = maximum_slot_capacity;
            });
          }
        });
      }
    });
  }

  let newResponse = {
    ...configResponse,
    collectionPoints: collectionPointsData,
    capacityAmountAtZoneLevel: configResponse.templateSlots !== undefined ? configResponse.templateSlots.reduce((a: any, b: any) => a + b.slotCapacity, 0) : 0,
  };
  return newResponse;
};

export const groupDefaultResponseBySupplierType: any = (configResponse: any) => {
  let capacityCount = 0;
  let allSupplierTypes: any = Object.keys(configResponse.defaultServiceTemplateSlots);
  let allSupplierTypesData: any = designDefaultServiceDayWiseSlot(allSupplierTypes, configResponse);

  Object.keys(configResponse.defaultServiceTemplateSlots).forEach((type: any) => {
    configResponse.defaultServiceTemplateSlots[type].forEach((obj: any) => {
      if (obj !== undefined && obj.slotCapacity !== undefined) {
        capacityCount = capacityCount + obj.slotCapacity;
      }
    });
  });

  let newResponse = {
    ...configResponse,
    supplierTypes: allSupplierTypesData,
    capacityAmountAtZoneLevel: capacityCount,
  };

  return newResponse;
};

export const createTemplateSlots: any = createAsyncThunk("create/templateSlots", async ({ posNo, service, zoneConfigIndex, zoneConfigurations }: any, { rejectWithValue }) => {
  try {
    const {
      response: { data },
    }: any = await POSService.createTemplateSlots(posNo, zoneConfigurations);
    if (service.toUpperCase() === SERVICE_TYPES.CLICK_N_COLLECT) {
      let transformedResponse = groupClickAndCollectResponseByCollectionPoints(data);
      return transformedResponse;
    } else if (service.toUpperCase() === SERVICE_TYPES.DEFAULT) {
      // let transformedResponse = { ...data.find((r: any) => r.zoneConfig.id === zoneConfigurations.zoneConfig.id) };
      let transformedResponse = groupTemplateSlotsByDay(data, service);
      return transformedResponse;
    } else {
      let transformedResponse = groupTemplateSlotsByDay(data, service);
      return transformedResponse;
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateTemplateSlots: any = createAsyncThunk("update/templateSlots", async ({ posNo, service, zoneConfigIndex, zoneConfigurations }: any, { rejectWithValue }) => {
  try {
    const {
      response: { data },
    }: any = await POSService.updateTemplateSlots(posNo, zoneConfigurations);
    let transformedResponse = {};
    if (service !== "click_n_collect") {
      transformedResponse = { ...data.find((r: any) => r.zoneConfig.id === zoneConfigurations.zoneConfig.id) };
      transformedResponse = groupTemplateSlotsByDay(transformedResponse, service);
      return transformedResponse;
    } else {
      transformedResponse = { ...data.find((r: any) => r.zoneConfig.id === zoneConfigurations.zoneConfig.id) };
      transformedResponse = groupClickAndCollectResponseByCollectionPoints(transformedResponse);
      return transformedResponse;
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const fetchSlotsInformationByServiceType: any = createAsyncThunk("slots/fetchSlotsByService", async ({ posNo, serviceType, serviceTypeName }: any, { rejectWithValue }) => {
  try {
    const {
      response: { data },
    }: any = await POSService.fetchTemplateSlots(posNo, { serviceType });
    let transformedResponse = {};
    if (data.slots) {
      if (serviceTypeName.toUpperCase() === SERVICE_TYPES.CLICK_N_COLLECT) {
        transformedResponse = {
          ...data,
          slots: data.slots.map((r: any) => {
            return groupClickAndCollectResponseByCollectionPoints(r);
          }),
        };
        return transformedResponse;
      } else {
        transformedResponse = {
          ...data,
          slots: data.slots.map((r: any) => ({
            ...groupTemplateSlotsByDay(r, serviceTypeName.toUpperCase()),
          })),
        };
      }
    } else {
      return { slots: [] };
    }
    return transformedResponse;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const makeTemplateSlotEditable: any = createAsyncThunk("template/makeEditable", ({ serviceTypeName, slotData, canBeUpdated }: any) => {
  return canBeUpdated;
});

export const makeCnCTemplateSlotEditable: any = createAsyncThunk("template/Cnc/makeEditable", ({ serviceTypeName, slotData, canBeUpdated }: any) => {
  return canBeUpdated;
});

export const createNewTemplateSlot: any = createAsyncThunk("templateSlot/create", async ({ serviceTypeName, slotData }: any, { rejectWithValue }) => {
  try {
    const { response }: any = await addNewTemplateSlot(slotData);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createCnCTemplateSlot: any = createAsyncThunk("templateSlot/CnC/create", async ({ serviceTypeName, slotData }: any, { rejectWithValue }) => {
  try {
    const { response }: any = await addNewTemplateSlot(slotData);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateTemplateSlotBySlotId: any = createAsyncThunk("templateSlot/updateTemplateSlotBySlotId", async ({ serviceTypeName, slotData }: any, { rejectWithValue }) => {
  try {
    const { response }: any = await updateTemplateSlot(slotData);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateCnCTemplateSlot: any = createAsyncThunk("templateSlot/CnC/update", async ({ serviceTypeName, slotData }: any, { rejectWithValue }) => {
  try {
    const { response }: any = await updateTemplateSlot(slotData);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const deleteATemplateSlot: any = createAsyncThunk("template/delete", async ({ serviceTypeName, slotData }: any, { rejectWithValue }) => {
  try {
    const { response }: any = await deleteTemplateSlot(slotData.id);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const deleteCnCTemplateSlot: any = createAsyncThunk("templateSlot/CnC/delete", async ({ serviceTypeName, slotData }: any, { rejectWithValue }) => {
  try {
    const { response }: any = await deleteTemplateSlot(slotData.id);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const deleteZoneConfig: any = createAsyncThunk("zoneConfig/delete", async ({ serviceTypeName, zoneConfigId, posNo }: any, { rejectWithValue }) => {
  try {
    let requestObject = {
      posNo: posNo,
      serviceTypeName: serviceTypeName,
      zoneId: zoneConfigId,
    };
    const { response }: any = await POSService.deleteZoneConfigByZoneId(requestObject);
    return response;
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const makeDefaultTemplateSlotEditable: any = createAsyncThunk("template/makeDefaultTemplateSlotEditable", ({ serviceTypeName, slotData, canBeUpdated, objectKey }: any) => {
  return canBeUpdated;
});

export const updateDefaultTemplateSlotBySlotId: any = createAsyncThunk("templateSlot/updateDefaultTemplateSlotBySlotId", async ({ serviceTypeName, slotData }: any, { rejectWithValue }) => {
  try {
    const { response }: any = await updateTemplateSlot(slotData);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

const initialState = {
  standard: [],
  express: [],
  default: [],
  click_n_collect: [],

  fetchingSlots: false,
  fetchingSlotsError: "",
};

const slotsSlice: any = createSlice({
  name: "slots",
  initialState,
  reducers: {
    resetSlotsInformation: (state: any, action: any) => {
      return initialState;
    },
  },
  extraReducers: {
    [createTemplateSlots.pending]: (state: any, action: any) => {
      const { service, zoneConfigIndex } = action.meta.arg;
      state[service][zoneConfigIndex] = {
        ...state[service][zoneConfigIndex],
        isCreating: true,
        isCreated: false,
        createError: "",
      };
    },
    [createTemplateSlots.fulfilled]: (state: any, action: any) => {
      const { service, zoneConfigIndex } = action.meta.arg;
      state[service][zoneConfigIndex] = { ...action.payload, isCreating: false, isCreated: true };
    },
    [createTemplateSlots.rejected]: (state: any, action: any) => {
      const { service, zoneConfigIndex } = action.meta.arg;
      state[service][zoneConfigIndex] = { isCreating: false, isCreated: false, createError: action.payload.message };
    },
    [updateTemplateSlots.pending]: (state: any, action: any) => {
      const { service, zoneConfigIndex } = action.meta.arg;
      state[service][zoneConfigIndex] = {
        ...state[service][zoneConfigIndex],
        isUpdating: true,
        isUpdated: false,
        updateError: "",
      };
    },
    [updateTemplateSlots.fulfilled]: (state: any, action: any) => {
      const { service, zoneConfigIndex } = action.meta.arg;
      state[service][zoneConfigIndex] = { ...action.payload, isUpdating: false, isUpdated: true };
    },
    [updateTemplateSlots.rejected]: (state: any, action: any) => {
      const { service, zoneConfigIndex } = action.meta.arg;
      state[service][zoneConfigIndex] = {
        ...state[service][zoneConfigIndex],
        isUpdating: false,
        isUpdated: false,
        updateError: action.payload.message,
      };
    },
    [fetchSlotsInformationByServiceType.pending]: (state: any, action: any) => {
      state.fetchingSlots = true;
      state.fetchingSlotsError = "";
    },
    [fetchSlotsInformationByServiceType.fulfilled]: (state: any, action: any) => {
      const {
        meta: {
          arg: { serviceTypeName },
        },
        payload,
      } = action;
      state.fetchingSlots = false;
      state[serviceTypeName] = payload.slots;
    },
    [fetchSlotsInformationByServiceType.rejected]: (state: any, action: any) => {
      const {
        meta: {
          arg: { serviceTypeName },
        },
        payload,
      } = action;
      state.fetchingSlots = false;
      state[serviceTypeName] = [];
      state.fetchingSlotsError = payload.message;
    },
    [makeTemplateSlotEditable.fulfilled]: (state: any, action: any) => {
      const {
        meta: {
          arg: {
            serviceTypeName,
            slotData: { zoneId, day, id: slotId },
          },
        },
        payload,
      } = action;
      state[serviceTypeName].find((item: any) => item.zoneConfig.id === zoneId).dayWiseSlots[day].find((slot: any) => slot.id === slotId).canBeUpdated = payload;
    },
    [makeCnCTemplateSlotEditable.fulfilled]: (state: any, action: any) => {
      const {
        meta: {
          arg: {
            serviceTypeName,
            slotData: {
              zoneId,
              day,
              id: slotId,
              clickAndCollect: { displayName },
            },
          },
        },
        payload,
      } = action;
      state[serviceTypeName].find((item: any) => item.zoneConfig.id === zoneId).collectionPoints[displayName].dayWiseSlots[day].find((slot: any) => slot.id === slotId).canBeUpdated = payload;
    },
    [createNewTemplateSlot.fulfilled]: (state: any, action: any) => {
      const {
        meta: {
          arg: {
            serviceTypeName,
            slotData: { zoneId, day },
          },
        },
        payload,
      } = action;
      state[serviceTypeName].find((item: any) => item.zoneConfig.id === zoneId).dayWiseSlots[day].push(payload.data);
    },
    [createCnCTemplateSlot.fulfilled]: (state: any, action: any) => {
      const {
        meta: {
          arg: {
            serviceTypeName,
            slotData: {
              zoneId,
              day,
              clickAndCollect: { displayName },
            },
          },
        },
        payload,
      } = action;
      let zoneIndex = state[serviceTypeName].findIndex((item: any) => item.zoneConfig.id === zoneId);
      state[serviceTypeName][zoneIndex].collectionPoints[displayName].dayWiseSlots[day].push(payload.data);
    },
    [createCnCTemplateSlot.rejected]: (state: any, action: any) => {},

    [updateTemplateSlotBySlotId.pending]: (state: any, action: any) => {
      const {
        meta: {
          arg: {
            serviceTypeName,
            slotData: { zoneId, day, id: slotId },
          },
        },
      } = action;
      state[serviceTypeName].find((item: any) => item.zoneConfig.id === zoneId).dayWiseSlots[day].find((slot: any) => slot.id === slotId).isUpdating = true;
      state[serviceTypeName].find((item: any) => item.zoneConfig.id === zoneId).dayWiseSlots[day].find((slot: any) => slot.id === slotId).updateError = "";
    },
    [updateTemplateSlotBySlotId.fulfilled]: (state: any, action: any) => {
      const {
        meta: {
          arg: {
            serviceTypeName,
            slotData: { zoneId, day, id: slotId },
          },
        },
        payload,
      } = action;
      let zoneIndex = state[serviceTypeName].findIndex((item: any) => item.zoneConfig.id === zoneId);
      let slotToBeUpdateIndex = state[serviceTypeName].find((item: any) => item.zoneConfig.id === zoneId).dayWiseSlots[day].findIndex((slot: any) => slot.id === slotId);

      state[serviceTypeName][zoneIndex].dayWiseSlots[day][slotToBeUpdateIndex] = {
        ...payload.data,
        isUpdating: false,
        isUpdated: true,
        updateError: "",
        id: slotId,
      };
    },
    [updateTemplateSlotBySlotId.rejected]: (state: any, action: any) => {
      const {
        meta: {
          arg: {
            serviceTypeName,
            slotData: { zoneId, day, id: slotId },
          },
        },
        payload,
      } = action;
      let zoneIndex = state[serviceTypeName].findIndex((item: any) => item.zoneConfig.id === zoneId);
      let slotToBeUpdateIndex = state[serviceTypeName].find((item: any) => item.zoneConfig.id === zoneId).dayWiseSlots[day].findIndex((slot: any) => slot.id === slotId);

      state[serviceTypeName][zoneIndex].dayWiseSlots[day][slotToBeUpdateIndex] = {
        ...state[serviceTypeName][zoneIndex].dayWiseSlots[day][slotToBeUpdateIndex],
        isUpdating: false,
        isUpdated: false,
        updateError: payload.message,
        id: slotId,
      };
    },

    [deleteATemplateSlot.pending]: (state: any, action: any) => {
      const {
        meta: {
          arg: {
            serviceTypeName,
            slotData: { zoneId, day, id: slotId },
          },
        },
      } = action;
      state[serviceTypeName].find((item: any) => item.zoneConfig.id === zoneId).dayWiseSlots[day].find((slot: any) => slot.id === slotId).isDeleting = true;
    },
    [deleteATemplateSlot.fulfilled]: (state: any, action: any) => {
      const {
        meta: {
          arg: {
            serviceTypeName,
            slotData: { zoneId, day, id: slotId },
          },
        },
      } = action;
      let filteredSlots = state[serviceTypeName].find((item: any) => item.zoneConfig.id === zoneId).dayWiseSlots[day].filter((slot: any) => slot.id !== slotId);
      state[serviceTypeName].find((item: any) => item.zoneConfig.id === zoneId).dayWiseSlots[day] = filteredSlots;
    },
    [updateCnCTemplateSlot.pending]: (state: any, action: any) => {
      const {
        meta: {
          arg: {
            serviceTypeName,
            slotData: {
              zoneId,
              day,
              id: slotId,
              clickAndCollect: { displayName },
            },
          },
        },
      } = action;
      let zoneIndex = state[serviceTypeName].findIndex((item: any) => item.zoneConfig.id === zoneId);
      let slotToBeUpdateIndex = state[serviceTypeName][zoneIndex].collectionPoints[displayName].dayWiseSlots[day].findIndex((sl: any) => sl.id === slotId);

      state[serviceTypeName][zoneIndex].collectionPoints[displayName].dayWiseSlots[day][slotToBeUpdateIndex].isUpdating = true;
    },
    [updateCnCTemplateSlot.fulfilled]: (state: any, action: any) => {
      const {
        meta: {
          arg: {
            serviceTypeName,
            slotData: {
              zoneId,
              day,
              id: slotId,
              clickAndCollect: { displayName },
            },
          },
        },
        payload,
      } = action;
      let zoneIndex = state[serviceTypeName].findIndex((item: any) => item.zoneConfig.id === zoneId);
      let slotToBeUpdateIndex = state[serviceTypeName][zoneIndex].collectionPoints[displayName].dayWiseSlots[day].findIndex((sl: any) => sl.id === slotId);

      state[serviceTypeName][zoneIndex].collectionPoints[displayName].dayWiseSlots[day][slotToBeUpdateIndex] = {
        ...payload.data,
        isUpdating: false,
        isUpdated: true,
      };
    },
    [updateCnCTemplateSlot.rejected]: (state: any, action: any) => {
      const {
        meta: {
          arg: {
            serviceTypeName,
            slotData: {
              zoneId,
              day,
              id: slotId,
              clickAndCollect: { displayName },
            },
          },
        },
        payload,
      } = action;
      let zoneIndex = state[serviceTypeName].findIndex((item: any) => item.zoneConfig.id === zoneId);
      let slotToBeUpdateIndex = state[serviceTypeName][zoneIndex].collectionPoints[displayName].dayWiseSlots[day].findIndex((sl: any) => sl.id === slotId);

      state[serviceTypeName][zoneIndex].collectionPoints[displayName].dayWiseSlots[day][slotToBeUpdateIndex] = {
        ...state[serviceTypeName][zoneIndex].collectionPoints[displayName].dayWiseSlots[day][slotToBeUpdateIndex],
        ...payload.data,
        isUpdating: false,
        isUpdated: false,
      };
    },
    [deleteCnCTemplateSlot.pending]: (state: any, action: any) => {
      const {
        meta: {
          arg: {
            serviceTypeName,
            slotData: {
              zoneId,
              day,
              id: slotId,
              clickAndCollect: { displayName },
            },
          },
        },
      } = action;
      let zoneIndex = state[serviceTypeName].findIndex((item: any) => item.zoneConfig.id === zoneId);
      let slotToBeUpdateIndex = state[serviceTypeName][zoneIndex].collectionPoints[displayName].dayWiseSlots[day].findIndex((sl: any) => sl.id === slotId);

      state[serviceTypeName][zoneIndex].collectionPoints[displayName].dayWiseSlots[day][slotToBeUpdateIndex].isDeleting = true;
    },
    [deleteCnCTemplateSlot.fulfilled]: (state: any, action: any) => {
      const {
        meta: {
          arg: {
            serviceTypeName,
            slotData: {
              zoneId,
              day,
              id: slotId,
              clickAndCollect: { displayName },
            },
          },
        },
      } = action;
      let zoneIndex = state[serviceTypeName].findIndex((item: any) => item.zoneConfig.id === zoneId);

      let filteredSlots = state[serviceTypeName][zoneIndex].collectionPoints[displayName].dayWiseSlots[day].filter((s: any) => s.id !== slotId);

      state[serviceTypeName][zoneIndex].collectionPoints[displayName].dayWiseSlots[day] = filteredSlots;
    },
    [deleteCnCTemplateSlot.rejected]: (state: any, action: any) => {
      const {
        meta: {
          arg: {
            serviceTypeName,
            slotData: {
              zoneId,
              day,
              id: slotId,
              clickAndCollect: { displayName },
            },
          },
        },
      } = action;
      let zoneIndex = state[serviceTypeName].findIndex((item: any) => item.zoneConfig.id === zoneId);
      let slotToBeUpdateIndex = state[serviceTypeName][zoneIndex].collectionPoints[displayName].dayWiseSlots[day].findIndex((sl: any) => sl.id === slotId);

      state[serviceTypeName][zoneIndex].collectionPoints[displayName].dayWiseSlots[day][slotToBeUpdateIndex].isDeleting = false;
    },
    /** Delete Zone config */
    [deleteZoneConfig.pending]: (state: any) => {
      state.isDeleting = true;
      state.deleteError = "";
    },
    [deleteZoneConfig.fulfilled]: (state: any, action: any) => {
      const {
        meta: {
          arg: { serviceTypeName, zoneConfigId },
        },
      } = action;
      state.isDeleting = false;

      state[serviceTypeName] = state[serviceTypeName].filter((s: any) => s.zoneConfig.id !== zoneConfigId);
    },
    [deleteZoneConfig.rejected]: (state: any, action: any) => {
      const { payload } = action;
      state.isDeleting = false;
      state.deleteError = payload.message;
    },
    [makeDefaultTemplateSlotEditable.fulfilled]: (state: any, action: any) => {
      const {
        meta: {
          arg: {
            serviceTypeName,
            slotData: { zoneId, day, id: slotId },
            objectKey,
          },
        },
        payload,
      } = action;
      state[serviceTypeName].find((item: any) => item.zoneConfig.id === zoneId).supplierTypes[objectKey].dayWiseSlots[day].find((slot: any) => slot.id === slotId).canBeUpdated = payload;
    },
    [updateDefaultTemplateSlotBySlotId.pending]: (state: any, action: any) => {
      const {
        meta: {
          arg: {
            serviceTypeName,
            slotData: { zoneId, day, id: slotId },
            objectKey,
          },
        },
      } = action;

      state[serviceTypeName].find((item: any) => item.zoneConfig.id === zoneId).supplierTypes[objectKey].dayWiseSlots[day].find((slot: any) => slot.id === slotId).isUpdating = true;
      state[serviceTypeName].find((item: any) => item.zoneConfig.id === zoneId).supplierTypes[objectKey].dayWiseSlots[day].find((slot: any) => slot.id === slotId).updateError = "";
    },
    [updateDefaultTemplateSlotBySlotId.fulfilled]: (state: any, action: any) => {
      const {
        meta: {
          arg: {
            serviceTypeName,
            slotData: { zoneId, day, id: slotId },
            objectKey,
          },
        },
        payload,
      } = action;
      let zoneIndex = state[serviceTypeName].findIndex((item: any) => item.zoneConfig.id === zoneId);
      let slotToBeUpdateIndex = state[serviceTypeName].find((item: any) => item.zoneConfig.id === zoneId).supplierTypes[objectKey].dayWiseSlots[day].findIndex((slot: any) => slot.id === slotId);

      state[serviceTypeName][zoneIndex].supplierTypes[objectKey].dayWiseSlots[day][slotToBeUpdateIndex] = {
        ...payload.data,
        isUpdating: false,
        isUpdated: true,
        updateError: "",
        id: slotId,
      };
    },
    [updateDefaultTemplateSlotBySlotId.rejected]: (state: any, action: any) => {
      const {
        meta: {
          arg: {
            serviceTypeName,
            slotData: { zoneId, day, id: slotId },
            objectKey,
          },
        },
        payload,
      } = action;
      let zoneIndex = state[serviceTypeName].findIndex((item: any) => item.zoneConfig.id === zoneId);
      let slotToBeUpdateIndex = state[serviceTypeName].find((item: any) => item.zoneConfig.id === zoneId).supplierTypes[objectKey].dayWiseSlots[day].findIndex((slot: any) => slot.id === slotId);

      state[serviceTypeName][zoneIndex].supplierTypes[objectKey].dayWiseSlots[day][slotToBeUpdateIndex] = {
        ...state[serviceTypeName][zoneIndex].supplierTypes[objectKey].dayWiseSlots[day][slotToBeUpdateIndex],
        isUpdating: false,
        isUpdated: false,
        updateError: payload.message,
        id: slotId,
      };
    },
  },
});

export default slotsSlice.reducer;

export const { resetSlotsInformation } = slotsSlice.actions;
