import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllUserRoleTypes } from "../../api/RolesAPI";
import { API_RESPONSE_STATUS } from "../constants";
import * as ConfigurationsService from "./../../api/ConfigurationsService";
import { fetchAllAreas, fetchAllCountries, fetchAllRegions } from "./../../api/CountryApi";
import * as POSService from "./../../api/POSService";


export const fetchListOfCountries = createAsyncThunk("configurations/fetchCountries", async (_, { rejectWithValue }) => {
  try {
    const { response } = await fetchAllCountries();
    let newResponse = response.map((country) => ({ ...country, id: country.name, countryId: country.id }));
    return newResponse;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const fetchAllRegionsOfCountry = createAsyncThunk("configurations/fetchRegions", async (countryId, { rejectWithValue }) => {
  try {
    const { response } = await fetchAllRegions(countryId);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const fetchAllAreasOfCountry = createAsyncThunk("configurations/fetchAreas", async (countryId, { rejectWithValue }) => {
  try {
    const { response } = await fetchAllAreas(countryId);
    let transformedResponse = { ...response };
    let allAreas = transformedResponse.regions.reduce((acc, r) => (r.areas ? [...acc, ...r.areas.map((area) => ({ ...area, regionId: r.id, regionName: r.name }))] : [...acc]), []);

    transformedResponse = { ...response, allAreas };
    return transformedResponse;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const fetchServiceTypes = createAsyncThunk("configurations/fetchServiceTypes", async (_, { rejectWithValue }) => {
  try {
    const { response } = await POSService.getServiceTypes();
    let newResponse = response.filter((item) => item.active).map((r) => ({ ...r, label: r.displayName, value: r.id }));
    return newResponse;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const fetchPOSTypes = createAsyncThunk("configurations/fetchPOSTypes", async (_, { rejectWithValue }) => {
  try {
    const { response } = await POSService.getPosTypes();
    let newResponse = response.map((r) => ({ ...r, id: r.name, posTypeId: r.id, label: r.name, value: r.name }));
    return newResponse;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const fetchAllCollectionPointsTypes = createAsyncThunk("configurations/fetchCollectionPoints", async (_, { rejectWithValue }) => {
  try {
    const { response } = await ConfigurationsService.getCollectionPointsTypes();
    let newResponse = response.data.map((r) => ({ ...r, id: r.code, name: r.displayName }));
    return newResponse;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const fetchAllUserRolesTypes = createAsyncThunk("configurations/fetchUserRolesTypes", async (_, { rejectWithValue }) => {
  try {
    const { response } = await getAllUserRoleTypes();
    if (response && response.success && response.data && Array.isArray(response.data)) {
      let newResponse = response.data.map((role) => ({ id: role.name, name: role.displayName }));
      return newResponse;
    }
    return [];
  } catch (error) {
    console.error("error :: ", error);
    return rejectWithValue(error);
  }
});

const commonFetchInitialState = {
  fetchStatus: API_RESPONSE_STATUS.IDLE,
  error: "",
  data: [],
};

const initialState = {
  serviceTypes: {
    ...commonFetchInitialState,
  },

  posTypes: {
    ...commonFetchInitialState,
  },

  countries: {
    ...commonFetchInitialState,
  },

  regions: {
    ...commonFetchInitialState,
  },

  areas: {
    ...commonFetchInitialState,
    // data: {},
  },

  collectionPointsTypes: {
    ...commonFetchInitialState,
  },

  roles: {
    ...commonFetchInitialState,
  },
};

const configurationsSlice = createSlice({
  name: "configurations",
  initialState,
  reducers: {
    resetRegionsAndAreas(state) {
      if (state.areas.fetchStatus !== API_RESPONSE_STATUS.LOADING) {
        state.regions = commonFetchInitialState;
        state.areas = commonFetchInitialState;
      }
    },
  },
  extraReducers: (builders) => {
    builders
      /* Service Types */
      .addCase(fetchServiceTypes.pending, (state) => {
        state.serviceTypes.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.serviceTypes.error = "";
      })
      .addCase(fetchServiceTypes.fulfilled, (state, action) => {
        state.serviceTypes.data = action.payload;
        state.serviceTypes.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
      })
      .addCase(fetchServiceTypes.rejected, (state, action) => {
        state.serviceTypes.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.serviceTypes.data = [];
        state.serviceTypes.error = action.error.message;
      })
      /* POS Types */
      .addCase(fetchPOSTypes.pending, (state) => {
        state.posTypes.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.posTypes.error = "";
      })
      .addCase(fetchPOSTypes.fulfilled, (state, action) => {
        state.posTypes.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.posTypes.error = "";
        state.posTypes.data = action.payload;
      })
      .addCase(fetchPOSTypes.rejected, (state, action) => {
        state.posTypes.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.posTypes.error = action.error.message;
        state.posTypes.data = [];
      })
      /* Countries */
      .addCase(fetchListOfCountries.pending, (state) => {
        state.countries.fetchStatus = API_RESPONSE_STATUS.LOADING;
      })
      .addCase(fetchListOfCountries.fulfilled, (state, action) => {
        state.countries.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.countries.data = action.payload;
      })
      /* Regions */
      .addCase(fetchAllRegionsOfCountry.pending, (state) => {
        state.regions.fetchStatus = API_RESPONSE_STATUS.LOADING;
      })
      .addCase(fetchAllRegionsOfCountry.fulfilled, (state, action) => {
        state.regions.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.regions.data = action.payload;
      })
      /* Areas */
      .addCase(fetchAllAreasOfCountry.pending, (state) => {
        state.areas.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.areas.error = "";
      })
      .addCase(fetchAllAreasOfCountry.fulfilled, (state, action) => {
        state.areas.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.areas.data = action.payload.allAreas;
      })
      .addCase(fetchAllAreasOfCountry.rejected, (state, action) => {
        state.areas.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.areas.data = [];
      })
      /* Collection Points Types */
      .addCase(fetchAllCollectionPointsTypes.pending, (state) => {
        state.collectionPointsTypes.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.collectionPointsTypes.error = "";
      })
      .addCase(fetchAllCollectionPointsTypes.fulfilled, (state, action) => {
        state.collectionPointsTypes.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.collectionPointsTypes.data = action.payload;
      })
      .addCase(fetchAllCollectionPointsTypes.rejected, (state, action) => {
        state.collectionPointsTypes.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.collectionPointsTypes.error = action.error.message;
        state.collectionPointsTypes.data = [];
      })
      /* User Role Types */
      .addCase(fetchAllUserRolesTypes.pending, (state) => {
        state.roles.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.roles.error = "";
      })
      .addCase(fetchAllUserRolesTypes.fulfilled, (state, action) => {
        state.roles.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.roles.data = action.payload;
      })
      .addCase(fetchAllUserRolesTypes.rejected, (state, action) => {
        state.roles.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.roles.error = action.error.message;
        state.roles.data = [];
      });
  },
});

export const { resetRegionsAndAreas } = configurationsSlice.actions;

export default configurationsSlice.reducer;

export const selectServiceTypes = (state) => state.configurations.serviceTypes.data;

export const selectPosTypes = (state) => state.configurations.posTypes.data;

export const selectAllCountries = (state) => state.configurations.countries.data;

export const selectAllRegions = (state) => state.configurations.regions.data;

// Returns the array of all areas for all the regions
export const selectAllAreas = (state) => state.configurations.areas.data;

// Returns the obj with fetchStatus, data and error
export const selectAreas = (state) => state.configurations.areas;

export const selectCollectionPointsTypes = (state) => state.configurations.collectionPointsTypes;

export const selectAllRoles = (state) => state.configurations.roles.data;
