import React from "react";
import { Link } from "react-router-dom";

import { TableBody, TableCell, TableRow, Tooltip, Typography } from "@material-ui/core";

import Pencil from "../../assets/svg/Pencil.svg";
import DeleteIcon from "../../assets/svg/DeleteIcon.svg";
import EyeIconDetails from "../../assets/svg/EyeIconDetails.svg";

import { APP_ROUTES, USER_TYPES } from "../../../config/constants";
import { userStyles } from "../Styles/DeliveryFeePageStyles";
import { TABLE_BODY_NO_DATA_AVAILABLE } from "../Utils/DeliveryFeeConstants";

interface DeliveryFeeCardBodyInterface {
  deliveryFeeListData: any;
  deliveryFeeHeaderData: any;
  userInfo: any;
}
const DeliveryFeeCardBody = (props: DeliveryFeeCardBodyInterface) => {
  const classes = userStyles();
  const { deliveryFeeListData, deliveryFeeHeaderData, userInfo } = props;

  return (
    <TableBody>
      {deliveryFeeListData && deliveryFeeListData.length > 0 ? (
        deliveryFeeListData.map((fee: any, index: any) => (
          <TableRow key={`DeliveryFeeDataRecord-${index}`}>
            <TableCell className={classes.cellDataStyle}>{fee.id || "-"}</TableCell>
            <TableCell className={classes.cellDataStyle}>{fee.storeName || "-"}</TableCell>
            <TableCell className={classes.cellDataStyle}>{fee.zone || "-"}</TableCell>
            {deliveryFeeHeaderData &&
              deliveryFeeHeaderData.headerRowsValues !== undefined &&
              Array.isArray(deliveryFeeHeaderData.headerRowsValues) &&
              deliveryFeeHeaderData.headerRowsValues.length > 0 &&
              deliveryFeeHeaderData.headerRowsValues.map((headerType: any, headerTypeIndex: any) =>
                fee && fee.shipmentLevelDeliveryFeeConfigs && fee.shipmentLevelDeliveryFeeConfigs[headerType.shipmentId] ? (
                  <>
                    <TableCell align="left" className={classes.cellDataStyle} key={`0-${headerTypeIndex}-${headerTypeIndex}-headerRowsValues`}>
                      {fee.shipmentLevelDeliveryFeeConfigs[headerType.shipmentId].deliveryFeeRangeConfigDTOList !== undefined &&
                      Array.isArray(fee.shipmentLevelDeliveryFeeConfigs[headerType.shipmentId].deliveryFeeRangeConfigDTOList) &&
                      fee.shipmentLevelDeliveryFeeConfigs[headerType.shipmentId].deliveryFeeRangeConfigDTOList.length > 0 ? (
                        <Tooltip title={`Threshold range configured`}>
                          <Typography className={classes.rangeConfiguredTextStyle}>{`Range`}</Typography>
                        </Tooltip>
                      ) : (
                        <Typography className={classes.thresholdValueTextStyle}>{fee.shipmentLevelDeliveryFeeConfigs[headerType.shipmentId].orderThreshold}</Typography>
                      )}
                    </TableCell>
                    <TableCell align="left" className={classes.cellDataStyle} key={`1-${headerTypeIndex}-${headerTypeIndex}-headerRowsValues`}>
                      {fee.shipmentLevelDeliveryFeeConfigs[headerType.shipmentId].aboveThreshold}
                    </TableCell>
                    <TableCell align="left" className={classes.cellDataStyle} key={`2-${headerTypeIndex}-${headerTypeIndex}-headerRowsValues`}>
                      {fee.shipmentLevelDeliveryFeeConfigs[headerType.shipmentId].belowThreshold}
                    </TableCell>
                  </>
                ) : (
                  <>
                    <TableCell align="left" className={classes.cellDataStyle} key={`3-${headerTypeIndex}-${headerTypeIndex}-headerRowsValues`}>
                      {"-"}
                    </TableCell>
                    <TableCell align="left" className={classes.cellDataStyle} key={`4-${headerTypeIndex}-${headerTypeIndex}-headerRowsValues`}>
                      {"-"}
                    </TableCell>
                    <TableCell align="left" className={classes.cellDataStyle} key={`5-${headerTypeIndex}-${headerTypeIndex}-headerRowsValues`}>
                      {"-"}
                    </TableCell>
                  </>
                )
              )}
            {/* <TableCell align="left" className={classes.cellDataStyle}>
              {fee.id || "-"}
            </TableCell>
            <TableCell align="center" className={classes.cellDataStyle}>
              {fee.id || "-"}
            </TableCell>
            <TableCell align="left" className={classes.cellDataStyle}>
              {fee.id || "-"}
            </TableCell>
            <TableCell align="left" className={classes.cellDataStyle}>
              {fee.id || "-"}
            </TableCell>
            <TableCell align="center" className={classes.cellDataStyle}>
              {fee.id || "-"}
            </TableCell>
            <TableCell align="left" className={classes.cellDataStyle}>
              {fee.id || "-"}
            </TableCell>
            <TableCell align="left" className={classes.cellDataStyle}>
              {fee.id || "-"}
            </TableCell>
            <TableCell align="center" className={classes.cellDataStyle}>
              {fee.id || "-"}
            </TableCell>
            <TableCell align="left" className={classes.cellDataStyle}>
              {fee.id || "-"}
            </TableCell> */}
            <TableCell className={classes.cellActionStyle}>
              <div className={classes.actionDiv}>
                {userInfo && userInfo.userProfile && userInfo.userProfile.role !== undefined && userInfo.userProfile.role !== "" && userInfo?.userProfile?.role === USER_TYPES.MAF_ADMIN && (
                  <Link
                    to={{
                      pathname: `${APP_ROUTES.DELIVERY_FEE_CONFIGURATION}`,
                      search: `?posNo=${fee.id}&zoneName=${fee.zone}`,
                    }}
                    style={{ margin: "auto" }}
                  >
                    <img src={Pencil} alt="Edit" className={classes.actionIconStyle} />
                  </Link>
                )}
                <Link
                  to={{
                    pathname: `${APP_ROUTES.DELIVERY_FEE_PREVIEW_CONFIGURATION}`,
                    search: `?posNo=${fee.id}&zoneName=${fee.zone}`,
                  }}
                  style={{ margin: "auto" }}
                >
                  <img src={EyeIconDetails} alt="EyeIconDetails" className={classes.actionIconStyle} />
                </Link>

                {/* <img src={DeleteIcon} alt="DeleteIcon" className={classes.actionIconStyle} /> */}
              </div>
            </TableCell>
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell
            rowSpan={1}
            colSpan={deliveryFeeHeaderData && deliveryFeeHeaderData.headerRowsValues && deliveryFeeHeaderData.headerRowsValues.length > 0 ? 4 + deliveryFeeHeaderData.headerRowsValues.length * 3 : 4}
            className={classes.noDataRowStyle}
          >{`${TABLE_BODY_NO_DATA_AVAILABLE}`}</TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};

export default React.memo(DeliveryFeeCardBody);
