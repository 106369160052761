import { createStyles, makeStyles } from "@material-ui/core";
import { COLORS } from "../../../config/theme/theme";

export const userStyles = makeStyles(() =>
  createStyles({
    // header start
    container: {
      marginTop: "80px",
      padding: "24px",
    },
    heading: {
      fontWeight: 600,
      fontSize: "24px",
      color: COLORS.DARK_GRAY,
      margin: "auto 0px",
    },
    configDetailsDiv: {
      padding: 0,
      margin: 0,
      marginTop: "24px",
    },
    // header end

    // mainContent Start
    accordionStyle: {
      background: COLORS.WHITE,
      border: "1px solid #C3C3C3",
      borderRadius: "4px",
      marginTop: "12px",
      "&:last-child": {
        padding: 0,
        margin: 0,
        marginTop: "12px",
      },
    },
    accordionSummaryStyle: {
      padding: "12px 24px 12px 12px",
    },
    accordionTitle: {
      fontSize: "18px",
      fontWeight: 600,
    },
    accordionDetailsStyle: {
      borderTop: "1px solid #C3C3C3",
      display: "block",
      padding: "12px 0px 12px 0px",
    },
    dlvFeeServiceTypeDiv: {
      margin: "0px 0px 24px 0px",
      padding: "12px",
      background: "rgb(249, 249, 249)",
      "&:last-child": {
        margin: "0px",
      },
    },
    dlvFeeServiceTypeTextDivStyle: {
      margin: 0,
      padding: "12px 0px 12px 0px",
    },
    dlvFeeServiceTypeTextStyle: {
      margin: "auto",
      padding: 0,
      color: COLORS.BLACK,
      fontSize: "18px",
      fontWeight: 600,
    },
    thresholdInputMainDiv: {
      margin: 0,
      padding: 0,
      paddingTop: "12px",
      display: "flex",
      justifyContent: "flex-start",
      "&:first-child": {
        padding: "0px",
      },
    },
    thresholdInputDiv: {
      paddingRight: "12px",
    },
    addConfigIconMainDivStyle: {
      width: "100%",
      height: "100%",
    },
    addConfigIconDivStyle: {
      margin: 0,
      padding: 0,
      display: "flex",
      justifyContent: "flex-start",
      cursor: "pointer",
    },
    addConfigIconDivStyleDisabled: {
      margin: 0,
      padding: 0,
      display: "flex",
      justifyContent: "flex-start",
      cursor: "default",
      opacity: "0.5",
    },
    addConfigIconStyle: {
      margin: "auto 0px",
      width: "20px",
      height: "20px",
      color: COLORS.PRIMARY_MAIN,
    },
    addConfigTextStyle: {
      color: COLORS.PRIMARY_MAIN,
      fontSize: "12px",
      fontWeight: 600,
      margin: "auto 0px auto 12px",
    },
    disableSwitch: {
      opacity: 0.5,
    },
    switchStyle: {},
    marginAuto: {
      margin: "auto",
    },
    useCalculationTextdivStyle: {
      margin: 0,
      marginRight: "12px",
      textAlign: "right",
    },
    innerAccordionMaindiv: {
      margin: 0,
      padding: 0,
      display: "flex",
      justifyContent: "space-between",
    },
    // mainContent end

    // footer start
    configButtonDiv: {
      display: "flex",
      justifyContent: "flex-end",
      padding: "24px 0px",
    },
    cancelConfigStyle: {
      width: "212px",
      background: "#FFFFFF",
      border: "1px solid #0E5AA7",
      borderRadius: "4px",
      color: "#0E5AA7",
      textDecoration: "none",
      textTransform: "none",
      marginRight: "24px",
      "&:hover": {
        background: "#FFFFFF",
        border: "1px solid #0E5AA7",
      },
      "&.MuiButton-label": {
        background: "#FFFFFF",
        border: "1px solid #0E5AA7",
        "&:hover": {
          background: "#FFFFFF",
          border: "1px solid #0E5AA7",
        },
      },
    },
    previewAndUpdateConfigStyle: {
      width: "300px",
      background: "#0E5AA7",
      border: "1px solid #0E5AA7",
      borderRadius: "4px",
      color: "#FFFFFF",
      textDecoration: "none",
      textTransform: "none",
      "&:hover": {
        background: "#0E5AA7",
        border: "1px solid #0E5AA7",
      },
      "&.MuiButton-label": {
        background: "#0E5AA7",
        border: "1px solid #0E5AA7",
        "&:hover": {
          background: "#0E5AA7",
          border: "1px solid #0E5AA7",
        },
      },
    },
    actionIconDivStyle: {
      margin: 0,
      marginTop: "auto",
      marginBottom: "auto",
      padding: 0,
      paddingLeft: "12px",
    },
    actionIconStyle: {
      cursor: "pointer",
    },
    // footer end

    // AdditionalDeliveryFeeConfigList start
    additionDlvFeeMainCard: {
      margin: 0,
      padding: "12px 0px 12px 0px",
      width: "100%",
      height: "auto",
      boxShadow: "none",
      background: "rgb(249, 249, 249)",
    },
    additionDlvFeeMainTitleDivStyle: {
      margin: 0,
      padding: "0px 0px 12px 0px",
    },
    additionDlvFeeMainTitleStyle: {
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "20px",
      color: "#141414",
    },
    additionDlvFeeInnerCardStyle: {
      margin: 0,
      padding: "0px",
      width: "100%",
      height: "auto",
      boxShadow: "none",
      background: "rgb(249, 249, 249)",
    },
    additionDlvFeeInnerTitleDivStyle: {
      margin: 0,
      padding: "12px 0px 12px 0px",
      width: "100%",
      height: "auto",
    },
    additionDlvFeeInnerTitleTextStyle: {
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "20px",
      color: "#2D2D2D",
    },
    additionDlvFeeInnerRowHeaderStyle: {
      margin: 0,
      padding: "12px 0px 0px 0px",
      width: "100%",
      height: "auto",
      boxShadow: "none",
    },
    additionDlvFeeInnerRowHeaderTextStyle: {
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "17px",
      color: "#2D2D2D",
    },
    additionDlvFeeInnerRowBodyStyle: {
      margin: 0,
      padding: "12px 0px 0px 0px",
      width: "100%",
      height: "auto",
      boxShadow: "none",
    },
    additionDlvFeeInnerRowDivStyle: {
      margin: 0,
      marginTop: "auto",
      marginBottom: "auto",
      padding: 0,
      paddingRight: "12px",
    },
    additionDlvFeeInnerRowBodyTextStyle: {
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "17px",
      color: "#2D2D2D",
    },
    // AdditionalDeliveryFeeConfigList end

    // changes for Threshold range
    dlvFeeCalculationDivStyle: {
      margin: "auto",
      padding: 0,
      textAlign: "right",
    },
    dlvFeeCalculationTextStyle: {
      fontWeight: 400,
      fontSize: "14px",
      color: "#141414",
      margin: "auto 12px",
    },
    dlvFeeCalculationTextBoldStyle: {
      fontWeight: 600,
      fontSize: "14px",
      color: "#0E5AA7",
      margin: "auto 12px",
    },
    multiDlvFeePercentageCalculationTextStyle: {
      fontWeight: 400,
      fontSize: "14px",
      color: "#141414",
      margin: "auto 12px auto -6%",
    },
    multiDlvFeePercentageCalculationTextBoldStyle: {
      fontWeight: 600,
      fontSize: "14px",
      color: "#0E5AA7",
      margin: "auto 12px auto -6%",
    },
    useCalculationDivStyle: {
      textAlign: "right",
    },
    addConfigurationInputDivStyle: {
      margin: 0,
      padding: 0,
      paddingTop: "12px",
      display: "flex",
      justifyContent: "flex-start",
    },
    textAlignLeft: {
      textAlign: "left",
    },

    // multi-shipment Content
    multiShipmentButtonDiv: {
      display: "flex",
      justifyContent: "flex-end",
      margin: 0,
      padding: 0,
    },
    multiShipmentButtonStyle: {
      margin: 0,
      padding: 0,
      width: "340px",
      background: "#FFFFFF",
      border: "1px solid #0E5AA7",
      borderRadius: "4px",
      color: "#0E5AA7",
      textDecoration: "none",
      textTransform: "none",
      "&:hover": {
        background: "#FFFFFF",
        border: "1px solid #0E5AA7",
      },
      "&:disabled": {
        background: "#FFFFFF",
        border: "1px solid #B2B2B2",
        opacity: "0.5",
      },
      "&.MuiButton-label": {
        background: "#FFFFFF",
        border: "1px solid #0E5AA7",
        "&:hover": {
          background: "#FFFFFF",
          border: "1px solid #0E5AA7",
        },
      },
    },
    shipmentLevelButtonStyle: {
      margin: 0,
      padding: 0,
      width: "340px",
      background: "#0E5AA7",
      border: "1px solid #0E5AA7",
      borderRadius: "4px",
      color: "#FFFFFF",
      textDecoration: "none",
      textTransform: "none",
      "&:hover": {
        background: "#0E5AA7",
        border: "1px solid #0E5AA7",
      },
      "&:disabled": {
        background: "#CFD8DC",
        border: "1px solid #B2B2B2",
        opacity: "0.5",
      },
      "&.MuiButton-label": {
        background: "#0E5AA7",
        border: "1px solid #0E5AA7",
        "&:hover": {
          background: "#0E5AA7",
          border: "1px solid #0E5AA7",
        },
      },
    },
    textAlignRight: {
      textAlign: "right",
    },
    percentageBasedDivStyle: {
      display: "flex",
      justifyContent: "flex-end",
    },
    multi_shipment_tooltip_style: {
      background: "#FFFFFF",
      minWidth: "380px",
      padding: "8px",
      margin: "8px 0px",
      border: "1px solid #FFFFFF",
      borderRadius: "4px",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    },
    multi_shipment_tooltip_arrow_style: {
      color: "#FFFFFF",
    },
    tooltipStyle: {
      ontWeight: 500,
      fontSize: "8px",
      lineHeight: "16px",
      color: "#2D2D2D",
    },
    multiShipmentDlvFeeServiceTypeTextDivStyle: {
      margin: 0,
      padding: "12px 0px 0px 0px",
    },
    drawerCheckBoxInputMainDiv: {
      margin: 0,
      padding: 0,
      paddingBottom: "12px",
      display: "flex",
      justifyContent: "flex-start",
      "&:first-child": {
        padding: "0px",
      },
    },
    drawerCheckBoxDiv: {
      margin: "auto",
    },
    checkboxLabel: {
      fontWeight: 400,
      fontSize: "12px",
      color: "#252525",
      margin: "auto 8px",
    },
    dlvFeeValueDisplayDataStyle: {
      fontFamily: "Montserrat",
      fontWeight: 500,
      color: "#141414",
      fontSize: "14px",
      lineHeight: "20px",
    },
    serviceTypeItem: {},
  })
);
