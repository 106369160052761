// Material UI Components
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Grid from "@material-ui/core/Grid";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useState } from "react";
// Constants/Utils
import { ALL_DAYS, QUOTA_VIEW_OPTIONS, SERVICE_TYPES } from "../../../../config/constants";
import ConfirmModal from "../../../common/ConfirmModal/ConfirmModal";
import LinkButton from "../../../common/LinkButton/LinkButton";
import MultiSelectBox from "../../../common/MultiSelectBox/MultiSelectBox";
// Custom Components
import PrimaryButton from "../../../common/PrimaryButton/PrimaryButton";
import SelectBox from "../../../common/SelectBox/SelectBox";
import TextBox from "../../../common/TextBox/index";
import TimePicker from "../../../common/TimePicker/TimePicker";
import Toast from "../../../common/Toast/Toast";
import QuotaSettings from "../../Standard/QuotaSettings/QuotaSettings";
import TemplateSlot from "../TemplateSlot/TemplateSlot";
import { generateDropdownItems } from "./../../../../utils/helperFunctions";
// Styles
import createTemplateSlotsStyles from "./CreateTemplateSlotsStyles";





const HOURS = generateDropdownItems(0, 95, "hours");
const MINUTES = generateDropdownItems(0, 59, "mins");
const TWENTY_FOUR_HOURS = generateDropdownItems(0, 23, "hours");

const CreateTemplateSlotsForm = ({
  serviceTypeName,
  days,
  remainingDays,
  daysSelectionDisabled = false,
  slotDurationHours,
  slotDurationMinutes,
  bufferTimeHours,
  bufferTimeMinutes,
  serviceStartTime,
  serviceEndTime,
  defaultSlotCapacity,
  quotas = [],
  handleDaysChange,
  handleServiceTimeChange,
  handleSlotsGeneratorFormFields,
  handleGenerateSlots,
  handleResetSlots,
  generatedSlots,
  handleIndividualSlotFieldsChange,
  handleIndividualSlotCheckbox,
  handleModifyQuotaSettings,
}) => {
  const classes = createTemplateSlotsStyles();

  const [formErrors, setFormErrors] = useState({
    days: "",
    slotDurationHours: "",
    slotDurationMinutes: "",
    bufferTimeHours: "",
    bufferTimeMinutes: "",
    serviceStartTime: "",
    serviceEndTime: "",
    defaultSlotCapacity: "",
  });

  const [showQuotaDrawer, setShowQuotaDrawer] = useState(false);
  const [quotaSettingsAction, setQuotaSettingsViewAction] = useState(QUOTA_VIEW_OPTIONS.MODIFY);
  const [selectedSlot, setSelectedSlot] = useState(null);

  const [showResetSlotsModal, setShowResetSlotsModal] = useState(false);

  const validateSlotsGeneratorForm = () => {
    let newErrors = {};
    if (!days.length) {
      newErrors.days = "Please select days";
    }

    if (slotDurationHours === "") {
      newErrors.slotDurationHours = "Please select slot duration hours";
    }

    if (!serviceStartTime) {
      newErrors.serviceStartTime = "Please select service start time";
    }

    if (!serviceEndTime) {
      newErrors.serviceEndTime = "Please select service end time";
    }

    if (serviceStartTime && serviceEndTime) {
      const serviceStartTimeInMinutes = new Date(serviceStartTime).getTime();
      const serviceEndTimeInMinutes = new Date(serviceEndTime).getTime();

      if (serviceStartTimeInMinutes >= serviceEndTimeInMinutes) {
        newErrors.genericError = "Service start time should be lesser than the service end time";
      }
    }

    if (defaultSlotCapacity === "") {
      newErrors.defaultSlotCapacity = "Please enter default slot capacity";
    }

    if (!slotDurationHours && !slotDurationMinutes) {
      newErrors.genericError = "Please select slot duration";
    }

    setFormErrors(newErrors);

    return Object.keys(newErrors).filter((err) => newErrors[err]).length === 0;
  };

  const isButtonDisabled = () => {
    let disabled = false;

    disabled =
      !days.length ||
      slotDurationHours === "" ||
      slotDurationMinutes === "" ||
      !serviceStartTime ||
      !serviceEndTime ||
      defaultSlotCapacity === "";

    return disabled;
  };

  const isResetButtonDisabled = () => {
    return !generatedSlots.length;
  };

  const handleResetSlotsClick = () => {
    setShowResetSlotsModal(true);
  };

  const handleResetSlotsApprove = () => {
    handleResetSlots();
    setShowResetSlotsModal(false);
  };

  const handleGenerateSlotsClick = () => {
    if (validateSlotsGeneratorForm()) {
      handleGenerateSlots();
    }
  };

  const handleQuotaSettingsViewOption = (action, slot = null) => {
    setQuotaSettingsViewAction(action);
    setSelectedSlot(slot);
    setShowQuotaDrawer(true);
  };

  const renderDaysInput = () => {
    return (
      <>
        <div className={classes.fieldLabel}>Days</div>
        <MultiSelectBox
          menuitems={remainingDays}
          value={days}
          onChange={handleDaysChange}
          disabled={daysSelectionDisabled}
        />

        {formErrors.days && <div className={classes.error}>{formErrors.days}</div>}
      </>
    );
  };

  const renderSlotDurationInputs = () => {
    return (
      <>
        <div className={classes.fieldLabel}>Slot Duration (Hrs/Mins)</div>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <SelectBox
              name="slotDurationHours"
              menuItems={TWENTY_FOUR_HOURS}
              label="Hrs"
              value={slotDurationHours}
              handleChange={handleSlotsGeneratorFormFields}
              labelClass={classes.hideShrinkedLabel}
              selectClass={classes.selectClass}
            />
            {formErrors.slotDurationHours && !slotDurationHours && (
              <div className={classes.error}>{formErrors.slotDurationHours}</div>
            )}
          </Grid>
          <Grid item xs={6}>
            <SelectBox
              name="slotDurationMinutes"
              label="Mins"
              menuItems={MINUTES}
              value={slotDurationMinutes}
              handleChange={handleSlotsGeneratorFormFields}
              labelClass={classes.hideShrinkedLabel}
              selectClass={classes.selectClass}
            />
            {formErrors.slotDurationMinutes && !slotDurationMinutes && (
              <div className={classes.error}>{formErrors.slotDurationMinutes}</div>
            )}
          </Grid>
        </Grid>
      </>
    );
  };

  const renderBufferTimeInputs = () => {
    return (
      <>
        <div className={classes.fieldLabel}>Buffer Time (Hrs/Mins)</div>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <SelectBox
              name="bufferTimeHours"
              label="Hrs"
              menuItems={HOURS}
              value={bufferTimeHours}
              handleChange={handleSlotsGeneratorFormFields}
              labelClass={classes.hideShrinkedLabel}
              selectClass={classes.selectClass}
            />
            {formErrors.bufferTimeHours && !bufferTimeHours && (
              <div className={classes.error}>{formErrors.bufferTimeHours}</div>
            )}
          </Grid>
          <Grid item xs={6}>
            <SelectBox
              name="bufferTimeMinutes"
              label="Mins"
              menuItems={MINUTES}
              value={bufferTimeMinutes}
              handleChange={handleSlotsGeneratorFormFields}
              labelClass={classes.hideShrinkedLabel}
              selectClass={classes.selectClass}
            />
            {formErrors.bufferTimeMinutes && !bufferTimeMinutes && (
              <div className={classes.error}>{formErrors.bufferTimeMinutes}</div>
            )}
          </Grid>
        </Grid>
      </>
    );
  };

  const renderServiceTimeInputs = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {renderServiceStartTimeInput()}
        </Grid>
        <Grid item xs={6}>
          {renderServiceEndTimeInput()}
        </Grid>
      </Grid>
    );
  };

  const renderServiceStartTimeInput = () => {
    return (
      <>
        <TimePicker
          fieldLabel="Service Start Time"
          value={serviceStartTime}
          handleChange={(date) => handleServiceTimeChange(date, "serviceStartTime")}
        />
        {formErrors.serviceStartTime && !serviceStartTime && (
          <div className={classes.error}>{formErrors.serviceStartTime}</div>
        )}
      </>
    );
  };

  const renderServiceEndTimeInput = () => {
    return (
      <>
        <TimePicker
          fieldLabel="Service End Time"
          value={serviceEndTime}
          handleChange={(date) => handleServiceTimeChange(date, "serviceEndTime")}
        />
        {formErrors.serviceEndTime && !serviceEndTime && (
          <div className={classes.error}>{formErrors.serviceEndTime}</div>
        )}
      </>
    );
  };

  const renderDefaultSlotCapacityInput = () => {
    return (
      <>
        <TextBox
          fieldLabel="Slot Capacity"
          placeholderText="Slot Capacity"
          name="defaultSlotCapacity"
          type="number"
          value={defaultSlotCapacity}
          handleChange={handleSlotsGeneratorFormFields}
          isNegativeAllowed={false}
        />
        {formErrors.defaultSlotCapacity && defaultSlotCapacity === "" && (
          <div className={classes.error}>{formErrors.defaultSlotCapacity}</div>
        )}
      </>
    );
  };

  const renderTemplateSlots = () => {
    return (
      <div className={classes.generatedSlotsContainer}>
        <div className={classes.separation} />
        <Grid container>
          <Grid className={classes.fontSize16}>
            {`Generated Slots for : `}
            {
              <span className={classes.slotDays}>{`${days.length >= 7 ? ALL_DAYS : days.join(", ").toLowerCase()
                }`}</span>
            }
          </Grid>
        </Grid>

        <Grid container className={classes.generatedSlotsTable}>
          <Grid item xs={2} className={classes.slotsTableLabel}>
            Slots
          </Grid>
          <Grid item xs={3} className={classes.slotsTableLabel}>
            Capacity
          </Grid>
          <Grid item xs={3} className={classes.slotsTableLabel}>
            Buffer Time (Hrs/Mins)
          </Grid>
          <Grid item xs={2} className={`${classes.slotsTableLabel} ${classes.orderCutOffHeader}`}>
            Order Cut-off
          </Grid>
          {quotas && quotas.length ? (
            <Grid item xs={2} className={`${classes.slotsTableLabel} `}>
              Quota Settings
            </Grid>
          ) : null}
        </Grid>

        <div>
          {generatedSlots.map((slot, index) => (
            <TemplateSlot
              key={slot.time}
              slot={slot}
              showQuotaColumn={!!quotas.length}
              handleIndividualSlotFieldsChange={(e) => handleIndividualSlotFieldsChange(index, e)}
              handleIndividualSlotCheckbox={(e) => handleIndividualSlotCheckbox(index, e)}
              handleViewQuotaSettings={handleQuotaSettingsViewOption}
            />
          ))}
        </div>
      </div>
    );
  };

  const renderCnCBasedForm = () => {
    return (
      <>
        <div className={classes.cncBasedSlotsForm}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              {renderDaysInput()}
            </Grid>
            <Grid item xs={12}>
              {renderSlotsGeneratorForm()}
            </Grid>
          </Grid>
        </div>
        {generatedSlots.length ? renderTemplateSlots() : null}
        {renderResetSlotsModal()}
      </>
    );
  };

  const renderSlotsGeneratorForm = () => {
    return (
      <div className={classes.generatorForm}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                {renderSlotDurationInputs()}
              </Grid>
              <Grid item xs={6}>
                {renderBufferTimeInputs()}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                {renderServiceTimeInputs()}
              </Grid>
              <Grid item xs={4}>
                {renderDefaultSlotCapacityInput()}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3} className={classes.buttons}>
          <Grid item xs={3}>
            {formErrors.genericError && <Toast message={formErrors.genericError} />}
            {serviceTypeName.toUpperCase() === SERVICE_TYPES.STANDARD && (
              <LinkButton
                buttonLabel="Quota Settings"
                onClick={() => handleQuotaSettingsViewOption(QUOTA_VIEW_OPTIONS.MODIFY)}
                disabled={!generatedSlots.length}
              />
            )}
          </Grid>
          <Grid item xs={4}></Grid>

          <Grid item xs={5}>
            <Grid container justifyContent="flex-end">
              <Grid item xs={1}></Grid>
              {
                <Grid item xs={5}>
                  <PrimaryButton
                    buttonLabel="Reset Slots"
                    onClick={handleResetSlotsClick}
                    disabled={isResetButtonDisabled()}
                  />
                </Grid>
              }
              <Grid item xs={6} className={classes.generateSlotsButton}>
                <PrimaryButton
                  buttonLabel="Generate Slots"
                  onClick={handleGenerateSlotsClick}
                  disabled={isButtonDisabled()}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };

  const renderResetSlotsModal = () => {
    return (
      <ConfirmModal
        show={showResetSlotsModal}
        title="Reset Slots?"
        description="Are you sure you want to reset your slots?"
        confirmButtonText="Approve"
        onClose={() => setShowResetSlotsModal(false)}
        onProceed={handleResetSlotsApprove}
      />
    );
  };

  if (serviceTypeName.toUpperCase() === SERVICE_TYPES.CLICK_N_COLLECT) {
    return renderCnCBasedForm();
  }

  return (
    <div className={classes.slotsGeneratorForm}>
      <Accordion
        defaultExpanded={true}
        className={
          serviceTypeName.toUpperCase() === SERVICE_TYPES.CLICK_N_COLLECT
            ? classes.greyBackground
            : classes.whiteBackground
        }
      >
        <AccordionSummary
          className={classes.slotsGeneratorAccordionSummary}
          expandIcon={<ExpandMoreIcon />}
          classes={{ expanded: classes.expandedStyle }}
        >
          <Grid container>
            <Grid item xs={6} className={classes.daysInput}>
              {renderDaysInput()}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails className={classes.slotsGeneratorAccordionDetails}>
          {renderSlotsGeneratorForm()}

          {generatedSlots.length ? renderTemplateSlots() : null}
        </AccordionDetails>
        {showQuotaDrawer ? (
          <QuotaSettings
            action={quotaSettingsAction}
            open={showQuotaDrawer}
            selectedSlot={selectedSlot}
            handleClose={() => setShowQuotaDrawer(false)}
            quotas={quotas}
            handleModifyQuotaSettings={handleModifyQuotaSettings}
          />
        ) : null}
        {renderResetSlotsModal()}
      </Accordion>
    </div>
  );
};

export default CreateTemplateSlotsForm;

CreateTemplateSlotsForm.defaultProps = {
  serviceTypeName: "",
};
