import React from "react";

import { TableCell, TableHead, TableRow } from "@material-ui/core";

import { userStyles } from "../Styles/DeliveryFeePageStyles";

interface DeliveryFeeCardHeaderInterface {
  deliveryFeeHeaderData: any;
}

const DeliveryFeeCardHeader = (props: DeliveryFeeCardHeaderInterface) => {
  const classes = userStyles();
  const { deliveryFeeHeaderData } = props;

  return (
    <TableHead className={classes.tableHeaderStyle}>
      <TableRow>
        <TableCell padding="none" size="small" align="left" rowSpan={2} className={classes.cellHeaderStyle}>
          POS No.
        </TableCell>
        <TableCell padding="none" size="small" align="left" rowSpan={2} className={classes.cellHeaderStyle}>
          POS Name
        </TableCell>
        <TableCell padding="none" size="small" align="left" rowSpan={2} className={classes.cellHeaderStyle}>
          Zone Name
        </TableCell>

        {deliveryFeeHeaderData &&
          deliveryFeeHeaderData.headerRowsValues !== undefined &&
          Array.isArray(deliveryFeeHeaderData.headerRowsValues) &&
          deliveryFeeHeaderData.headerRowsValues.length > 0 &&
          deliveryFeeHeaderData.headerRowsValues.map((headerObj: any, headerObjIndex: any) => (
            <TableCell
              padding="none"
              size="small"
              align="left"
              rowSpan={headerObj.rowSpan}
              colSpan={headerObj.colSpan}
              className={classes.cellHeaderStyle}
              key={`${headerObjIndex}-deliveryFeeHeaderData`}
            >
              {headerObj.shipmentName}
            </TableCell>
          ))}

        {/* <TableCell padding="none" size="small" align="left" rowSpan={1} colSpan={3} className={classes.cellHeaderStyle}>
          Standard Delivery Fee (AED)
        </TableCell>
        <TableCell padding="none" size="small" align="left" rowSpan={1} colSpan={3} className={classes.cellHeaderStyle}>
          Express Delivery Fee (AED)
        </TableCell>
        <TableCell padding="none" size="small" align="left" rowSpan={1} colSpan={3} className={classes.cellHeaderStyle}>
          Order level Delivery Fee (AED)
        </TableCell> */}

        <TableCell padding="none" size="small" align="left" rowSpan={2} className={classes.cellHeaderStyle}>
          Actions
        </TableCell>
      </TableRow>
      <TableRow>
        {deliveryFeeHeaderData &&
          deliveryFeeHeaderData.headerRowsValues !== undefined &&
          Array.isArray(deliveryFeeHeaderData.headerRowsValues) &&
          deliveryFeeHeaderData.headerRowsValues.length > 0 &&
          deliveryFeeHeaderData.headerRowsValues.map((headerObj: any, headerObjIndex: any) => (
            <>
              <TableCell padding="none" size="small" align="left" className={classes.cellSubHeaderStyle} key={`01-${headerObjIndex}-${headerObjIndex}-headerRowsValues`}>
                Threshold
              </TableCell>
              <TableCell padding="none" size="small" align="center" className={classes.cellSubHeaderStyle} key={`02-${headerObjIndex}-${headerObjIndex}-headerRowsValues`}>
                Fees Above Threshold
              </TableCell>
              <TableCell padding="none" size="small" align="left" className={classes.cellSubHeaderStyle} key={`03-${headerObjIndex}-${headerObjIndex}-headerRowsValues`}>
                Fees Below Threshold
              </TableCell>
            </>
          ))}
        {/* <TableCell padding="none" size="small" align="left" className={classes.cellSubHeaderStyle}>
          Threshold
        </TableCell>
        <TableCell padding="none" size="small" align="center" className={classes.cellSubHeaderStyle}>
          Fees Above Threshold
        </TableCell>
        <TableCell padding="none" size="small" align="left" className={classes.cellSubHeaderStyle}>
          Fees Below Threshold
        </TableCell>

        <TableCell padding="none" size="small" align="left" className={classes.cellSubHeaderStyle}>
          Threshold
        </TableCell>
        <TableCell padding="none" size="small" align="center" className={classes.cellSubHeaderStyle}>
          Fees Above Threshold
        </TableCell>
        <TableCell padding="none" size="small" align="left" className={classes.cellSubHeaderStyle}>
          Fees Below Threshold
        </TableCell>

        <TableCell padding="none" size="small" align="left" className={classes.cellSubHeaderStyle}>
          Threshold
        </TableCell>
        <TableCell padding="none" size="small" align="center" className={classes.cellSubHeaderStyle}>
          Fees Above Threshold
        </TableCell>
        <TableCell padding="none" size="small" align="left" className={classes.cellSubHeaderStyle}>
          Fees Below Threshold
        </TableCell> */}
      </TableRow>
    </TableHead>
  );
};

export default React.memo(DeliveryFeeCardHeader);
