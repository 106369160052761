import React from "react";

import { Grid } from "@material-ui/core";

import Pencil from "../../../assets/svg/Pencil.svg";
import TextBoxWithLabel from "../../../common/TextBoxWithLabel/TextBoxWithLabel";
import { AddSubtractThresholdRangeObjectButtons } from "../utils/DeliveryFeeConfigViewUtils";

interface ThresholdRangeDeliveryFeeViewInterface {
  classes: any;
  name: any;
  multiShipmentLevel: any;
  deliveryFeeRangeConfigDTOList: any;
  handleRangeInputChange: any;
  _handle_click_add_threshold_range: any;
  _handle_click_delete_threshold_range: any;
  updateConfigPosId: any;
  updateConfigZoneName: any;
  _handle_edit_threshold_rane_toggle_change_enable_Edit: any;
}

const ThresholdRangeDeliveryFeeView = (props: ThresholdRangeDeliveryFeeViewInterface) => {
  const {
    classes,
    name,
    multiShipmentLevel,
    deliveryFeeRangeConfigDTOList,
    handleRangeInputChange,
    _handle_click_add_threshold_range,
    _handle_click_delete_threshold_range,
    updateConfigPosId,
    updateConfigZoneName,
    _handle_edit_threshold_rane_toggle_change_enable_Edit,
  } = props;
  return (
    <>
      {deliveryFeeRangeConfigDTOList !== undefined && Array.isArray(deliveryFeeRangeConfigDTOList) && deliveryFeeRangeConfigDTOList.length > 0 ? (
        deliveryFeeRangeConfigDTOList.map((rangeObject: any, rangeObjectIndex: any) => (
          <Grid container className={classes.thresholdInputMainDiv} key={`${rangeObjectIndex}-deliveryFeeRangeConfigDTOList-multiShipmentLevel-${name}`}>
            <Grid item xs={3} className={classes.thresholdInputDiv}>
              <TextBoxWithLabel
                label={"Greater than or Equals to (Threshold Value)"}
                name="previousOrderThreshold"
                value={rangeObject.previousOrderThreshold}
                placeholderText="Greater than or Equals to (Threshold Value)"
                handleChange={(e: any) => handleRangeInputChange(e, multiShipmentLevel, name, rangeObjectIndex)}
                type="number"
                className={classes.rightPaddingStyle}
                disabled={true}
              ></TextBoxWithLabel>
            </Grid>

            <Grid item xs={3} className={classes.thresholdInputDiv}>
              <TextBoxWithLabel
                label={"Less than (Threshold Value)"}
                name="newOrderThreshold"
                value={rangeObject.newOrderThreshold}
                placeholderText="Less than (Threshold Value)"
                handleChange={(e: any) => handleRangeInputChange(e, multiShipmentLevel, name, rangeObjectIndex)}
                type="number"
                className={classes.rightPaddingStyle}
                disabled={rangeObject.enableEdit === false ? (rangeObjectIndex + 1 !== deliveryFeeRangeConfigDTOList.length ? true : false) : true}
                helperText={rangeObject.isErrorText}
              ></TextBoxWithLabel>
            </Grid>

            <Grid item xs={3} className={classes.thresholdInputDiv}>
              <TextBoxWithLabel
                label={"Delivery Fees"}
                name="deliveryFee"
                value={rangeObject.deliveryFee}
                placeholderText="Delivery Fees"
                handleChange={(e: any) => handleRangeInputChange(e, multiShipmentLevel, name, rangeObjectIndex)}
                type="number"
                className={classes.rightPaddingStyle}
                disabled={rangeObject.enableEdit}
              ></TextBoxWithLabel>
            </Grid>

            <Grid item xs={1} className={classes.thresholdInputDiv}>
              {rangeObjectIndex + 1 === deliveryFeeRangeConfigDTOList.length ? (
                updateConfigPosId === "" && updateConfigZoneName === "" ? (
                  <AddSubtractThresholdRangeObjectButtons
                    classes={classes}
                    name={name}
                    multiShipmentLevel={multiShipmentLevel}
                    rangeObject={rangeObject}
                    _handle_click_delete_threshold_range={_handle_click_delete_threshold_range}
                    _handle_click_add_threshold_range={_handle_click_add_threshold_range}
                  />
                ) : rangeObject.enableEdit === true ? (
                  <img
                    src={Pencil}
                    alt="Edit"
                    className={classes.actionIconStyle}
                    onClick={(e: any) => _handle_edit_threshold_rane_toggle_change_enable_Edit(e, multiShipmentLevel, name, rangeObjectIndex)}
                  />
                ) : (
                  <AddSubtractThresholdRangeObjectButtons
                    classes={classes}
                    name={name}
                    multiShipmentLevel={multiShipmentLevel}
                    rangeObject={rangeObject}
                    _handle_click_delete_threshold_range={_handle_click_delete_threshold_range}
                    _handle_click_add_threshold_range={_handle_click_add_threshold_range}
                  />
                )
              ) : rangeObject.enableEdit === true ? (
                <img
                  src={Pencil}
                  alt="Edit"
                  className={classes.actionIconStyle}
                  onClick={(e: any) => _handle_edit_threshold_rane_toggle_change_enable_Edit(e, multiShipmentLevel, name, rangeObjectIndex)}
                />
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        ))
      ) : (
        <></>
      )}
    </>
  );
};

export default React.memo(ThresholdRangeDeliveryFeeView);
