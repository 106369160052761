import { Accordion, AccordionDetails, AccordionSummary, Grid } from "@material-ui/core";
import ExpandMoreIcon from "../../assets/svg/ExpandAccordionIcon.svg";

interface RenderDefaultServiceSlotsProps {
  dayWiseSlots: any;
  classes: any;
}

export const RenderDefaultServiceSlots = (props: RenderDefaultServiceSlotsProps) => {
  const { dayWiseSlots, classes } = props;
  return (
    <div>
      {dayWiseSlots &&
        Object.keys(dayWiseSlots).map((dayWiseSlot) => (
          <Accordion key={dayWiseSlot} className={classes.slotsAccordion} onClick={(e) => e.stopPropagation()}>
            <AccordionSummary expandIcon={<img src={ExpandMoreIcon} alt="Expand Accordion Icon" />} className={classes.slotsAccordionSummary}>
              <div className={classes.fieldTitle}>
                <span className={classes.fontWeight500}>Slots for : </span>
                <span className={classes.dayLabel}>{`${dayWiseSlot.toLowerCase()} (Capacity - ${dayWiseSlots[dayWiseSlot][0].maximum_slot_capacity || ""})`}</span>
              </div>
            </AccordionSummary>
            <AccordionDetails className={classes.dBlock}>
              <Grid container>
                <Grid item xs={8}>
                  <Grid container spacing={2} className={classes.slotsContainer}>
                    <Grid item xs={3} className={classes.slotsTableHeader}>
                      Minimum Days
                    </Grid>
                    <Grid item xs={3} className={classes.slotsTableHeader}>
                      Maximum Days
                    </Grid>
                    <Grid item xs={3} className={classes.slotsTableHeader}>
                      Buffer Time
                    </Grid>
                    <Grid item xs={3} className={classes.slotsTableHeader}>
                      Capacity
                    </Grid>
                  </Grid>

                  {dayWiseSlots[dayWiseSlot]?.map((slot: any, index: number) => (
                    <div key={slot.id} className={classes.slotDivider} style={{ fontSize: "12px" }}>
                      <Grid container spacing={2} className={classes.slotsContainer}>
                        <Grid item xs={3}>{`${slot.minDay}`}</Grid>
                        <Grid item xs={3}>{`${slot.maxDay}`}</Grid>
                        <Grid item xs={3}>{`${slot.bufferTime} hour`}</Grid>
                        <Grid item xs={3}>
                          {slot.slotCapacity}
                        </Grid>
                      </Grid>
                    </div>
                  ))}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
    </div>
  );
};
