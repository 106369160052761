import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import moment from "moment";

import { Grid, Paper, Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

import previewModalStyles from "components/ServiceAndSlots/PreviewModal/PreviewModalStyles";

import { ALERT_TYPES, APP_ROUTES, AREA_POS_MAPPING_TYPE, DISPLAY_WEEKLY_HOLIDAYS, EXPRESS_TYPES, SERVICE_TYPES, STATUS_OK } from "../../config/constants";
import { fetchAllAreasOfCountry, fetchAllRegionsOfCountry, selectAllCountries, selectServiceTypes } from "../../config/redux/configurationsSlice";
import { ReactComponent as CloseButton } from "../assets/svg/closeButton.svg";

import ModalContainer from "base/ModalContainer";
import ConfirmModal from "../common/ConfirmModal/ConfirmModal";
import CustomAlert from "../common/CustomAlert/CustomAlert";
import LinkButton from "../common/LinkButton/LinkButton";
import Loader from "../common/Loader/Loader";

import PrimaryButton from "../common/PrimaryButton/PrimaryButton";
import SelectBox from "../common/SelectBox/SelectBox";
import Toast from "../common/Toast/Toast";
import { countrySettingsStyles } from "./CountrySettingsStyles";
import {
  ADVANCE_DAYS_DDN_OPTIONS,
  ClickCollectInitConfig,
  countrySettingsDetailsPayload,
  DefaultInitConfig,
  ExpressInitConfig,
  FBCInitialConfig,
  getDateFromPicker,
  getHolidaysListObject,
  ServiceTypeTabList,
  StandardInitConfig,
  WeeklyHolidayConfig,
} from "./CountrySettingsUtils";

import { DefaultServiceTypeDetails, PosTabInfo, PlasticBagconfigurationViewUtils } from "./CountrySettingView";
import { CountryChangesConfirmationModal } from "./modal/CountryChangesConfirmationModal";
import { createCountrySettings, fetchActivePOSAndServiceTypesCount, fetchCountrySettings, updateCountrySettings } from "./redux/countrySettingsSlice";

const CountrySettings = ({ history }) => {
  const { countryName } = useParams();

  const classes = countrySettingsStyles();
  const dispatch = useDispatch();

  const {
    countrySettings: {
      posCount,
      fetchingPosCount,
      posCountError,
      loading,
      countryDetails: { data: savedCountryDetails },
      countrySettingsError,
    },
  } = useSelector((state) => state);
  const allCountries = useSelector(selectAllCountries);
  const avialbleServiceTypes = useSelector(selectServiceTypes);

  const { countryId } = allCountries.length ? allCountries.find((country) => country.name === countryName) : {};

  const [selectedServiceType, setSelectedServiceType] = useState(SERVICE_TYPES.STANDARD);
  const [expressType, setExpressType] = useState("");

  const [standardDetails, setStandardDetails] = useState(StandardInitConfig);
  const [expressDetails, setExpressDetails] = useState(ExpressInitConfig);
  const [clickCollectDetails, setClickCollectDetails] = useState(ClickCollectInitConfig);
  const [defaultDetails, setDefaultDetails] = useState(DefaultInitConfig);
  const [fbcDetails, setFBCDetails] = useState(FBCInitialConfig);

  const [showApplyChangesModal, setShowApplyChangesModal] = useState(false);
  const [showExpressTypeChangeConfirmModal, setShowExpressTypeChangeConfirmModal] = useState(false);

  const [weeklyHolidays, setWeeklyHolidays] = useState(WeeklyHolidayConfig);
  const [calendarHoliday, setCalendarHoliday] = useState([]);
  const [holidayDates, setHolidayDates] = useState([]);
  const [showInfoModal, setShowInfoModal] = useState({ show: false, duplicates: [], oldDates: false });

  const [displayHolidays, setDisplayHolidays] = useState([]);
  const [displayElementCount, setDisplayelementCount] = useState([]);
  const [defaultDateState, setDefaultDateState] = useState({
    dateFrom: "",
    dateTo: "",
    dateLabel: "",
  });

  const [areaPosMappingType, setAreaPosMappingType] = useState(AREA_POS_MAPPING_TYPE.AREA_BASED);

  const handleServiceTypeDetails = (serviceType) => {
    setSelectedServiceType(serviceType.toUpperCase());
  };

  const handleAdvanceDaysClick = (serviceType) => {
    // eslint-disable-next-line
    switch (serviceType) {
      case SERVICE_TYPES.STANDARD:
        setStandardDetails({ ...standardDetails, showAdvanceSlotDaysInput: true });
        break;
      case SERVICE_TYPES.EXPRESS:
        setExpressDetails({ ...expressDetails, showAdvanceSlotDaysInput: true });
        break;
      case SERVICE_TYPES.CLICK_N_COLLECT:
        setClickCollectDetails({ ...clickCollectDetails, showAdvanceSlotDaysInput: true });
        break;
    }
  };

  const handleStandardDetailsChange = (event) => {
    const { name, value } = event.target;
    let newValue = value === "true";

    switch (name) {
      case "standardNextDaySlotVisibility":
        setStandardDetails({ ...standardDetails, nextDaySlotVisibility: newValue, [name]: newValue });
        break;
      case "standardAdvanceSlotDays":
        setStandardDetails({
          ...standardDetails,
          [name]: newValue,
          editSlotDays: value,
        });
        break;
      default:
        setStandardDetails({ ...standardDetails, [name]: newValue });
        return "";
    }
  };

  const handleExpressDetailsChange = (event) => {
    const { name, value } = event.target;

    let newValue = value === "true";
    setExpressDetails({ ...expressDetails, [name]: newValue });
    switch (name) {
      case "expressNextDaySlotVisibility":
        setExpressDetails({ ...expressDetails, nextDaySlotVisibility: newValue });
        break;
      case "expressType":
        setExpressDetails({ ...expressDetails, expressType: value });
        break;
      case "expressAdvanceSlotDays":
        setExpressDetails({
          ...expressDetails,
          editSlotDays: value,
        });
        break;
      default:
        return "";
    }
  };

  const handleExpressTypeChangeApprove = () => {
    setExpressDetails({ ...expressDetails, expressType });
    setShowExpressTypeChangeConfirmModal(false);
  };

  const handleClickAndCollectDetailsChange = (event) => {
    const { name, value } = event.target;
    let newValue = value === "true";
    setClickCollectDetails({ ...clickCollectDetails, [name]: newValue });
    switch (name) {
      case "cncNextDaySlotVisibility":
        setClickCollectDetails({ ...clickCollectDetails, nextDaySlotVisibility: newValue });
        break;
      case "cncAdvanceSlotDays":
        setClickCollectDetails({
          ...clickCollectDetails,
          editSlotDays: value,
        });
        break;
      default:
        return "";
    }
  };

  const handleMappingType = (event) => {
    setAreaPosMappingType(event.target.value);
  };

  const handleApplyChangesModalClose = () => {
    setShowApplyChangesModal(false);
  };

  const handleApplyChangesClick = () => {
    let holidaysList = [];
    calendarHoliday.forEach((defaultDateState) => {
      if (defaultDateState && defaultDateState.dateLabel !== "") {
        let list = getHolidaysListObject(defaultDateState);
        holidaysList = [...holidaysList, ...list];
      }
    });

    holidaysList = [...new Map(holidaysList.map((item) => [item["objectDate"], item])).values()];

    setDefaultDetails((prev) => ({
      ...prev,
      holidays: holidaysList,
    }));
    setShowApplyChangesModal(true);
  };

  const handleSubmit = async () => {
    let requestPayload = {
      countryId,
      countryData: countrySettingsDetailsPayload(countryName, standardDetails, expressDetails, clickCollectDetails, defaultDetails, areaPosMappingType, weeklyHolidays),
    };
    // If country setting avialble then call update Api else call Create
    if (!(savedCountryDetails && Object.keys(savedCountryDetails).length)) {
      const {
        payload: { status },
      } = await dispatch(createCountrySettings(requestPayload));
      if (status === STATUS_OK) {
        CustomAlert(ALERT_TYPES.SUCCESS, "Country Settings saved successfully");
        //history.push("/pos-list");
      }
    } else {
      const {
        payload: { status },
      } = await dispatch(updateCountrySettings(requestPayload));
      if (status === STATUS_OK) {
        CustomAlert(ALERT_TYPES.SUCCESS, "Country Settings updated successfully");
        //history.push("/pos-list");
      }
    }
    setShowApplyChangesModal(false);
  };

  useEffect(() => {
    dispatch(fetchActivePOSAndServiceTypesCount({ country: countryName }));
  }, [dispatch, countryName]);

  useEffect(() => {
    if (savedCountryDetails && Object.keys(savedCountryDetails).length) {
      const { areaPosMappingType, serviceTypes: savedServiceTypes } = savedCountryDetails;
      setAreaPosMappingType(areaPosMappingType);
      // Match servicetypeId given with service type Data Modal so respective config will set in modal
      savedServiceTypes &&
        savedServiceTypes.forEach((serviceInfo) => {
          // eslint-disable-next-line
          let serviceTypeDetails = avialbleServiceTypes && avialbleServiceTypes.filter((element) => element.id == serviceInfo.serviceTypeID);
          if (serviceTypeDetails && serviceTypeDetails.length > 0) {
            // eslint-disable-next-line
            switch (serviceTypeDetails[0].name) {
              case SERVICE_TYPES.STANDARD:
                setStandardDetails((standardDetails) => ({
                  ...standardDetails,
                  serviceTypeID: 1,
                  nextDaySlotVisibility: serviceInfo.nextDaySlotVisibility,
                  advanceSlotDays: serviceInfo.advanceSlotDays,
                  editSlotDays: serviceInfo.advanceSlotDays,
                  bagChargeEnabled: serviceInfo.bagChargeEnabled,
                }));
                break;
              case SERVICE_TYPES.EXPRESS:
                setExpressDetails((expressDetails) => ({
                  ...expressDetails,
                  serviceTypeID: 3,
                  nextDaySlotVisibility: serviceInfo.nextDaySlotVisibility,
                  advanceSlotDays: serviceInfo.advanceSlotDays,
                  editSlotDays: serviceInfo.advanceSlotDays,
                  expressType: serviceInfo.expressType,
                  bagChargeEnabled: serviceInfo.bagChargeEnabled,
                }));
                setExpressType(serviceInfo.expressType ? serviceInfo.expressType : "");
                break;
              case SERVICE_TYPES.CLICK_N_COLLECT:
                setClickCollectDetails((clickCollectDetails) => ({
                  ...clickCollectDetails,
                  serviceTypeID: 4,
                  nextDaySlotVisibility: serviceInfo.nextDaySlotVisibility,
                  advanceSlotDays: serviceInfo.advanceSlotDays,
                  editSlotDays: serviceInfo.advanceSlotDays,
                  bagChargeEnabled: serviceInfo.bagChargeEnabled,
                }));

                break;
              case SERVICE_TYPES.DEFAULT:
                if (serviceInfo.holidays && serviceInfo.holidays) {
                  let calHolidays = [];
                  let weekHolidays = [];
                  let holidayDates = [];
                  serviceInfo.holidays.forEach((item) => {
                    if (item.offDay != null || item.offDay != undefined) {
                      weekHolidays.push(item.offDay);
                    } else if (item.offDate != null || item.offDate != undefined) {
                      let actualDate = moment(item.offDate).format("YYYY-MM-DD");
                      holidayDates.push(actualDate);
                      calHolidays.push({
                        dateFrom: actualDate,
                        dateTo: actualDate,
                        dateLabel: actualDate,
                      });
                    }
                  });
                  setCalendarHoliday(calHolidays);
                  setHolidayDates(holidayDates);
                  setWeeklyHolidays({
                    holidays: weekHolidays,
                  });
                  setDefaultDetails({ ...defaultDetails, bagChargeEnabled: serviceInfo.bagChargeEnabled });
                }

                break;
            }
          }
        });
    }
    // eslint-disable-next-line
  }, [savedCountryDetails]);

  useEffect(() => {
    if (allCountries.length) {
      console.log("**** IF HERE CHECK HOW MANY TIMES 22222***");
      dispatch(fetchCountrySettings({ countryId }));
      dispatch(fetchAllRegionsOfCountry({ countryId }));
      dispatch(fetchAllAreasOfCountry({ countryId }));
    }
  }, [dispatch, countryId, allCountries.length]);

  useEffect(() => {
    let sortedArray = calendarHoliday.sort((a, b) => (a.dateLabel > b.dateLabel ? 1 : -1));
    setCalendarHoliday(sortedArray);
    if (calendarHoliday.length >= displayHolidays.length) {
      setDisplayelementCount(Array.from(Array(calendarHoliday.length).keys()));
    } else {
      setDisplayelementCount(Array.from(Array(displayHolidays.length).keys()));
    }
  }, [calendarHoliday]);

  useEffect(() => {
    let displayHolidays = [];
    weeklyHolidays &&
      weeklyHolidays.holidays.length > 0 &&
      DISPLAY_WEEKLY_HOLIDAYS.map((item) => {
        let itemName = item.name.toUpperCase().trim();
        if (weeklyHolidays.holidays.indexOf(itemName) != -1) {
          displayHolidays.push(item.name);
        }
      });
    if (calendarHoliday.length >= displayHolidays.length) {
      setDisplayelementCount(Array.from(Array(calendarHoliday.length).keys()));
    } else {
      setDisplayelementCount(Array.from(Array(displayHolidays.length).keys()));
    }
    setDisplayHolidays(displayHolidays);
  }, [weeklyHolidays]);

  const renderStandardServiceTypeDetails = () => {
    return (
      <Card className={classes.serviceTypeCard}>
        <CardContent>
          <Grid container>
            <Grid item xs={2}>
              <FormControl component="fieldset">
                <FormLabel component="legend" className={classes.serviceTypeContentTitle}>
                  Next Day Slot Visibility
                </FormLabel>
                <RadioGroup aria-label="slots" name="standardNextDaySlotVisibility" value={standardDetails.nextDaySlotVisibility} onChange={handleStandardDetailsChange}>
                  <FormControlLabel value={true} control={<Radio color="primary" />} label="On" classes={{ label: classes.formControlLabel }} />
                  <FormControlLabel value={false} control={<Radio color="primary" />} label="Off" classes={{ label: classes.formControlLabel }} />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={3} style={{ marginLeft: "12px" }}>
              <PlasticBagconfigurationViewUtils classes={classes} bagChargeEnabled={standardDetails.bagChargeEnabled} handleBagChargeChange={handleBagChargeChange} name={SERVICE_TYPES.STANDARD} />
            </Grid>
          </Grid>

          <Grid item className={`${classes.serviceTypeContentTitle} ${classes.advanceSlotDaysContainer}`}>
            Advance Slot Days : {standardDetails.advanceSlotDays}
          </Grid>
          <Grid>
            {standardDetails.showAdvanceSlotDaysInput ? (
              <Grid container className={classes.advanceSlotDaysInput}>
                <Grid item xs={1}>
                  <SelectBox name="standardAdvanceSlotDays" menuItems={ADVANCE_DAYS_DDN_OPTIONS} value={standardDetails.editSlotDays} handleChange={handleStandardDetailsChange} />
                </Grid>
              </Grid>
            ) : (
              <LinkButton buttonLabel="Edit Days" className={classes.editDaysButton} onClick={() => handleAdvanceDaysClick(SERVICE_TYPES.STANDARD)} />
            )}
          </Grid>
        </CardContent>
      </Card>
    );
  };

  const renderExpressServiceTypeDetails = () => {
    return (
      <Card className={classes.serviceTypeCard}>
        <CardContent>
          <Grid container>
            <Grid item xs={2}>
              <FormControl component="fieldset" classes={{ root: classes.formControlLabelRoot }}>
                <FormLabel component="legend" className={classes.serviceTypeContentTitle}>
                  Express Type
                </FormLabel>
                <RadioGroup
                  aria-label="slots"
                  name="expressType"
                  value={expressDetails.expressType}
                  // onClick={handleExpressTypeChange}
                >
                  <FormControlLabel value={EXPRESS_TYPES.SLOT_BASED.value} control={<Radio color="primary" />} label="Slot Based" classes={{ label: classes.formControlLabel }} disabled />
                  <FormControlLabel
                    value={EXPRESS_TYPES.ON_DEMAND.value}
                    // disabled
                    control={<Radio color="primary" />}
                    label="On Demand"
                    classes={{ label: classes.formControlLabel }}
                    disabled
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl component="fieldset" classes={{ root: classes.formControlLabelRoot }}>
                <FormLabel component="legend" className={classes.serviceTypeContentTitle}>
                  Next Day Slot Visibility
                </FormLabel>
                <RadioGroup aria-label="slotsExpress" name="expressNextDaySlotVisibility" value={expressDetails.nextDaySlotVisibility} onChange={handleExpressDetailsChange}>
                  <FormControlLabel value={true} control={<Radio color="primary" />} label="On" classes={{ label: classes.formControlLabel }} disabled />
                  <FormControlLabel value={false} control={<Radio color="primary" />} label="Off" classes={{ label: classes.formControlLabel }} disabled />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={3} style={{ marginLeft: "12px" }}>
              <PlasticBagconfigurationViewUtils classes={classes} bagChargeEnabled={expressDetails.bagChargeEnabled} handleBagChargeChange={handleBagChargeChange} name={SERVICE_TYPES.EXPRESS} />
            </Grid>
          </Grid>

          <Grid item className={`${classes.serviceTypeContentTitle} ${classes.advanceSlotDaysContainer}`}>
            Advance Slot Days : {expressDetails.advanceSlotDays}
          </Grid>
          <Grid>
            {expressDetails.showAdvanceSlotDaysInput ? (
              <Grid container className={classes.advanceSlotDaysInput}>
                <Grid item xs={1}>
                  {/* <TextBox
                    name="expressAdvanceSlotDays"
                    textBoxId="expressAdvanceDays"
                    type="number"
                    placeholderText="Enter Days"
                    value={expressDetails.editSlotDays}
                    handleChange={handleExpressDetailsChange}
                    error={!!expressDetails.editSlotDaysError}
                    helperText={expressDetails.editSlotDaysError}
                  /> */}
                  <SelectBox
                    name="expressAdvanceSlotDays"
                    menuItems={ADVANCE_DAYS_DDN_OPTIONS}
                    value={expressDetails.editSlotDays}
                    handleChange={handleExpressDetailsChange}
                    // disabled
                  />
                </Grid>
              </Grid>
            ) : (
              <LinkButton buttonLabel="Edit Days" className={classes.editDaysButton} onClick={() => handleAdvanceDaysClick(SERVICE_TYPES.EXPRESS)} />
            )}
          </Grid>
        </CardContent>
      </Card>
    );
  };

  const renderClickAndCollectServiceTypeDetails = () => {
    return (
      <Card className={classes.serviceTypeCard}>
        <CardContent>
          <Grid container style={{ borderBottom: "1px solid #9E9E9E", paddingBottom: "8px" }}>
            <Grid item className={classes.collectionPointStyle}>
              <Typography className={classes.serviceTypeContentTitle}>Total Collection Point </Typography>
            </Grid>
            <Grid item>
              <div className={classes.collectionPtCount}>
                <div className={classes.serviceTypeCountData}>{posCount.cncPoints}</div>
              </div>
            </Grid>
          </Grid>
          <Grid container style={{ paddingTop: "12px" }}>
            <Grid item xs={2}>
              <FormControl component="fieldset" className={classes.nextDaySlotVisibility}>
                <FormLabel component="legend" className={classes.serviceTypeContentTitle}>
                  Next Day Slot Visibility
                </FormLabel>
                <RadioGroup aria-label="slots" name="cncNextDaySlotVisibility" value={clickCollectDetails.nextDaySlotVisibility} onChange={handleClickAndCollectDetailsChange}>
                  <FormControlLabel value={true} control={<Radio color="primary" />} label="On" classes={{ label: classes.formControlLabel }} />
                  <FormControlLabel value={false} control={<Radio color="primary" />} label="Off" classes={{ label: classes.formControlLabel }} />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={3} style={{ marginLeft: "12px", marginTop: "12px" }}>
              <PlasticBagconfigurationViewUtils
                classes={classes}
                bagChargeEnabled={clickCollectDetails.bagChargeEnabled}
                handleBagChargeChange={handleBagChargeChange}
                name={SERVICE_TYPES.CLICK_N_COLLECT}
              />
            </Grid>
          </Grid>

          <Grid item className={`${classes.serviceTypeContentTitle} ${classes.advanceSlotDaysContainer}`}>
            Advance Slot Days : {clickCollectDetails.advanceSlotDays}
          </Grid>
          <Grid>
            {clickCollectDetails.showAdvanceSlotDaysInput ? (
              <Grid container className={classes.advanceSlotDaysInput}>
                <Grid item xs={1}>
                  <SelectBox name="cncAdvanceSlotDays" menuItems={ADVANCE_DAYS_DDN_OPTIONS} value={clickCollectDetails.editSlotDays} handleChange={handleClickAndCollectDetailsChange} />
                </Grid>
              </Grid>
            ) : (
              <LinkButton buttonLabel="Edit Days" className={classes.editDaysButton} onClick={() => handleAdvanceDaysClick(SERVICE_TYPES.CLICK_N_COLLECT)} />
            )}
          </Grid>
        </CardContent>
      </Card>
    );
  };

  const handleDateChangeEvent = (event, picker) => {
    if (event && event.target) {
      if (event.target.name === "date") {
        const dateStart = getDateFromPicker(picker.startDate);
        const dateEnd = getDateFromPicker(picker.endDate);
        let label = "";
        label = dateStart === dateEnd ? dateStart : dateStart + " - " + dateEnd;
        setDefaultDateState({
          dateFrom: dateStart,
          dateTo: dateEnd,
          dateLabel: label,
        });
      }
    }
  };

  const handleCalendarHoliday = async (event, type, label) => {
    if (!label || label.trim().length == 0) {
      return;
    }

    const dateFrom = new Date(defaultDateState.dateFrom);
    const dateTo = new Date(defaultDateState.dateTo);
    const currentDate = new Date();

    if (type === "ADD") {
      if (dateFrom <= currentDate || dateTo <= currentDate) {
        setShowInfoModal({ show: true, duplicates: [], oldDates: true });
        return;
      }

      let list = await getHolidaysListObject(defaultDateState);
      let arr = list.map((item) => item.objectDate);

      let allDates = [...arr, ...holidayDates];
      let duplicate = allDates.filter((e, i, a) => a.indexOf(e) !== i);
      if (duplicate && duplicate.length > 0) {
        setShowInfoModal({ show: true, duplicates: duplicate, oldDates: false });
        return;
      }
      setHolidayDates((prev) => [...prev, ...arr]);
      setCalendarHoliday((prev) => [...prev, defaultDateState]);
      setDefaultDateState({
        dateFrom: "",
        dateTo: "",
        dateLabel: "",
      });
    }
    if (type === "REMOVE") {
      let dateState = calendarHoliday.filter((item) => item.dateLabel == label)[0];
      let list = await getHolidaysListObject(dateState);
      let dateArray = list.map((item) => item.objectDate);
      let allDates = [];
      for (let i = 0; i < holidayDates.length; i++) {
        if (dateArray.indexOf(holidayDates[i]) == -1) {
          allDates.push(holidayDates[i]);
        }
      }
      setHolidayDates(allDates);
      let arr = calendarHoliday.filter((item) => item.dateLabel != label);
      setCalendarHoliday(arr);
    }
  };

  const handleCancel = (event, picker) => {
    event.preventDefault();
    picker.element.val("");
    setDefaultDateState({
      dateFrom: "",
      dateTo: "",
      dateLabel: "",
    });
  };

  useEffect(() => {
    let holidaysList = [];
    if (defaultDateState && defaultDateState.dateLabel !== "") {
      holidaysList = getHolidaysListObject(defaultDateState);
    }
    setDefaultDetails((prev) => ({
      ...prev,
      holidays: holidaysList,
    }));
  }, [defaultDateState]);

  const handleBagChargeChange = (event) => {
    const { value } = event.target;
    let newValue = value === "true";
    setExpressDetails({ ...expressDetails, bagChargeEnabled: newValue });
    setStandardDetails({ ...standardDetails, bagChargeEnabled: newValue });
    setClickCollectDetails({ ...clickCollectDetails, bagChargeEnabled: newValue });
    setDefaultDetails({ ...defaultDetails, bagChargeEnabled: newValue });
  };

  const renderDefaultTypeDetails = () => {
    return (
      <Card className={classes.serviceTypeCard}>
        <CardContent>
          <Grid container style={{ paddingTop: "12px" }}>
            <Grid item xs={3} style={{ marginLeft: "12px" }}>
              <PlasticBagconfigurationViewUtils classes={classes} bagChargeEnabled={defaultDetails.bagChargeEnabled} handleBagChargeChange={handleBagChargeChange} name={SERVICE_TYPES.DEFAULT} />
            </Grid>
          </Grid>
          <DefaultServiceTypeDetails
            handleDetailsChange={(e) => {
              setWeeklyHolidays((prev) => ({ ...prev, holidays: [...e] }));
            }}
            defaultDetails={weeklyHolidays}
            classes={classes}
            defaultDateState={defaultDateState}
            handleDateChangeEvent={handleDateChangeEvent}
            handleCancel={handleCancel}
            handleCalendarHoliday={handleCalendarHoliday}
            calendarHoliday={calendarHoliday}
            displayHolidays={displayHolidays}
            displayElementCount={displayElementCount}
          />
        </CardContent>
      </Card>
    );
  };

  return (
    <div className={classes.countrySection}>
      <div className={classes.lists}>
        <Typography className={classes.title}>Country Settings - {countryName}</Typography>

        {/* Page Tabs  */}
        <Paper className={classes.posDetailsPaperStyle}>
          <Grid container alignContent="space-between">
            <Grid
              item
              // xs={3}
              className={classes.posDetails}
            >
              <Grid container>
                <Grid item xs={8} className={classes.posTitle}>
                  <Typography style={{ marginLeft: "35px" }} className={classes.activePosCount}>
                    Total Active POS
                  </Typography>
                </Grid>
                <Grid item xs={4} className={classes.posValue}>
                  {fetchingPosCount && <Loader />}
                  {!fetchingPosCount && <Typography className={classes.posCountNo}>{posCount.activePos}</Typography>}
                  {!fetchingPosCount && !posCount && posCountError && <div className={classes.error}>{posCountError.toString()}</div>}
                </Grid>
              </Grid>
            </Grid>
            {ServiceTypeTabList.map((element, index) => (
              <PosTabInfo
                key={index}
                handleServiceTypeDetails={handleServiceTypeDetails}
                selectedServiceType={selectedServiceType}
                serviceType={element.serviceType}
                serviceTypeLabel={element.serviceTypeLabel}
                classes={classes}
                countStyle={classes[element.countStyle]}
                // DropdownIcon={DropdownIcon}
                fetchingPosCount={fetchingPosCount}
                posCount={posCount[element.countKey]}
                posCountError={posCountError}
                hideDivisonView={element.hideDivisonView}
              />
            ))}
          </Grid>
        </Paper>
        <InfoModal show={showInfoModal.show} oldDates={showInfoModal.oldDates} onClose={() => setShowInfoModal({ show: false, duplicates: [], oldDates: false })} holidays={showInfoModal.duplicates} />
        {selectedServiceType === SERVICE_TYPES.STANDARD ? <div>{renderStandardServiceTypeDetails()}</div> : ""}
        {selectedServiceType === SERVICE_TYPES.EXPRESS ? <div>{renderExpressServiceTypeDetails()}</div> : ""}
        {selectedServiceType === SERVICE_TYPES.CLICK_N_COLLECT ? <div>{renderClickAndCollectServiceTypeDetails()}</div> : ""}
        {selectedServiceType === SERVICE_TYPES.DEFAULT ? <div>{renderDefaultTypeDetails()}</div> : ""}

        <Card className={classes.areaMappingContainer}>
          <CardContent>
            <FormControl component="fieldset" classes={{ root: classes.formControlLabelRoot }}>
              <FormLabel component="legend" className={classes.serviceTypeContentTitle}>
                Select the Mapping Type
              </FormLabel>
              <RadioGroup aria-label="slots" name="slotVisibility" value={areaPosMappingType} /* onClick={handleMappingType} */>
                <FormControlLabel
                  disabled={true}
                  value={AREA_POS_MAPPING_TYPE.AREA_BASED}
                  control={<Radio color="primary" />}
                  label="Area Based Assignment"
                  classes={{ label: classes.formControlLabel }}
                />
                <FormControlLabel
                  value={AREA_POS_MAPPING_TYPE.POLYGON_BASED}
                  control={<Radio color="primary" />}
                  label="Polygon Based Assignment"
                  classes={{ label: classes.formControlLabel }}
                  disabled={true}
                  // For now polygon based radio button has been disabled
                  // onClick={(e) => e.stopPropagation()}
                />
              </RadioGroup>
            </FormControl>
          </CardContent>
        </Card>

        <Grid container direction="row" spacing={2} className={classes.divButton}>
          <Grid item xs={6}>
            {countrySettingsError && <Toast message={countrySettingsError} />}
          </Grid>
          <Grid item xs={6}>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Link to={`${APP_ROUTES.POS_LIST}`} style={{ textDecoration: "none" }}>
                  <PrimaryButton buttonLabel="CANCEL" buttonVariant="outlined" />
                </Link>
              </Grid>
              <Grid item className={classes.applyChangesButton}>
                <PrimaryButton buttonLabel="APPLY CHANGES" onClick={handleApplyChangesClick} />{" "}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Confirmation modal */}

        <CountryChangesConfirmationModal
          open={showApplyChangesModal}
          onClose={handleApplyChangesModalClose}
          classes={classes}
          standardDetails={standardDetails}
          expressDetails={expressDetails}
          clickCollectDetails={clickCollectDetails}
          defaultDetails={defaultDetails}
          loading={loading}
          areaPosMappingType={areaPosMappingType}
          handleSubmit={handleSubmit}
          weeklyHolidays={weeklyHolidays}
        />
        {
          <ConfirmModal
            show={showExpressTypeChangeConfirmModal}
            title="Confirm Express Type Change"
            description="Slots needs to be re-validated as the express type is changed"
            confirmButtonText="Approve"
            onClose={() => setShowExpressTypeChangeConfirmModal(false)}
            onProceed={handleExpressTypeChangeApprove}
          />
        }
      </div>
    </div>
  );
};

export default CountrySettings;

const InfoModal = ({ show, onClose, holidays, oldDates }) => {
  const classes = previewModalStyles({});

  return (
    <div className={classes.modalContainer}>
      <ModalContainer className={classes.modal} open={show} onClose={onClose} disableBackdropClick>
        <div className={`${classes.modalContent} ${classes.modalContentWindow}`}>
          <div className={classes.closeButton}>
            <CloseButton className={classes.closeButtonSVG} onClick={onClose} />
          </div>

          <div className={classes.modalHeader}>
            <div className={classes.modalTitle}>{oldDates ? "Older Dates" : "Duplicate Holidays"}</div>
          </div>

          <div className={classes.modalBody}>
            {oldDates ? (
              <Typography> Please don't use older dates. Use dates tomorrow onwards.</Typography>
            ) : (
              <>
                <Typography className={classes.applyInfoModalBodyContent}>Following dates are already selected as holiday. Please check date range and select appropriately.</Typography>
                <div style={{ marginTop: "10px" }}>{holidays?.map((info) => info).join(", ")}</div>
              </>
            )}
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};
