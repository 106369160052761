import React from "react";

import moment from "moment";

import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";

// Common Components
import TextBox from "../../../common/TextBox/index";
import SelectBox from "../../../common/SelectBox/SelectBox";

import SwitchButton from "../../../common/SwitchButton/SwitchButton";
import LinkButton from "../../../common/LinkButton/LinkButton";

// Constants
import { QUOTA_VIEW_OPTIONS } from "../../../../config/constants";
import { generateDropdownItems } from "./../../../../utils/helperFunctions";

// Styles
import templateSlotStyles from "./TemplateSlotStyles";

const HOURS = generateDropdownItems(0, 95, "hours");
const MINUTES = generateDropdownItems(0, 59, "mins");

const TemplateSlot = ({
  slot,
  showQuotaColumn,
  handleIndividualSlotCheckbox,
  handleIndividualSlotFieldsChange,
  handleIndividualSlotQuotaCheck,
  handleViewQuotaSettings,
}) => {
  const classes = templateSlotStyles();

  const calculateOrderCutOff = (slot) => {
    let orderCutOff = "";
    const { bufferTimeHours, bufferTimeMinutes, startTime } = slot;
    if (startTime && moment(startTime).isValid()) {
      const totalBufferTimeInMinutes = bufferTimeHours * 60 + bufferTimeMinutes;
      orderCutOff = moment(startTime).subtract(totalBufferTimeInMinutes, "m").format("HH:mm");
    }

    return orderCutOff || "";
  };

  return (
    <Grid container key={`${slot.time}`} className={classes.templateSlotContainer}>
      <Grid item xs={2}>
        <FormControlLabel
          control={<Checkbox checked={slot.status} onChange={handleIndividualSlotCheckbox} value={slot.time} />}
          label={<Typography className={classes.checkboxLabel}>{slot.time}</Typography>}
        />
      </Grid>
      <Grid item xs={3} className={classes.capacityInput}>
        <FormControl>
          <TextBox
            type="number"
            name="slotCapacity"
            value={slot.slotCapacity}
            handleChange={handleIndividualSlotFieldsChange}
            inputProps={{ maxLength: 15 }}
            textBoxId="capacityGenerated"
            placeholderText="Enter Capacity"
            isNegativeAllowed={false}
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SelectBox
              label="Hrs"
              name="bufferTimeHours"
              menuItems={HOURS}
              value={slot.bufferTimeHours}
              handleChange={handleIndividualSlotFieldsChange}
              labelClass={classes.hideShrinkedLabel}
              selectClass={classes.selectClass}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectBox
              label="Mins"
              name="bufferTimeMinutes"
              menuItems={MINUTES}
              value={slot.bufferTimeMinutes}
              handleChange={handleIndividualSlotFieldsChange}
              labelClass={classes.hideShrinkedLabel}
              selectClass={classes.selectClass}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2} className={classes.orderCutOff}>
        {calculateOrderCutOff(slot)}
      </Grid>
      {showQuotaColumn ? (
        <Grid item xs={2}>
          <Grid container alignItems="center">
            <Grid item xs={4}>
              <SwitchButton
                name="isQuotaEnabled"
                value={!!slot.isQuotaEnabled}
                checked={!!slot.isQuotaEnabled}
                handleChange={handleIndividualSlotFieldsChange}
              />
            </Grid>
            <Grid item xs={6}>
              <LinkButton buttonLabel="View" onClick={() => handleViewQuotaSettings(QUOTA_VIEW_OPTIONS.VIEW, slot)} />
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default TemplateSlot;
