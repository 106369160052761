import { createStyles, makeStyles, PopoverOrigin } from "@material-ui/core";
import { COLORS } from "../../../config/theme/theme";

export const selectBoxStyles = makeStyles((theme) =>
  createStyles({
    fieldLabel: { marginBottom: "8px" },
    formControl: {
      "& .open": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderBottom: "none",
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          border: "1px solid #0A437C",
        },
      },
      "& .MuiOutlinedInput-root.Mui-disabled": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#E6E6E6",
          top: "0px",
        },
      },
      "& .MuiInputBase-formControl": {
        backgroundColor: COLORS.WHITE,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#E6E6E6",
        top: "0px",
      },
    },
    menuPropsList: {
      "& .MuiListItem-root": {
        fontSize: 12,
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        whiteSpace: "normal",
      },
      "& .MuiCheckbox-root": {
        backgroundSize: "16px 16px",
        marginRight: theme.spacing(0.75),
      },
    },
    menuPropsPaper: {
      "& .open": {
        border: "1px solid #0A437C",
      },
      maxHeight: 200,
      border: "1px solid #0A437C",
      borderRadius: theme.spacing(1),
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      boxShadow: "none",
    },
  })
);

export const anchorOriginDefault: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "left",
};
