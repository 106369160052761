import { Grid, Typography } from "@material-ui/core";
import Toast from "components/common/Toast/Toast";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { config, publicClientApplication } from "../../../config/SSOConfig";
import CarrefourLogo from "../../assets/svg/CarrefourLogo.svg";
import DOSLogo from "../../assets/svg/DOSTitle.svg";
import LineSVG from "../../assets/svg/Line.svg";
import LoginFooter from "../../assets/svg/LoginFooter.svg";
import PrimaryButton from "../../common/PrimaryButton/PrimaryButton";
import { loginStyles } from "../LoginPageStyles";
import { validateUserToken } from "../redux/userSlice";
import { loginInitState, LoginPageState } from "./LoginInterfaces";
import msLogo from "./Microsoft.png";

function SSOLoginPage() {
  const classes = loginStyles();

  const appDispatch = useDispatch();

  const [state, setState] = useState<LoginPageState>(loginInitState);

  const ERROR_MESSAGE = "Error while login please try again";
  const ERROR_MESSAGE_MICROSOFT = "Error while login to Microsoft account. Please try again";
  const ERROR_LOGIN = "Login Required";

  const login = async () => {
    try {
      setState({ ...state, loading: true, error: {} });
      const loginRequest = {
        scopes: config.scopes,
        prompt: "select_account",
      };
      await publicClientApplication.loginPopup(loginRequest);
      getUserProfile();
    } catch (err) {
      console.error("Error in login :: ", err);
      addError(ERROR_MESSAGE);
    }
  };

  async function addError(message: string) {
    let errorsFound: any = { login: message };
    const accounts = publicClientApplication.getAllAccounts();
    if (accounts && accounts.length > 0) {
      errorsFound.login = `Hello ${accounts[0].name}. ${message}.`;
    }
    setState({ ...state, loading: false, error: errorsFound });
  }

  async function getUserProfile() {
    try {
      setState({ ...state, loading: true, error: {} });
      var accessToken = await getAccessToken(config.scopes);
      if (accessToken) {
        await getUserDetails(accessToken);
      } else {
        addError(ERROR_MESSAGE_MICROSOFT);
      }
    } catch (err) {
      console.error("Error in getUserProfile :: ", err);
      addError(ERROR_LOGIN);
    }
  }

  async function getAccessToken(scopes: any) {
    try {
      const accounts = publicClientApplication.getAllAccounts();
      if (accounts.length <= 0) throw new Error(ERROR_LOGIN);

      var silentResult = await publicClientApplication.acquireTokenSilent({
        scopes: scopes,
        account: accounts[0],
      });
      return silentResult.accessToken;
    } catch (err) {
      console.error("Error in getAccessToken function :: ", err);
      addError(ERROR_LOGIN);
    }
  }

  async function getUserDetails(accessToken: any) {
    try {
      const response: any = await appDispatch(validateUserToken(accessToken));
      if (response && response.payload && response.payload.message) {
        setState({ ...state, loading: false, error: { login: response.payload.message } });
      } else {
        setState({ ...state, loading: false, error: {} });
      }
    } catch (err) {
      console.error("Error in getUserDetails :: ", err);
      addError(ERROR_MESSAGE);
    }
  }

  return (
    <div className={classes.loginDivRoot}>
      <div className={classes.mainContainer}>
        <Grid container className={classes.card}>
          <Grid item xs={12} className={classes.heading}>
            <img src={CarrefourLogo} className={classes.bottomLogoSVG} alt="CarrefourLogo"></img>
            <img src={LineSVG} alt="line" className={classes.linesvg} />
            <Typography noWrap>
              <img src={DOSLogo} alt="DOSText" className={classes.logoName} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              style={{
                marginLeft: "50px",
              }}
            >
              <Grid item xs={12}>
                <Typography noWrap className={classes.loginText}>
                  Login to your account
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <PrimaryButton
                  buttonLabel={state.loading ? "loading..." : "Sign in with Microsoft"}
                  disabled={state.loading}
                  className={classes.loginButton}
                  buttonVariant="outlined"
                  startIcon={<img src={msLogo} alt="MicrosoftLogo" />}
                  onClick={() => {
                    login();
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              background: "#31261D",
              display: "flex",
              height: "72px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={LoginFooter} alt="copyText" className={classes.logoFooter} />
          </Grid>

          {state && state.error && state.error.login && <Toast message={state.error.login} />}
        </Grid>
      </div>
    </div>
  );
}

export default React.memo(SSOLoginPage);
