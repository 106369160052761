import React from "react";

import { Grid, IconButton, InputAdornment, List, ListItem, ListItemText, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { userStyles } from "./CustomPosZoneMappingStyle";
import { TOTAL_AREAS } from "../../SingleUsePlastic/CurrentConfiguration/Utils/PlasticBagCurrentCofigurationConstants";

import TextBox from "../TextBox";
import searchIcon from "../../assets/svg/SearchIcon.svg";
import Loader from "../Loader/Loader";

interface CustomPosZoneAssignedListInterface {
  title: any;
  titleCount: any;
  searchTextValue: any;
  searhTextName: any;
  handlePosSearchTextChange: any;
  fetchingList: any;
  selectedPosServices: any;
  deleteSelectedRow: any;
}

const CustomPosZoneAssignedList = (props: CustomPosZoneAssignedListInterface) => {
  const classes = userStyles();
  const { title, titleCount, searchTextValue, searhTextName, handlePosSearchTextChange, fetchingList, selectedPosServices, deleteSelectedRow } = props;
  const renderList = (listItems: any) => {
    return (
      <>
        <List component="div" dense={true} disablePadding={true}>
          {listItems.map((item: any, index: any) => (
            <ListItem key={`CustomPosZoneAssignedList-renderList=${index}`} dense={true} alignItems="flex-start" className={classes.assignedListItemStyle}>
              <ListItemText style={{ width: "10%" }} className={classes.assignedListItemTextStyle}>
                {item.posNo}
              </ListItemText>
              <ListItemText style={{ width: "30%" }} className={classes.assignedListItemTextStyle}>
                {item.posName}
              </ListItemText>
              <ListItemText style={{ width: "25%" }} className={classes.assignedListItemTextStyle}>
                {item.serviceType}
              </ListItemText>
              <ListItemText style={{ width: "25%" }} className={classes.assignedListItemTextStyle}>
                {item.zoneName}
              </ListItemText>
              <ListItemText style={{ textAlign: "center", width: "10%" }}>
                <CloseIcon
                  fontSize="small"
                  style={{ width: "20px", height: "20px", margin: 0, padding: "2px", color: "#000000", background: "#FFFFFF", border: "1px solid #000000", borderRadius: "50%", cursor: "pointer" }}
                  onClick={(e) => deleteSelectedRow(e, item, index)}
                />
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </>
    );
  };

  return (
    <Grid className={classes.rootDiv}>
      <Grid className={classes.containerDiv} style={{ float: "right" }}>
        <Typography className={classes.containerTitle}> {`${title || TOTAL_AREAS} (${titleCount || "0"})`}</Typography>
        <TextBox
          textBoxId="searchTextBox"
          placeholderText="Quick search by Pos number"
          endAdornment={
            <InputAdornment position="end">
              <IconButton edge="end" size="small">
                <img src={searchIcon} alt="search" />
              </IconButton>
            </InputAdornment>
          }
          value={searchTextValue}
          name={searhTextName}
          handleChange={handlePosSearchTextChange}
          className={classes.searchBoxGridDiv}
        ></TextBox>
        <Grid item xs={12} className={classes.tableMainGridDiv}>
          <>
            {fetchingList ? <Loader /> : null}
            {renderList(selectedPosServices)}
          </>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(CustomPosZoneAssignedList);
