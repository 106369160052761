import { DAYS_DROPDOWN_OPTIONS, FOOD_SUB_TYPES } from "../../../config/constants";

export const foodSplitRegex = ", ";

export const PRE_SELECTED_FOOD_SUB_TYPES = FOOD_SUB_TYPES.reduce((acc, subType) => [...acc, subType.value], []).join(foodSplitRegex);

export const ALL_DAYS_VALUES = DAYS_DROPDOWN_OPTIONS.reduce((acc, day) => [...acc, day.id], []);

export const DEFAULT_TEMPLATE_SLOT = {
  startTime: null,
  endTime: null,
  slotCapacity: "",
  bufferTimeHours: "",
  bufferTimeMinutes: "",
  orderCutOff: "",
  status: true,
  isQuotaEnabled: false,
};

export const DEFAULT_SLOTS_GENERATOR_FORMS_VALUES = {
  days: [],
  slotDurationHours: "",
  slotDurationMinutes: 0,
  bufferTimeHours: "",
  bufferTimeMinutes: 0,
  serviceStartTime: null,
  serviceEndTime: null,
  defaultSlotCapacity: "",
  generatedSlots: [],
  quotas: [],
};

export const DEFAULT_TAB_CONFIGURATIONS = {
  zoneName: "",
  foodTypes: "",
  foodSubType: PRE_SELECTED_FOOD_SUB_TYPES,
  showFirstSlotAtOption: false,
  showServiceCutOffTimeOption: false,
  showFirstSlotAt: null,
  serviceCutOffTime: null,
  slotOptimizationEnabled: false,
  capacityThreshold: "",
  timeThreshold: "",

  slotsGeneratorForms: [{ ...DEFAULT_SLOTS_GENERATOR_FORMS_VALUES, days: ALL_DAYS_VALUES }],
  selectedAreas: [],
  status: "",
};
