import { makeStyles } from "@material-ui/core";
import { COLORS } from "../../../../config/theme/theme";

const styles = makeStyles({
  whiteBackground: { marginTop: "12px", backgroundColor: COLORS.WHITE },
  slotsAccordion: {
    padding: "4px 24px !important",
    "&.MuiAccordionSummary-content": {
      "&.Mui-expanded": {
        margin: "16px 0px",
      },
    },
  },
  accordionDetails: {
    padding: "0px 24px 16px 24px",
    display: "block",
  },
  dayStyles: {
    fontWeight: 600,
    paddingLeft: "5px",
    textTransform: "capitalize",
  },
  slotsHeader: {
    fontWeight: 600,
  },
  slotsContainer: {
    marginTop: "12px",
  },
  isSlotUpdatedIconStyle: {
    marginLeft: "8px",
  },
  addNewSlot: {
    marginTop: "12px",
  },
});

export default styles;
