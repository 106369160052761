import React from "react";

import { Card, Grid, IconButton, InputAdornment } from "@material-ui/core";

import searchIcon from "../assets/svg/SearchIcon.svg";
import TextBox from "../common/TextBox";
import Table from "../common/Table/Table";

import { getSingleUsePlasticListColumns } from "./Utils/SingleUsePlasticViewUtils";

interface SingleUsePlasticCardInterface {
  classes: any;
  usePlasticState: any;
  handleSearchChange: any;
  fetchNextRecord: any;
  userInfo: any;
  handleSearchOnClick: any;
}

const SingleUsePlasticCard = (props: SingleUsePlasticCardInterface) => {
  const { classes, usePlasticState, handleSearchChange, fetchNextRecord, userInfo, handleSearchOnClick } = props;

  const tableHeaderColumns = getSingleUsePlasticListColumns(userInfo);

  return (
    <Card className={classes.cardRoot}>
      <div className={classes.topHeaderDiv}>
        <Grid container className={classes.searchAndFilterContainer} justifyContent="space-between">
          <Grid item xs={4} className={classes.searchFilter}>
            <TextBox
              textBoxId="searchTextBox"
              placeholderText="Quick search by User Name"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton edge="end" size="small">
                    <img src={searchIcon} alt="search" onClick={handleSearchOnClick} />
                  </IconButton>
                </InputAdornment>
              }
              value={usePlasticState.searchText}
              handleChange={handleSearchChange}
            ></TextBox>
          </Grid>
        </Grid>
      </div>

      <div className={classes.tableDivStyle}>
        <div className={classes.tableStyle}>
          <Table
            columnsData={tableHeaderColumns}
            rowsData={usePlasticState.usePlasticList}
            hideFooter={true}
            pageSize={usePlasticState.pageSize}
            rowCount={usePlasticState.maxRecordPresent}
            pageNumber={usePlasticState.page}
            fetchNextRecord={fetchNextRecord}
          />
        </div>
      </div>
    </Card>
  );
};

export default React.memo(SingleUsePlasticCard);
