import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router-dom";
// Constants
import { API_RESPONSE_STATUS, APP_ROUTES, AREA_POS_MAPPING_TYPE, EXPRESS_TYPES, SERVICE_TYPES, STATUS_OK } from "../../config/constants";
import { selectServiceTypes } from "../../config/redux/configurationsSlice";
import { getServiceTypeDetailsFromServiceName } from "../../utils/helperFunctions";
import { selectCountrySettings } from "../CountrySettings/redux/countrySettingsSlice";
import DefaultService from "../ServiceAndSlots/DefaultService/DefaultService";
import ClickAndCollect from "./ClickAndCollect/ClickAndCollect";
// Custom components
import Express from "./Express/Express";
import StandardService from "./Standard/Standard";

const ServiceAndSlots = () => {
  const { path } = useRouteMatch();
  const { fetchStatus, countryDetails } = useSelector(selectCountrySettings);
  const serviceTypes = useSelector(selectServiceTypes);

  const isAreaPOSMappingAreaBased = React.useMemo(() => {
    let areaPOSMapping = AREA_POS_MAPPING_TYPE.POLYGON_BASED;

    if (fetchStatus === API_RESPONSE_STATUS.SUCCEEDED) {
      if (countryDetails && countryDetails.status === STATUS_OK) {
        let {
          data: { areaPosMappingType },
        } = countryDetails;
        areaPOSMapping = areaPosMappingType;
      }
    }

    return areaPOSMapping === AREA_POS_MAPPING_TYPE.AREA_BASED;
  }, [countryDetails, fetchStatus]);

  const getExpressType = React.useMemo(() => {
    let expressType = EXPRESS_TYPES.ON_DEMAND.value;

    if (countryDetails && countryDetails.status === STATUS_OK && serviceTypes.length) {
      let {
        data: { serviceTypes: countrySettingServices },
      } = countryDetails;

      let expressId = getServiceTypeDetailsFromServiceName(serviceTypes, SERVICE_TYPES.EXPRESS).id;

      expressType = countrySettingServices.find((s) => s.serviceTypeID === expressId).expressType;
    }

    return expressType;
  }, [countryDetails, serviceTypes]);

  return (
    <div>
      <Switch>
        <Route exact path={`${path}${APP_ROUTES.STANDARD}/:posNo?`}>
          <StandardService
            serviceTypeId={getServiceTypeDetailsFromServiceName(serviceTypes, SERVICE_TYPES.STANDARD).id}
            serviceTypeName={SERVICE_TYPES.STANDARD.toLowerCase()}
            isPOSMappingAreaBased={isAreaPOSMappingAreaBased}
          />
        </Route>
        <Route exact path={`${path}${APP_ROUTES.EXPRESS}/:posNo?`}>
          <Express
            serviceTypeId={getServiceTypeDetailsFromServiceName(serviceTypes, SERVICE_TYPES.EXPRESS).id}
            serviceTypeName={SERVICE_TYPES.EXPRESS.toLowerCase()}
            isPOSMappingAreaBased={isAreaPOSMappingAreaBased}
            expressType={getExpressType}
          />
        </Route>
        <Route exact path={`${path}${APP_ROUTES.CLICK_N_COLLECT}/:posNo?`}>
          <ClickAndCollect serviceTypeId={getServiceTypeDetailsFromServiceName(serviceTypes, SERVICE_TYPES.CLICK_N_COLLECT).id} serviceTypeName={SERVICE_TYPES.CLICK_N_COLLECT.toLowerCase()} />
        </Route>
        <Route exact path={`${path}${APP_ROUTES.DEFAULT}/:posNo?`}>
          <DefaultService
            serviceTypeId={getServiceTypeDetailsFromServiceName(serviceTypes, SERVICE_TYPES.DEFAULT).id}
            serviceTypeName={SERVICE_TYPES.DEFAULT.toLowerCase()}
            isPOSMappingAreaBased={isAreaPOSMappingAreaBased}
          />
        </Route>
      </Switch>
    </div>
  );
};

export default ServiceAndSlots;
