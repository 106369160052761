import React from "react";

import PropTypes from "prop-types";

// Material UI Components
import Grid from "@material-ui/core/Grid";

// Common Components
import SwitchButton from "../../../common/SwitchButton/SwitchButton";
import SelectBox from "../../../common/SelectBox/SelectBox";
import TextBox from "../../../common/TextBox";

// Constants/Utils
import { generateDropdownItems } from "../../../../utils/helperFunctions";

// Styles
import SlotOptimizationStyles from "./SlotOptimizationStyles";

const HOURS = generateDropdownItems(2, 24, "hours");

const ACCEPTED_CAPACITY = {
  min: 50,
  max: 100,
};
const ACCEPTED_CAPACITY_ERROR = "Allowed values are 50 to 100";

const SlotOptimization = ({
  slotOptimizationEnabled,
  capacityThreshold,
  timeThreshold,
  handleSlotOptimizationSwitch,
  handleSlotOptimizationInputs,
}) => {
  const classes = SlotOptimizationStyles();

  const isCapacityValid = () => {
    return (
      capacityThreshold && (capacityThreshold > ACCEPTED_CAPACITY.max || capacityThreshold < ACCEPTED_CAPACITY.min)
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container alignItems="center">
          <Grid item xs={10} className={classes.fontWeight500}>
            Slot Optimization
          </Grid>
          <Grid item xs={2} className={classes.switchButton}>
            <SwitchButton
              name="slotOptimizationEnabled"
              checked={!!slotOptimizationEnabled}
              handleChange={handleSlotOptimizationSwitch}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} className={classes.inputsContainer}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextBox
              type="number"
              name="capacityThreshold"
              placeholderText="Capacity (50%-100%)"
              value={capacityThreshold}
              handleChange={handleSlotOptimizationInputs}
              disabled={!slotOptimizationEnabled}
            />
            {slotOptimizationEnabled && isCapacityValid() ? (
              <div className={classes.error}>{ACCEPTED_CAPACITY_ERROR}</div>
            ) : null}
          </Grid>
          <Grid item xs={6}>
            <SelectBox
              name="timeThreshold"
              label="Time Threshold"
              menuItems={HOURS}
              value={timeThreshold}
              disabled={!slotOptimizationEnabled}
              handleChange={handleSlotOptimizationInputs}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SlotOptimization;

SlotOptimization.propTypes = {
  slotOptimizationEnabled: PropTypes.bool.isRequired,
  capacityThreshold: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  timeThreshold: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleSlotOptimizationSwitch: PropTypes.func.isRequired,
  handleSlotOptimizationInputs: PropTypes.func.isRequired,
};

SlotOptimization.defaultProps = {
  timeThreshold: "",
};
