import { makeStyles } from "@material-ui/core/styles";

import { COLORS } from "../../../config/theme/theme";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  cards: {
    minWidth: 275,
    marginTop: "16px",
    background: COLORS.WHITE,
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    padding: "8px",
  },
  cardTitle: {
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "22px",
    color: COLORS.TEXT_PRIMARY,
  },
  cardContent: {
    marginTop: "18px",
    display: "flex",
  },
  posDetailLabel: {
    fontSize: "14px",
    fontWeight: 500,
  },
  posDetailData: {
    marginTop: "8px",
    fontSize: "14px",
    fontWeight: 600,
  },
  foodSubType: {
    marginRight: "40px",
  },
  cardText: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "17px",
    letterSpacing: "0em",
    textAlign: "left",
    paddingRight: "124px",
  },
  cardTextData: {
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "17px",
    letterSpacing: "0em",
    textAlign: "left",
    paddingRight: "150px",
  },
  tabsIndicator: {
    backgroundColor: "#0E5AA7",
  },
  tabRoot: {
    textTransform: "initial",
    fontWeight: theme.typography.fontWeightRegular,

    "&:hover": {
      color: "#0E5AA7",
      opacity: 1,
    },
    "&:focus": {
      color: "#0E5AA7",
    },
  },
  tabLabelIcon: {
    minHeight: "0",
  },
  deleteConfigTabIcon: {
    position: "absolute",
    top: 0,
    height: "15px",
    right: 0,
  },
  tabSelected: {
    color: "#0E5AA7",
    fontWeight: "600",
  },
  typography: {
    padding: theme.spacing(3),
  },
  vertical: {
    borderLeft: "1px solid #B4B4B4",
    height: "25px",
    marginLeft: "80px",
    marginTop: "8px",
    marginRight: "80px",
  },
  accordionStyle: {
    marginTop: "12px",
  },
  accordionCards: {
    width: "1044px",
    background: COLORS.WHITE,
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
  },
  accordionTitle: {
    fontSize: "18px",
    fontWeight: 600,
  },
  dropdown: {
    minWidth: "90%",
    maxWidth: "100%",
    // height: "48px",
  },
  fontStyle: {
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "15px",
    color: COLORS.TEXT_PRIMARY,
    "& .MuiFormLabel-root": {
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "15px",
      color: COLORS.TEXT_PRIMARY,
    },
  },
  inputRounded: {
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "15px",
    textAlign: "left",
    left: 0,
    top: 0,
    background: COLORS.WHITE,
    border: "1px solid #B2B2B2",
    borderRadius: "8px",
  },
  selectDropdownPaper: {
    border: "1px solid #0A437C",
    borderRadius: "8px",

    fontSize: "12px",
    lineHeight: "15px",
    fontWeight: "500",
    maxHeight: "250px",
  },
  buttons: {
    margin: "24px 0px",
  },
  cardHeading: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    color: COLORS.TEXT_PRIMARY,
    paddingTop: "12px",
  },
  slotsDropdown: {
    width: "200px",
    height: "40px",
  },
  input: {
    borderRadius: "8px",
    "& .MuiOutlinedInput-input": {
      padding: "15px 14px",
    },
  },
  label: {
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "15px",
    color: COLORS.TEXT_PRIMARY,
  },
  slotsTableLabel: {
    fontWeight: 600,
  },
  resetSlotsModalBody: {
    border: "none",
    height: "300px",
    width: "500px",
    backgroundColor: "white",
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-40%, -50%)",
    padding: "24px",
    textAlign: "center",
    borderRadius: "8px",
    "&:focus": {
      outline: "none",
    },
  },
  closeModalIcon: {
    position: "absolute",
    right: "24px",
    top: "24px",
    cursor: "pointer",
  },
  modalTitle: {
    fontSize: "24px",
    fontWeight: 600,
    marginTop: "22px",
  },
  modalDescription: { marginTop: "60px", fontSize: "18px", fontWeight: 400 },
  error: {
    marginTop: "5px",
    fontSize: "12px",
    color: "red",
  },
  checkboxLabel: {
    fontSize: "12px",

    fontWeight: "normal",
    lineHeight: "15px",
    paddingLeft: "8px",
  },
  addOtherDays: {
    display: "flex",
    justifyContent: "flex-end",
    placeSelf: "flex-end",
  },
}));

export default useStyles;
