import Checkbox from "@material-ui/core/Checkbox";
// Material UI
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select, { SelectProps } from "@material-ui/core/Select";
import React, { useRef, useState } from "react";
import { SELECT_ALL } from "../../../config/constants";
// Assets
import { ReactComponent as downArrowIcon } from "../../assets/svg/DownArrow.svg";
import { anchorOriginDefault } from "../SelectBox/SelectBoxStyles";
// Styles
import { multiSelectBoxStyles } from "./MultiSelectBoxStyles";


interface MultiSelectBoxProps extends SelectProps {
  fieldLabel?: string
  label?: string
  name?: string
  onChange: any
  menuitems: any
  anchorOrigin?: any
  menuPaperProps?: any
  className?: any
  hideIconComponent?: boolean
  renderValue?: any
  value: any,
  isDeliveryFee?:any
}

// Not used in form
// renderValue = (selected: any) => selected.join(", "),

const MultiSelectBox = (props: MultiSelectBoxProps) => {

  const { fieldLabel, label, value = [], menuitems = [], name, onChange, className,
    hideIconComponent, menuPaperProps, anchorOrigin, isDeliveryFee =false } = props;

  const classes = multiSelectBoxStyles();
  const anchorEl = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const getLabelForSelectedValue = (selectedValues: any) => {
    if (selectedValues && selectedValues.length === menuitems.length) return "All";
    const labels =
      selectedValues && menuitems.length > 0 &&
      selectedValues
        .reduce((acc: any, val: any) => {
          let obj = menuitems?.find((name: any) => name.id === val);
          return obj ? [...acc, obj.name] : acc;
        }, [])
        .join(", ");
    return labels;
  };

  const handleChange = (event: any) => {
    const selectedValues = event.target.value;
    if (selectedValues[selectedValues.length - 1] === SELECT_ALL.id) {
      onChange(value.length === menuitems.length ? [] : menuitems.reduce((acc: any, item: any) => [...acc, item.id], []));
      return;
    }
    onChange(selectedValues);
  };

  return (
    <>
      {fieldLabel && <div className={classes.fieldLabel}>{fieldLabel}</div>}
      <FormControl variant={isDeliveryFee ?"filled" : "outlined"} className={classes.formControl}>
        <InputLabel
          classes={{
            // TODO Check Design Class
            // root: classes.inputLabel,
          }}
        >
          {label}
        </InputLabel>
        <Select
          {...props}
          multiple
          displayEmpty
          label={label}
          className={`${className || ""} ${isOpen ? "open " : ""}${label ? "hasLabel " : ""}`}
          name={name}
          onClick={(e) => e.stopPropagation()}
          value={value}
          onChange={handleChange}
          onOpen={() => {
            setIsOpen(true);
          }}
          onClose={() => {
            setIsOpen(false);
          }}
          ref={anchorEl}
          IconComponent={hideIconComponent ? () => <></> : downArrowIcon}
          renderValue={(selectedValues) => getLabelForSelectedValue(selectedValues)}
          MenuProps={{
            classes: {
              list: classes.menuPropsList,
              paper: menuPaperProps || classes.menuPropsPaper,
            },
            anchorEl: anchorEl.current,
            getContentAnchorEl: null,
            anchorOrigin: anchorOrigin || anchorOriginDefault,
          }}
        >
          <MenuItem
            value={SELECT_ALL.id}>
            <Checkbox checked={menuitems.length > 0 && value.length === menuitems.length} />
            {SELECT_ALL.name}
          </MenuItem>
          {menuitems.map((item: any) => (
            <MenuItem key={item.id}
              value={item.id}>
              <Checkbox checked={value.includes(item.id)} />
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default React.memo(MultiSelectBox);
