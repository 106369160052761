import { makeStyles } from "@material-ui/core";

const createPOSStyles = makeStyles({
  sideBarContainer: {},
  mainContent: {
    margin: 0,
    padding: "0px 0px 0px 12px",
  },
  mainContentSection: {
    marginTop: "80px",
    padding: "24px 12px 24px 24px",
  },
});

export default createPOSStyles;
