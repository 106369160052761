import { Typography } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Grid from "@material-ui/core/Grid";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import ModalContainer from "../../../base/ModalContainer";
import { ReactComponent as CloseButton } from "../../assets/svg/closeButton.svg";
import PrimaryButton from "../../common/PrimaryButton/PrimaryButton";
import commonStyles from "../RuleEngineCommonStyles";
// Styles
import previewDeliveryPromiseStyles from "./PreviewDeliveryPromiseStyles";

interface PreviewDeliveryPromiseProps {
  show: boolean;
  onClose: any;
  configDetails: any;
  isLoading: boolean;
  onProceed: any;
  updatedConfigInfo: any;
}

const PreviewDeliveryPromise = (props: PreviewDeliveryPromiseProps) => {
  const { show = true, onClose, configDetails, isLoading, onProceed, updatedConfigInfo } = props;
  const commonClasses = commonStyles();
  const classes = previewDeliveryPromiseStyles();

  const renderModalBody = () => {
    return (
      <div className={classes.modalBody}>
        {configDetails.posZoneIdentifierList.length ? (
          <Accordion defaultExpanded className={commonClasses.accordion}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className={commonClasses.accordionSummary}>
              POS-Zone Mapping
            </AccordionSummary>
            <AccordionDetails className={commonClasses.accordionDetails}>
              <div className={classes.posZoneMappingDetails}>
                <div className={classes.selectedZonesText}>Assigned POS Zones</div>
                <Grid container>
                  <Grid item xs={3}>
                    {configDetails.posZoneIdentifierList.map((zone: any) => (
                      <div className={classes.posZoneName} key={zone.id}>
                        {zone.posNoPosNameZoneName || `${zone.posNo}-${zone.posName}-${zone.zoneName}`}
                      </div>
                    ))}
                  </Grid>
                </Grid>
              </div>
            </AccordionDetails>
          </Accordion>
        ) : null}
        <Accordion defaultExpanded className={commonClasses.accordion}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} className={commonClasses.accordionSummary}>
            Express
          </AccordionSummary>
          <AccordionDetails className={commonClasses.accordionDetails}>
            <div className={classes.deliveryPromiseDetails}>
              <Grid container spacing={1}>
                {/* {updatedConfigInfo.upDatedConfig.isPromiseChange && */}
                <Grid item xs={3}>
                  <div className={classes.fieldLabel}>Type Of Promise</div>
                  <div className={classes.fieldValue}>{configDetails.dynamicPromise ? "Dynamic" : "Static"}</div>
                </Grid>
                {/* } */}
                {(updatedConfigInfo.defaultOrFixedTimeHours !== configDetails.defaultOrFixedTimeHours || updatedConfigInfo.defaultOrFixedTimeMinutes !== configDetails.defaultOrFixedTimeMinutes) && (
                  <Grid item xs={3}>
                    <div className={classes.fieldLabel}>Default Time</div>
                    <div className={classes.fieldValue}>{`${configDetails.defaultOrFixedTimeHours || 0} hours ${configDetails.defaultOrFixedTimeMinutes || 0} minutes`}</div>
                  </Grid>
                )}

                {configDetails.dynamicPromise && (
                  <>
                    <>
                      {configDetails.weightRule.thresholdWeight !== updatedConfigInfo.weightRule.thresholdWeight && (
                        <Grid item xs={3}>
                          <div className={classes.fieldLabel}>Weight Of Consignment</div>
                          <div className={classes.fieldValue}>{`${configDetails.weightRule.thresholdWeight} Kg`}</div>
                        </Grid>
                      )}
                      {configDetails.weightRule.incrementalTime !== updatedConfigInfo.weightRule.incrementalTime && (
                        <Grid item xs={4}>
                          <div className={classes.fieldLabel}>Incremental Time Above Threshold</div>
                          <div className={classes.fieldValue}>{`${configDetails.weightRule.incrementalTime || 0} minutes`}</div>
                        </Grid>
                      )}
                      {configDetails.countRule.thresholdCount !== updatedConfigInfo.countRule.thresholdCount && (
                        <Grid item xs={3}>
                          <div className={classes.fieldLabel}>Item Count</div>
                          <div className={classes.fieldValue}>{configDetails.countRule.thresholdCount}</div>
                        </Grid>
                      )}
                      {configDetails.countRule.incrementalTime !== updatedConfigInfo.countRule.incrementalTime && (
                        <Grid item xs={4}>
                          <div className={classes.fieldLabel}>Incremental Time Above Threshold</div>
                          <div className={classes.fieldValue}>{`${configDetails.countRule.incrementalTime || 0} seconds`}</div>
                        </Grid>
                      )}
                    </>
                    <>
                      {configDetails.scalableTypeRule.itemList.length > 0 && (
                        <Grid item xs={6}>
                          <div className={classes.fieldLabel}>Type of Items (Incremental Time)</div>
                          <div className={classes.fieldValue}>
                            {configDetails.scalableTypeRule.itemList.map((item: any) => (
                              <div key={item.section}>{`${item.section} ( ${item.incrementalTime} minutes )`}</div>
                            ))}
                          </div>
                        </Grid>
                      )}
                      {/* <Grid item xs={3}>
                    <div className={classes.fieldLabel}>Incremental Time Above Threshold</div>
                    <div className={classes.fieldValue}>
                      {configDetails.scalableTypeRule.itemList.map((item: any) => (
                        <div key={item.section}>{`${item.incrementalTime} minutes`}</div>
                      ))}
                    </div>
                  </Grid> */}
                    </>
                  </>
                )}
              </Grid>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className={classes.modalFooter}>
        <Grid container spacing={1}>
          <Grid item xs={8}></Grid>
          <Grid item xs={4}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <PrimaryButton className={classes.buttonStyles} buttonLabel="CANCEL" buttonVariant="outlined" onClick={onClose} />
              </Grid>
              <Grid item xs={6}>
                <PrimaryButton className={classes.buttonStyles} buttonLabel={isLoading ? "loading..." : "Proceed"} onClick={onProceed} disabled={isLoading} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };
  return (
    <div>
      <ModalContainer className={classes.modal} open={show} onClose={onClose} disableBackdropClick>
        <div className={classes.modalContent}>
          <div className={classes.mainDivStyle}>
            <Typography className={classes.modalTitle}>Rule Engine Set up Preview</Typography>
            <CloseButton className={classes.closeButtonSVG} onClick={onClose} />
          </div>
          {renderModalBody()}
          {renderFooter()}
        </div>
      </ModalContainer>
    </div>
  );
};

export default PreviewDeliveryPromise;
