import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  fontWeight500: {
    fontWeight: 500,
  },
  switchButton: {
    display: "flex",
    justifyContent: "flex-end",
  },
  timePicker: {
    marginTop: "10px",
  },
});

export default useStyles;
