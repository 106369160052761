import React from "react";

import { Grid, Typography } from "@material-ui/core";
import TextBoxWithLabel from "../../common/TextBoxWithLabel/TextBoxWithLabel";
import { AddCapacityUtilisationRangeText, AddSubtractCapacityRangeObjectButtons } from "../Configuration/utils/DeliveryFeeConfigViewUtils";

interface CapacityUtilisationConfigurationInterface {
  classes: any;
  state_obj: any;
  handleCapacityRangeChange: any;
  handleCapacityFeesChange: any;
  _handle_click_add_capacity_range: any;
  _handle_click_remove_capacity_range: any;
}

const CapacityUtilisationConfiguration = (props: CapacityUtilisationConfigurationInterface) => {
  const { classes, state_obj, handleCapacityRangeChange, handleCapacityFeesChange, _handle_click_add_capacity_range, _handle_click_remove_capacity_range } = props;

  return (
    <>
      <Grid container className={classes.capacityUtilisationMainDiv}>
        <Grid item xs={3}>
          <Typography className={classes.configDateTextStyle}>{"Range Start (Greater Than or Equal To)"}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.configDateTextStyle}>{"Range End (Less Than)"}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.configDateTextStyle}>{"Delivery Fees"}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.configDateNoteTextStyle}>{"** Maximum Range End Value 100"}</Typography>
        </Grid>
      </Grid>
      {state_obj !== undefined && Array.isArray(state_obj) && state_obj.length !== undefined && state_obj.length > 0 ? (
        state_obj.map((state_data: any, state_index: any) => (
          <Grid container key={`state_obj-capacity_utilisation_configuration-${state_index}`} className={classes.capacityUtilisationValueDiv}>
            <Grid item xs={3}>
              <TextBoxWithLabel
                label={"Range Start (Greater Than or Equal To) (%)"}
                name="capacityLow"
                value={state_data.capacityLow}
                placeholderText="Range Start (%)"
                handleChange={(e: any) => handleCapacityRangeChange(e, state_index)}
                type="number"
                className={classes.rightPaddingStyle}
                disabled={state_index === 0 && state_obj.length === 1 ? false : state_index !== state_obj.length - 1 ? true : state_index === state_obj.length - 1 ? true : false}
                error={state_data.capacity_low_error_message === "" ? false : true}
                helperText={state_data.capacity_low_error_message}
              ></TextBoxWithLabel>
            </Grid>
            <Grid item xs={3}>
              <TextBoxWithLabel
                label={"Range End (Less Than) (%)"}
                name="capacityHigh"
                value={state_data.capacityHigh}
                placeholderText="Range End (%)"
                handleChange={(e: any) => handleCapacityRangeChange(e, state_index)}
                type="number"
                className={classes.rightPaddingStyle}
                disabled={state_index !== state_obj.length - 1}
                error={state_data.capacity_high_error_message === "" ? false : true}
                helperText={state_data.capacity_high_error_message}
              ></TextBoxWithLabel>
            </Grid>
            <Grid item xs={2}>
              <TextBoxWithLabel
                label={"Delivery Fees"}
                name="deliveryFee"
                value={state_data.deliveryFee}
                placeholderText="Delivery Fees"
                handleChange={(e: any) => handleCapacityFeesChange(e, state_index)}
                type="number"
                className={classes.rightPaddingStyle}
                disabled={false}
                error={state_data.capacity_fee_error_message === "" ? false : true}
                helperText={state_data.capacity_fee_error_message}
              ></TextBoxWithLabel>
            </Grid>
            <Grid item xs={3}>
              {state_obj.length === 1 ? (
                <AddCapacityUtilisationRangeText
                  classes={classes}
                  _handle_click_add_capacity_range={_handle_click_add_capacity_range}
                  capacityHigh={state_data.capacityHigh}
                  capacityLow={state_data.capacityLow}
                  deliveryFee={state_data.deliveryFee}
                />
              ) : state_index === state_obj.length - 1 ? (
                <AddSubtractCapacityRangeObjectButtons
                  classes={classes}
                  _handle_click_add_capacity_range={_handle_click_add_capacity_range}
                  capacityHigh={state_data.capacityHigh}
                  capacityLow={state_data.capacityLow}
                  deliveryFee={state_data.deliveryFee}
                  state_index={state_index}
                  _handle_click_remove_capacity_range={_handle_click_remove_capacity_range}
                />
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        ))
      ) : (
        <></>
      )}
    </>
  );
};

export default React.memo(CapacityUtilisationConfiguration);
