import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Route, useHistory } from "react-router";
import { Switch, useLocation } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { useStyles } from "./common/Styles/AppStyles";

// Constans
import { API_RESPONSE_STATUS, APP_ROUTES, CURRENT_COUNTRY, NEW_PATH_SELECTED, USER_INFO, USER_INFO_DOS } from "../config/constants";

// actions
import {
  fetchAllAreasOfCountry,
  fetchAllCollectionPointsTypes,
  fetchAllRegionsOfCountry,
  fetchAllUserRolesTypes,
  fetchListOfCountries,
  fetchPOSTypes,
  fetchServiceTypes,
  resetRegionsAndAreas,
} from "../config/redux/configurationsSlice";
import { isEmptyOrNull } from "./../utils/DataUtils";
import { ReactComponent as CloseIconButton } from "./assets/svg/closeButton.svg";

// Common Components
import { CountryRoute, PosRoute, PrivateRoute, RuleEngineRoute, DeliveryFeeRoute } from "./common/PrivateRoute";
import { fetchCountrySettings, resetCountrySettings } from "../components/CountrySettings/redux/countrySettingsSlice";
import { selectCountrySettings } from "./CountrySettings/redux/countrySettingsSlice";
import CreatePOS from "./CreatePOS/CreatePOS";
import { validateUserToken } from "./Login/redux/userSlice";
import { fetchAllAdditionalServicesTypes } from "./UserManagement/Redux/UserManagementSlice";

// Custom Components
import TopMenu from "./common/TopMenu/TopMenu";
import SideMenu from "./common/SideMenu/SideMenu";
import Loader from "./common/Loader/Loader";

// Route Component
import SSOLoginPage from "./Login/sso/SSOLoginPage";
import POSList from "./POSList/POSList";
import CompleteSetup from "./CompleteSetup/CompleteSetup";
import CountrySettings from "./CountrySettings/CountrySettings";
import CurrentConfiguration from "./RuleEnginePage/CurrentConfiguration/CurrentCofiguration";
import RuleEngine from "./RuleEnginePage/RuleEnginePage";
import PlasticBagCurrentCofiguration from "./SingleUsePlastic/CurrentConfiguration/PlasticBagCurrentCofiguration";
import EditPlasticBagConfiguration from "./SingleUsePlastic/EditPlasticBagConfiguration";
import SingleUsePlasticPage from "./SingleUsePlastic/SingleUsePlasticPage";
import UserManagementPage from "./UserManagement/UserManagementPage";
import DeliveryFeePage from "./DeliveryFee/DeliveryFeePage";
import AddUpdateDlvFeeConfigPage from "./DeliveryFee/Configuration/AddUpdateDlvFeeConfigPage";
import ConfigurationPreview from "./DeliveryFee/ConfigurationPreview";
import { changeCountry } from "config/redux/appConfigSlice";
import { getTopBarMenuItems } from "./common/TopMenu/TopMenuUtil";

const ToastCloseIcon = ({ closeToast }) => {
  return <CloseIconButton onClick={closeToast} className="close-icon" />;
};

const App = () => {
  const { pathname } = useLocation();

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [isLoading, setisLoading] = useState(false);
  const [inValiduser, setInvaliduser] = useState(false);
  const [openSideMenu, SetOpenSideMenu] = useState(false);
  const [SideBarMenuItemsList, setSideBarMenuItemsList] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(1);

  // Selectors Start
  const { fetchStatus: countrySettingsFetchStatus } = useSelector(selectCountrySettings);
  const { countries, serviceTypes, posTypes, regions, areas, collectionPointsTypes, roles } = useSelector((state) => state.configurations, shallowEqual);
  const { additionalServices } = useSelector((state) => state.userMgmtConfigurations, shallowEqual);
  // Selectors End

  const showTopMenu = pathname !== APP_ROUTES.LOGIN && pathname !== APP_ROUTES.HOME;

  let userInfo = localStorage.getItem(USER_INFO_DOS);
  userInfo = !isEmptyOrNull(userInfo) ? JSON.parse(userInfo) : userInfo;

  const { isLoggedIn, userDetails } = useSelector((state) => state.user);
  const {
    currentCountry: { countryId },
  } = useSelector((state) => state.appConfig);

  const mouseOverEventOnSideMenu = (event) => {
    event.preventDefault();
    if (!openSideMenu) {
      SetOpenSideMenu(true);
    }
    window.dispatchEvent(new CustomEvent("resize"));
  };

  const mouseOverEventOnMainArea = (event) => {
    event.preventDefault();
    if (openSideMenu) {
      SetOpenSideMenu(false);
      window.dispatchEvent(new CustomEvent("resize"));
    }
  };

  const fetch_application_record_list = (selected_country_obj) => {
    dispatch(changeCountry(selected_country_obj));
    dispatch(fetchCountrySettings(selected_country_obj));

    if (regions.fetchStatus === API_RESPONSE_STATUS.IDLE && regions.fetchStatus !== API_RESPONSE_STATUS.LOADING) {
      dispatch(fetchAllRegionsOfCountry(selected_country_obj.countryId));
    }

    if (areas.fetchStatus === API_RESPONSE_STATUS.IDLE && areas.fetchStatus !== API_RESPONSE_STATUS.LOADING) {
      dispatch(fetchAllAreasOfCountry(selected_country_obj.countryId));
    }

    if (serviceTypes.fetchStatus === API_RESPONSE_STATUS.IDLE) {
      dispatch(fetchServiceTypes());
    }

    if (posTypes.fetchStatus === API_RESPONSE_STATUS.IDLE) {
      dispatch(fetchPOSTypes());
    }

    if (collectionPointsTypes.fetchStatus === API_RESPONSE_STATUS.IDLE) {
      dispatch(fetchAllCollectionPointsTypes());
    }

    if (roles.fetchStatus === API_RESPONSE_STATUS.IDLE) {
      dispatch(fetchAllUserRolesTypes());
    }

    if (additionalServices.fetchStatus === API_RESPONSE_STATUS.IDLE) {
      dispatch(fetchAllAdditionalServicesTypes());
    }
  };

  const _fetch_country_list_record = () => {
    if (countries.fetchStatus === API_RESPONSE_STATUS.IDLE) {
      dispatch(fetchListOfCountries());
    }
  };

  const handleListItemClick = (event, selected_index, toLink) => {
    setSelectedIndex(selected_index);
    localStorage.setItem(NEW_PATH_SELECTED, toLink);
  };

  const getSelectedIndexOnFirstLoad = (_new_path_name) => {
    if (_new_path_name !== undefined && _new_path_name !== null && _new_path_name !== "") {
      switch (_new_path_name) {
        case APP_ROUTES.DASHBOARD:
          return 0;

        case APP_ROUTES.POS_LIST:
          return 1;

        case APP_ROUTES.USER_MANAGEMENT:
          return 2;

        case APP_ROUTES.RULE_ENGINE:
          return 3;

        case APP_ROUTES.SINGLE_USE_PLASTIC:
          return 4;

        case APP_ROUTES.DELIVERY_FEE:
          return 5;

        default:
          return 1;
      }
    }
  };

  useEffect(() => {
    const checkUserInfo = async () => {
      if (!isLoggedIn && userInfo && userInfo.token) {
        let response = await dispatch(validateUserToken(userInfo.token));
        if (response && response.payload && response.payload.error) {
          setInvaliduser(true);
        }
      }
      if (isLoggedIn) {
        setisLoading(true);
        _fetch_country_list_record();
      } else {
        history.replace(APP_ROUTES.LOGIN);
      }
    };
    isLoggedIn !== undefined && checkUserInfo();
  }, [isLoggedIn]);

  useEffect(() => {
    const update_country_value = () => {
      let selected_country_obj = null;
      let storedCountry = localStorage.getItem(CURRENT_COUNTRY) ? JSON.parse(localStorage.getItem(CURRENT_COUNTRY)) : undefined;
      if (
        storedCountry !== undefined &&
        storedCountry !== null &&
        storedCountry.countryId !== undefined &&
        storedCountry.countryId !== "" &&
        userDetails !== undefined &&
        userDetails.userProfile !== undefined &&
        userDetails.userProfile.countryId !== undefined &&
        Array.isArray(userDetails.userProfile.countryId) &&
        userDetails.userProfile.countryId.includes(storedCountry.countryId)
      ) {
        selected_country_obj = storedCountry;
      } else {
        selected_country_obj = countries.data[0];

        if (
          userDetails !== undefined &&
          userDetails.userProfile !== undefined &&
          userDetails.userProfile.countryId !== undefined &&
          Array.isArray(userDetails.userProfile.countryId) &&
          userDetails.userProfile.countryId.length > 0
        ) {
          let userAccessedCountries = countries.data.filter((item) => userDetails.userProfile.countryId.includes(item.countryId));
          userAccessedCountries.forEach((country) => {
            if (country !== undefined && country.countryId !== undefined && countries.data.includes(country)) {
              selected_country_obj = country;
            }
          });
        }
        localStorage.setItem(CURRENT_COUNTRY, JSON.stringify(selected_country_obj));
      }

      selected_country_obj !== undefined && selected_country_obj.countryId !== undefined && selected_country_obj.countryId !== "" && fetch_application_record_list(selected_country_obj);
    };
    countries.data !== undefined && Array.isArray(countries.data) && countries.data.length > 0 && countrySettingsFetchStatus === API_RESPONSE_STATUS.IDLE && update_country_value();
  }, [countries.data, countries.data.length, countrySettingsFetchStatus]);

  useEffect(() => {
    if (
      isLoggedIn &&
      countries.fetchStatus !== API_RESPONSE_STATUS.IDLE &&
      serviceTypes.fetchStatus !== API_RESPONSE_STATUS.IDLE &&
      posTypes.fetchStatus !== API_RESPONSE_STATUS.IDLE &&
      regions.fetchStatus !== API_RESPONSE_STATUS.IDLE &&
      roles.fetchStatus !== API_RESPONSE_STATUS.IDLE &&
      additionalServices.fetchStatus !== API_RESPONSE_STATUS.IDLE
    ) {
      const _new_path_name =
        localStorage.getItem(NEW_PATH_SELECTED) !== undefined && localStorage.getItem(NEW_PATH_SELECTED) !== null && localStorage.getItem(NEW_PATH_SELECTED) !== ""
          ? localStorage.getItem(NEW_PATH_SELECTED)
          : pathname !== APP_ROUTES.LOGIN
          ? pathname
          : APP_ROUTES.POS_LIST;
      history.push(_new_path_name !== undefined && _new_path_name !== null && _new_path_name !== "" ? _new_path_name : APP_ROUTES.POS_LIST);
      if (userDetails !== undefined && userDetails.userProfile !== undefined) {
        setSideBarMenuItemsList(getTopBarMenuItems(userDetails.userProfile));
      }
      setSelectedIndex(getSelectedIndexOnFirstLoad(_new_path_name));
      setisLoading(false);
    }
  }, [isLoggedIn, countries.fetchStatus && serviceTypes.fetchStatus && posTypes.fetchStatus && regions.fetchStatus && roles.fetchStatus && additionalServices.fetchStatus]);

  return (
    <>
      {!inValiduser && isLoading ? (
        <Loader />
      ) : (
        <div className={classes.root} data-test="AppComponent">
          {userDetails !== undefined &&
            userDetails.userProfile !== undefined &&
            userDetails.userProfile.role !== undefined &&
            userDetails.userProfile.role !== "" &&
            pathname !== undefined &&
            pathname !== APP_ROUTES.LOGIN && (
              <>
                <TopMenu handleListItemClick={handleListItemClick} />
                <SideMenu
                  openSideMenu={openSideMenu}
                  classes={classes}
                  SideBarMenuItemsList={SideBarMenuItemsList}
                  mouseOverEventOnSideMenu={mouseOverEventOnSideMenu}
                  selectedIndex={selectedIndex}
                  handleListItemClick={handleListItemClick}
                />
              </>
            )}
          <main className={classes.content} onMouseOver={mouseOverEventOnMainArea}>
            <div className={classes.mainRouteDiv}>
              <Switch>
                <Route exact path={APP_ROUTES.LOGIN} component={SSOLoginPage} />
                {/* POS routes */}
                {/*  */}
                <PrivateRoute path={`${APP_ROUTES.POS_LIST}`} component={POSList} />
                <CountryRoute path={`${APP_ROUTES.COUNTRY_SETTINGS}/:countryName`} component={CountrySettings} userProfile={userDetails} />
                <PosRoute path={`${APP_ROUTES.CREATE_POS}`} component={CreatePOS} userRole={userDetails?.userProfile?.role} />
                <PosRoute path={`${APP_ROUTES.COMPLETE_SETUP}/:posNo?`} component={CompleteSetup} userRole={userDetails?.userProfile?.role} />
                {/* Rule Engine Routes */}
                {/*  */}
                <RuleEngineRoute exact path={`${APP_ROUTES.RULE_ENGINE}`} component={CurrentConfiguration} userProfile={userDetails} />
                <RuleEngineRoute exact path={`${APP_ROUTES.RULE_ENGINE_CURRENT_CONFIGURATION}`} component={RuleEngine} userProfile={userDetails} />
                {/* User Routes */}
                {/*  */}
                <PrivateRoute exact path={`${APP_ROUTES.USER_MANAGEMENT}/:user?`} component={UserManagementPage} />
                {/* Delivery Fee  */}
                {/*  */}
                <DeliveryFeeRoute exact path={`${APP_ROUTES.DELIVERY_FEE}`} component={DeliveryFeePage} userRole={userDetails?.userProfile?.role} />
                <DeliveryFeeRoute exact path={`${APP_ROUTES.DELIVERY_FEE_CONFIGURATION}`} component={AddUpdateDlvFeeConfigPage} userRole={userDetails?.userProfile?.role} />
                <DeliveryFeeRoute exact path={`${APP_ROUTES.DELIVERY_FEE_PREVIEW_CONFIGURATION}`} component={ConfigurationPreview} userRole={userDetails?.userProfile?.role} />
                {/* Single Use Plastic */}
                {/*  */}
                <PrivateRoute exact path={`${APP_ROUTES.SINGLE_USE_PLASTIC}`} component={SingleUsePlasticPage} />
                <PrivateRoute exact path={`${APP_ROUTES.SINGLE_USE_PLASTIC_CURRENT_CONFIGURATION}`} component={PlasticBagCurrentCofiguration} />
                <PrivateRoute exact path={`${APP_ROUTES.EDIT_SINGLE_USE_PLASTIC_CURRENT_CONFIGURATION}`} component={EditPlasticBagConfiguration} history={history} />
              </Switch>
            </div>
          </main>
        </div>
      )}
      {/* {showTopMenu ? <TopMenu /> : null} */}
      {/* {areAllConfigsFetched || !userInfo || inValiduser ? <></> : <Loader />} */}
      <ToastContainer position="top-center" transition={Slide} hideProgressBar closeButton={ToastCloseIcon} autoClose={5000} />
    </>
  );
};

export default React.memo(App);
