import { ALL_DAYS_VALUES } from "../utils/constants";

export interface DefaultServiceProps {
  serviceTypeId: string;
  serviceTypeName: string;
  isPOSMappingAreaBased: boolean;
}

export interface DEFAULTRANGEGENERATORFORM {
  minimumDays: string;
  maximumDays: string;
  bufferTime: any;
  defaultSlotCapacity: string;
  days: any;
  generatedRange: any;
  generatedSlots?: any;
}

export const DEFAULT_RANGE_GENERATOR_FORM_VALUES: DEFAULTRANGEGENERATORFORM = {
  minimumDays: "",
  maximumDays: "",
  bufferTime: "",
  defaultSlotCapacity: "",
  days: [],
  generatedRange: [],
  generatedSlots: [],
};

export const DEFAULT_CONFIG_TAB = {
  zoneName: "",
  foodTypes: ["NON_FOOD"],
  slotsGeneratorForms: {
    carrefour: [
      {
        ...DEFAULT_RANGE_GENERATOR_FORM_VALUES,
        days: ALL_DAYS_VALUES,
      },
    ],
    supplier: [
      {
        ...DEFAULT_RANGE_GENERATOR_FORM_VALUES,
        days: ALL_DAYS_VALUES,
      },
    ],
  },
  selectedAreas: [],
};

export interface slotsGeneratorFormsnterface {
  carrefour: Array<DEFAULTRANGEGENERATORFORM>;
  supplier: Array<DEFAULTRANGEGENERATORFORM>;
}
export interface ConfigTabsState {
  zoneName: string;
  foodTypes: Array<string>;
  slotsGeneratorForms: slotsGeneratorFormsnterface;
  selectedAreas?: Array<any>;
  zoneId?: string;
  isCreating: boolean;
  isUpdating: boolean;
}

export const tabInitValues = [
  {
    zoneName: "Zone01",
    foodTypes: ["NON_FOOD"],
    selectedAreas: [],
    isCreating: false,
    isUpdating: false,
    slotsGeneratorForms: {
      carrefour: [
        {
          ...DEFAULT_RANGE_GENERATOR_FORM_VALUES,
          days: ALL_DAYS_VALUES,
        },
      ],
      supplier: [
        {
          ...DEFAULT_RANGE_GENERATOR_FORM_VALUES,
          days: ALL_DAYS_VALUES,
        },
      ],
    },
  },
];

export const currentAllowedDeliveryTypes = ["carrefour", "supplier"];
