import { Checkbox, FormControlLabel, Grid, Typography } from "@material-ui/core";
import SelectBox from "../../../components/common/SelectBox/SelectBox";
import { generateDropdownItems } from "../../../utils/helperFunctions";
import { DEFAULT_RULE_CONFIG } from "../RuleEngineUtils";
import { DEFAULT_TIME_HOURS } from "./ServiceRuleEngine";

interface ChangeConfigOtionProps {
  config: DEFAULT_RULE_CONFIG;
  classes: any;
  checkChangeHandler: any;
  checked: boolean;
  changeExisting?: boolean;
  xsValue?: any;
  onConfigEdit: any;
}

export const ChangeConfigOtion = (props: ChangeConfigOtionProps) => {
  const { config, classes, checkChangeHandler, checked, xsValue, changeExisting, onConfigEdit } = props;
  return config.isEditMode ? (
    <Grid item xs={xsValue ? xsValue : 3}>
      <FormControlLabel
        control={<Checkbox className={classes.sameAsEarlierCheckbox} checked={changeExisting} onChange={onConfigEdit} />}
        label={<Typography className={classes.checkboxLabel}>Change existing configuration</Typography>}
      />
    </Grid>
  ) : (
    <Grid item xs={xsValue ? xsValue : 3}>
      <FormControlLabel
        control={<Checkbox className={classes.sameAsEarlierCheckbox} checked={checked} onChange={checkChangeHandler} />}
        label={<Typography className={classes.checkboxLabel}>Use existing configuration</Typography>}
      />
    </Grid>
  );
};

export const getDefaultOrFixedTimeMinutes = (defaultOrFixedTimeHours: any) => {
  let minutesItems = [];

  if (!defaultOrFixedTimeHours) {
    minutesItems = generateDropdownItems(15, 59, "minutes");
    return minutesItems;
  }

  minutesItems = generateDropdownItems(0, 59, "minutes");
  return minutesItems;
};

interface DefaultTimeRuleProps {
  config: DEFAULT_RULE_CONFIG;
  classes: any;
  onDefaultTimeIsSameAsEarlierChange: any;
  onDefaultOrFixedMinuteChange: any;
  onDefaultOrFixedHourhange: any;
  onDefaultTimeConfigEdit: any;
}

export const DefaultTimeRule = (props: DefaultTimeRuleProps) => {
  const { config, classes, onDefaultTimeIsSameAsEarlierChange, onDefaultOrFixedMinuteChange, onDefaultOrFixedHourhange, onDefaultTimeConfigEdit } = props;

  return (
    <Grid container className={classes.ruleContainer} spacing={2}>
      <Grid item xs={8} className={classes.ruleLabel}>
        {config.dynamicPromise ? "Default Time" : " Fixed Time Promise"}
      </Grid>
      {config.dynamicPromise || config.isEditMode ? (
        <ChangeConfigOtion
          config={config}
          checked={config.defaultOrFixedTimeSameAsEarlier}
          classes={classes}
          xsValue={4}
          checkChangeHandler={onDefaultTimeIsSameAsEarlierChange}
          changeExisting={config.enableDefaultTimeEdit}
          onConfigEdit={onDefaultTimeConfigEdit}
        />
      ) : (
        <Grid item xs={4} />
      )}

      {(!config.defaultOrFixedTimeSameAsEarlier || config.isEditMode || !config.dynamicPromise) && (
        <>
          <Grid item xs={4}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <SelectBox label="Hours" menuItems={DEFAULT_TIME_HOURS} value={config.defaultOrFixedTimeHours} handleChange={onDefaultOrFixedHourhange} disabled={!config.enableDefaultTimeEdit} />
              </Grid>
              <Grid item xs={6}>
                <SelectBox
                  label="Minutes"
                  menuItems={getDefaultOrFixedTimeMinutes(config.defaultOrFixedTimeHours)}
                  value={config.defaultOrFixedTimeMinutes}
                  handleChange={onDefaultOrFixedMinuteChange}
                  disabled={!config.enableDefaultTimeEdit}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};
