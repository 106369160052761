import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import { isEmptyOrNull } from "utils/DataUtils";
import { FOOD_SUB_TYPES, PRODUCT_TYPES } from "../../../../config/constants";
import { exclusionMessage, subExclusionMessage } from '../../../../utils/MessageUtils';
import { foodSplitRegex } from "../../utils/constants";
import productTypeStyles from "./ProductTypeStyles";


const ProductType = ({
  isProductTypeDisabled,
  foodType,
  foodSubType,
  error,
  onFoodTypeChange,
  onSubFoodTypeChange,
}) => {
  const selectedFoodType = isEmptyOrNull(foodType) ? [] : foodType.split(foodSplitRegex);
  let selectedSubFoodType = isEmptyOrNull(foodSubType) ? [] : foodSubType.split(foodSplitRegex);
  const classes = productTypeStyles();

  const handleFoodTypeChange = (event) => {
    const { value, checked } = event.target;
    onFoodTypeChange(value, checked);
  };

  const handleSubFoodTypeChange = (event, checked) => {
    const { value } = event.target;
    if (!checked) {
      selectedSubFoodType = selectedSubFoodType.filter((item) => item !== value);
    } else {
      selectedSubFoodType.push(value);
    }

    onSubFoodTypeChange(selectedSubFoodType.join(foodSplitRegex));
  };


  return (
    <Accordion className={classes.accordion} defaultExpanded={true}>
      <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.accordionTitle}>{exclusionMessage}</Typography>
        {/* <Typography className={classes.accordionSubTitle}>{subExclusionMessage}</Typography> */}
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Typography className={classes.accordionSubTitle}>{subExclusionMessage}</Typography>
        <div>
          <FormControl component="fieldset">
            <FormGroup>
              <div className={classes.dFlex}>
                <FormControlLabel
                  control={
                    <Checkbox
                      // set checked if any sub food item is selected
                      checked={selectedFoodType.includes(PRODUCT_TYPES.FOOD) && selectedSubFoodType.length > 0}
                      // indeterminate={selectedFoodType.includes(PRODUCT_TYPES.FOOD) && selectedSubFoodType.length < 3}
                      onChange={handleFoodTypeChange}
                      value={PRODUCT_TYPES.FOOD}
                    // Enable both food type in Edit mode for exculsion logic
                    // disabled={!!isProductTypeDisabled}
                    />
                  }
                  label={<Typography className={classes.checkboxLabel}>Food</Typography>}
                />
                {selectedFoodType.includes(PRODUCT_TYPES.FOOD) ? (
                  <div className={classes.dFlex}>
                    <div className={classes.vertical} />
                    {FOOD_SUB_TYPES.map((subType) => (
                      <FormControlLabel
                        key={subType.value}
                        className={classes.foodSubTypeCheckbox}
                        control={
                          <Checkbox
                            name={subType.name}
                            value={subType.value}
                            checked={!!selectedSubFoodType.includes(subType.value)}
                            onChange={handleSubFoodTypeChange}
                          />
                        }
                        label={<Typography className={classes.checkboxLabel}>{subType.label}</Typography>}
                      />
                    ))}
                  </div>
                ) : null}
              </div>
              <FormControlLabel
                className={classes.nonFood}
                control={
                  <Checkbox
                    checked={selectedFoodType.includes(PRODUCT_TYPES.NON_FOOD)}
                    onChange={handleFoodTypeChange}
                    value={PRODUCT_TYPES.NON_FOOD}
                  // Enable both food type in Edit mode for exculsion logic
                  // disabled={!!isProductTypeDisabled}
                  />
                }
                label={<Typography className={classes.checkboxLabel}>Non Food</Typography>}
              />
            </FormGroup>
          </FormControl>
          {error && isEmptyOrNull(foodType) && <div className={classes.error}>{error}</div>}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(ProductType);
