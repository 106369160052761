import { makeStyles } from "@material-ui/core";

const slotsGeneratorFormStyles = makeStyles(() => ({
  whiteBackground: { marginTop: "12px", backgroundColor: "#FFFFFF !important" },
  greyBackground: { marginTop: "12px", backgroundColor: "#F9F9F9" },
  generatedSlotsAccordionDetails: {
    padding: "0px 24px 16px 24px",
    display: "block",
  },
  dayStyles: {
    fontWeight: 600,
    paddingLeft: "5px",
    textTransform: "capitalize",
  },
  isSlotUpdatedIconStyle: { marginLeft: "8px" },
  error: {
    marginTop: "5px",
    fontSize: "12px",
    color: "red",
  },
  slotsTableLabel: {
    fontWeight: 600,
  },
  slotsAccordion: {
    padding: "4px 24px !important",
    "&.MuiAccordionSummary-content": {
      "&.Mui-expanded": {
        margin: "16px 0px",
      },
    },
  },
  addNewSlot: {
    padding: "0px",
  },
  slotsContainer: {
    marginTop: "18px",
  },
}));

export default slotsGeneratorFormStyles;
