import { makeStyles } from "@material-ui/core";

const previewModalStyles = makeStyles((theme) => ({
  modal: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
  modalContent: {
    border: "none",
    width: "90%",
    backgroundColor: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "24px",
    borderRadius: "8px",
    height: "80vh",
    overflow: "auto",
    "&:focus": {
      outline: "none",
    },
  },

  modalContentWindow : {width: "40% !important",  height: "80vh",},
  applyInfoModalBodyContent: {
    paddingTop: "8px",
    fontSize: "14px",
    lineHeight: "17px",
    display: "flex",
    justifyContent: "flex-start",
  },
  closeButton: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  closeButtonSVG: {
    cursor: "pointer",
    fill: theme.palette.primary.main,
  },
  modalTitle: {
    fontSize: "24px",
    fontWeight: 600,
    // textAlign: "center",
  },
  modalBody: {
    // padding: "24px",
    padding: "24px 0px",
  },
  modalFooter: {
    marginTop: "24px",
  },
  buttonStyles: {
    minWidth: "100%",
  },
}));

export default previewModalStyles;
