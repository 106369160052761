import { isEmptyOrNull } from "../../utils/DataUtils";
import { convertSecondsIntoMinutes } from "../../utils/helperFunctions";
import CustomAlert from "../common/CustomAlert/CustomAlert";
import { ALERT_TYPES, APP_ROUTES, SCALABLE_OR_NPN_SCALABLE_ITEMS, SERVICE_TYPES, SERVICE_TYPES_KEYS } from "./../../config/constants";
import { convertWeightInGramsToKiloGrams } from "./CurrentConfiguration/CurrentConfigUtils";

interface WeightRule {
  active: boolean;
  sameAsEarlier: boolean;
  incrementalTime: any;
  thresholdWeight: string;
}

export interface DEFAULT_RULE_CONFIG {
  serviceType: SERVICE_TYPES_KEYS;
  dynamicPromise: boolean;
  defaultOrFixedTimeHours: string;
  defaultOrFixedTimeMinutes: string;
  defaultOrFixedTime: number;
  defaultOrFixedTimeSameAsEarlier: boolean;
  posZoneIdentifierList: Array<any>;
  weightRule: WeightRule;
  isEditMode: boolean;
  enableDefaultTimeEdit: boolean;
  enableWeightRuleEdit: boolean;
  enableCountRuleEdit: boolean;
  enableScaleRuleEdit: boolean;
  countRule: {
    active: boolean;
    sameAsEarlier: boolean;
    incrementalTime: string;
    thresholdCount: string;
  };
  scalableTypeRule: {
    active: boolean;
    sameAsEarlier: boolean;
    itemList: Array<any>;
  };
  vehicleTypeRule: {
    active: boolean;
    sameAsEarlier: boolean;
    itemList: Array<any>;
  };
  status: string;
}

export const DEFAULT_RULE_CONFIG_STATE: DEFAULT_RULE_CONFIG = {
  serviceType: SERVICE_TYPES.STANDARD,
  isEditMode: false,
  dynamicPromise: true,
  defaultOrFixedTimeHours: "",
  defaultOrFixedTimeMinutes: "",
  defaultOrFixedTime: 0,
  defaultOrFixedTimeSameAsEarlier: true,
  posZoneIdentifierList: [],
  enableDefaultTimeEdit: true,
  enableWeightRuleEdit: true,
  enableCountRuleEdit: true,
  enableScaleRuleEdit: true,
  weightRule: {
    active: false,
    sameAsEarlier: true,
    incrementalTime: "",
    thresholdWeight: "",
  },
  countRule: {
    active: false,
    sameAsEarlier: true,
    incrementalTime: "",
    thresholdCount: "",
  },
  scalableTypeRule: {
    active: false,
    sameAsEarlier: true,
    itemList: [],
  },
  vehicleTypeRule: {
    active: false,
    sameAsEarlier: true,
    itemList: [],
  },
  status: "ACTIVATED",
};

export const getRuleConfig = (isPromiseChnaged: boolean, dynamicPromise: boolean, currentState: DEFAULT_RULE_CONFIG) => {
  let engineRule: any = {};
  if (isPromiseChnaged) {
    if (dynamicPromise) {
      engineRule = {
        weightRule: currentState.weightRule,
        countRule: currentState.countRule,
        scalableTypeRule: currentState.scalableTypeRule,
      };
    } else {
      engineRule = {
        weightRule: DEFAULT_RULE_CONFIG_STATE.weightRule,
        countRule: DEFAULT_RULE_CONFIG_STATE.countRule,
        scalableTypeRule: DEFAULT_RULE_CONFIG_STATE.scalableTypeRule,
      };
    }
  } else {
    engineRule = {
      weightRule: currentState.weightRule,
      countRule: currentState.countRule,
      scalableTypeRule: currentState.scalableTypeRule,
    };
  }
  return engineRule;
};

export const getConfiguredData = (Info: any) => {
  let minutes = Math.floor(Info.defaultOrFixedTime / 60);
  let hour: any = Math.floor(minutes / 60);
  let mins: any = minutes % 60;
  return {
    isEditMode: true,
    serviceType: Info.serviceType,
    dynamicPromise: Info.dynamicPromise,
    defaultOrFixedTimeHours: hour,
    defaultOrFixedTimeMinutes: mins,
    defaultOrFixedTime: Info.defaultOrFixedTime,
    enableDefaultTimeEdit: false,
    enableWeightRuleEdit: false,
    enableCountRuleEdit: false,
    enableScaleRuleEdit: false,
    weightRule: {
      active: Info.rules_data.weightRule.active,
      incrementalTime: convertSecondsIntoMinutes(Info.rules_data.weightRule.incrementalTime),
      // sameAsEarlier: Info.rules_data.weightRule.sameAsEarlier,
      sameAsEarlier: false,
      thresholdWeight: convertWeightInGramsToKiloGrams(Info.rules_data.weightRule.thresholdWeight),
    },
    countRule: {
      active: Info.rules_data.countRule.active,
      incrementalTime: Info.rules_data.countRule.incrementalTime,
      // sameAsEarlier: Info.rules_data.countRule.sameAsEarlier,
      sameAsEarlier: false,
      thresholdCount: Info.rules_data.countRule.thresholdCount,
    },
    scalableTypeRule: {
      active: Info.rules_data.scalableTypeRule.active,
      // sameAsEarlier: Info.rules_data.scalableTypeRule.sameAsEarlier,
      sameAsEarlier: false,
      itemList:
        (Info.rules_data.scalableTypeRule.itemList &&
          Info.rules_data.scalableTypeRule.itemList.map((element: any) => ({
            ...element,
            incrementalTime: convertSecondsIntoMinutes(element.incrementalTime),
          }))) ||
        [],
    },
    posZoneIdentifierList: [
      {
        posNo: Info.posNo,
        posName: Info.posName,
        zoneId: Info.zoneId,
        zoneName: Info.zoneName,
        // posNoPosNameZoneName: "POS582-dos582-Zone01",
        // value: 93854,
        // label: "POS582-dos582-Zone01"
      },
    ],
  };
};

export const BREADCRUMB_LINKS = [
  {
    name: "Delivery Promise Rule Engine",
    to: APP_ROUTES.RULE_ENGINE,
    isCurrentRoute: true,
  },
];

export const convertHoursOrMinutesToSeconds = (time: any, unit: string) => {
  let timeInSeconds = 0;
  switch (unit) {
    case "hours":
      timeInSeconds = time * 60 * 60;
      break;
    case "minutes":
      timeInSeconds = time * 60;
      break;

    default:
      break;
  }
  return timeInSeconds;
};

export const convertWeightInKiloGramsToGrams = (weightInGrams: any = 0) => {
  return weightInGrams * 1000;
};

export function valiadteRuleEngineData(expressRuleConfig: DEFAULT_RULE_CONFIG) {
  const itemLength = expressRuleConfig.scalableTypeRule.itemList.length;
  let scalableItemError =
    expressRuleConfig.scalableTypeRule.active &&
    (expressRuleConfig.scalableTypeRule.itemList.some(
      (item: any) =>
        // ((isEmptyOrNull(item.section) && !isEmptyOrNull(item.incrementalTime)) || (!isEmptyOrNull(item.section) && isEmptyOrNull(item.incrementalTime)))
        isEmptyOrNull(item.section) || isEmptyOrNull(item.incrementalTime)
    ) ||
      itemLength === 0);
  if (!expressRuleConfig.dynamicPromise) {
    if (isEmptyOrNull(expressRuleConfig.defaultOrFixedTimeHours) && isEmptyOrNull(expressRuleConfig.defaultOrFixedTimeMinutes)) {
      CustomAlert(ALERT_TYPES.ERROR, "Please enter fixed time promise");
      return;
      // eslint-disable-next-line
    } else if (!isEmptyOrNull(expressRuleConfig.defaultOrFixedTimeHours) && expressRuleConfig.defaultOrFixedTimeHours == "0" && isEmptyOrNull(expressRuleConfig.defaultOrFixedTimeMinutes)) {
      CustomAlert(ALERT_TYPES.ERROR, "Please enter fixed time promise");
      return;
    } else if (!expressRuleConfig.posZoneIdentifierList.length) {
      CustomAlert(ALERT_TYPES.ERROR, "Please select pos zones from POS Zone Mapping to proceed");
      return;
    }
  } else {
    if (expressRuleConfig.isEditMode) {
      if (isEmptyOrNull(expressRuleConfig.defaultOrFixedTimeHours) && isEmptyOrNull(expressRuleConfig.defaultOrFixedTimeMinutes)) {
        CustomAlert(ALERT_TYPES.ERROR, "Please enter fixed time promise");
        return;
        // eslint-disable-next-line
      } else if (!isEmptyOrNull(expressRuleConfig.defaultOrFixedTimeHours) && expressRuleConfig.defaultOrFixedTimeHours == "0" && isEmptyOrNull(expressRuleConfig.defaultOrFixedTimeMinutes)) {
        CustomAlert(ALERT_TYPES.ERROR, "Please enter fixed time promise");
        return;
      } else if (!expressRuleConfig.posZoneIdentifierList.length) {
        CustomAlert(ALERT_TYPES.ERROR, "Please select pos zones from POS Zone Mapping to proceed");
        return;
      }
    }

    if (!expressRuleConfig.defaultOrFixedTimeSameAsEarlier && isEmptyOrNull(expressRuleConfig.defaultOrFixedTimeHours) && isEmptyOrNull(expressRuleConfig.defaultOrFixedTimeMinutes)) {
      CustomAlert(ALERT_TYPES.ERROR, "Please enter fixed time promise");
      return;
      // eslint-disable-next-line
    } else if (
      !expressRuleConfig.defaultOrFixedTimeSameAsEarlier &&
      !isEmptyOrNull(expressRuleConfig.defaultOrFixedTimeHours) &&
      expressRuleConfig.defaultOrFixedTimeHours == "0" &&
      isEmptyOrNull(expressRuleConfig.defaultOrFixedTimeMinutes)
    ) {
      CustomAlert(ALERT_TYPES.ERROR, "Please enter fixed time promise");
      return;
    } else if (
      expressRuleConfig.weightRule.active &&
      (isEmptyOrNull(expressRuleConfig.weightRule.thresholdWeight) || isEmptyOrNull(expressRuleConfig.weightRule.incrementalTime))
      // && ((!isEmptyOrNull(expressRuleConfig.weightRule.thresholdWeight) && isEmptyOrNull(expressRuleConfig.weightRule.incrementalTime)) ||
      //     (isEmptyOrNull(expressRuleConfig.weightRule.thresholdWeight) && !isEmptyOrNull(expressRuleConfig.weightRule.incrementalTime)))
    ) {
      if (isEmptyOrNull(expressRuleConfig.weightRule.thresholdWeight)) {
        CustomAlert(ALERT_TYPES.ERROR, "Please enter item count threshold weight limit");
        return;
      } else if (isEmptyOrNull(expressRuleConfig.weightRule.incrementalTime)) {
        CustomAlert(ALERT_TYPES.ERROR, "Please enter incremental time above weight threshold");
        return;
      }
    } else if (
      expressRuleConfig.countRule.active &&
      (isEmptyOrNull(expressRuleConfig.countRule.thresholdCount) || isEmptyOrNull(expressRuleConfig.countRule.incrementalTime))
      // ((isEmptyOrNull(expressRuleConfig.countRule.thresholdCount) && !isEmptyOrNull(expressRuleConfig.countRule.incrementalTime)) ||
      //     (!isEmptyOrNull(expressRuleConfig.countRule.thresholdCount) && isEmptyOrNull(expressRuleConfig.countRule.incrementalTime)))
    ) {
      if (isEmptyOrNull(expressRuleConfig.countRule.thresholdCount)) {
        CustomAlert(ALERT_TYPES.ERROR, "Please enter item count threshold limit");
        return;
      } else if (isEmptyOrNull(expressRuleConfig.countRule.incrementalTime)) {
        CustomAlert(ALERT_TYPES.ERROR, "Please enter incremental time above threshold");
        return;
      }
    } else if (scalableItemError) {
      if (itemLength === 0) {
        CustomAlert(ALERT_TYPES.ERROR, "Please add products in type of Item");
        return;
      } else {
        for (let index = 0; index < itemLength; index++) {
          const element = expressRuleConfig.scalableTypeRule.itemList[index];
          if (isEmptyOrNull(element.section)) {
            CustomAlert(ALERT_TYPES.ERROR, "Please select products from catalog");
            return;
          } else if (isEmptyOrNull(element.incrementalTime)) {
            CustomAlert(ALERT_TYPES.ERROR, `Please enter incremental time for ${element.section}`);
            return;
          }
        }
      }
    } else if (!expressRuleConfig.posZoneIdentifierList.length) {
      CustomAlert(ALERT_TYPES.ERROR, "Please select pos zones from POS Zone Mapping to proceed");
      return;
    }
  }

  return true;
}

export function getUniqueList(selectedProduct: Array<any>, currentItem: any) {
  let selectedItem = selectedProduct && selectedProduct.map((element: any) => element.section);
  let uniqueItem = SCALABLE_OR_NPN_SCALABLE_ITEMS.filter((item: any) => !selectedItem.includes(item.id));
  if (currentItem && currentItem.section) {
    uniqueItem.push({
      id: currentItem.section,
      name: currentItem.section,
    });
  }
  return uniqueItem;
}
