import React from "react";

import { DateRangePicker } from "react-bootstrap-daterangepicker";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";

const CountryDefaultTypeDatePicker = ({ classes, defaultDateState, handleDateChangeEvent, handleCancel }) => {
  return (
  
    <DateRangePicker
      onEvent={handleDateChangeEvent}
      onCancel={handleCancel}
      initialSettings={{
        singleDatePicker: false,
        autoUpdateInput: false,
        cancelButtonClasses: "btn-primary",
        locale: { format: "YYYY-MM-DD", cancelLabel: "Clear" },
      }}
    >
      <input type="text" name="date" placeholder="Select Holiday(s)" onChange={handleDateChangeEvent} className={classes.dateSearchBoxOpen} value={defaultDateState.dateLabel} />
    </DateRangePicker>
  );
};

export default React.memo(CountryDefaultTypeDatePicker);
