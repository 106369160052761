import Fetch from "./Fetch";

import { API_HOST_URL, REACT_APP_DOS_RULE_ENGINE_API_URL } from "../config/constants";
import { handleError, handleResponse } from "./ApiUtils";

export const fetchShipmentTypeList = () => {
  return Fetch.get(`${REACT_APP_DOS_RULE_ENGINE_API_URL}/shipment-type`).then(handleResponse).catch(handleError);
};

export const fetchPosZoneServiceTypeList = (data) => {
  return Fetch.get(`${API_HOST_URL}/delivery-bag/pos-zone-service-mapping`, data).then(handleResponse).catch(handleError);
};

export const fetchDlvFeeCurrentConfigurationList = (data) => {
  return Fetch.get(`${REACT_APP_DOS_RULE_ENGINE_API_URL}/delivery-fee-config/country/${data.countryName}`, data.payload).then(handleResponse).catch(handleError);
};

export const postCreateNewDeliveryFeeconfig = (data) => {
  return Fetch.create(`${REACT_APP_DOS_RULE_ENGINE_API_URL}/delivery-fee-config/country/${data.name}`, data.payload_Object).then(handleResponse).catch(handleError);
};

export const postUpdateDeliveryFeeconfig = (data) => {
  return Fetch.update(`${REACT_APP_DOS_RULE_ENGINE_API_URL}/delivery-fee-config`, data.payload_Object).then(handleResponse).catch(handleError);
};

export const fetchDlvFeeConfigurationBasedOnPosAndZone = (data) => {
  return Fetch.get(`${REACT_APP_DOS_RULE_ENGINE_API_URL}/delivery-fee-config/${data.posNo}/${data.zone}`).then(handleResponse).catch(handleError);
};

export const fetchMultiShipmenthDlvFeeConfigurationList = (data) => {
  return Fetch.get(`${REACT_APP_DOS_RULE_ENGINE_API_URL}/delivery-fee-config/multi-shipment/country/${data.countryName}`).then(handleResponse).catch(handleError);
};

export const createOrUpdateMultiShipmentDeliveryFeeconfig = (data) => {
  return Fetch.create(`${REACT_APP_DOS_RULE_ENGINE_API_URL}/delivery-fee-config/multi-shipment/country/${data.countryName}`, data.payload_Object).then(handleResponse).catch(handleError);
};
