import React, { useState } from "react";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";

import LinkButton from "../../../common/LinkButton/LinkButton";
import OnDemandSlotInEditMode from "./OnDemandSlotInEditMode/OnDemandSlotInEditMode";

import { ReactComponent as CheckedIcon } from "../../../assets/svg/CheckedIcon.svg";

import editOnDemandSlotsStyles from "./EditOnDemandSlotsStyles";

const EditOnDemandSlots = ({
  tabIndex,
  formIndex,
  days,
  generatedSlots,
  updateOnDemandSlot,
  deleteOnDemandSlot,
  addNewOnDemandSlot,
  handleSaveNewOnDemandSlot,
}) => {
  const classes = editOnDemandSlotsStyles();

  const [isModified, setIsModified] = useState(false);

  const renderSlotsForDay = () => {
    return (
      <div>
        {`Slots for : `}
        <span className={classes.dayStyles}>{days[0].toLowerCase()}</span>
        {isModified ? <CheckedIcon className={classes.isSlotUpdatedIconStyle} /> : null}
      </div>
    );
  };

  const renderOnDemandSlots = () => {
    return (
      <>
        <div className={classes.slotsHeader}>
          <Grid container spacing={2}>
            {/* <Grid item xs={2}>
              Slots
            </Grid> */}
            <Grid item xs={3}>
              First customer order time
            </Grid>
            <Grid item xs={3}>
              Last order Cut-off Time
            </Grid>
            <Grid item xs={3}>
              Capacity
            </Grid>
            {/* <Grid item xs={1}></Grid> */}

            <Grid item xs={2}>
              Actions
            </Grid>
          </Grid>
        </div>

        <div className={classes.slotsContainer}>
          {generatedSlots.map((slot, index) => (
            <OnDemandSlotInEditMode
              tabIndex={tabIndex}
              formIndex={formIndex}
              slotIndex={index}
              key={slot.id}
              slot={slot}
              canSlotBeDeleted={!!(generatedSlots && generatedSlots.length !== 1)}
              handleSlotsModified={setIsModified}
              updateOnDemandSlot={updateOnDemandSlot}
              deleteOnDemandSlot={deleteOnDemandSlot}
              saveNewOnDemandSlot={handleSaveNewOnDemandSlot}
            />
          ))}
        </div>
      </>
    );
  };

  return (
    <Accordion defaultExpanded={false} className={classes.whiteBackground}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.slotsAccordion}>
        <Grid container>
          <Grid item xs={6}>
            {renderSlotsForDay()}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        {generatedSlots.length ? renderOnDemandSlots() : null}
        <LinkButton
          className={classes.addNewSlot}
          buttonLabel="Add New Slot"
          onClick={() => addNewOnDemandSlot(tabIndex, formIndex, days)}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default EditOnDemandSlots;
