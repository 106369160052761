import React from "react";

import { Accordion, AccordionDetails, AccordionSummary, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { getSingleUsePlasticTotalAreasListColumns, SERVICE_TYPE } from "components/common/CustomPosZoneMapping/Utils/CustomPosZoneMappingUtils";

interface PosZoneMappingPreviewConfigurationInterface {
  classes: any;
  state: any;
}

const PosZoneMappingPreviewConfiguration = (props: PosZoneMappingPreviewConfigurationInterface) => {
  const { classes, state } = props;
  const tableHeaderColumns = getSingleUsePlasticTotalAreasListColumns();
  tableHeaderColumns.shift();

  return (
    <Accordion defaultExpanded={true} className={classes.accordionStyle}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummaryStyle}>
        <Grid container alignItems="center">
          <Grid>
            <Typography className={classes.accordionTitle}>{"POS-Zone Mapping"}</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.previewAccordionDetailsStyle}>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaderColumns && Array.isArray(tableHeaderColumns) && tableHeaderColumns.length > 0 ? (
                tableHeaderColumns.map(
                  (header: any, headerIndex: any) =>
                    header.headerName !== SERVICE_TYPE && (
                      <TableCell key={`${headerIndex}-tableHeaderColumns-row-${headerIndex}`} align="left" padding="none" size="small" width={header.width} className={classes.tableDataRowHeaderCell}>
                        <Typography className={classes.dlvFeeValueDisplayTitleStyle}>{header.headerName}</Typography>
                      </TableCell>
                    )
                )
              ) : (
                <></>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {state &&
              state.selectedPosServiceTypeZone &&
              Array.isArray(state.selectedPosServiceTypeZone) &&
              state.selectedPosServiceTypeZone.length > 0 &&
              state.selectedPosServiceTypeZone.map((item: any) => (
                <TableRow key={`inner-posZoneServiceDataList-${"obj_" + item.posNo}`}>
                  <TableCell align="left" padding="none" size="small" width="20%" className={classes.tableDataRowCell}>
                    <Typography className={classes.dlvFeeValueDisplayDataStyle}>{item.posNo}</Typography>
                  </TableCell>
                  <TableCell align="left" padding="none" size="small" width="30%" className={classes.tableDataRowCell}>
                    <Typography className={classes.dlvFeeValueDisplayDataStyle}>{item.posName}</Typography>
                  </TableCell>
                  <TableCell align="left" padding="none" size="small" width="15%" className={classes.tableDataRowCell}>
                    <Typography className={classes.dlvFeeValueDisplayDataStyle}>{item.zoneName}</Typography>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(PosZoneMappingPreviewConfiguration);
