import React from "react";

import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { capitalizeFirstLetter } from "../../Utils/DeliveryFeeUtils";
import { ASSIGNED_AREAS, POS_ZONE_MAPPING } from "../../Utils/DeliveryFeeConstants";
import { TOTAL_AREAS } from "../../Utils/DeliveryFeeConstants";

import CustomPosZoneMapping from "../../../common/CustomPosZoneMapping/CustomPosZoneMapping";
import CustomPosZoneAssignedList from "../../../common/CustomPosZoneMapping/CustomPosZoneAssignedList";
interface DlvFeeConfigPosZoneMappingInterface {
  classes: any;
  dlvFeeConfigState: any;
  _handle_Pos_Zone_Search_Text_Change: any;
  _handle_update_pos_number_open_index: any;
  _handle_header_row_check_box_on_change: any;
  _handle_header_main_row_check_box_on_change: any;
  _handle_header_inner_row_check_box_on_change: any;
  _handle_delete_selected_row: any;
  openPosZonMapping: any;
  _handle_accordion_open_state: any;
}

const DlvFeeConfigPosZoneMapping = (props: DlvFeeConfigPosZoneMappingInterface) => {
  const {
    classes,
    dlvFeeConfigState,
    _handle_Pos_Zone_Search_Text_Change,
    _handle_update_pos_number_open_index,
    _handle_header_row_check_box_on_change,
    _handle_header_main_row_check_box_on_change,
    _handle_header_inner_row_check_box_on_change,
    _handle_delete_selected_row,
    openPosZonMapping,
    _handle_accordion_open_state,
  } = props;
  return (
    <Accordion expanded={openPosZonMapping} className={classes.accordionStyle} onChange={() => _handle_accordion_open_state("openPosZonMapping")}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummaryStyle}>
        <Grid container alignItems="center">
          <Grid>
            <Typography className={classes.accordionTitle}>{capitalizeFirstLetter(POS_ZONE_MAPPING)}</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetailsStyle}>
        <Grid className={classes.innerAccordionMaindiv}>
          <CustomPosZoneMapping
            title={TOTAL_AREAS}
            titleCount={dlvFeeConfigState.totalRowsCountData}
            fetchingList={dlvFeeConfigState.loading}
            searchTextValue={dlvFeeConfigState.posZoneSearchText}
            searhTextName="posZoneSearchText"
            handlePosSearchTextChange={_handle_Pos_Zone_Search_Text_Change}
            posZoneServiceDataList={dlvFeeConfigState.posZoneServiceRecord}
            selectedPosServices={dlvFeeConfigState.selectedPosServiceTypeZone}
            updatePosNumberOpenIndex={_handle_update_pos_number_open_index}
            headerRowCheckboxOnchange={_handle_header_row_check_box_on_change}
            headerMainRowCheckboxOnchange={_handle_header_main_row_check_box_on_change}
            headerInnerRowCheckboxOnchange={_handle_header_inner_row_check_box_on_change}
            totalRowsCountData={dlvFeeConfigState.totalRowsCountData}
            handleSearch={undefined}
          />
          <CustomPosZoneAssignedList
            title={ASSIGNED_AREAS}
            titleCount={dlvFeeConfigState.totalAssignedAreaCount}
            fetchingList={dlvFeeConfigState.loading}
            searchTextValue={dlvFeeConfigState.assignedPosSearchText}
            searhTextName="assignedPosSearchText"
            handlePosSearchTextChange={_handle_Pos_Zone_Search_Text_Change}
            selectedPosServices={dlvFeeConfigState.selectedPosServiceTypeZone}
            deleteSelectedRow={_handle_delete_selected_row}
          />
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(DlvFeeConfigPosZoneMapping);
