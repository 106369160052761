import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_RESPONSE_STATUS } from "../../../config/constants";
import * as DeliveryFeeService from "../../../api/DeliveryFeeService";

export const fetchShipmentTypeRecordList = createAsyncThunk("DeliveryFeeSlice/fetchShipmentTypeList", async (_, { rejectWithValue }) => {
  try {
    const { response }: any = await DeliveryFeeService.fetchShipmentTypeList();
    if (response && response.success && response.data && Array.isArray(response.data)) {
      return response.data;
    }
    return [];
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const fetchPosZoneServiceTypeListByCountryIdAndServiceType = createAsyncThunk("singleUsePlastic/fetchPosZoneServiceTypeList", async (data: any, { rejectWithValue }) => {
  try {
    const { response }: any = await DeliveryFeeService.fetchPosZoneServiceTypeList(data);
    if (response && response.data && response.data.content && Array.isArray(response.data.content)) {
      return response.data.content;
    }
    return [];
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const fetchDlvFeeConfigurationList = createAsyncThunk("singleUsePlastic/fetchDlvFeeCurrentConfigurationList", async (data: any, { rejectWithValue }) => {
  try {
    const { response }: any = await DeliveryFeeService.fetchDlvFeeCurrentConfigurationList(data);
    if (response && response.data) {
      return response.data;
    }
    return [];
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createNewDeliveryFeeconfig = createAsyncThunk("singleUsePlastic/postCreateNewDeliveryFeeconfig", async (data: any, { rejectWithValue }) => {
  try {
    const { response }: any = await DeliveryFeeService.postCreateNewDeliveryFeeconfig(data);
    if (response && response.success) {
      return response;
    }
    return [];
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateDeliveryFeeconfig = createAsyncThunk("singleUsePlastic/postUpdateDeliveryFeeconfig", async (data: any, { rejectWithValue }) => {
  try {
    const { response }: any = await DeliveryFeeService.postUpdateDeliveryFeeconfig(data);
    if (response && response.success) {
      return response;
    }
    return [];
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDlvFeeConfigurationBasedOnPosAndZone = createAsyncThunk("singleUsePlastic/fetchDlvFeeConfigurationBasedOnPosAndZone", async (data: any, { rejectWithValue }) => {
  try {
    const { response }: any = await DeliveryFeeService.fetchDlvFeeConfigurationBasedOnPosAndZone(data);
    if (response && response.data && typeof response.data === "object" && Object.keys(response.data).length > 0) {
      return response.data;
    }
    return [];
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const fetchMultiShipmenthDlvFeeConfigurationRecordList = createAsyncThunk("singleUsePlastic/fetchMultiShipmenthDlvFeeConfigurationList", async (data: any, { rejectWithValue }) => {
  try {
    const { response }: any = await DeliveryFeeService.fetchMultiShipmenthDlvFeeConfigurationList(data);
    if (response && response.data) {
      return response.data;
    }
    return [];
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createOrUpdateMultiShipmentDeliveryFeeconfigAction = createAsyncThunk("singleUsePlastic/createOrUpdateMultiShipmentDeliveryFeeconfig", async (data: any, { rejectWithValue }) => {
  try {
    const { response }: any = await DeliveryFeeService.createOrUpdateMultiShipmentDeliveryFeeconfig(data);
    if (response && response.success) {
      return response;
    }
    return [];
  } catch (error) {
    return rejectWithValue(error);
  }
});

const commonFetchInitialState = {
  fetchStatus: API_RESPONSE_STATUS.IDLE,
  error: "",
  data: [] as any,
  loading: false,
};

const DeliveryFeeSlice = createSlice({
  name: "deliveryFee",
  initialState: {
    shipmentTypeDetailsService: { ...commonFetchInitialState },
    posZoneListDetailsService: { ...commonFetchInitialState },
    dlvFeeConfigurationRecordService: { ...commonFetchInitialState },
    createNewDlvFeeConfiguration: { ...commonFetchInitialState },
    updateNewDlvFeeConfiguration: { ...commonFetchInitialState },
    dlvFeeSingleConfigurationRecordService: { ...commonFetchInitialState },
    multiShipmentDlvFeeConfigurationRecordService: { ...commonFetchInitialState },
    createOrUpdateMultishipmentDlvFeeConfiguration: { ...commonFetchInitialState },
  },
  reducers: {
    resetDlvFeeSingleConfigurationRecordServiceState: (state: any, action: any) => {
      state.dlvFeeSingleConfigurationRecordService = { ...commonFetchInitialState };
    },
  },
  extraReducers: (builders) => {
    builders
      /* fetchShipmentTypeRecordList */
      .addCase(fetchShipmentTypeRecordList.pending, (state: any) => {
        state.shipmentTypeDetailsService.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.shipmentTypeDetailsService.loading = true;
        state.shipmentTypeDetailsService.data = [];
        state.shipmentTypeDetailsService.error = "";
      })
      .addCase(fetchShipmentTypeRecordList.fulfilled, (state: any, action: any) => {
        state.shipmentTypeDetailsService.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.shipmentTypeDetailsService.loading = false;
        state.shipmentTypeDetailsService.data = action.payload;
      })
      .addCase(fetchShipmentTypeRecordList.rejected, (state: any, action: any) => {
        state.shipmentTypeDetailsService.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.shipmentTypeDetailsService.loading = false;
        state.shipmentTypeDetailsService.error = action.error.message;
      })
      /* fetchPosZoneServiceTypeListByCountryIdAndServiceType */
      .addCase(fetchPosZoneServiceTypeListByCountryIdAndServiceType.pending, (state: any) => {
        state.posZoneListDetailsService.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.posZoneListDetailsService.loading = true;
        state.posZoneListDetailsService.data = [];
        state.posZoneListDetailsService.error = "";
      })
      .addCase(fetchPosZoneServiceTypeListByCountryIdAndServiceType.fulfilled, (state: any, action: any) => {
        state.posZoneListDetailsService.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.posZoneListDetailsService.loading = false;
        state.posZoneListDetailsService.data = action.payload;
      })
      .addCase(fetchPosZoneServiceTypeListByCountryIdAndServiceType.rejected, (state: any, action: any) => {
        state.posZoneListDetailsService.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.posZoneListDetailsService.loading = false;
        state.posZoneListDetailsService.error = action.error.message;
      })
      /* fetchDlvFeeConfigurationList */
      .addCase(fetchDlvFeeConfigurationList.pending, (state: any) => {
        state.dlvFeeConfigurationRecordService.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.dlvFeeConfigurationRecordService.loading = true;
        state.dlvFeeConfigurationRecordService.data = [];
        state.dlvFeeConfigurationRecordService.error = "";
      })
      .addCase(fetchDlvFeeConfigurationList.fulfilled, (state: any, action: any) => {
        state.dlvFeeConfigurationRecordService.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.dlvFeeConfigurationRecordService.loading = false;
        state.dlvFeeConfigurationRecordService.data = action.payload;
      })
      .addCase(fetchDlvFeeConfigurationList.rejected, (state: any, action: any) => {
        state.dlvFeeConfigurationRecordService.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.dlvFeeConfigurationRecordService.loading = false;
        state.dlvFeeConfigurationRecordService.error = action.error.message;
      })
      /* createNewDeliveryFeeconfig */
      .addCase(createNewDeliveryFeeconfig.pending, (state: any) => {
        state.createNewDlvFeeConfiguration.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.createNewDlvFeeConfiguration.loading = true;
        state.createNewDlvFeeConfiguration.data = [];
        state.createNewDlvFeeConfiguration.error = "";
      })
      .addCase(createNewDeliveryFeeconfig.fulfilled, (state: any, action: any) => {
        state.createNewDlvFeeConfiguration.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.createNewDlvFeeConfiguration.loading = false;
        state.createNewDlvFeeConfiguration.data = action.payload;
      })
      .addCase(createNewDeliveryFeeconfig.rejected, (state: any, action: any) => {
        state.createNewDlvFeeConfiguration.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.createNewDlvFeeConfiguration.loading = false;
        state.createNewDlvFeeConfiguration.error = action.error.message;
      })
      /* updateDeliveryFeeconfig */
      .addCase(updateDeliveryFeeconfig.pending, (state: any) => {
        state.updateNewDlvFeeConfiguration.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.updateNewDlvFeeConfiguration.loading = true;
        state.updateNewDlvFeeConfiguration.data = [];
        state.updateNewDlvFeeConfiguration.error = "";
      })
      .addCase(updateDeliveryFeeconfig.fulfilled, (state: any, action: any) => {
        state.updateNewDlvFeeConfiguration.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.updateNewDlvFeeConfiguration.loading = false;
        state.updateNewDlvFeeConfiguration.data = action.payload;
      })
      .addCase(updateDeliveryFeeconfig.rejected, (state: any, action: any) => {
        state.updateNewDlvFeeConfiguration.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.updateNewDlvFeeConfiguration.loading = false;
        state.updateNewDlvFeeConfiguration.error = action.error.message;
      })
      /* getDlvFeeConfigurationBasedOnPosAndZone */
      .addCase(getDlvFeeConfigurationBasedOnPosAndZone.pending, (state: any) => {
        state.dlvFeeSingleConfigurationRecordService.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.dlvFeeSingleConfigurationRecordService.loading = true;
        state.dlvFeeSingleConfigurationRecordService.data = [];
        state.dlvFeeSingleConfigurationRecordService.error = "";
      })
      .addCase(getDlvFeeConfigurationBasedOnPosAndZone.fulfilled, (state: any, action: any) => {
        state.dlvFeeSingleConfigurationRecordService.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.dlvFeeSingleConfigurationRecordService.loading = false;
        state.dlvFeeSingleConfigurationRecordService.data = action.payload;
      })
      .addCase(getDlvFeeConfigurationBasedOnPosAndZone.rejected, (state: any, action: any) => {
        state.dlvFeeSingleConfigurationRecordService.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.dlvFeeSingleConfigurationRecordService.loading = false;
        state.dlvFeeSingleConfigurationRecordService.error = action.error.message;
      })
      /* fetchMultiShipmenthDlvFeeConfigurationRecordList */
      .addCase(fetchMultiShipmenthDlvFeeConfigurationRecordList.pending, (state: any) => {
        state.multiShipmentDlvFeeConfigurationRecordService.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.multiShipmentDlvFeeConfigurationRecordService.loading = true;
        state.multiShipmentDlvFeeConfigurationRecordService.data = [];
        state.multiShipmentDlvFeeConfigurationRecordService.error = "";
      })
      .addCase(fetchMultiShipmenthDlvFeeConfigurationRecordList.fulfilled, (state: any, action: any) => {
        state.multiShipmentDlvFeeConfigurationRecordService.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.multiShipmentDlvFeeConfigurationRecordService.loading = false;
        state.multiShipmentDlvFeeConfigurationRecordService.data = action.payload;
      })
      .addCase(fetchMultiShipmenthDlvFeeConfigurationRecordList.rejected, (state: any, action: any) => {
        state.multiShipmentDlvFeeConfigurationRecordService.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.multiShipmentDlvFeeConfigurationRecordService.loading = false;
        state.multiShipmentDlvFeeConfigurationRecordService.error = action.error.message;
      })
      /* createOrUpdateMultiShipmentDeliveryFeeconfigAction */
      .addCase(createOrUpdateMultiShipmentDeliveryFeeconfigAction.pending, (state: any) => {
        state.createOrUpdateMultishipmentDlvFeeConfiguration.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.createOrUpdateMultishipmentDlvFeeConfiguration.loading = true;
        state.createOrUpdateMultishipmentDlvFeeConfiguration.data = [];
        state.createOrUpdateMultishipmentDlvFeeConfiguration.error = "";
      })
      .addCase(createOrUpdateMultiShipmentDeliveryFeeconfigAction.fulfilled, (state: any, action: any) => {
        state.createOrUpdateMultishipmentDlvFeeConfiguration.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.createOrUpdateMultishipmentDlvFeeConfiguration.loading = false;
        state.createOrUpdateMultishipmentDlvFeeConfiguration.data = action.payload;
      })
      .addCase(createOrUpdateMultiShipmentDeliveryFeeconfigAction.rejected, (state: any, action: any) => {
        state.createOrUpdateMultishipmentDlvFeeConfiguration.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.createOrUpdateMultishipmentDlvFeeConfiguration.loading = false;
        state.createOrUpdateMultishipmentDlvFeeConfiguration.error = action.error.message;
      });
  },
});

export default DeliveryFeeSlice.reducer;

export const { resetDlvFeeSingleConfigurationRecordServiceState } = DeliveryFeeSlice.actions;
export const selectServiceTypePosZoneMapRecord = (state: any) => state.deliveryFee.posZoneListDetailsService;
export const selectShipmentTypeDetailsRecord = (state: any) => state.deliveryFee.shipmentTypeDetailsService;
export const selectDlvFeeConfigurationRecord = (state: any) => state.deliveryFee.dlvFeeConfigurationRecordService;
export const selectSingleDlvFeeConfigurationRecord = (state: any) => state.deliveryFee.dlvFeeSingleConfigurationRecordService;
export const selectMultiShipmentDlvFeeConfigurationRecord = (state: any) => state.deliveryFee.multiShipmentDlvFeeConfigurationRecordService;
