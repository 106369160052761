import { Divider, Grid, Modal, Typography } from "@material-ui/core";
import { AREA_POS_MAPPING_TYPE, EXPRESS_TYPES } from "../../../config/constants";
import { ReactComponent as CloseButton } from "../../assets/svg/closeButton.svg";
import PrimaryButton from "../../common/PrimaryButton/PrimaryButton";

interface CountryChangesConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  classes: any;
  standardDetails: any;
  expressDetails: any;
  clickCollectDetails: any;
  defaultDetails: any;
  areaPosMappingType: string;
  loading: boolean;
  handleSubmit: () => void;
  weeklyHolidays: any;
}

export const CountryChangesConfirmationModal = (props: CountryChangesConfirmationModalProps) => {
  const { open, onClose, classes, standardDetails = {}, expressDetails = {}, clickCollectDetails = {}, areaPosMappingType, loading, handleSubmit, defaultDetails = {}, weeklyHolidays } = props;
  return (
    <Modal open={open} onClose={onClose}>
      <div className={classes.applyChangesModalBody}>
        <div className={classes.closeModalButton} onClick={onClose}>
          {/* <img src={closeButton} alt="Modal close button" /> */}
          <CloseButton className={classes.closeModalIcon} />
        </div>
        <span className={classes.modalTitle}>Are you sure you want to apply the following changes? </span>
        <div className={classes.modalBody}>
          <Grid item>
            <Typography className={classes.applyModalBodyTitle}>Standard:</Typography>
            <Typography className={classes.applyModalBodyContent}>
              Next Day slot visibility: <div className={classes.selectedValuesData}>{standardDetails.nextDaySlotVisibility ? "On" : "Off"}</div>
            </Typography>
            <Typography className={classes.applyModalBodyContent}>
              Advance slot days:
              <div className={classes.selectedValuesData}>{standardDetails.editSlotDays}</div>
            </Typography>
            <Typography className={classes.applyModalBodyContent}>
              Plastic Bag Charge:
              <div className={classes.selectedValuesData}>{standardDetails.bagChargeEnabled ? "Enabled" : "Disabled"}</div>
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.applyModalBodyTitle}>Express:</Typography>
            <Grid container>
              <Grid item xs={6}>
                <Typography className={classes.applyModalBodyContent}>
                  Express Type:
                  <div className={classes.selectedValuesData}>{expressDetails.expressType === EXPRESS_TYPES.SLOT_BASED.value ? EXPRESS_TYPES.SLOT_BASED.label : EXPRESS_TYPES.ON_DEMAND.label}</div>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.applyModalBodyContent}>
                  Next Day slot visibility:
                  <div className={classes.selectedValuesData}>{expressDetails.nextDaySlotVisibility ? "On" : "Off"}</div>
                </Typography>
              </Grid>
            </Grid>
            <Typography className={classes.applyModalBodyContent}>
              Advance slot days:
              <div className={classes.selectedValuesData}>{expressDetails.editSlotDays}</div>{" "}
            </Typography>
            <Grid container>
              <Grid item xs={8}>
                <Typography className={classes.applyModalBodyContent}>
                  Plastic Bag Charge:
                  <div className={classes.selectedValuesData}>{expressDetails.bagChargeEnabled ? "Enabled" : "Disabled"}</div>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography className={classes.applyModalBodyTitle}>Click & Collect:</Typography>
            <Grid item xs={6}>
              <Typography className={classes.applyModalBodyContent}>
                Next Day slot visibility:
                <div className={classes.selectedValuesData}>{clickCollectDetails.nextDaySlotVisibility ? "On" : "Off"}</div>
              </Typography>
            </Grid>
            <Typography className={classes.applyModalBodyContent}>
              Advance slot days:
              <div className={classes.selectedValuesData}>{clickCollectDetails.editSlotDays}</div>
            </Typography>
            <Typography className={classes.applyModalBodyContent}>
              Plastic Bag Charge:
              <div className={classes.selectedValuesData}>{clickCollectDetails.bagChargeEnabled ? "Enabled" : "Disabled"}</div>
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.applyModalBodyTitle}>Default:</Typography>
            <Grid item xs={12}>
              <Typography className={classes.applyModalBodyContent}>
                Calendar Holidays:
                <div className={classes.selectedValuesData}>{defaultDetails.holidays?.map((info: any) => info.objectDate).join(", ")}</div>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.applyModalBodyContent}>
                Weekly Holidays:
                <div className={classes.selectedValuesData}>{weeklyHolidays.holidays?.map((info: any) => info).join(", ")}</div>
              </Typography>
            </Grid>
            <Typography className={classes.applyModalBodyContent}>
              Plastic Bag Charge:
              <div className={classes.selectedValuesData}>{defaultDetails.bagChargeEnabled ? "Enabled" : "Disabled"}</div>
            </Typography>
          </Grid>
          <Divider className={classes.dividerStyle} />
          <Grid item>
            <Typography className={classes.applyModalBodyTitle}>Area Assignment:</Typography>
            <Typography className={classes.applyModalBodyContent}>{areaPosMappingType === AREA_POS_MAPPING_TYPE.AREA_BASED ? "Area Based Assignment" : "Polygon Based Assignment"}</Typography>
          </Grid>
        </div>
        <div className={classes.modalButtons}>
          <Grid container spacing={2}>
            <Grid item xs={1}></Grid>
            <Grid item xs={5}>
              <PrimaryButton className={classes.buttonStyles} buttonLabel="CANCEL" buttonVariant="outlined" onClick={onClose} />
            </Grid>
            <Grid item xs={5}>
              <PrimaryButton className={classes.buttonStyles} buttonLabel={loading ? "loading..." : "PROCEED"} onClick={handleSubmit} disabled={loading} />
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
        </div>
      </div>
    </Modal>
  );
};

// CountryChangesConfirmationModal.defaultProps = {
//     standardDetails: {},
//     expressDetails: {},
//     clickCollectDetails: {}
// }
