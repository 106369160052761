import PrimaryButton from "../../common/PrimaryButton/PrimaryButton";
import { Link } from "react-router-dom";
import { APP_ROUTES, USER_TYPES } from "../../../config/constants";
import SingleUsePlasticCard from "../SingleUsePlasticCard";

interface SingleUsePlasticCardAccessInterface {
  userInfo: any;
  classes: any;
  usePlasticState: any;
  handleSearchChange: any;
  fetchNextRecord: any;
  handleSearchOnClick: any;
}

export const SingleUsePlasticCardAccess = (props: SingleUsePlasticCardAccessInterface) => {
  const { userInfo, classes, usePlasticState, handleSearchChange, fetchNextRecord, handleSearchOnClick } = props;
  return userInfo?.userProfile?.role !== "" ? (
    <SingleUsePlasticCard
      classes={classes}
      usePlasticState={usePlasticState}
      handleSearchChange={handleSearchChange}
      fetchNextRecord={fetchNextRecord}
      userInfo={userInfo}
      handleSearchOnClick={handleSearchOnClick}
    />
  ) : (
    <div className={classes.cardRoot}>
      <div className={classes.accessDiv}>
        <span className={classes.heading}>Access Denied</span>
      </div>
    </div>
  );
};

export const designPlsticBagRecordList = (record: any) => {
  if (record && Array.isArray(record) && record.length > 0) {
    let recordData: any = record.map((item: any) => ({
      ...item,
      id: item.posId,
    }));
    return recordData;
  }
  return [];
};

interface SingleUsePlasticCreateConfigInterface {
  userInfo: any;
  classes: any;
}

export const SingleUsePlasticCreateConfig = (props: SingleUsePlasticCreateConfigInterface) => {
  const { userInfo, classes } = props;
  return userInfo?.userProfile?.role !== USER_TYPES.READONLY_USER && userInfo?.userProfile?.role !== USER_TYPES.ADMIN ? (
    <Link to={APP_ROUTES.SINGLE_USE_PLASTIC_CURRENT_CONFIGURATION} style={{ textDecoration: "none" }}>
      <PrimaryButton className={classes.buttonStyles} buttonLabel="Create New Configuration" disableFocusRipple={true} disableRipple={true} />
    </Link>
  ) : (
    <></>
  );
};
