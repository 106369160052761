import { withStyles } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import MuiListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
// Constants
import { APP_ROUTES } from "../../../config/constants";
import CheckedIcon from "../../assets/svg/CheckedIcon.svg";
import DashboardActive from "../../assets/svg/DashboardActive.svg";
import DashboardMenu from "../../assets/svg/DashboardMenu.svg";
import IconArrowClose from "../../assets/svg/IconArrowClose.svg";
import ProductsMenu from "../../assets/svg/ProductsMenu.svg";
import ServiceActive from "../../assets/svg/Shape.svg";
// Styles
import sideBarStyles from "./SideBarStyles";

const ListItem = withStyles({
  root: {
    "&$selected": {
      backgroundColor: "#E9EFF2",
      color: "#0E5AA7",
      fill: "#0E5AA7",
    },
    "&$selected:hover": {
      backgroundColor: "#E9EFF2",
      color: "#0E5AA7",
      fill: "#0E5AA7",
    },
    "&:hover": {
      backgroundColor: "#E9EFF2",
      color: "#0E5AA7",
      fill: "#0E5AA7",
    },
  },
  selected: {},
})(MuiListItem);

function SideBar({ userInfo }) {
  const sideMenuClasses = sideBarStyles();
  const { pathname } = useLocation();

  const {
    posDetails: { services = [], posNo },
    isCreated,
    isUpdated,
  } = useSelector((state) => state.pos);
  let enableServiceType = services.filter((item) => userInfo?.userProfile.serviceTypeId.includes(item.id));

  const [openServices, setOpenServices] = useState(true);

  const handleServiceClick = (event) => {
    setOpenServices(!openServices);
  };

  return (
    <Drawer
      className={sideMenuClasses.drawer}
      variant="permanent"
      anchor="left"
      classes={{
        paper: sideMenuClasses.drawerPaper,
        paperAnchorLeft: sideMenuClasses.paperAnchorLeft,
      }}
    >
      <div className={sideMenuClasses.drawerContainer}>
        <List className={sideMenuClasses.listStyleMain}>
          <ListItem
            button
            key={"BASICDETAILS"}
            component={NavLink}
            to={`${APP_ROUTES.CREATE_POS}${APP_ROUTES.BASIC_DETAILS}`}
            className={sideMenuClasses.listStyle}
            selected={pathname.includes(APP_ROUTES.BASIC_DETAILS)}
          >
            <ListItemIcon>
              {pathname.includes(APP_ROUTES.BASIC_DETAILS) ? (
                <img src={DashboardActive} className={sideMenuClasses.listIconSVG} alt="dashboardActive" />
              ) : (
                <img src={DashboardMenu} className={sideMenuClasses.listIconSVG} alt="dashboard" />
              )}
            </ListItemIcon>
            <ListItemText primary={"BASIC DETAILS"} classes={{ primary: sideMenuClasses.listItemSpan }} />
            {isCreated || isUpdated ? <img src={CheckedIcon} alt="checked" /> : null}
          </ListItem>

          {enableServiceType && enableServiceType.length ? (
            <ListItem button key={"SERVICESLOTS"} className={sideMenuClasses.listStyle} onClick={handleServiceClick} selected={pathname.includes(APP_ROUTES.SERVICE_AND_SLOTS)}>
              <ListItemIcon>
                {pathname.includes(APP_ROUTES.SERVICE_AND_SLOTS) ? (
                  <img src={ServiceActive} className={sideMenuClasses.listIconSVG} alt="servicesActive" />
                ) : (
                  <img src={ProductsMenu} className={sideMenuClasses.listIconSVG} alt="services" />
                )}
              </ListItemIcon>
              <ListItemText primary={"SERVICE & SLOTS"} classes={{ primary: sideMenuClasses.listItemSpan }} />
              <img src={IconArrowClose} alt="listClose" className={`${sideMenuClasses.listIconArrow} ${openServices ? sideMenuClasses.rotateBy270 : ""}`} />
            </ListItem>
          ) : null}

          <Collapse in={openServices} timeout="auto" unmountOnExit>
            <List className={sideMenuClasses.nestedUl}>
              {enableServiceType && enableServiceType.length
                ? enableServiceType.map((service) =>
                    service !== undefined && service.name !== undefined ? (
                      service.name === "FBC" ? (
                        <ListItem key={service.id} className={sideMenuClasses.nested} disabled={true}>
                          <ListItemText primary={service.displayName.toUpperCase()} classes={{ primary: sideMenuClasses.listItemSpan }} />
                        </ListItem>
                      ) : (
                        <ListItem
                          key={service.id}
                          button
                          component={NavLink}
                          to={`${APP_ROUTES.CREATE_POS}${APP_ROUTES.SERVICE_AND_SLOTS}/${service.name.toLowerCase()}/${posNo}`}
                          className={sideMenuClasses.nested}
                          selected={pathname.includes(service.name.toLowerCase())}
                        >
                          <ListItemText primary={service.displayName.toUpperCase()} classes={{ primary: sideMenuClasses.listItemSpan }} />
                        </ListItem>
                      )
                    ) : (
                      <></>
                    )
                  )
                : null}
            </List>
          </Collapse>
        </List>
      </div>
    </Drawer>
  );
}

SideBar.prototype = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  handleListItemClick: PropTypes.func.isRequired,
};

export default React.memo(SideBar);
