export const APP_ROUTES = {
  HOME: "/login",
  LOGIN: "/login",
  DASHBOARD: "/dashboard",
  CREATE_POS: "/create-pos",
  SERVICE_AND_SLOTS: "/service-and-slots",
  BASIC_DETAILS: "/basic",
  STANDARD: "/standard",
  EXPRESS: "/express",
  CLICK_N_COLLECT: "/click_n_collect",
  DEFAULT: "/DEFAULT",
  COMPLETE_SETUP: "/complete-setup",
  SETUP_POS: "/setup-pos",
  POS_LIST: "/pos-list",
  COUNTRY_SETTINGS: "/country-settings",
  USER_MANAGEMENT: "/user-management",
  RULE_ENGINE: "/rule-engine",
  RULE_ENGINE_CURRENT_CONFIGURATION: "/rule-engine/configuration",
  DELIVERY_FEE: "/delivery-fee",
  DELIVERY_FEE_CONFIGURATION: "/delivery-fee/configuration",
  DELIVERY_FEE_PREVIEW_CONFIGURATION: "/delivery-fee/preview-configuration",
  SINGLE_USE_PLASTIC: "/single-use-plastic",
  SINGLE_USE_PLASTIC_CURRENT_CONFIGURATION: "/single-use-plastic/configuration",
  EDIT_SINGLE_USE_PLASTIC_CURRENT_CONFIGURATION: "/single-use-plastic/edit-configuration",
  APPROVAL_REQUESTS: "/approval-flow-request",
};

export const SERVICE_TYPES = {
  STANDARD: "STANDARD",
  EXPRESS: "EXPRESS",
  CLICK_N_COLLECT: "CLICK_N_COLLECT",
  DEFAULT: "DEFAULT",
  FBC: "FBC",
} as const;

export type SERVICE_TYPES_KEYS = keyof typeof SERVICE_TYPES;

export const API_HOST_URL = `${process.env.REACT_APP_BASE_URL || "https://slotservice-api-develop.mafrservicesstg.com/api/v1"}`;
export const REACT_APP_DOS_RULE_ENGINE_API_URL = `${process.env.REACT_APP_DOS_RULE_ENGINE_API_URL}`;

export const API_RESPONSE_STATUS = {
  IDLE: "idle",
  LOADING: "loading",
  SUCCEEDED: "succeeded",
  FAILED: "failed",
};

export const PRODUCT_TYPES = {
  FOOD: "food",
  NON_FOOD: "nonfood",
};

export const FOOD_SUB_TYPES = [
  { label: "Dry", value: "FOOD_DRY" },
  { label: "Fresh", value: "FOOD_FRESH" },
  { label: "Frozen", value: "FOOD_FROZEN" },
];

export const FOOD_SUB_TYPE_KEY_VALUE_PAIR = {
  FOOD_DRY: "Dry",
  FOOD_FRESH: "Fresh",
  FOOD_FROZEN: "Frozen",
};
// eslint-disable-next-line
export const VALIDATE_EMAIL = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export const VALIDATE_POS_NUMBER = /^[A-Za-z\d\s]+$/;
export const VALIDATE_CUST_CONTACT = /^[+]?\d+$/;
export const VALIDATE_COORDINATES = /^\d+(\.\d+)?$/;
export const NUMBER_REGEX = /^[0-9\s]*$/;

export const ALL_DAYS = "All Days";

export const SELECT_ALL = { id: "All", name: "Select All" };

export const DAYS_DROPDOWN_OPTIONS = [
  { id: "MONDAY", name: "Monday" },
  { id: "TUESDAY", name: "Tuesday" },
  { id: "WEDNESDAY", name: "Wednesday" },
  { id: "THURSDAY", name: "Thursday" },
  { id: "FRIDAY", name: "Friday" },
  { id: "SATURDAY", name: "Saturday" },
  { id: "SUNDAY", name: "Sunday" },
];

export const DISPLAY_WEEKLY_HOLIDAYS = [
  { value: false, name: "Monday" },
  { value: false, name: "Tuesday" },
  { value: false, name: "Wednesday" },
  { value: false, name: "Thursday" },
  { value: false, name: "Friday" },
  { value: false, name: "Saturday" },
  { value: false, name: "Sunday" },
];

export const ALL_DAYS_VALUES = DAYS_DROPDOWN_OPTIONS.reduce((acc: any, day: any) => [...acc, day.id], []);

export const COLLECTION_POINT_TYPES = [
  { id: "PARKING", name: "PARKING" },
  { id: "STORE", name: "STORE" },
  { id: "CLEVERON", name: "CLEVERON" },
  { id: "LOCKER", name: "LOCKER" },
];

export const API_ERROR_CODES = {
  SERVICE_NOT_CONFIGURED: "Stores_R01",
};

export const SLOT_STATUSES = {
  ACTIVATED: "ACTIVATED",
  PENDING_ACTIVATION: "PENDING_ACTIVATION",
  DEACTIVATED: "DEACTIVATED",
  DELETED: "DELETED",
};

export const HH_MM_SS = "HH:mm:ss";

export const CURRENT_COUNTRY = "currentCountry";
export const USER_INFO = "user";
export const USER_INFO_DOS = "user_dos";
export const ALERT_TYPES = {
  SUCCESS: "success",
  ERROR: "error",
  WARN: "warn",
};

export const STATUS_OK = "OK";

export const QUOTA_VIEW_OPTIONS = {
  VIEW: "view",
  MODIFY: "modify",
};

export const AREA_POS_MAPPING_TYPE = {
  AREA_BASED: "AREA_BASED",
  POLYGON_BASED: "POLYGON_BASED",
};

export const EXPRESS_TYPES = {
  ON_DEMAND: { value: "ON_DEMAND", label: "On Demand" },
  SLOT_BASED: { value: "SLOT_BASED", label: "Slot Based" },
};

export const REPORT_TYPES = {
  POS_BASIC: "POS_BASIC",
  CNC_CONFIG: "CNC_CONFIG",
  STANDARD_CONFIG: "STANDARD_CONFIG",
  EXPRESS_CONFIG: "EXPRESS_CONFIG",
};

export const SCALABLE_OR_NPN_SCALABLE_ITEMS = [
  { id: "050 - Delicatessen Counter", name: "050 - Delicatessen Counter" },
  { id: "051 - Dairy Counter", name: "051 - Dairy Counter" },
  { id: "052 - Butchery", name: "052 - Butchery" },
  { id: "053 - Fishery", name: "053 - Fishery" },
  { id: "054 - Bakery/Pastry", name: "054 - Bakery/Pastry" },
  { id: "055 - Pastry", name: "055 - Pastry" },
  { id: "056 - Fruits & Vegetables", name: "056 - Fruits & Vegetables" },
];

export const TYPES_OF_RULE_ENGINES = [
  { value: "Delivery Promise", label: "Delivery Promise", isDisabled: false },
  { value: "Delivery Fees", label: "Delivery Fees", isDisabled: true },
];

export const ITEMS_PER_PAGE = 10;

export const PHARMACY = "Pharmacy";

export const APP_USER_MGMT_URL = `${process.env.REACT_APP_USER_MGMT_URL}`;

export const MAF_USER = "MAF";
export const SUPER_ADMIN = "SUPER";
export const READONLY_USER = "READONLY";

export const USER_TYPES = {
  MAF_ADMIN: "MAF_ADMIN",
  SUPER_ADMIN: "SUPER_ADMIN",
  ADMIN: "ADMIN",
  READONLY_USER: "READONLY_USER",
} as const;

export const ADDITIONAL_SERVICES = {
  DELIVERY_PROMISE_RULE_ENGINE: 1,
  COUNTRY_SETTINGS: 2,
  DELIVERY_FEE: 3,
};

export type USER_TYPES_KEYS = keyof typeof USER_TYPES;
export const EXPORT_STANDARD_AND_EXPRESS_ENABLE_FLAG = false;

export const ZONE_CHANGE_STATUS = {
  ACTIVATED: "ACTIVATED",
  DEACTIVATED: "DEACTIVATED",
  DELETED: "DELETED",
};

export const NEW_PATH_SELECTED = "newPathSelectedOnSideMenu";

export const APP_DLV_PROMISE_URL = `${process.env.REACT_APP_DOS_DELIVERY_PROMISE_API_URL || "https://dos-delivery-promise-test.mafrservicesstg.com/api/v1"}`;
