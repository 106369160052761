import produce from "@reduxjs/toolkit/node_modules/immer";
import CustomAlert from "components/common/CustomAlert/CustomAlert";
import moment from "moment";
import { isEmptyOrNull } from "utils/DataUtils";
import { weekday } from "../common/constant";

export const validate_request_payload = (configurationState: any, request_type_string: any) => {
  //
  //
  // configuration start
  //
  let configuration: any = [];
  configurationState.allOk = true;
  if (configurationState.openAddConfiguration === true) {
    configuration = produce(configurationState.configuration, (draft: any) => {
      let dayselectionMessage = false;
      if (draft && Array.isArray(draft) && draft.length > 0) {
        let dates: any = [];
        let allOk = true;
        draft.forEach((configObj: any, configIndex: any) => {
          if (configObj && configObj.dates && dates && dates.length > 0) {
            let arr = configObj.dates.filter((d: any) => dates.includes(d));
            if (arr && arr.length > 0) {
              configObj = { ...configObj, errorMessage: "One or more date may have configuration already present" };
              allOk = false;
              draft[configIndex] = configObj;
            }
          }
          if (configObj && configObj.dates) {
            dates = [...new Set([...dates, ...configObj.dates])];
          }
          if (configObj.daysConfiguration && Array.isArray(configObj.daysConfiguration) && configObj.daysConfiguration.length > 0) {
            configObj.daysConfiguration.forEach((dayObj: any, dayIndex: any) => {
              if (dayObj && dayObj.days && Array.isArray(dayObj.days) && dayObj.days.length > 0) {
                if (dayObj.timeZoneConfiguration && Array.isArray(dayObj.timeZoneConfiguration) && dayObj.timeZoneConfiguration.length > 0) {
                  dayObj.timeZoneConfiguration.forEach((timeObj: any, timeIndex: any) => {
                    if (isEmptyOrNull(timeObj.startTime)) {
                      draft[configIndex].daysConfiguration[dayIndex].timeZoneConfiguration[timeIndex].errorMessage = "Please provide valid Start Time value.";
                      allOk = false;
                    } else if (isEmptyOrNull(timeObj.endTime)) {
                      draft[configIndex].daysConfiguration[dayIndex].timeZoneConfiguration[timeIndex].errorMessage = "Please provide valid End Time value.";
                      allOk = false;
                    } else if (isEmptyOrNull(timeObj.thresholdAmount)) {
                      draft[configIndex].daysConfiguration[dayIndex].timeZoneConfiguration[timeIndex].errorMessage = "Please provide valid Threshold Amount value.";
                      allOk = false;
                    } else if (isNaN(timeObj.thresholdAmount) || Number(timeObj.thresholdAmount) <= 0) {
                      draft[configIndex].daysConfiguration[dayIndex].timeZoneConfiguration[timeIndex].errorMessage = "Threshold Amount cannot be zero.";
                      allOk = false;
                    } else if (isEmptyOrNull(timeObj.thresholdBelowAmount)) {
                      draft[configIndex].daysConfiguration[dayIndex].timeZoneConfiguration[timeIndex].errorMessage = "Please provide valid Threshold Below Amount value.";
                      allOk = false;
                    } else if (isEmptyOrNull(timeObj.thresholdAboveAmount)) {
                      draft[configIndex].daysConfiguration[dayIndex].timeZoneConfiguration[timeIndex].errorMessage = "Please provide valid Threshold Above Amount value.";
                      allOk = false;
                    } else if (timeObj.endTime == "Invalid Date" || timeObj.startTime == "Invalid Date") {
                      allOk = false;
                    } else if (timeObj.startTime && timeObj.startTime.getTime() < moment("4:00am", "h:mma")) {
                      draft[configIndex].daysConfiguration[dayIndex].timeZoneConfiguration[timeIndex].errorMessage = "Start time cannot be less than 04:00 AM.";
                      allOk = false;
                    } else if (timeObj.startTime && timeObj.endTime && timeObj.startTime.getTime() > timeObj.endTime.getTime()) {
                      draft[configIndex].daysConfiguration[dayIndex].timeZoneConfiguration[timeIndex].errorMessage = "Please select valid start time and end time.";
                      allOk = false;
                    } else {
                      draft[configIndex].daysConfiguration[dayIndex].timeZoneConfiguration[timeIndex].errorMessage = "";
                    }
                  });
                } else {
                  draft[configIndex].daysConfiguration[dayIndex].errorMessage = " Please add valid time slots.";
                  allOk = false;
                }
              } else {
                dayselectionMessage = true;
                //CustomAlert("error", "Please select days");
                allOk = false;
              }
            });
          } else {
            dayselectionMessage = true;
            //CustomAlert("error", "Please select days");
            allOk = false;
          }
        });
        configurationState.allOk = allOk;
        if (dayselectionMessage) {
          CustomAlert("error", "Please select days");
        }
      }
    });
  }
  //
  //
  // configuration end
  //

  //
  //
  // capacity_utilisation_configuration start
  //
  let capacity_utilisation_configuration: any = [];
  configurationState.capacity_all_ok = true;
  if (configurationState.openCapacityUtilisation === true) {
    capacity_utilisation_configuration = produce(configurationState.capacity_utilisation_configuration, (draft: any) => {
      if (draft && Array.isArray(draft) && draft.length > 0) {
        draft.forEach((capacity: any, capacity_index: any) => {
          if (capacity !== undefined) {
            if (capacity.capacityLow !== undefined && capacity.capacityLow !== "") {
              if (Number(capacity.capacityLow) <= 0) {
                configurationState.capacity_all_ok = false;
                draft[capacity_index].capacity_low_error_message = "Value cannot be zero";
              } else {
                draft[capacity_index].capacity_low_error_message = "";
              }
            } else {
              configurationState.capacity_all_ok = false;
              draft[capacity_index].capacity_low_error_message = "Required";
            }

            if (capacity.capacityHigh !== undefined && capacity.capacityHigh !== "") {
              if (Number(capacity.capacityHigh) > 100) {
                configurationState.capacity_all_ok = false;
                draft[capacity_index].capacity_high_error_message = "Invalid Range";
              } else if (Number(capacity.capacityHigh) <= Number(capacity.capacityLow)) {
                configurationState.capacity_all_ok = false;
                draft[capacity_index].capacity_high_error_message = "Value may have configuration already present";
              } else {
                draft[capacity_index].capacity_high_error_message = "";
              }
            } else {
              configurationState.capacity_all_ok = false;
              draft[capacity_index].capacity_high_error_message = "Required";
            }

            if (capacity.deliveryFee !== undefined && capacity.deliveryFee !== "") {
              draft[capacity_index].capacity_fee_error_message = "";
            } else {
              configurationState.capacity_all_ok = false;
              draft[capacity_index].capacity_fee_error_message = "Required";
            }
          }
        });

        if (configurationState.capacity_all_ok === true) {
          // Add 100
          const max_range_value = draft[draft.length - 1].capacityHigh;
          if (Number(max_range_value) < 100) {
            configurationState.capacity_all_ok = false;
            draft.push({
              capacityLow: max_range_value,
              capacityHigh: "100",
              deliveryFee: "0",
              active: true,
              capacity_low_error_message: "",
              capacity_high_error_message: request_type_string === "Save" ? "** Range closure required" : "",
              capacity_fee_error_message: request_type_string === "Save" ? "Required" : "",
              enableEdit: true,
            });
          }
        }
      }
    });
  }
  //
  //
  // capacity_utilisation_configuration end
  //

  configurationState = { ...configurationState, configuration: configuration, capacity_utilisation_configuration: capacity_utilisation_configuration };
  return configurationState;
};

export const getDates = (startDate: Date, endDate: Date) => {
  let dateArray: any = [];
  let currDate = moment(startDate).startOf("day");
  let lastDate = moment(endDate).startOf("day");
  if (currDate) {
    const date = moment(currDate.toDate()).format("YYYY-MM-DD");
    dateArray.push(date);
  }
  while (currDate.add(1, "days").diff(lastDate) <= 0) {
    const date = moment(currDate.toDate()).format("YYYY-MM-DD");
    dateArray.push(date);
  }
  return dateArray;
};

export const handle_time_validation = (itemconfigIndex: any, dayObjectIndex: any, configIndex: any, prev_configuration: any) => {
  let data = prev_configuration[configIndex].daysConfiguration[dayObjectIndex].timeZoneConfiguration[itemconfigIndex];
  let allOk = true;
  let errorMessage = "";
  prev_configuration[configIndex].daysConfiguration[dayObjectIndex].timeZoneConfiguration.forEach((timeObj: any, index: any) => {
    if (
      index !== itemconfigIndex &&
      data.startTime &&
      data.endTime &&
      timeObj.startTime &&
      data.endTime &&
      ((timeObj.startTime.getTime() >= data.startTime.getTime() &&
        timeObj.startTime.getTime() >= data.endTime.getTime() &&
        timeObj.endTime.getTime() >= data.startTime.getTime() &&
        timeObj.endTime.getTime() >= data.endTime.getTime()) ||
        (timeObj.startTime.getTime() <= data.startTime.getTime() &&
          timeObj.startTime.getTime() <= data.endTime.getTime() &&
          timeObj.endTime.getTime() <= data.startTime.getTime() &&
          timeObj.endTime.getTime() <= data.endTime.getTime()))
    ) {
    } else if (index !== itemconfigIndex && data.startTime && data.endTime && data.startTime.getTime() && data.endTime.getTime()) {
      allOk = false;
    }
  });

  if (!allOk) {
    errorMessage = "One or more Slots cannot be between another Slot.";
    //CustomAlert("error", errorMessage);
  }
  if (data.startTime && data.endTime && data.startTime.getTime() > data.endTime.getTime()) {
    errorMessage = "Please select valid start time and end time.";
    // CustomAlert("error", errorMessage);
  }
  //prev_configuration[configIndex].daysConfiguration[dayObjectIndex].timeZoneConfiguration[itemconfigIndex].errorMessage = errorMessage;
  return errorMessage;
};

export const handle_display_days_on_change = (configIndex: any, prev_configuration: any, daysIndex: any, event: any, isRemove: any) => {
  const configuration: any = produce(prev_configuration, (draft: any) => {
    if (isRemove) {
      draft[configIndex].daysConfiguration.splice(daysIndex, 1);
    } else {
      draft[configIndex].daysConfiguration[daysIndex].days = event;
    }

    if (draft[configIndex] && draft[configIndex].daysConfiguration && Array.isArray(draft[configIndex].daysConfiguration) && draft[configIndex].daysConfiguration.length >= 0) {
      let selectDays: any = [];
      draft[configIndex].daysConfiguration.forEach((dayConfig: any) => {
        if (dayConfig && dayConfig.days && Array.isArray(dayConfig.days) && dayConfig.days.length > 0) {
          dayConfig.days.forEach((day: any) => selectDays.push(day));
        }
      });
      if (selectDays && selectDays.length >= 0) {
        draft[configIndex].daysConfiguration.forEach((dayConfig: any, index: any) => {
          let remainingDaysList = draft[configIndex].allDays.filter((element: any) => dayConfig.days.indexOf(element.id) !== -1 || (selectDays && selectDays.indexOf(element.id) === -1));
          draft[configIndex].daysConfiguration[index].availableDays = remainingDaysList;
        });
        draft[configIndex].selectedDays = selectDays;
      }
    }
  });

  return configuration;
};

export const handle_add_days_calculate_days_on_click = (indexValue: any, configurationState: any) => {
  const prev_configuration: any = produce(configurationState.configuration, (draft: any) => {
    if (draft[indexValue] !== undefined) {
      if (draft[indexValue].startDate && draft[indexValue].startDate !== "" && draft[indexValue].endDate && draft[indexValue].endDate !== "") {
        let daysList = [];
        let startDate = new Date(draft[indexValue].startDate);
        let endDate = new Date(draft[indexValue].endDate);
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(0, 0, 0, 0);
        let dateList = getDates(draft[indexValue].startDate, draft[indexValue].endDate);
        let diff = (endDate.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000);
        if (diff < 7) {
          let dayNumber = draft[indexValue].startDate.getDay();
          while (diff >= 0) {
            daysList.push({ id: weekday[dayNumber], name: weekday[dayNumber] });
            dayNumber = (dayNumber + 1) % 7;
            diff--;
          }
        } else {
          weekday.forEach((item) => {
            daysList.push({ id: item, name: item });
          });
        }
        draft[indexValue] = {
          ...draft[indexValue],
          allDays: daysList, // [SUN, MON, TUE]
          remainingDays: daysList, // [SUN, MON, TUE]
          dates: dateList,
          daysConfiguration: [
            {
              availableDays: daysList, // [SUN, MON, TUE]
              days: [], // 'SUN'
              timeZoneConfiguration: [
                {
                  startTime: null,
                  endTime: null,
                  thresholdAmount: "",
                  thresholdBelowAmount: "",
                  thresholdAboveAmount: "",
                },
              ],
            },
          ],
        };
      }
    }
  });

  return prev_configuration;
};

export const handle_add_days_on_click = (indexValue: any, configurationState: any) => {
  const prev_configuration: any = produce(configurationState.configuration, (draft: any) => {
    if (draft[indexValue] !== undefined) {
      if (draft[indexValue] && draft[indexValue].allDays && Array.isArray(draft[indexValue].allDays) && draft[indexValue].allDays.length > 0) {
        let remainingDaysList = [];
        let selectDays: any = [];
        if (draft[indexValue] && draft[indexValue].daysConfiguration && Array.isArray(draft[indexValue].daysConfiguration) && draft[indexValue].daysConfiguration.length >= 0) {
          draft[indexValue].daysConfiguration.forEach((dayConfig: any) => {
            if (dayConfig && dayConfig.days && Array.isArray(dayConfig.days) && dayConfig.days.length > 0) {
              dayConfig.days.forEach((day: any) => selectDays.push(day));
            }
          });

          if (selectDays && selectDays.length >= 0) {
            remainingDaysList = draft[indexValue].allDays.filter((element: any) => selectDays && selectDays.indexOf(element.id) === -1);
            draft[indexValue].remainingDays = remainingDaysList;
            draft[indexValue].daysConfiguration.push({
              availableDays: remainingDaysList, // [SUN, MON, TUE]
              days: [], // 'SUN'
              timeZoneConfiguration: [
                {
                  startTime: moment("04:00:00", "HH:mm:ss").toDate(),
                  endTime: null,
                  thresholdAmount: "",
                  thresholdBelowAmount: "",
                  thresholdAboveAmount: "",
                  active: true,
                },
              ],
            });
          }
        }
      }
    }
  });

  return prev_configuration;
};

export const handle_add_time__threshold_object = (dayObjectIndex: any, configIndex: any, configurationState: any) => {
  const prev_configuration: any = produce(configurationState.configuration, (draft: any) => {
    if (
      dayObjectIndex >= 0 &&
      configIndex >= 0 &&
      draft[configIndex] &&
      draft[configIndex].daysConfiguration &&
      draft[configIndex].daysConfiguration[dayObjectIndex] &&
      draft[configIndex].daysConfiguration[dayObjectIndex].timeZoneConfiguration &&
      Array.isArray(draft[configIndex].daysConfiguration[dayObjectIndex].timeZoneConfiguration) &&
      draft[configIndex].daysConfiguration[dayObjectIndex].timeZoneConfiguration.length >= 0
    ) {
      draft[configIndex].daysConfiguration[dayObjectIndex].timeZoneConfiguration.push({
        startTime: moment("04:00:00", "HH:mm:ss").toDate(),
        endTime: null,
        thresholdAmount: "",
        thresholdBelowAmount: "",
        thresholdAboveAmount: "",
        active: true,
        enableEdit: false,
      });
    }
  });
  return prev_configuration;
};

export const handle_start_time_validation_minimum_time = (itemconfigIndex: any, dayObjectIndex: any, configIndex: any, prev_configuration: any) => {
  let data = prev_configuration[configIndex].daysConfiguration[dayObjectIndex].timeZoneConfiguration[itemconfigIndex];
  let errorMessage = "";
  if (data && data.startTime && data.startTime.getTime() && data.startTime.getTime() < moment("4:00am", "h:mma")) {
    errorMessage = "Start time cannot be less than 04:00 AM.";
  }
  return errorMessage;
};

export const _design_capacity_utilisation_demo_list = () => {
  let data_list = [];
  data_list.push({
    capacityLow: "0.1",
    capacityHigh: "100",
    deliveryFee: "0",
    active: true,
    capacity_high_error_message: "",
    capacity_low_error_message: "",
    capacity_fee_error_message: "",
    enableEdit: true,
  });
  return data_list;
};

export const _update_capacity_utilisation_list_record = (configurationState: any) => {
  const prev_configuration: any = produce(configurationState, (draft: any) => {
    if (
      draft !== undefined &&
      draft.capacity_utilisation_configuration !== undefined &&
      Array.isArray(draft.capacity_utilisation_configuration) &&
      draft.capacity_utilisation_configuration.length > 0
    ) {
      draft.capacity_utilisation_configuration.push({
        capacityLow: draft.capacity_utilisation_configuration[draft.capacity_utilisation_configuration.length - 1].capacityLow,
        capacityHigh: "100",
        deliveryFee: "0",
        active: true,
        capacity_high_error_message: "",
        capacity_low_error_message: "",
        capacity_fee_error_message: "",
        enableEdit: true,
      });
    }
  });
  return prev_configuration;
};
