import React from "react";

import Grid from "@material-ui/core/Grid";

import SwitchButton from "../../../common/SwitchButton/SwitchButton";
import TimePicker from "../../../common/TimePicker/TimePicker";

import useStyles from "./ShowFirstSlotAndServiceCutOffStyles";

const ShowFirstSlotAndServiceCutOffTime = ({
  showFirstSlotAtOption,
  showFirstSlotAt,
  showServiceCutOffTimeOption,
  serviceCutOffTime,
  handleSwitchButton,
  handleShowFirstSlotOrServiceCutOffTime,
}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Grid container>
          <Grid item xs={8} className={classes.fontWeight500}>
            Show First Slot at
          </Grid>
          <Grid item xs={4} className={classes.switchButton}>
            <SwitchButton
              name="showFirstSlotAtOption"
              checked={!!showFirstSlotAtOption}
              handleChange={handleSwitchButton}
            />
          </Grid>
        </Grid>
        <TimePicker
          className={classes.timePicker}
          value={showFirstSlotAt}
          handleChange={(date) => handleShowFirstSlotOrServiceCutOffTime(date, "showFirstSlotAt")}
          disabled={!showFirstSlotAtOption}
        />
      </Grid>
      <Grid item xs={6}>
        <Grid container>
          <Grid item xs={8} className={classes.fontWeight500}>
            Service Cut-off
          </Grid>
          <Grid item xs={4} className={classes.switchButton}>
            <SwitchButton
              name="showServiceCutOffTimeOption"
              checked={!!showServiceCutOffTimeOption}
              handleChange={handleSwitchButton}
            />
          </Grid>
        </Grid>
        <TimePicker
          className={classes.timePicker}
          value={serviceCutOffTime}
          handleChange={(date) => handleShowFirstSlotOrServiceCutOffTime(date, "serviceCutOffTime")}
          disabled={!showServiceCutOffTimeOption}
        />
      </Grid>
    </Grid>
  );
};

export default ShowFirstSlotAndServiceCutOffTime;
