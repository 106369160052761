import { API_HOST_URL } from "../config/constants";
import { handleError, handleResponse } from "./ApiUtils";
import Fetch from "./Fetch";

export const addNewTemplateSlot = (data) => {
  return Fetch.create(`${API_HOST_URL}`, data).then(handleResponse).catch(handleError);
};

export const updateTemplateSlot = (data) => {
  // eslint-disable-next-line
  if (data.serviceTypeId == 2) {
    return Fetch.update(`${API_HOST_URL}/default`, data).then(handleResponse).catch(handleError);
  } else {
    return Fetch.update(`${API_HOST_URL}`, data).then(handleResponse).catch(handleError);
  }
};

export const deleteTemplateSlot = (slotId) => {
  return Fetch.patch(`${API_HOST_URL}/${slotId}`).then(handleResponse).catch(handleError);
};
