import React from "react";
import { Card, Grid, Typography } from "@material-ui/core";

import TextBox from "../../../common/TextBox";
import Pencil from "../../../assets/svg/Pencil.svg";

interface AdditionalCapacityUtilisationConfigListInterface {
  classes: any;
  feeConfigList: any;
  shipment_name?: any;
  multiShipmentLevel?: any;
  handleCapacityUtilisationConfigInputChange?: any;
  _handle_edit_toggle_capacity_utilisation_toggle_change?: any;
}

const AdditionalCapacityUtilisationConfigList = (props: AdditionalCapacityUtilisationConfigListInterface) => {
  const { classes, feeConfigList, shipment_name, multiShipmentLevel, handleCapacityUtilisationConfigInputChange, _handle_edit_toggle_capacity_utilisation_toggle_change } = props;

  return (
    <Card className={classes.additionDlvFeeMainCard}>
      <Grid container className={classes.additionDlvFeeMainTitleDivStyle}>
        <Grid item xs={12}>
          <Typography className={classes.additionDlvFeeMainTitleStyle}>Capacity Utilisation configuration</Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.additionDlvFeeInnerRowHeaderStyle}>
        <Grid item xs={3} className={classes.dlvFeeValueDisplayDivStyle}>
          <Typography className={classes.dlvFeeValueDisplayTitleStyle}>Range Start (Greater Than or Equal To)</Typography>
        </Grid>
        <Grid item xs={3} className={classes.dlvFeeValueDisplayDivStyle}>
          <Typography className={classes.dlvFeeValueDisplayTitleStyle}>Range End (Less Than)</Typography>
        </Grid>
        <Grid item xs={2} className={classes.dlvFeeValueDisplayDivStyle}>
          <Typography className={classes.dlvFeeValueDisplayTitleStyle}>Delivery Fees</Typography>
        </Grid>
      </Grid>
      {feeConfigList && Array.isArray(feeConfigList) && feeConfigList.length > 0 ? (
        feeConfigList.map((config: any, configIndex: any) => (
          <Grid container key={`${configIndex}-feeConfigList-config`} className={classes.additionDlvFeeInnerRowHeaderStyle}>
            <Grid item xs={3} className={classes.additionDlvFeeInnerRowDivStyle}>
              <Typography className={classes.dlvFeeValueDisplayDataStyle}> {config.capacityLow}</Typography>
            </Grid>
            <Grid item xs={3} className={classes.additionDlvFeeInnerRowDivStyle}>
              <Typography className={classes.dlvFeeValueDisplayDataStyle}> {config.capacityHigh}</Typography>
            </Grid>

            <Grid item xs={2} className={classes.additionDlvFeeInnerRowDivStyle}>
              {handleCapacityUtilisationConfigInputChange !== undefined && multiShipmentLevel !== undefined && shipment_name !== undefined ? (
                <TextBox
                  name="deliveryFee"
                  type="number"
                  textBoxId="deliveryFee"
                  placeholderText="Delivery Fees"
                  value={config.deliveryFee}
                  handleChange={(e: any) => handleCapacityUtilisationConfigInputChange(e, multiShipmentLevel, shipment_name, configIndex)}
                  className={classes.rightPaddingStyle}
                  disabled={config.enableEdit}
                ></TextBox>
              ) : (
                <Typography className={classes.dlvFeeValueDisplayDataStyle}> {config.deliveryFee}</Typography>
              )}
            </Grid>
            {handleCapacityUtilisationConfigInputChange !== undefined && _handle_edit_toggle_capacity_utilisation_toggle_change !== undefined && shipment_name !== undefined && config.enableEdit && (
              <Grid item xs={1} className={classes.actionIconDivStyle}>
                <img
                  src={Pencil}
                  alt="Edit"
                  className={classes.actionIconStyle}
                  onClick={(e: any) => _handle_edit_toggle_capacity_utilisation_toggle_change(multiShipmentLevel, shipment_name, configIndex)}
                  style={{ cursor: "pointer" }}
                />
              </Grid>
            )}
          </Grid>
        ))
      ) : (
        <></>
      )}
    </Card>
  );
};

export default React.memo(AdditionalCapacityUtilisationConfigList);
