import { makeStyles } from "@material-ui/core";
import { COLORS } from "../../config/theme/theme";

const ruleEngineStyles = makeStyles({
  root: {
    marginTop: "80px",
    padding: "24px 24px 24px 24px",
  },
  dFlexEnd: {
    display: "flex",
    justifyContent: "flex-end",
  },
  headerRoot: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "24px",
  },
  header: {
    display: "flex",
    justifyContent: "flex-start",
  },
  heading: {
    fontWeight: 600,
    fontSize: "24px",
    color: COLORS.DARK_GRAY,
  },
  backButtonStyles: {
    width: "364px",
    height: "40px",
    background: "#0E5AA7",
    color: "#FFFFFF",
    border: "1px solid #0E5AA7",
    borderRadius: "4px",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    "&:hover": {
      backgroundColor: "#0E5AA7",
      color: "#FFFFFF",
      border: "1px solid #0E5AA7",
      borderRadius: "8px",
    },
  },
  footerDivStyle: {
    margin: 0,
    padding: "24px 0px 0px 0px",
  },
  buttonStyle: {
    width: "100%",
  },
});

export default ruleEngineStyles;
