import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { userStyles } from "./Styles/DeliveryFeePageStyles";

import { DeliveryFeeInitialState, DeliveryFeeInitialStateInterface } from "./Utils/DeliveryFeeInterface";
import {
  fetchDlvFeeConfigurationList,
  fetchShipmentTypeRecordList,
  resetDlvFeeSingleConfigurationRecordServiceState,
  selectDlvFeeConfigurationRecord,
  selectShipmentTypeDetailsRecord,
} from "./Redux/DeliveryFeeSlice";
import { _design_delivery_fee_listing_screen_object, _design_delivery_fee_list_data } from "./Utils/DeliveryFeeUtils";

import DeliveryFeeHeader from "./common/DeliveryFeeHeader";
import DeliveryFeeSearchSection from "./common/DeliveryFeeSearchSection";
import DeliveryFeeCardSection from "./Card/DeliveryFeeCardSection";
import Loader from "../common/Loader/Loader";
import { selectUserDetails } from "../Login/redux/userSlice";

const DeliveryFeePage = () => {
  const classes = userStyles();

  const { currentCountry } = useSelector((state: any) => state.appConfig);
  const userInfo: any = useSelector(selectUserDetails);

  const shipmentTypeDetailsService = useSelector(selectShipmentTypeDetailsRecord);
  const dlvFeeConfigurationRecordService = useSelector(selectDlvFeeConfigurationRecord);

  const [deliveryFeeState, setDeliveryFeeState] = useState<DeliveryFeeInitialStateInterface>(DeliveryFeeInitialState);

  const appDispatch = useDispatch();

  const handleSearchchange = (event: any) => {
    let { value } = event.target;
    setDeliveryFeeState({ ...deliveryFeeState, searchText: value });
  };

  const configureDlvFeeConfigurationRecord = () => {
    if (dlvFeeConfigurationRecordService && dlvFeeConfigurationRecordService.data) {
      const { responseList, pageNumber, maxRecordPresent } = _design_delivery_fee_list_data(dlvFeeConfigurationRecordService.data, deliveryFeeState);
      setDeliveryFeeState({ ...deliveryFeeState, deliveryFeeListData: responseList, page: pageNumber, maxRecordPresent: maxRecordPresent, loading: false });
    }
  };

  useEffect(() => {
    setDeliveryFeeState({ ...deliveryFeeState, loading: false });
  }, [dlvFeeConfigurationRecordService, dlvFeeConfigurationRecordService.error]);

  useEffect(() => {
    dlvFeeConfigurationRecordService && dlvFeeConfigurationRecordService.data && configureDlvFeeConfigurationRecord();
  }, [dlvFeeConfigurationRecordService, dlvFeeConfigurationRecordService.data]);

  const fetchConfigurationListRecord = () => {
    appDispatch(
      fetchDlvFeeConfigurationList({
        countryName: currentCountry.name,
        payload: {
          page: deliveryFeeState.page,
          size: deliveryFeeState.size,
        },
      })
    );
  };

  const configureShipmentTypeData = () => {
    if (shipmentTypeDetailsService.data && Array.isArray(shipmentTypeDetailsService.data) && shipmentTypeDetailsService.data.length > 0) {
      setDeliveryFeeState({ ...deliveryFeeState, deliveryFeeHeaderData: {}, deliveryFeeListData: [], loading: true });
      let newDlvFeeConfigState = _design_delivery_fee_listing_screen_object(shipmentTypeDetailsService.data);
      setDeliveryFeeState({ ...deliveryFeeState, deliveryFeeHeaderData: newDlvFeeConfigState });
      fetchConfigurationListRecord();
    }
  };

  useEffect(() => {
    shipmentTypeDetailsService && shipmentTypeDetailsService.data && configureShipmentTypeData();
  }, [shipmentTypeDetailsService, shipmentTypeDetailsService.data]);

  useEffect(() => {
    shipmentTypeDetailsService &&
      shipmentTypeDetailsService.error &&
      shipmentTypeDetailsService.error !== "" &&
      setDeliveryFeeState({ ...deliveryFeeState, deliveryFeeHeaderData: {}, deliveryFeeListData: [], loading: false });
  }, [shipmentTypeDetailsService, shipmentTypeDetailsService.error]);

  useEffect(() => {
    appDispatch(fetchShipmentTypeRecordList());
    appDispatch(resetDlvFeeSingleConfigurationRecordServiceState({}));
  }, [appDispatch]);

  const fetchNextRecord = (event: any, pageNumber: any) => {
    setDeliveryFeeState({ ...deliveryFeeState, loading: true });
    appDispatch(
      fetchDlvFeeConfigurationList({
        countryName: currentCountry.name,
        payload: {
          page: pageNumber,
          size: deliveryFeeState.size,
        },
      })
    );
  };

  return (
    <div className={classes.container}>
      <DeliveryFeeHeader userInfo={userInfo} />
      {deliveryFeeState.loading ? (
        <Loader />
      ) : (
        <>
          <DeliveryFeeSearchSection deliveryFeeState={deliveryFeeState} handleSearchchange={handleSearchchange} />
          <DeliveryFeeCardSection
            deliveryFeeHeaderData={deliveryFeeState.deliveryFeeHeaderData}
            deliveryFeeListData={deliveryFeeState.deliveryFeeListData}
            pageSize={deliveryFeeState.size}
            page={deliveryFeeState.page}
            rowCount={deliveryFeeState.maxRecordPresent}
            fetchNextRecord={fetchNextRecord}
            userInfo={userInfo}
          />
        </>
      )}
    </div>
  );
};

export default React.memo(DeliveryFeePage);
