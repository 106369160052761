import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Grid, Typography } from "@material-ui/core";

import { userStyles } from "../Styles/AddUpdateDlvFeeConfigStyles";
import { ALERT_TYPES, APP_ROUTES } from "../../../config/constants";

import {
  _handle_toggle_change,
  _design_Pos_Zone_Service_List_Record,
  _design_update_delivery_fee_state_object,
  _handle_accordion_open_state_util,
  _handle_change_time_config_record_threshould_data,
  _handle_delete_selected_row_utils,
  _handle_design_add_additional_configuration,
  _handle_edit_toggle_additional_config,
  _handle_edit_update_calculation_toggle_change_util,
  _handle_empty_pos_zone_mapping_data,
  _handle_empty_pos_zone_mapping_data_and_loading,
  _handle_empty_pos_zone_mapping_data_and_loading_off,
  _handle_header_inner_row_check_box_on_change_utils,
  _handle_header_main_row_check_box_on_change_utils,
  _handle_pos_zone_header_row_check_box_change,
  _handle_pos_zone_number_open_index,
  _handle_Pos_Zone_Service_List_Record,
  _handle_remove_time_config_record_row,
  _handle_service_active_toggle_change,
  _handle_service_type_input_change,
  _validate_configuration_save_object,
  _handle_threshold_range_action,
  _handle_service_type_threshold_range_input_change,
  _handle_edit_threshold_rane_toggle_change_enable_Edit_util,
  _handle_view_change_configuration_util,
  _handle_toggle_change_is_multi_shipment_calculation_util,
  _handle_service_multi_shiment_input_change,
  _handle_edit_multi_shipment_enable_toggle_change_util,
  _validate_multi_shipment_configuration_object,
  _design_update_multi_shipment_delivery_fee_state_object,
  _handle_service_type_drawer_checkbox_input_change,
  _handle_change_capacity_utilisation_field_data,
  _handle_edit_toggle_change_capacity_utilisation_data,
} from "../Utils/DeliveryFeeUtils";
import {
  createNewDeliveryFeeconfig,
  createOrUpdateMultiShipmentDeliveryFeeconfigAction,
  fetchMultiShipmenthDlvFeeConfigurationRecordList,
  fetchPosZoneServiceTypeListByCountryIdAndServiceType,
  getDlvFeeConfigurationBasedOnPosAndZone,
  selectServiceTypePosZoneMapRecord,
  selectShipmentTypeDetailsRecord,
  selectSingleDlvFeeConfigurationRecord,
  updateDeliveryFeeconfig,
} from "../Redux/DeliveryFeeSlice";

import PrimaryButton from "../../common/PrimaryButton/PrimaryButton";
import CustomAlert from "../../common/CustomAlert/CustomAlert";
import ShipmentLevelConfiguration from "./common/ShipmentLevelConfiguration";
import DlvFeeConfigPosZoneMapping from "./common/DlvFeeConfigPosZoneMapping";
import Loader from "../../common/Loader/Loader";
import DeliveryFeeDateTime from "../DeliveryFeeDateTime";
import { ERROR_CREATE_CONFIG, NO_MESSAGE_AVAILABLE } from "../Utils/DeliveryFeeConstants";
import MultiShipmentLevelConfiguration from "./common/MultiShipmentLevelConfiguration";

const AddUpdateDlvFeeConfigPage = () => {
  const classes = userStyles();

  let location = useLocation<any>();
  const history = useHistory();
  const appDispatch = useDispatch();

  const { currentCountry } = useSelector((state: any) => state.appConfig);
  const shipmentTypeDetailsService = useSelector(selectShipmentTypeDetailsRecord);
  const dlvFeeConfigurationRecordService = useSelector(selectSingleDlvFeeConfigurationRecord);
  const posZoneMapListService = useSelector(selectServiceTypePosZoneMapRecord);

  const [dlvFeeConfigState, setDlvFeeConfigState] = useState({
    loading: true,
    stateConfigurationDialog: false,
    stateConfigurationStateObject: {},
    shipmentLevel: {},
    multiShipmentLevel: {},
    posZoneSearchText: "",
    assignedPosSearchText: "",
    totalAreaCount: 0,
    totalAssignedAreaCount: 0,
    totalRowsCountData: 0,
    posZoneServiceRecord: [],
    selectedPosServiceTypeZone: [],
    activeServiceTypeList: [],
    openShipmentLevel: false,
    openMultiShipmentLevel: true,
    openPosZonMapping: false,
    updateConfigPosId: "",
    updateConfigZoneName: "",
    isShipmentLevelConfigurationView: true,
    isMultiShipmentCalculationPercentageBased: false,
  }) as any;

  // Create Shipment and Multi shipment object based on shipment types available
  // First method designing object
  const configureShipmentTypeData = () => {
    if (shipmentTypeDetailsService.data && Array.isArray(shipmentTypeDetailsService.data) && shipmentTypeDetailsService.data.length > 0) {
      fetchCurrentDlvFeeConfiguration(); // To check if edit screen, data already present
    }
  };

  const fetchCurrentDlvFeeConfiguration = async () => {
    let params = new URLSearchParams(location.search);
    if (location.search) {
      let requestPayload = {
        posId: params.get("posNo"),
        zoneName: params.get("zoneName"),
      } as any;

      if (requestPayload && requestPayload.posId !== "" && requestPayload.zoneName !== "") {
        appDispatch(
          getDlvFeeConfigurationBasedOnPosAndZone({
            posNo: requestPayload.posId,
            zone: requestPayload.zoneName,
          })
        );
      }
    }
  };

  const getCurrentDlvFeeConfiguration = () => {
    if (dlvFeeConfigurationRecordService && dlvFeeConfigurationRecordService.data) {
      setDlvFeeConfigState({ ...dlvFeeConfigState, loading: true });
      let updatedNewDlvFeeConfigState = _design_update_delivery_fee_state_object(dlvFeeConfigurationRecordService.data, dlvFeeConfigState, shipmentTypeDetailsService.data);
      setTimeout(() => setDlvFeeConfigState(updatedNewDlvFeeConfigState), 2000);
    }
  };

  // Front end interation starts from here
  // enabeling a service type to start the configuration
  const _handle_accordion_open_state = (openStateName: any) => {
    let newDlvFeeConfigState = _handle_accordion_open_state_util(dlvFeeConfigState, openStateName);
    setDlvFeeConfigState(newDlvFeeConfigState);
  };

  const _handle_calculation_toggle_change = (event: any, multiShipmentLevel: any, name: any) => {
    if (name && name !== "") {
      let newDlvFeeConfigState = _handle_toggle_change(multiShipmentLevel, name, "isEditMode", dlvFeeConfigState);
      setDlvFeeConfigState(newDlvFeeConfigState);
    }
  };

  const handleInputChange = (event: any, multiShipmentLevel: any, shipmentName: any) => {
    if (event && event.target) {
      const { name, value } = event.target;
      if (shipmentName && shipmentName !== "") {
        let newDlvFeeConfigState = _handle_service_type_input_change(shipmentName, multiShipmentLevel, name, value, dlvFeeConfigState);
        setDlvFeeConfigState(newDlvFeeConfigState);
      }
    }
  };

  const _handle_click_add_threshold_range = (name: any, multiShipmentLevel: any) => {
    if (name && name !== "") {
      let newDlvFeeConfigState = _handle_threshold_range_action(multiShipmentLevel, name, "add", dlvFeeConfigState);
      setDlvFeeConfigState(newDlvFeeConfigState);
    }
  };

  const handleRangeInputChange = (event: any, multiShipmentLevel: any, shipmentName: any, rangeObjectIndex: any) => {
    if (event && event.target) {
      const { name, value } = event.target;
      if (shipmentName && shipmentName !== "") {
        let newDlvFeeConfigState = _handle_service_type_threshold_range_input_change(multiShipmentLevel, shipmentName, name, value, rangeObjectIndex, dlvFeeConfigState);
        setDlvFeeConfigState(newDlvFeeConfigState);
      }
    }
  };

  const _handle_click_delete_threshold_range = (name: any, multiShipmentLevel: any) => {
    if (name && name !== "") {
      let newDlvFeeConfigState = _handle_threshold_range_action(multiShipmentLevel, name, "remove", dlvFeeConfigState);
      setDlvFeeConfigState(newDlvFeeConfigState);
    }
  };

  const _handle_open_configuration_dialog = (name: any, isMultiShipmentLevel: any) => {
    setDlvFeeConfigState({
      ...dlvFeeConfigState,
      stateConfigurationDialog: true,
      stateConfigurationStateObject: { ...dlvFeeConfigState.stateConfigurationStateObject, serviceTypeName: name, isMultiShipmentLevel: isMultiShipmentLevel },
    });
  };

  const _handle_close_configuration_dialog = () => {
    setDlvFeeConfigState({ ...dlvFeeConfigState, stateConfigurationDialog: false, stateConfigurationStateObject: {} });
  };

  const _handle_on_save_configuration_dialog = (dateTimeData: any, shipmentType: any, isMultiShipmentLevel: any, capacity_utilisation_configuration: any) => {
    if (shipmentType !== "") {
      let newDlvFeeConfigState = _handle_design_add_additional_configuration(dlvFeeConfigState, shipmentType, isMultiShipmentLevel, dateTimeData, capacity_utilisation_configuration);
      setDlvFeeConfigState(newDlvFeeConfigState);
    }
  };

  const _handle_Pos_Zone_Search_Text_Change = (event: any) => {
    if (event && event.target) {
      let { name, value } = event.target;
      setDlvFeeConfigState({
        ...dlvFeeConfigState,
        [name]: value,
      });
    }
  };

  const _handle_update_pos_number_open_index = (posListIdName: any, posIndex: any) => {
    let newDlvFeeConfigState = _handle_pos_zone_number_open_index(dlvFeeConfigState, posListIdName, posIndex);
    setDlvFeeConfigState(newDlvFeeConfigState);
  };

  const _handle_header_row_check_box_on_change = (event: any) => {
    if (event && event.target) {
      const { checked } = event.target;
      let newDlvFeeConfigState = _handle_pos_zone_header_row_check_box_change(dlvFeeConfigState, checked);
      setDlvFeeConfigState(newDlvFeeConfigState);
    }
  };

  const _handle_header_main_row_check_box_on_change = (event: any, posListIndex: any) => {
    if (event && event.target) {
      const { checked } = event.target;
      let newDlvFeeConfigState = _handle_header_main_row_check_box_on_change_utils(dlvFeeConfigState, checked, posListIndex);
      setDlvFeeConfigState(newDlvFeeConfigState);
    }
  };

  const _handle_header_inner_row_check_box_on_change = (event: any, posListIndex: any, posDataIndex: any) => {
    if (event && event.target) {
      const { checked } = event.target;
      let newDlvFeeConfigState = _handle_header_inner_row_check_box_on_change_utils(dlvFeeConfigState, checked, posListIndex, posDataIndex);
      setDlvFeeConfigState(newDlvFeeConfigState);
    }
  };

  const _handle_delete_selected_row = (event: any, item: any, index: any) => {
    let newDlvFeeConfigState = _handle_delete_selected_row_utils(dlvFeeConfigState, item, index);
    setDlvFeeConfigState(newDlvFeeConfigState);
  };

  const fetchPosZoneServiceTypeRecord = () => {
    if (dlvFeeConfigState && dlvFeeConfigState.activeServiceTypeList && Array.isArray(dlvFeeConfigState.activeServiceTypeList) && dlvFeeConfigState.activeServiceTypeList.length > 0) {
      let newDlvFeeConfigState = _handle_empty_pos_zone_mapping_data_and_loading(dlvFeeConfigState);
      setDlvFeeConfigState(newDlvFeeConfigState);
      appDispatch(
        fetchPosZoneServiceTypeListByCountryIdAndServiceType({
          countryId: currentCountry.countryId,
          pageSize: 200,
          pageNumber: 0,
          serviceType: dlvFeeConfigState.activeServiceTypeList,
        })
      );
    } else {
      let newDlvFeeConfigState = _handle_empty_pos_zone_mapping_data(dlvFeeConfigState);
      setDlvFeeConfigState(newDlvFeeConfigState);
    }
  };

  const configurePosZoneData = () => {
    if (posZoneMapListService.data && Array.isArray(posZoneMapListService.data)) {
      const { totalRowsCountData, listData }: any = _design_Pos_Zone_Service_List_Record(posZoneMapListService.data);
      let newDlvFeeConfigState = _handle_Pos_Zone_Service_List_Record(dlvFeeConfigState, totalRowsCountData, listData);
      setDlvFeeConfigState(newDlvFeeConfigState);
    }
  };

  const configurePosZoneDataError = () => {
    if (posZoneMapListService && posZoneMapListService.error !== "") {
      let newDlvFeeConfigState = _handle_empty_pos_zone_mapping_data_and_loading_off(dlvFeeConfigState);
      setDlvFeeConfigState(newDlvFeeConfigState);
    }
  };

  const saveNewDlvFeeConfig = async (payload_Object: any) => {
    const requestPayload = {
      name: currentCountry.name,
      payload_Object: payload_Object,
    };
    const { payload }: any = await appDispatch(createNewDeliveryFeeconfig(requestPayload));
    if (payload && payload.success) {
      CustomAlert(ALERT_TYPES.SUCCESS, `Configuration added successfully`);
      history.push(APP_ROUTES.DELIVERY_FEE);
    } else {
      setDlvFeeConfigState({ ...dlvFeeConfigState, loading: false });
      let errorMessage = ERROR_CREATE_CONFIG;
      if (payload.message && payload.message !== NO_MESSAGE_AVAILABLE) {
        errorMessage = payload.message;
      }
      CustomAlert(ALERT_TYPES.ERROR, errorMessage);
    }
  };

  const updateDlvFeeConfig = async (payload_Object: any) => {
    const requestPayload = {
      name: currentCountry.name,
      payload_Object: payload_Object,
    };
    const { payload }: any = await appDispatch(updateDeliveryFeeconfig(requestPayload));
    if (payload && payload.success) {
      CustomAlert(ALERT_TYPES.SUCCESS, `Configuration updated successfully`);
      history.push(APP_ROUTES.DELIVERY_FEE);
    } else {
      setDlvFeeConfigState({ ...dlvFeeConfigState, loading: false });
      let errorMessage = ERROR_CREATE_CONFIG;
      if (payload.message && payload.message !== NO_MESSAGE_AVAILABLE) {
        errorMessage = payload.message;
      }
      CustomAlert(ALERT_TYPES.ERROR, errorMessage);
    }
  };

  const _handle_dlv_fee_config_save = () => {
    const payload_Object = _validate_configuration_save_object(dlvFeeConfigState, "save");
    if (payload_Object && Object.keys(payload_Object).length !== 0) {
      setDlvFeeConfigState({ ...dlvFeeConfigState, loading: true });
      saveNewDlvFeeConfig(payload_Object);
    }
  };

  const _handle_dlv_fee_config_update = () => {
    const payload_Object = _validate_configuration_save_object(dlvFeeConfigState, "update");
    if (payload_Object && Object.keys(payload_Object).length !== 0) {
      setDlvFeeConfigState({ ...dlvFeeConfigState, loading: true });
      updateDlvFeeConfig(payload_Object);
    }
  };

  const _handle_calculation_active_toggle_change = (event: any, multiShipmentLevel: any, name: any) => {
    if (name && name !== "") {
      let newDlvFeeConfigState = _handle_service_active_toggle_change(multiShipmentLevel, name, dlvFeeConfigState);
      setDlvFeeConfigState(newDlvFeeConfigState);
    }
  };

  const _handle_edit_update_calculation_toggle_change = (event: any, multiShipmentLevel: any, name: any, fieldName: any) => {
    if (name && name !== "") {
      let newDlvFeeConfigState = _handle_edit_update_calculation_toggle_change_util(multiShipmentLevel, name, fieldName, dlvFeeConfigState);
      setDlvFeeConfigState(newDlvFeeConfigState);
    }
  };

  const removeTimeConfigRecord = (multiShipmentLevel: any, shipment_name: any, configIndex: any, daysConfigIndex: any, timeConfigIndex: any) => {
    if (shipment_name !== undefined && shipment_name !== "") {
      let newDlvFeeConfigState = _handle_remove_time_config_record_row(multiShipmentLevel, shipment_name, configIndex, daysConfigIndex, timeConfigIndex, dlvFeeConfigState);
      setDlvFeeConfigState(newDlvFeeConfigState);
    }
  };

  const handleAdditionalConfigInputChange = (event: any, multiShipmentLevel: any, shipment_name: any, configIndex: any, daysConfigIndex: any, timeConfigIndex: any) => {
    if (shipment_name !== undefined && shipment_name !== "") {
      if (event && event.target) {
        const { name, value } = event.target;
        if (name !== undefined && name !== "" && value !== undefined) {
          const decimalRegex = /^(\d+([.]{1}\d*)?)$/;
          if (name === "thresholdAmount" && value !== "" && decimalRegex.test(value) && (value === 0 || value === "0")) {
            event.target.value = "";
            return;
          }
          if (value !== "" && !decimalRegex.test(value)) {
            event.target.value = "";
            return;
          } else {
            let newDlvFeeConfigState = _handle_change_time_config_record_threshould_data(
              multiShipmentLevel,
              shipment_name,
              configIndex,
              daysConfigIndex,
              timeConfigIndex,
              name,
              value,
              dlvFeeConfigState
            );
            setDlvFeeConfigState(newDlvFeeConfigState);
          }
        }
      }
    }
  };

  const _handle_edit_toggle_additional_calculation_toggle_change = (multiShipmentLevel: any, shipment_name: any, configIndex: any, daysConfigIndex: any, timeConfigIndex: any) => {
    if (shipment_name !== undefined && shipment_name !== "") {
      let newDlvFeeConfigState = _handle_edit_toggle_additional_config(multiShipmentLevel, shipment_name, configIndex, daysConfigIndex, timeConfigIndex, dlvFeeConfigState);
      setDlvFeeConfigState(newDlvFeeConfigState);
    }
  };

  const _handle_edit_threshold_rane_toggle_change_enable_Edit = (e: any, multiShipmentLevel: any, shipment_name: any, rangeObjectIndex: any) => {
    if (shipment_name !== undefined && shipment_name !== "") {
      let newDlvFeeConfigState = _handle_edit_threshold_rane_toggle_change_enable_Edit_util(multiShipmentLevel, shipment_name, rangeObjectIndex, dlvFeeConfigState);
      setDlvFeeConfigState(newDlvFeeConfigState);
    }
  };

  const _handle_view_change_configuration = () => {
    let newDlvFeeConfigState = _handle_view_change_configuration_util(dlvFeeConfigState);
    setDlvFeeConfigState(newDlvFeeConfigState);
  };

  const _handle_toggle_change_is_multi_shipment_calculation = (event: any) => {
    let newDlvFeeConfigState = _handle_toggle_change_is_multi_shipment_calculation_util(dlvFeeConfigState);
    setDlvFeeConfigState(newDlvFeeConfigState);
  };

  const _handle_multi_shiment_input_change = (event: any, multiShipmentLevel: any, shipmentName: any) => {
    if (event && event.target) {
      const { name, value } = event.target;
      if (shipmentName && shipmentName !== "") {
        let newDlvFeeConfigState = _handle_service_multi_shiment_input_change(shipmentName, multiShipmentLevel, name, value, dlvFeeConfigState);
        setDlvFeeConfigState(newDlvFeeConfigState);
      }
    }
  };

  const _handle_edit_multi_shipment_enable_toggle_change = (multiShipmentLevel: any, shipment_name: any, toggleName: any) => {
    if (shipment_name !== undefined && shipment_name !== "") {
      let newDlvFeeConfigState = _handle_edit_multi_shipment_enable_toggle_change_util(multiShipmentLevel, shipment_name, toggleName, dlvFeeConfigState);
      setDlvFeeConfigState(newDlvFeeConfigState);
    }
  };

  const fetchMultiShipmentThresholdRecord = async () => {
    setDlvFeeConfigState({ ...dlvFeeConfigState, loading: true });
    const requestPayload = {
      countryName: currentCountry.name,
    };
    const { payload }: any = await appDispatch(fetchMultiShipmenthDlvFeeConfigurationRecordList(requestPayload));
    if (payload && payload.multiShipmentConfigList) {
      if (payload.multiShipmentConfigList && Array.isArray(payload.multiShipmentConfigList) && payload.multiShipmentConfigList.length > 0) {
        let updatedNewDlvFeeConfigState = _design_update_multi_shipment_delivery_fee_state_object(payload.multiShipmentConfigList, dlvFeeConfigState);
        setTimeout(() => setDlvFeeConfigState(updatedNewDlvFeeConfigState), 1000);
      } else {
        setDlvFeeConfigState({ ...dlvFeeConfigState, loading: false });
      }
    } else {
      setDlvFeeConfigState({ ...dlvFeeConfigState, loading: false });
      let errorMessage = ERROR_CREATE_CONFIG;
      if (payload.message && payload.message !== NO_MESSAGE_AVAILABLE) {
        errorMessage = payload.message;
      }
      CustomAlert(ALERT_TYPES.ERROR, errorMessage);
    }
  };

  const updateMultiShipmentDlvFeeConfigChanges = async (payload_Object: any) => {
    const requestPayload = {
      countryName: currentCountry.name,
      payload_Object: payload_Object,
    };
    const { payload }: any = await appDispatch(createOrUpdateMultiShipmentDeliveryFeeconfigAction(requestPayload));
    if (payload && payload.success) {
      CustomAlert(ALERT_TYPES.SUCCESS, `Configuration changes updated successfully`);
      fetchMultiShipmentThresholdRecord();
    } else {
      setDlvFeeConfigState({ ...dlvFeeConfigState, loading: false });
      let errorMessage = ERROR_CREATE_CONFIG;
      if (payload.message && payload.message !== NO_MESSAGE_AVAILABLE) {
        errorMessage = payload.message;
      }
      CustomAlert(ALERT_TYPES.ERROR, errorMessage);
    }
  };

  const _handle_multi_shipment_dlv_fee_update_changes = () => {
    const payload_Object = _validate_multi_shipment_configuration_object(dlvFeeConfigState);
    if (payload_Object && Object.keys(payload_Object).length !== 0) {
      setDlvFeeConfigState({ ...dlvFeeConfigState, loading: true });
      updateMultiShipmentDlvFeeConfigChanges(payload_Object);
    }
  };

  const handleServiceType = (event: any, objName: any, multiShipmentLevel: any, shipmentName: any) => {
    if (event !== undefined && event.target !== undefined && objName !== undefined) {
      const { checked } = event.target;
      let newDlvFeeConfigState = _handle_service_type_drawer_checkbox_input_change(shipmentName, multiShipmentLevel, objName, checked, dlvFeeConfigState);
      setDlvFeeConfigState(newDlvFeeConfigState);
    }
  };

  const handleCapacityUtilisationConfigInputChange = (event: any, multiShipmentLevel: any, shipment_name: any, configIndex: any) => {
    if (shipment_name !== undefined && shipment_name !== "") {
      if (event && event.target) {
        const { name, value } = event.target;
        if (name !== undefined && name !== "" && value !== undefined) {
          const decimalRegex = /^(\d+([.]{1}\d*)?)$/;
          if (name === "deliveryFee" && value !== "" && !decimalRegex.test(value)) {
            event.target.value = "";
            return;
          } else {
            let newDlvFeeConfigState = _handle_change_capacity_utilisation_field_data(multiShipmentLevel, shipment_name, configIndex, name, value, dlvFeeConfigState);
            setDlvFeeConfigState(newDlvFeeConfigState);
          }
        }
      }
    }
  };

  const _handle_edit_toggle_capacity_utilisation_toggle_change = (multiShipmentLevel: any, shipment_name: any, configIndex: any) => {
    if (shipment_name !== undefined && shipment_name !== "") {
      let newDlvFeeConfigState = _handle_edit_toggle_change_capacity_utilisation_data(multiShipmentLevel, shipment_name, configIndex, dlvFeeConfigState);
      setDlvFeeConfigState(newDlvFeeConfigState);
    }
  };

  useEffect(() => {
    // fetch the pos zone mapping list on change in length of activeServiceTypeList
    currentCountry && currentCountry !== "" && currentCountry.countryId !== undefined && currentCountry.countryId !== "" && fetchPosZoneServiceTypeRecord();
  }, [dlvFeeConfigState.activeServiceTypeList.length]);

  useEffect(() => {
    // fetched the pos zone mapping list, got error
    posZoneMapListService && posZoneMapListService.error && configurePosZoneDataError();
  }, [posZoneMapListService, posZoneMapListService.error]);

  useEffect(() => {
    // fetched the pos zone mapping list, got success. Configure the list
    posZoneMapListService && posZoneMapListService.data && configurePosZoneData();
  }, [posZoneMapListService, posZoneMapListService.data]);

  useEffect(() => {
    // First method on screen load. Fetch the shipment type list.
    shipmentTypeDetailsService && shipmentTypeDetailsService.data && configureShipmentTypeData();
  }, [shipmentTypeDetailsService, shipmentTypeDetailsService.data]);

  useEffect(() => {
    dlvFeeConfigurationRecordService && dlvFeeConfigurationRecordService.data && getCurrentDlvFeeConfiguration();
  }, [dlvFeeConfigurationRecordService, dlvFeeConfigurationRecordService.data]);

  useEffect(() => {
    // fetch the multi shipment record if the multi shipment screen is visible
    dlvFeeConfigState && dlvFeeConfigState.isShipmentLevelConfigurationView !== undefined && dlvFeeConfigState.isShipmentLevelConfigurationView === false && fetchMultiShipmentThresholdRecord();
  }, [dlvFeeConfigState.isShipmentLevelConfigurationView]);

  return (
    <div className={classes.container}>
      <Grid container justifyContent="space-between">
        <Grid item xs={6}>
          {dlvFeeConfigState && dlvFeeConfigState.updateConfigPosId !== "" ? (
            <Typography className={classes.heading}>{`Edit PosNo ${dlvFeeConfigState.updateConfigPosId} - ${dlvFeeConfigState.updateConfigZoneName}`}</Typography>
          ) : (
            <Typography className={classes.heading}>Create New Configuration</Typography>
          )}
        </Grid>
        <Grid item xs={6} className={classes.multiShipmentButtonDiv}>
          {dlvFeeConfigState.isShipmentLevelConfigurationView === true && (
            <PrimaryButton
              className={classes.multiShipmentButtonStyle}
              buttonLabel="View Multi Shipment Configurations"
              disableFocusRipple={true}
              disableRipple={true}
              onClick={_handle_view_change_configuration}
            />
          )}
        </Grid>
      </Grid>

      <Grid className={classes.configDetailsDiv}>
        {dlvFeeConfigState.loading ? (
          <Loader />
        ) : dlvFeeConfigState.isShipmentLevelConfigurationView !== undefined && dlvFeeConfigState.isShipmentLevelConfigurationView === true ? (
          <>
            <ShipmentLevelConfiguration
              classes={classes}
              state={dlvFeeConfigState.shipmentLevel}
              _handle_calculation_toggle_change={_handle_calculation_toggle_change}
              handleInputChange={handleInputChange}
              _handle_click_add_threshold_range={_handle_click_add_threshold_range}
              _handle_click_delete_threshold_range={_handle_click_delete_threshold_range}
              handleRangeInputChange={handleRangeInputChange}
              _handle_open_configuration_dialog={_handle_open_configuration_dialog}
              openShipmentLevel={dlvFeeConfigState.openShipmentLevel}
              _handle_accordion_open_state={_handle_accordion_open_state}
              updateConfigPosId={dlvFeeConfigState.updateConfigPosId}
              updateConfigZoneName={dlvFeeConfigState.updateConfigZoneName}
              _handle_calculation_active_toggle_change={_handle_calculation_active_toggle_change}
              _handle_edit_update_calculation_toggle_change={_handle_edit_update_calculation_toggle_change}
              removeTimeConfigRecord={removeTimeConfigRecord}
              handleAdditionalConfigInputChange={handleAdditionalConfigInputChange}
              _handle_edit_toggle_additional_calculation_toggle_change={_handle_edit_toggle_additional_calculation_toggle_change}
              _handle_edit_threshold_rane_toggle_change_enable_Edit={_handle_edit_threshold_rane_toggle_change_enable_Edit}
              handleServiceType={handleServiceType}
              handleCapacityUtilisationConfigInputChange={handleCapacityUtilisationConfigInputChange}
              _handle_edit_toggle_capacity_utilisation_toggle_change={_handle_edit_toggle_capacity_utilisation_toggle_change}
            />

            {dlvFeeConfigState && dlvFeeConfigState.updateConfigZoneName === "" && dlvFeeConfigState.updateConfigPosId === "" && (
              <DlvFeeConfigPosZoneMapping
                classes={classes}
                dlvFeeConfigState={dlvFeeConfigState}
                _handle_Pos_Zone_Search_Text_Change={_handle_Pos_Zone_Search_Text_Change}
                _handle_update_pos_number_open_index={_handle_update_pos_number_open_index}
                _handle_header_row_check_box_on_change={_handle_header_row_check_box_on_change}
                _handle_header_main_row_check_box_on_change={_handle_header_main_row_check_box_on_change}
                _handle_header_inner_row_check_box_on_change={_handle_header_inner_row_check_box_on_change}
                _handle_delete_selected_row={_handle_delete_selected_row}
                openPosZonMapping={dlvFeeConfigState.openPosZonMapping}
                _handle_accordion_open_state={_handle_accordion_open_state}
              />
            )}
          </>
        ) : (
          <MultiShipmentLevelConfiguration
            openMultiShipmentLevel={dlvFeeConfigState.openMultiShipmentLevel}
            classes={classes}
            _handle_accordion_open_state={_handle_accordion_open_state}
            mainState={dlvFeeConfigState}
            state={dlvFeeConfigState.multiShipmentLevel}
            _handle_toggle_change_is_multi_shipment_calculation={_handle_toggle_change_is_multi_shipment_calculation}
            _handle_calculation_toggle_change={_handle_calculation_toggle_change}
            _handle_multi_shiment_input_change={_handle_multi_shiment_input_change}
            _handle_edit_multi_shipment_enable_toggle_change={_handle_edit_multi_shipment_enable_toggle_change}
          />
        )}
      </Grid>

      <Grid className={classes.configButtonDiv}>
        {dlvFeeConfigState.isShipmentLevelConfigurationView === true && (
          <>
            <Link to={{ pathname: `${APP_ROUTES.DELIVERY_FEE}` }} style={{ textDecoration: "none" }}>
              <PrimaryButton className={classes.cancelConfigStyle} buttonLabel="Back To List" disableFocusRipple={true} disableRipple={true} />
            </Link>
            {dlvFeeConfigState.updateConfigZoneName !== "" ? (
              <PrimaryButton className={classes.previewAndUpdateConfigStyle} buttonLabel={`Update`} disableFocusRipple={true} disableRipple={true} onClick={_handle_dlv_fee_config_update} />
            ) : (
              <PrimaryButton className={classes.previewAndUpdateConfigStyle} buttonLabel="Save" disableFocusRipple={true} disableRipple={true} onClick={_handle_dlv_fee_config_save} />
            )}
          </>
        )}
        {dlvFeeConfigState.isShipmentLevelConfigurationView === false && (
          <>
            <PrimaryButton className={classes.cancelConfigStyle} buttonLabel="Cancel" disableFocusRipple={true} disableRipple={true} onClick={_handle_view_change_configuration} />
            <PrimaryButton
              className={classes.previewAndUpdateConfigStyle}
              buttonLabel="Update Changes"
              disableFocusRipple={true}
              disableRipple={true}
              onClick={_handle_multi_shipment_dlv_fee_update_changes}
            />
          </>
        )}
      </Grid>

      {dlvFeeConfigState && dlvFeeConfigState.stateConfigurationDialog && (
        <DeliveryFeeDateTime
          open={dlvFeeConfigState.stateConfigurationDialog}
          handleClose={_handle_close_configuration_dialog}
          serviceTypeDetailsObj={dlvFeeConfigState.stateConfigurationStateObject}
          _handle_on_save_configuration_dialog={_handle_on_save_configuration_dialog}
          state={dlvFeeConfigState}
        />
      )}
    </div>
  );
};
export default React.memo(AddUpdateDlvFeeConfigPage);
