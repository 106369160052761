import React from "react";

import moment from "moment";

import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";

import TextBox from "../../../common/TextBox/index";
import SelectBox from "../../../common/SelectBox/SelectBox";
import TimePicker from "../../../common/TimePicker/TimePicker";
import LinkButton from "../../../common/LinkButton/LinkButton";
import CustomAlert from "../../../common/CustomAlert/CustomAlert";
import SwitchButton from "../../../common/SwitchButton/SwitchButton";

import { ReactComponent as DeleteSlotIcon } from "../../../assets/svg/DeleteIcon.svg";
import { ReactComponent as EditIcon } from "../../../assets/svg/Pencil.svg";
import { ReactComponent as CheckedIcon } from "../../../assets/svg/CheckedIcon.svg";

import { ALERT_TYPES, SLOT_STATUSES, QUOTA_VIEW_OPTIONS } from "../../../../config/constants";
import { generateDropdownItems } from "./../../../../utils/helperFunctions";

// Styles
import templateSlotInEditModeStyles from "./TemplateSlotInEditModeStyles";

const HOURS = generateDropdownItems(0, 95, "hours");
const MINUTES = generateDropdownItems(0, 59, "mins");

const TemplateSlotInEditMode = ({
  slot,
  reduxSlot = {},
  canSlotBeDeleted,
  showQuotaColumn,
  handleIndividualSlotCheckbox,
  handleIndividualSlotTimeChange,
  handleIndividualSlotFieldsChange,
  makeSlotEditable,
  cancelEditingASlot,
  handleUpdateTemplateSlot,
  handleDeleteTemplateSlot,
  handleSaveNewTemplateSlot,
  handleViewQuotaSettings,
}) => {
  const classes = templateSlotInEditModeStyles();

  const isNewSlot = !slot.id;

  const { id, startTime, endTime, status, time, slotCapacity, bufferTimeHours, bufferTimeMinutes, isCreating } = slot;

  const { canBeUpdated, isUpdating, isDeleting, isUpdated } = reduxSlot;

  const calculateOrderCutOff = (slot) => {
    let orderCutOff = "";
    const { bufferTimeHours, bufferTimeMinutes, startTime } = slot;
    if (startTime && moment(startTime).isValid()) {
      const totalBufferTimeInMinutes = bufferTimeHours * 60 + bufferTimeMinutes;
      orderCutOff = moment(startTime).subtract(totalBufferTimeInMinutes, "m").format("HH:mm");
    }

    return orderCutOff || "";
  };

  const validateSlotTime = (slot) => {
    const { startTime, endTime } = slot;

    if (startTime && endTime) {
      const serviceStartTimeInMinutes = new Date(startTime).getTime();
      const serviceEndTimeInMinutes = new Date(endTime).getTime();

      if (serviceStartTimeInMinutes >= serviceEndTimeInMinutes) {
        CustomAlert(ALERT_TYPES.ERROR, "Service start time should be lesser than the service end time");
        return false;
      }
    }

    return true;
  };

  const handleUpdateSlot = () => {
    if (validateSlotTime(slot)) {
      handleUpdateTemplateSlot(slot);
    }
  };

  const handleNewSlotSubmit = () => {
    if (validateSlotTime(slot)) {
      handleSaveNewTemplateSlot(slot);
    }
  };

  return (
    <Grid
      container
      spacing={1}
      key={id}
      className={`${classes.templateSlot} ${canBeUpdated ? classes.isInEditMode : ""}`}
    >
      {canBeUpdated || isNewSlot ? (
        <Grid item xs={3}>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.slotCheckboxStyle}
                    checked={!!(status === SLOT_STATUSES.ACTIVATED || status === SLOT_STATUSES.PENDING_ACTIVATION)}
                    onChange={handleIndividualSlotCheckbox}
                    value={time}
                    disabled={!canBeUpdated}
                  />
                }
              />
            </Grid>
            <Grid item xs={11}>
              <Grid container spacing={1} className={classes.slotEditTimeContainer}>
                <Grid item xs={5}>
                  <TimePicker
                    showTimeIcon={false}
                    value={startTime}
                    handleChange={(date) => handleIndividualSlotTimeChange(date, "startTime")}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TimePicker
                    showTimeIcon={false}
                    value={endTime}
                    handleChange={(date) => handleIndividualSlotTimeChange(date, "endTime")}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                className={classes.slotCheckboxStyle}
                checked={!!(status === SLOT_STATUSES.ACTIVATED || status === SLOT_STATUSES.PENDING_ACTIVATION)}
                onChange={handleIndividualSlotCheckbox}
                value={slot.time}
                disabled={!canBeUpdated || !isNewSlot}
              />
            }
            label={
              <Grid container>
                <Typography className={classes.checkboxLabel}>{slot.time}</Typography>
                {isUpdated && <CheckedIcon className={classes.isSlotUpdatedIconStyle} />}
              </Grid>
            }
          />
        </Grid>
      )}
      <Grid item xs={showQuotaColumn ? 1 : 3}>
        <FormControl className={classes.slotField}>
          <TextBox
            type="number"
            name="slotCapacity"
            value={slotCapacity}
            handleChange={handleIndividualSlotFieldsChange}
            inputProps={{ maxLength: 15 }}
            textBoxId="capacityGenerated"
            disabled={!canBeUpdated && !isNewSlot}
            placeholderText="Enter Capacity"
            isNegativeAllowed={false}
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <SelectBox
              label="Hrs"
              name="bufferTimeHours"
              menuItems={HOURS}
              value={bufferTimeHours}
              handleChange={handleIndividualSlotFieldsChange}
              disabled={!canBeUpdated && !isNewSlot}
              labelClass={classes.hideShrinkedLabel}
              selectClass={classes.selectClass}
              className={classes.slotField}
            />
          </Grid>
          <Grid item xs={5}>
            <SelectBox
              label="Mins"
              name="bufferTimeMinutes"
              menuItems={MINUTES}
              value={bufferTimeMinutes}
              handleChange={handleIndividualSlotFieldsChange}
              disabled={!canBeUpdated && !isNewSlot}
              labelClass={classes.hideShrinkedLabel}
              selectClass={classes.selectClass}
              className={classes.slotField}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1}>
        {calculateOrderCutOff(slot)}
      </Grid>
      {showQuotaColumn ? (
        <Grid item xs={2}>
          <Grid container alignItems="center">
            <Grid item>
              <SwitchButton
                name="isQuotaEnabled"
                disabled={!canBeUpdated && !isNewSlot}
                checked={!!slot.isQuotaEnabled}
                value={!!slot.isQuotaEnabled}
                handleChange={handleIndividualSlotFieldsChange}
              />
            </Grid>
            <Grid item>
              <LinkButton buttonLabel="View" onClick={() => handleViewQuotaSettings(QUOTA_VIEW_OPTIONS.VIEW, slot)} />
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      {!isNewSlot && (
        <Grid item xs={2}>
          <Grid container spacing={4} alignItems="center">
            {canBeUpdated ? (
              <Grid item xs={10}>
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={5}>
                    <LinkButton
                      className={classes.updateSlotButton}
                      buttonLabel={isUpdating ? "saving..." : "Save"}
                      disabled={isUpdating || !startTime || !endTime}
                      onClick={handleUpdateSlot}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <LinkButton
                      className={classes.updateSlotButton}
                      buttonLabel="Cancel"
                      onClick={() => cancelEditingASlot(slot)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={2}>
                <EditIcon className={classes.iconButton} onClick={() => makeSlotEditable(slot)} />
              </Grid>
            )}
            {canSlotBeDeleted && !canBeUpdated && (
              <Grid item xs={2}>
                {isDeleting ? (
                  "deleting..."
                ) : (
                  <DeleteSlotIcon className={classes.iconButton} onClick={() => handleDeleteTemplateSlot(slot)} />
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
      {isNewSlot && (
        <Grid item xs={2}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={4}>
              <LinkButton
                className={classes.updateSlotButton}
                buttonLabel={isCreating ? "saving..." : "Save"}
                onClick={handleNewSlotSubmit}
                disabled={isCreating || !startTime || !endTime}
              />
            </Grid>
            <Grid item xs={2}>
              <LinkButton buttonLabel="Cancel" onClick={() => handleDeleteTemplateSlot(slot)} />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(TemplateSlotInEditMode);
