import { createStyles, makeStyles } from "@material-ui/core";
import { COLORS } from "../../../config/theme/theme";

const ruleEngineStyles = makeStyles(
  createStyles({
    root: {
      margin: 0,
      padding: 0,
    },
    accordionDetails: {
      margin: 0,
      padding: "24px",
      display: "block",
    },
    tabPanelStyle: {
      padding: "16px 8px",
    },
    tabStyle: {
      margin: "6px 8px",
      fontSize: "18px",
    },
    typeOfPromiseContainer: {
      padding: "12px",
      margin: "0px 0px 24px 0px",
      width: "100%",
      backgroundColor: COLORS.WHITE,
      boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
      borderRadius: "8px",
    },
    posNameContainer: {
      backgroundColor: COLORS.WHITE,
      borderRadius: "8px",
      padding: "10px",
      marginBottom: "24px",
    },
    posnameText: {
      color: COLORS.BLACK,
      fontSize: "20px",
      fontWeight: 600,
    },
    typeOfPromiseText: {
      color: COLORS.PRIMARY_MAIN,
      fontSize: "16px",
      fontWeight: 600,
    },
    tabsBorder: {
      borderBottom: `2px solid ${COLORS.SECONDARY_DARK}`,
      marginTop: "-2px",
    },
    tabsIndicator: {
      backgroundColor: "#0E5AA7",
    },
    ruleContainer: {
      margin: "0px 0px 24px 0px",
      padding: "12px",
      width: "100%",
      backgroundColor: "#F9F9F9",
      borderRadius: "4px",
      border: "1px solid #F9F9F9",
      boxShadow: "0px 1px 4px rgb(0 0 0 / 15%)",
    },
    ruleLabel: {
      fontWeight: 600,
      fontSize: "16px",
      // marginBottom: "16px",
      borderRadius: "8px",
    },
    sameAsEarlierCheckbox: {
      marginRight: "8px",
    },
    dFlexEnd: {
      display: "flex",
      justifyContent: "flex-end",
    },
    checkboxLabel: {
      fontSize: "16px",
    },
    disableCloseBtn: {
      color: COLORS.GRAY_LIGHT,
    },
    disableSwitch: {
      opacity: 0.5,
    },
  })
);

export default ruleEngineStyles;
