import React from "react";

import { Drawer, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";

import NavLinkDOS from "../App/NavLinkDOS";

import DashboardSideMenu from "../../assets/svg/SideMenu/DashboardSideMenu.svg";
import PosListSideMenu from "../../assets/svg/SideMenu/PosListSideMenu.svg";
import UserManagementSideMenu from "../../assets/svg/SideMenu/UserManagementSideMenu.svg";
import RuleEngineSideMenu from "../../assets/svg/SideMenu/RuleEngineSideMenu.svg";
import PlasticBagSideMenu from "../../assets/svg/SideMenu/PlasticBagSideMenu.svg";
import DeliveryFeeSideMenu from "../../assets/svg/SideMenu/DeliveryFeeSideMenu.svg";
import ApprovalFlowSideMenu from "../../assets/svg/SideMenu/ApprovalFlowSideMenu.svg";

import Phone from "../../assets/svg/Phone.svg";
import { APP_ROUTES } from "config/constants";

interface SideMenuInterface {
  openSideMenu: any;
  classes: any;
  SideBarMenuItemsList: any;
  mouseOverEventOnSideMenu: any;
  selectedIndex: any;
  handleListItemClick: any;
}
const SideMenu = (props: SideMenuInterface) => {
  const { openSideMenu, classes, SideBarMenuItemsList, mouseOverEventOnSideMenu, selectedIndex, handleListItemClick } = props;

  const getSVGIconName = (pathLink: any) => {
    switch (pathLink) {
      case APP_ROUTES.DASHBOARD:
        return DashboardSideMenu;

      case APP_ROUTES.POS_LIST:
        return PosListSideMenu;

      case APP_ROUTES.USER_MANAGEMENT:
        return UserManagementSideMenu;

      case APP_ROUTES.RULE_ENGINE:
        return RuleEngineSideMenu;

      case APP_ROUTES.SINGLE_USE_PLASTIC:
        return PlasticBagSideMenu;

      case APP_ROUTES.DELIVERY_FEE:
        return DeliveryFeeSideMenu;

      case APP_ROUTES.APPROVAL_REQUESTS:
        return ApprovalFlowSideMenu;

      default:
        return Phone;
    }
  };

  return (
    <Drawer
      open={openSideMenu}
      id="drawer-container"
      className={classes.drawer}
      variant="permanent"
      anchor="left"
      classes={{ paperAnchorLeft: classes.paperAnchorLeft, paper: openSideMenu ? classes.drawerPaperOpen : classes.drawerPaperClose }}
      onMouseOver={mouseOverEventOnSideMenu}
    >
      <List>
        {SideBarMenuItemsList !== undefined &&
          Array.isArray(SideBarMenuItemsList) &&
          SideBarMenuItemsList.length > 0 &&
          SideBarMenuItemsList.map((sideItem: any, sideItemIndex: any) => (
            <ListItem
              key={`${sideItemIndex}-sideItemIndex-${sideItem.value}`}
              button
              component={NavLinkDOS}
              to={sideItem.to}
              className={classes.listStyle}
              selected={selectedIndex === sideItemIndex}
              onClick={(event: any) => handleListItemClick(event, sideItemIndex, sideItem.to)}
              disabled={sideItem.isDisabled}
            >
              <ListItemIcon>
                <img src={getSVGIconName(sideItem.to)} className={classes.listIconSVG} alt="route_icon" />
              </ListItemIcon>
              <ListItemText primary={`${sideItem.label}`} classes={{ root: selectedIndex === sideItemIndex ? classes.listItemSelectedSpan : classes.listItemSpan }} disableTypography />
            </ListItem>
          ))}
      </List>
    </Drawer>
  );
};

export default React.memo(SideMenu);
