import { ALERT_TYPES } from "config/constants";
import { isEmptyOrNull } from "utils/DataUtils";
import CustomAlert from "../../../common/CustomAlert/CustomAlert";

export const capitalizeFirstLetter = (nameString: any) => {
  return nameString.charAt(0).toUpperCase() + nameString.slice(1);
};

export const designPosZoneServiceListRecord = (responseObject: any) => {
  let listData = [] as any;
  let totalRowsCountData = 0;
  if (responseObject && responseObject.content && Array.isArray(responseObject.content) && responseObject.content.length > 0) {
    responseObject.content.forEach((element: any) => {
      const { posId, posNo, storeName } = element;
      const mainPosObject = {
        id: posNo,
        posId: posId,
        posNo: posNo,
        posName: storeName,
        zoneServicetypeList: [] as any,
        serviceTypeRecord: 0,
        zoneTypeListRecord: 0,
        isOpened: false,
        isChecked: false,
      };
      const serviceTypeList = new Set<string>();
      const zoneTypeList = new Set<string>();
      if (element && element.zoneServiceTypeList && Array.isArray(element.zoneServiceTypeList) && element.zoneServiceTypeList.length > 0) {
        const { zoneServiceTypeList } = element;
        zoneServiceTypeList.forEach((elementType: any) => {
          serviceTypeList.add(elementType.serviceType);
          zoneTypeList.add(elementType.zoneName);
          const subPosObject = {
            posId: posId,
            posNo: posNo,
            posName: storeName,
            zoneName: elementType.zoneName,
            zoneId: elementType.zoneId,
            serviceTypeId: elementType.serviceTypeId,
            serviceType: elementType.serviceType,
            isChecked: false,
            cncReferenceId: elementType.cncReferenceId !== "" ? elementType.cncReferenceId : "",
          };
          mainPosObject.zoneServicetypeList.push(subPosObject);
          totalRowsCountData++;
        });
      }
      mainPosObject.serviceTypeRecord = serviceTypeList.size;
      mainPosObject.zoneTypeListRecord = zoneTypeList.size;
      listData.push(mainPosObject);
    });
  }
  const returnObject = {
    totalRowsCountData: totalRowsCountData,
    listData: listData,
  };
  return returnObject;
};

export const validateNewPosRecord = (configurationState: any, posZoneMappingState: any) => {
  if (configurationState) {
    if (isEmptyOrNull(configurationState.itemsPerBag)) {
      CustomAlert(ALERT_TYPES.ERROR, "Items per bag is required");
      return true;
    }

    if (Number(configurationState.itemsPerBag) <= 0) {
      CustomAlert(ALERT_TYPES.ERROR, "Items per bag cannot be less than one");
      return true;
    }

    if (isEmptyOrNull(configurationState.pricePerBag)) {
      CustomAlert(ALERT_TYPES.ERROR, "Price per bag is required");
      return true;
    }

    if (
      posZoneMappingState &&
      posZoneMappingState.selectedPosServiceTypeZone &&
      Array.isArray(posZoneMappingState.selectedPosServiceTypeZone) &&
      posZoneMappingState.selectedPosServiceTypeZone.length &&
      posZoneMappingState.selectedPosServiceTypeZone.length > 0
    ) {
      return false;
    } else {
      CustomAlert(ALERT_TYPES.ERROR, "Assigned area(s) list is required");
      return true;
    }
  }
  return true;
};

export const handleHeaderInnerRowCheckboxOnchange = (checked: any, posListIndex: any, posDataIndex: any, posZoneMappingState: any) => {
  const obj: any = {
    currentData: [...posZoneMappingState.posZoneServiceRecord],
    totalAssignedAreaCount: posZoneMappingState.totalAssignedAreaCount,
    selectedListItems: [...posZoneMappingState.selectedPosServiceTypeZone],
  };
  if (obj.currentData && obj.totalAssignedAreaCount >= 0 && obj.selectedListItems) {
    obj.currentData[posListIndex].zoneServicetypeList[posDataIndex].isChecked = checked;

    if (checked) {
      obj.selectedListItems.push(obj.currentData[posListIndex].zoneServicetypeList[posDataIndex]);
      obj.totalAssignedAreaCount++;
    } else {
      const dataToDelete = obj.currentData[posListIndex].zoneServicetypeList[posDataIndex];
      var removeIndex = obj.selectedListItems.findIndex((item: any) => item.posNo === dataToDelete.posNo && item.serviceType === dataToDelete.serviceType && item.zoneName === dataToDelete.zoneName);
      if (removeIndex !== -1) {
        obj.selectedListItems.splice(removeIndex, 1);
      }
      obj.totalAssignedAreaCount--;
    }

    // Checking if all inner are checked then make parent checked also
    // Checking if any inner are un-checked then make parent un-checked also
    //
    let totalRowsSelected = 0;
    obj.currentData[posListIndex].zoneServicetypeList.forEach((item: any) => {
      if (item.isChecked) {
        totalRowsSelected++;
      }
    });
    if (totalRowsSelected === obj.currentData[posListIndex].zoneServicetypeList.length) {
      obj.currentData[posListIndex].isChecked = checked;
    } else {
      obj.currentData[posListIndex].isChecked = false;
    }
  }
  return obj;
};

export const handleHeaderMainRowCheckboxOnchange = (checked: any, posListIndex: any, posZoneMappingState: any) => {
  const obj: any = {
    currentData: [...posZoneMappingState.posZoneServiceRecord],
    totalAssignedAreaCount: posZoneMappingState.totalAssignedAreaCount,
    selectedListItems: [...posZoneMappingState.selectedPosServiceTypeZone],
  };
  if (obj.currentData && obj.totalAssignedAreaCount >= 0 && obj.selectedListItems) {
    obj.currentData[posListIndex].isChecked = checked;
    obj.currentData[posListIndex].zoneServicetypeList.forEach((posDetails: any) => {
      posDetails.isChecked = checked;
      if (checked) {
        obj.totalAssignedAreaCount++;
        obj.selectedListItems.push(posDetails);
      } else {
        obj.totalAssignedAreaCount--;
        var removeIndex = obj.selectedListItems.findIndex((item: any) => item.posNo === posDetails.posNo && item.serviceType === posDetails.serviceType && item.zoneName === posDetails.zoneName);
        if (removeIndex !== -1) {
          obj.selectedListItems.splice(removeIndex, 1);
        }
      }
    });
  }
  return obj;
};

export const handleDeleteSelectedRow = (itemData: any, index: any, posZoneMappingState: any) => {
  const obj: any = {
    currentData: [...posZoneMappingState.posZoneServiceRecord],
    totalAssignedAreaCount: posZoneMappingState.totalAssignedAreaCount,
    selectedListItems: [...posZoneMappingState.selectedPosServiceTypeZone],
  };
  if (obj.currentData && obj.totalAssignedAreaCount >= 0 && obj.selectedListItems) {
    obj.selectedListItems.splice(index, 1);
    obj.totalAssignedAreaCount--;
    var removeIndex = obj.currentData.findIndex((item: any) => item.posNo === itemData.posNo);
    if (removeIndex !== -1) {
      obj.currentData[removeIndex].isChecked = false;
      const innerIndex = obj.currentData[removeIndex].zoneServicetypeList.findIndex(
        (item: any) => item.posNo === itemData.posNo && item.serviceType === itemData.serviceType && item.zoneName === itemData.zoneName
      );
      if (innerIndex !== -1) {
        obj.currentData[removeIndex].zoneServicetypeList[innerIndex].isChecked = false;
      }
    }
  }
  return obj;
};

export const generateRequestPayload = (countryId: any, configurationState: any, posZoneMappingState: any) => {
  const obj: any = {
    countryId: countryId,
    bagDetails: [],
    selectedPosServiceTypeZone: [],
  };
  const bagDetailsData = {
    itemsPerBag: Number(configurationState.itemsPerBag),
    pricePerBag: Number(configurationState.pricePerBag),
  };
  obj.bagDetails.push(bagDetailsData);
  let selectedListItems: any = [];
  if (posZoneMappingState && posZoneMappingState.selectedPosServiceTypeZone && Array.isArray(posZoneMappingState.selectedPosServiceTypeZone)) {
    posZoneMappingState.selectedPosServiceTypeZone.forEach((item: any) => {
      const obj = {
        posId: item.posId,
        posNo: item.posNo,
        storeName: item.posName,
        zoneId: item.zoneId,
        zone: item.zoneName,
        serviceTypeId: item.serviceTypeId,
        serviceType: item.serviceType,
        cncReferenceId: item.cncReferenceId,
      };
      selectedListItems.push(obj);
    });
  }
  obj.selectedPosServiceTypeZone = [...selectedListItems];
  return obj;
};

export const validateUpdatePosRecord = (editedBagList: any, itemsPerBagRegExp: any, pricePerBagRegExp: any) => {
  if (editedBagList) {
    let isErrorFound: boolean = false;
    Object.values(editedBagList).forEach((item: any) => {
      if (item) {
        if (!itemsPerBagRegExp.test(item.itemsPerBag)) {
          CustomAlert(ALERT_TYPES.ERROR, "Please provide valid input for Item Per Bag.");
          isErrorFound = true;
          return true;
        }
        if (Number(item.itemsPerBag) <= 0) {
          CustomAlert(ALERT_TYPES.ERROR, "Items per bag cannot be less than one");
          isErrorFound = true;
          return true;
        }
        if (!pricePerBagRegExp.test(item.pricePerBag)) {
          CustomAlert(ALERT_TYPES.ERROR, "Please provide valid input for Bag Price.");
          isErrorFound = true;
          return true;
        }
      }
    });

    if (!isErrorFound) {
      return false;
    }
  }
  return true;
};
