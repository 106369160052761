import produce from "immer";
import moment from "moment";

import CustomAlert from "../../common/CustomAlert/CustomAlert";

import { isEmptyArray, isEmptyOrNull } from "../../../utils/DataUtils";
import { ALERT_TYPES, TYPES_OF_RULE_ENGINES } from "../../../config/constants";
import { weekday } from "../common/constant";
import { getDates } from "../UtilsNew/DeliveryFeeDateTimeUtils";

export const thresholdAmountRegExp = new RegExp("^[0-9]+$");
export const amountValueRegExp = new RegExp("^[0-9]+.?[0-9]*$");

export const capitalizeFirstLetter = (nameString: any) => {
  return nameString.charAt(0).toUpperCase() + nameString.slice(1);
};

export const createCamelCase = (nameString: any) => {
  return nameString.charAt(0).toUpperCase() + nameString.slice(1).toLowerCase();
};

export const _handle_accordion_open_state_util = (dlvFeeConfigState: any, openStateName: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    draft[openStateName] = !draft[openStateName];
  });
  return newDlvFeeConfigState;
};

export const _design_delivery_fee_state_object = (shipmentLevelData: any, dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (shipmentLevelData && Array.isArray(shipmentLevelData) && shipmentLevelData.length > 0) {
      shipmentLevelData.forEach((item: any) => {
        if (item.multiShipment) {
          draft.multiShipmentLevel[item.shipment] = {
            shipmentId: item.id,
            multi_shipment: item.multiShipment,
            isEditMode: false, // main action, "Use this value in calculation"
            enabledEdit: true,
            orderThreshold: "",
            deliveryFee: "",
            isPercentage: false,
          };
        } else {
          draft.shipmentLevel[item.shipment] = {
            shipmentId: item.id,
            multi_shipment: item.multiShipment,
            isEditMode: false, // main action, "Use this value in calculation"
            enableBelowThresholdEdit: false, // to use in update screen, change with pencil icon
            enableAboveThresholdEdit: false, // to use in update screen, change with pencil icon
            active: true, // to use in update screen, make configuration active or in-active
            orderThreshold: "",
            greaterOrderThresold: "",
            belowThreshold: "",
            aboveThreshold: "",
            additionalDeliveryFeeConfigDTOList: [],
            deliveryFeeRangeConfigDTOList: [],
            showDrawerConfig: false,
            updateDrawerConfig: true,
            deliveryFeeSlotCapacityConfigDTOList: [],
            isDeliveryFeeSlotCapacityConfigApplicable: false,
          };
        }
      });
    }
    draft.loading = false;
  });
  return newDlvFeeConfigState;
};

export const _handle_toggle_change = (multiShipmentLevel: any, name: any, toggleName: any, dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (multiShipmentLevel) {
      draft.multiShipmentLevel[name][toggleName] = !draft.multiShipmentLevel[name][toggleName];
    } else {
      if (draft.updateConfigPosId === "" && draft.updateConfigZoneName === "") {
        if (!draft.shipmentLevel[name][toggleName]) {
          draft.activeServiceTypeList.push(name);
        } else {
          draft.activeServiceTypeList = draft.activeServiceTypeList.filter((serviceTypeName: any) => serviceTypeName !== name);
        }
        draft.shipmentLevel[name][toggleName] = !draft.shipmentLevel[name][toggleName];
      }
    }
  });
  return newDlvFeeConfigState;
};

export const _handle_service_type_input_change = (inputName: any, multiShipmentLevel: any, name: any, value: any, dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (name === "orderThreshold" && value !== "" && (isNaN(Number(value)) || !amountValueRegExp.test(value) || parseInt(value) < 0)) {
      return;
    } else if (value !== "" && (isNaN(Number(value)) || !amountValueRegExp.test(value))) {
      return;
    }

    if (multiShipmentLevel) {
      draft.multiShipmentLevel[inputName][name] = value;
    } else {
      if (name === "orderThreshold") {
        draft.shipmentLevel[inputName].greaterOrderThresold = value;
      }

      draft.shipmentLevel[inputName][name] = value;
    }
  });
  return newDlvFeeConfigState;
};

export const _handle_threshold_range_action = (multiShipmentLevel: any, name: any, action: any, dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (!multiShipmentLevel) {
      if (name !== undefined && name !== "") {
        if (action === "add") {
          let dummy_object = {
            enableEdit: false, // to use in update screen, change with pencil icon
            isValid: true, // to check if the newOrderThreshold is greater than previousOrderThreshold
            isErrorText: "",
            previousOrderThreshold: "",
            newOrderThreshold: "",
            deliveryFee: "",
          };
          if (
            draft.shipmentLevel[name].deliveryFeeRangeConfigDTOList &&
            Array.isArray(draft.shipmentLevel[name].deliveryFeeRangeConfigDTOList) &&
            draft.shipmentLevel[name].deliveryFeeRangeConfigDTOList.length > 0
          ) {
            dummy_object.previousOrderThreshold = draft.shipmentLevel[name].deliveryFeeRangeConfigDTOList[draft.shipmentLevel[name].deliveryFeeRangeConfigDTOList.length - 1].newOrderThreshold;
          } else {
            dummy_object.previousOrderThreshold = draft.shipmentLevel[name].orderThreshold;
          }
          draft.shipmentLevel[name].deliveryFeeRangeConfigDTOList.push(dummy_object);
        } else if (action === "remove") {
          if (
            draft.shipmentLevel &&
            draft.shipmentLevel[name] &&
            draft.shipmentLevel[name].deliveryFeeRangeConfigDTOList &&
            Array.isArray(draft.shipmentLevel[name].deliveryFeeRangeConfigDTOList) &&
            draft.shipmentLevel[name].deliveryFeeRangeConfigDTOList.length > 0
          ) {
            draft.shipmentLevel[name].deliveryFeeRangeConfigDTOList.pop();
            if (draft.shipmentLevel[name].deliveryFeeRangeConfigDTOList.length > 0) {
              draft.shipmentLevel[name].greaterOrderThresold =
                draft.shipmentLevel[name].deliveryFeeRangeConfigDTOList[draft.shipmentLevel[name].deliveryFeeRangeConfigDTOList.length - 1].newOrderThreshold;
            } else {
              draft.shipmentLevel[name].greaterOrderThresold = draft.shipmentLevel[name].orderThreshold;
            }
          }
        }
      }
    }
  });
  return newDlvFeeConfigState;
};

export const _handle_service_type_threshold_range_input_change = (multiShipmentLevel: any, shipmentName: any, name: any, value: any, rangeObjectIndex: any, dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (!multiShipmentLevel) {
      if (shipmentName !== undefined && shipmentName !== "" && name !== undefined && name !== "") {
        if (name === "newOrderThreshold" && value !== "" && (isNaN(Number(value)) || !amountValueRegExp.test(value) || parseInt(value) < 0)) {
          return;
        } else if (value !== "" && (isNaN(Number(value)) || !amountValueRegExp.test(value))) {
          return;
        }

        if (name === "newOrderThreshold") {
          draft.shipmentLevel[shipmentName].deliveryFeeRangeConfigDTOList[rangeObjectIndex].isErrorText = "";
          if (Number(value) > Number(draft.shipmentLevel[shipmentName].deliveryFeeRangeConfigDTOList[rangeObjectIndex].previousOrderThreshold)) {
            draft.shipmentLevel[shipmentName].greaterOrderThresold = value;
          } else {
            draft.shipmentLevel[shipmentName].greaterOrderThresold = draft.shipmentLevel[shipmentName].orderThreshold;
            draft.shipmentLevel[shipmentName].deliveryFeeRangeConfigDTOList[rangeObjectIndex].isErrorText = "Value may have configuration already present";
            draft.shipmentLevel[shipmentName].deliveryFeeRangeConfigDTOList.forEach((item: any) => {
              if (
                draft.shipmentLevel[shipmentName].greaterOrderThresold !== undefined &&
                draft.shipmentLevel[shipmentName].greaterOrderThresold !== "" &&
                Number(draft.shipmentLevel[shipmentName].greaterOrderThresold) < Number(item.newOrderThreshold)
              ) {
                draft.shipmentLevel[shipmentName].greaterOrderThresold = item.newOrderThreshold;
              }
            });
          }
        }
        draft.shipmentLevel[shipmentName].deliveryFeeRangeConfigDTOList[rangeObjectIndex][name] = value;
      }
    }
  });
  return newDlvFeeConfigState;
};

export const _handle_design_add_additional_configuration = (dlvFeeConfigState: any, shipmentType: any, isMultiShipmentLevel: any, dateTimeDataObject: any, capacity_utilisation_configuration: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    draft.stateConfigurationDialog = false;
    draft.stateConfigurationStateObject = {};
    if (shipmentType && shipmentType !== "") {
      if (!isMultiShipmentLevel) {
        draft.shipmentLevel[shipmentType].additionalDeliveryFeeConfigDTOList = dateTimeDataObject;
        draft.shipmentLevel[shipmentType].deliveryFeeSlotCapacityConfigDTOList = capacity_utilisation_configuration;
        draft.shipmentLevel[shipmentType].isDeliveryFeeSlotCapacityConfigApplicable =
          capacity_utilisation_configuration !== undefined && Array.isArray(capacity_utilisation_configuration) && capacity_utilisation_configuration.length > 0 ? true : false;
      }
    }
  });
  return newDlvFeeConfigState;
};

export const _handle_empty_pos_zone_mapping_data = (dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    draft.posZoneServiceRecord = [];
    draft.selectedPosServiceTypeZone = [];
    draft.totalRowsCountData = 0;
  });
  return newDlvFeeConfigState;
};

export const _handle_empty_pos_zone_mapping_data_and_loading = (dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    draft.loading = true;
    draft.posZoneServiceRecord = [];
    draft.selectedPosServiceTypeZone = [];
    draft.totalRowsCountData = 0;
    draft.totalAssignedAreaCount = 0;
  });
  return newDlvFeeConfigState;
};

export const _design_Pos_Zone_Service_List_Record = (responseObject: any) => {
  let listData = [] as any;
  let totalRowsCountData = 0;
  if (responseObject && Array.isArray(responseObject) && responseObject.length > 0) {
    responseObject.forEach((element: any) => {
      const { posId, posNo, storeName } = element;
      const mainPosObject = {
        id: posNo,
        posId: posId,
        posNo: posNo,
        posName: storeName,
        zoneServicetypeList: [] as any,
        serviceTypeRecord: 0,
        zoneTypeListRecord: 0,
        isOpened: false,
        isChecked: false,
      };
      const serviceTypeList = new Set<string>();
      const zoneTypeList = new Set<string>();
      if (element && element.zoneServiceTypeList && Array.isArray(element.zoneServiceTypeList) && element.zoneServiceTypeList.length > 0) {
        const { zoneServiceTypeList } = element;
        zoneServiceTypeList.forEach((elementType: any) => {
          serviceTypeList.add(elementType.serviceType);
          zoneTypeList.add(elementType.zoneName);
          const subPosObject = {
            posId: posId,
            posNo: posNo,
            posName: storeName,
            zoneName: elementType.zoneName,
            zoneId: elementType.zoneId,
            serviceTypeId: elementType.serviceTypeId,
            serviceType: elementType.serviceType,
            isChecked: false,
            cncReferenceId: elementType.cncReferenceId !== "" ? elementType.cncReferenceId : "",
          };
          mainPosObject.zoneServicetypeList.push(subPosObject);
          totalRowsCountData++;
        });
      }
      mainPosObject.serviceTypeRecord = serviceTypeList.size;
      mainPosObject.zoneTypeListRecord = zoneTypeList.size;
      listData.push(mainPosObject);
    });
  }
  const returnObject = {
    totalRowsCountData: totalRowsCountData,
    listData: listData,
  };
  return returnObject;
};

export const _handle_Pos_Zone_Service_List_Record = (dlvFeeConfigState: any, totalRowsCountData: any, listData: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    draft.loading = false;
    draft.posZoneServiceRecord = listData;
    draft.selectedPosServiceTypeZone = [];
    draft.totalRowsCountData = totalRowsCountData;
  });
  return newDlvFeeConfigState;
};

export const _handle_empty_pos_zone_mapping_data_and_loading_off = (dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    draft.loading = false;
  });
  return newDlvFeeConfigState;
};

export const _handle_pos_zone_number_open_index = (dlvFeeConfigState: any, posListIdName: any, posIndex: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (
      draft.posZoneServiceRecord &&
      Array.isArray(draft.posZoneServiceRecord) &&
      draft.posZoneServiceRecord.length > 0 &&
      draft.posZoneServiceRecord[posIndex] &&
      draft.posZoneServiceRecord[posIndex].id &&
      draft.posZoneServiceRecord[posIndex].id !== "" &&
      draft.posZoneServiceRecord[posIndex].id === posListIdName
    ) {
      draft.posZoneServiceRecord[posIndex].isOpened = !draft.posZoneServiceRecord[posIndex].isOpened;
    }
  });
  return newDlvFeeConfigState;
};

export const _handle_pos_zone_header_row_check_box_change = (dlvFeeConfigState: any, checked: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (draft.posZoneServiceRecord && Array.isArray(draft.posZoneServiceRecord) && draft.posZoneServiceRecord.length > 0) {
      let totalAssignedAreaCount = 0;
      let selectedPosServiceTypeZone: any = [];
      draft.posZoneServiceRecord.forEach((posItem: any) => {
        posItem.isChecked = checked;
        posItem.zoneServicetypeList.forEach((posDetails: any) => {
          posDetails.isChecked = checked;
          if (checked) {
            totalAssignedAreaCount++;
            selectedPosServiceTypeZone.push(posDetails);
          } else {
            totalAssignedAreaCount = 0;
            selectedPosServiceTypeZone = [];
          }
        });
      });
      draft.totalAssignedAreaCount = totalAssignedAreaCount;
      draft.selectedPosServiceTypeZone = selectedPosServiceTypeZone;
    }
  });
  return newDlvFeeConfigState;
};

export const _handle_header_main_row_check_box_on_change_utils = (dlvFeeConfigState: any, checked: any, posListIndex: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (draft.posZoneServiceRecord && draft.totalAssignedAreaCount >= 0 && draft.selectedPosServiceTypeZone) {
      draft.posZoneServiceRecord[posListIndex].isChecked = checked;
      draft.posZoneServiceRecord[posListIndex].zoneServicetypeList.forEach((posDetails: any) => {
        posDetails.isChecked = checked;
        if (checked) {
          draft.totalAssignedAreaCount++;
          draft.selectedPosServiceTypeZone.push(posDetails);
        } else {
          draft.totalAssignedAreaCount--;
          var removeIndex = draft.selectedPosServiceTypeZone.findIndex(
            (item: any) => item.posNo === posDetails.posNo && item.serviceType === posDetails.serviceType && item.zoneName === posDetails.zoneName
          );
          if (removeIndex !== -1) {
            draft.selectedPosServiceTypeZone.splice(removeIndex, 1);
          }
        }
      });
    }
  });
  return newDlvFeeConfigState;
};

export const _handle_header_inner_row_check_box_on_change_utils = (dlvFeeConfigState: any, checked: any, posListIndex: any, posDataIndex: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (draft.posZoneServiceRecord && draft.totalAssignedAreaCount >= 0 && draft.selectedPosServiceTypeZone) {
      draft.posZoneServiceRecord[posListIndex].zoneServicetypeList[posDataIndex].isChecked = checked;

      if (checked) {
        draft.selectedPosServiceTypeZone.push(draft.posZoneServiceRecord[posListIndex].zoneServicetypeList[posDataIndex]);
        draft.totalAssignedAreaCount++;
      } else {
        const dataToDelete = draft.posZoneServiceRecord[posListIndex].zoneServicetypeList[posDataIndex];
        var removeIndex = draft.selectedPosServiceTypeZone.findIndex(
          (item: any) => item.posNo === dataToDelete.posNo && item.serviceType === dataToDelete.serviceType && item.zoneName === dataToDelete.zoneName
        );
        if (removeIndex !== -1) {
          draft.selectedPosServiceTypeZone.splice(removeIndex, 1);
        }
        draft.totalAssignedAreaCount--;
      }

      let totalRowsSelected = 0;
      draft.posZoneServiceRecord[posListIndex].zoneServicetypeList.forEach((item: any) => {
        if (item.isChecked) {
          totalRowsSelected++;
        }
      });
      if (totalRowsSelected === draft.posZoneServiceRecord[posListIndex].zoneServicetypeList.length) {
        draft.posZoneServiceRecord[posListIndex].isChecked = checked;
      } else {
        draft.posZoneServiceRecord[posListIndex].isChecked = false;
      }
    }
  });
  return newDlvFeeConfigState;
};

export const _handle_delete_selected_row_utils = (dlvFeeConfigState: any, itemData: any, index: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (draft.posZoneServiceRecord && draft.totalAssignedAreaCount >= 0 && draft.selectedPosServiceTypeZone) {
      draft.selectedPosServiceTypeZone.splice(index, 1);
      draft.totalAssignedAreaCount--;
      var removeIndex = draft.posZoneServiceRecord.findIndex((item: any) => item.posNo === itemData.posNo);
      if (removeIndex !== -1) {
        draft.posZoneServiceRecord[removeIndex].isChecked = false;
        const innerIndex = draft.posZoneServiceRecord[removeIndex].zoneServicetypeList.findIndex(
          (item: any) => item.posNo === itemData.posNo && item.serviceType === itemData.serviceType && item.zoneName === itemData.zoneName
        );
        if (innerIndex !== -1) {
          draft.posZoneServiceRecord[removeIndex].zoneServicetypeList[innerIndex].isChecked = false;
        }
      }
    }
  });
  return newDlvFeeConfigState;
};

export const _validate_configuration_save_object = (dlvFeeConfigState: any, type: any) => {
  const payloadObject = {
    shipmentLevelDeliveryFeeConfigs: {},
    selectedPosServiceTypeZone: [],
  } as any;

  let responseValue: any = {};

  if (dlvFeeConfigState && dlvFeeConfigState !== undefined) {
    if (isEmptyArray(dlvFeeConfigState.selectedPosServiceTypeZone)) {
      CustomAlert(ALERT_TYPES.ERROR, "Pos Zone list required");
      return {};
    } else {
      dlvFeeConfigState.selectedPosServiceTypeZone.forEach((posDetails: any) => {
        payloadObject.selectedPosServiceTypeZone.push({
          posNo: posDetails.posNo,
          serviceType: posDetails.serviceType,
          serviceTypeId: posDetails.serviceTypeId,
          storeName: posDetails.posName,
          zone: posDetails.zoneName,
          zoneId: posDetails.zoneId,
        });
      });
    }

    if (dlvFeeConfigState.shipmentLevel) {
      let emptyCount = 0 as any;
      const threshold_zero_error = "Threshold cannot be less than or equal to zero";
      Object.keys(dlvFeeConfigState.shipmentLevel).forEach((supplierType: any, supplierTypeIndex: any) => {
        if (supplierType !== "" && dlvFeeConfigState.shipmentLevel[supplierType] && dlvFeeConfigState.shipmentLevel[supplierType].isEditMode === true) {
          const {
            shipmentId,
            orderThreshold,
            greaterOrderThresold,
            belowThreshold,
            aboveThreshold,
            active,
            deliveryFeeRangeConfigDTOList,
            additionalDeliveryFeeConfigDTOList,
            deliveryFeeSlotCapacityConfigDTOList,
            showDrawerConfig,
            updateDrawerConfig,
          } = dlvFeeConfigState.shipmentLevel[supplierType];

          payloadObject.shipmentLevelDeliveryFeeConfigs[shipmentId] = {
            active: false,
            orderThreshold: "",
            belowThreshold: "",
            aboveThreshold: "",
            additionalDeliveryFeeConfigDTOList: [],
            deliveryFeeRangeConfigDTOList: [],
            showDrawerConfig: showDrawerConfig,
            updateDrawerConfig: !updateDrawerConfig,
            deliveryFeeSlotCapacityConfigDTOList: [],
            isDeliveryFeeSlotCapacityConfigApplicable: false,
          };

          if (active === true) {
            payloadObject.shipmentLevelDeliveryFeeConfigs[shipmentId].active = true;
          }

          if (dlvFeeConfigState.shipmentLevel[supplierType].id !== undefined && dlvFeeConfigState.shipmentLevel[supplierType].id !== "") {
            payloadObject.shipmentLevelDeliveryFeeConfigs[shipmentId].id = dlvFeeConfigState.shipmentLevel[supplierType].id;
          }

          if (isEmptyOrNull(greaterOrderThresold)) {
            responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-thresholdAmount`] = "Enter valid Threshold";
          } else if (isNaN(greaterOrderThresold) || Number(greaterOrderThresold) <= 0) {
            responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-thresholdAmount`] = threshold_zero_error;
          } else {
            payloadObject.shipmentLevelDeliveryFeeConfigs[shipmentId].orderThreshold = Number(greaterOrderThresold);
          }

          if (isEmptyOrNull(belowThreshold)) {
            responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-belowThreshold`] = "Enter valid Below Threshold";
          } else {
            payloadObject.shipmentLevelDeliveryFeeConfigs[shipmentId].belowThreshold = Number(belowThreshold);
          }

          if (isEmptyOrNull(aboveThreshold)) {
            responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-aboveThreshold`] = "Enter valid Above Threshold";
          } else {
            payloadObject.shipmentLevelDeliveryFeeConfigs[shipmentId].aboveThreshold = Number(aboveThreshold);
          }

          //
          // Start
          if (deliveryFeeRangeConfigDTOList && Array.isArray(deliveryFeeRangeConfigDTOList) && deliveryFeeRangeConfigDTOList.length > 0) {
            // if range is present, then first thresold should be a range object
            //start
            if (isEmptyOrNull(orderThreshold)) {
              responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-thresholdAmount`] = "Enter valid Threshold";
            } else if (isNaN(orderThreshold) || Number(orderThreshold) <= 0) {
              responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-thresholdAmount`] = threshold_zero_error;
            } else {
              let pay_load_object = {
                orderThreshold: Number(orderThreshold),
                deliveryFee: Number(belowThreshold),
              } as any;
              payloadObject.shipmentLevelDeliveryFeeConfigs[shipmentId].deliveryFeeRangeConfigDTOList.push(pay_load_object);
            }
            // end
            deliveryFeeRangeConfigDTOList.forEach((rangeObject: any, rangeObjectIndex: any) => {
              if (rangeObject && rangeObject.isErrorText === "") {
                let pay_load_object = {
                  orderThreshold: "",
                  deliveryFee: "",
                } as any;
                const { newOrderThreshold, deliveryFee } = rangeObject;

                if (rangeObjectIndex + 1 === deliveryFeeRangeConfigDTOList.length) {
                  if (isEmptyOrNull(newOrderThreshold)) {
                    responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-deliveryFeeRangeConfigDTOList-${rangeObjectIndex}-newOrderThreshold`] = "Enter valid Threshold";
                  } else if (isNaN(newOrderThreshold) || Number(newOrderThreshold) <= 0) {
                    responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-deliveryFeeRangeConfigDTOList-${rangeObjectIndex}-newOrderThreshold`] = threshold_zero_error;
                  } else {
                    if (isEmptyOrNull(deliveryFee)) {
                      responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-deliveryFeeRangeConfigDTOList-${rangeObjectIndex}-newOrderThreshold`] = "Enter valid Threshold amount";
                    } else {
                      payloadObject.shipmentLevelDeliveryFeeConfigs[shipmentId].belowThreshold = Number(deliveryFee);
                    }
                  }
                } else {
                  if (isEmptyOrNull(newOrderThreshold)) {
                    responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-deliveryFeeRangeConfigDTOList-${rangeObjectIndex}-newOrderThreshold`] = "Enter valid Threshold";
                  } else if (isNaN(newOrderThreshold) || Number(newOrderThreshold) <= 0) {
                    responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-deliveryFeeRangeConfigDTOList-${rangeObjectIndex}-newOrderThreshold`] = threshold_zero_error;
                  } else {
                    pay_load_object.orderThreshold = Number(newOrderThreshold);
                  }

                  if (isEmptyOrNull(deliveryFee)) {
                    responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-belowThreshold`] = "Enter valid Threshold amount";
                  } else {
                    pay_load_object.deliveryFee = Number(deliveryFee);
                  }

                  payloadObject.shipmentLevelDeliveryFeeConfigs[shipmentId].deliveryFeeRangeConfigDTOList.push(pay_load_object);
                }
              } else {
                // last index delivery fee is below delivery fee for the greatest threshold range value
                responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-deliveryFeeRangeConfigDTOList-${rangeObjectIndex}`] = rangeObject.isErrorText;
                return;
              }
            });
          }
          //
          // End

          if (additionalDeliveryFeeConfigDTOList && Array.isArray(additionalDeliveryFeeConfigDTOList) && additionalDeliveryFeeConfigDTOList.length > 0) {
            let addition_config_payload_object = prepare_addtional_date_time_payload(additionalDeliveryFeeConfigDTOList, type);
            if (addition_config_payload_object && addition_config_payload_object.error_object && addition_config_payload_object.error_object !== "") {
              responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-additionalDeliveryFeeConfigDTOList`] = addition_config_payload_object.error_object;
            } else {
              payloadObject.shipmentLevelDeliveryFeeConfigs[shipmentId].additionalDeliveryFeeConfigDTOList = addition_config_payload_object.payload;
            }
          }

          if (deliveryFeeSlotCapacityConfigDTOList && Array.isArray(deliveryFeeSlotCapacityConfigDTOList) && deliveryFeeSlotCapacityConfigDTOList.length > 0) {
            let capacity_utilisation_config_payload_object = prepare_capacity_utilisation_record_payload(deliveryFeeSlotCapacityConfigDTOList, type);
            if (capacity_utilisation_config_payload_object && capacity_utilisation_config_payload_object.error_object && capacity_utilisation_config_payload_object.error_object !== "") {
              responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-deliveryFeeSlotCapacityConfigDTOList`] = capacity_utilisation_config_payload_object.error_object;
            } else {
              payloadObject.shipmentLevelDeliveryFeeConfigs[shipmentId].deliveryFeeSlotCapacityConfigDTOList = capacity_utilisation_config_payload_object.payload;
              payloadObject.shipmentLevelDeliveryFeeConfigs[shipmentId].isDeliveryFeeSlotCapacityConfigApplicable = true;
            }
          }
        } else {
          emptyCount++;
        }
      });

      if (emptyCount === Object.keys(dlvFeeConfigState.shipmentLevel).length) {
        CustomAlert(ALERT_TYPES.ERROR, "Minimum one configurtion is required."); // No supplier type is made active for calculation
        return {};
      } else if (responseValue && Object.keys(responseValue).length !== 0) {
        CustomAlert(ALERT_TYPES.ERROR, responseValue[Object.keys(responseValue)[0]]);
        return {};
      }
    }
  }
  return payloadObject;
};

export const prepare_addtional_date_time_payload = (additionalDlvFeeConfigList: any, type: any) => {
  let addition_config_payload_object = {
    payload: [],
    error_object: "",
  } as any;
  if (additionalDlvFeeConfigList && Array.isArray(additionalDlvFeeConfigList) && additionalDlvFeeConfigList.length > 0) {
    additionalDlvFeeConfigList.forEach((configObj: any) => {
      if (configObj && configObj.daysConfiguration && Array.isArray(configObj.daysConfiguration) && configObj.daysConfiguration.length > 0) {
        configObj.daysConfiguration.forEach((dayObj: any) => {
          if (dayObj && dayObj.days && Array.isArray(dayObj.days) && dayObj.days.length > 0) {
            if (dayObj.timeZoneConfiguration && Array.isArray(dayObj.timeZoneConfiguration) && dayObj.timeZoneConfiguration.length > 0) {
              dayObj.days.forEach((dayName: any) => {
                dayObj.timeZoneConfiguration.forEach((timeObj: any) => {
                  if (timeObj !== undefined && timeObj !== "") {
                    let data_object = {
                      startDate: moment(configObj.startDate).format("YYYY-MM-DD"),
                      endDate: moment(configObj.endDate).format("YYYY-MM-DD"),
                      day: dayName,
                      startTime: moment(timeObj.startTime).format("HH:mm:ss"),
                      endTime: moment(timeObj.endTime).format("HH:mm:ss"),
                      orderThreshold: "",
                      belowThreshold: "",
                      aboveThreshold: "",
                      active: true,
                    } as any;
                    if (timeObj.id !== undefined && timeObj.id !== "") {
                      data_object.id = timeObj.id;
                    }

                    if (isEmptyOrNull(timeObj.thresholdAmount)) {
                      addition_config_payload_object.error_object = "Threshold amount is required";
                      return;
                    } else {
                      data_object.orderThreshold = timeObj.thresholdAmount;
                    }
                    if (isEmptyOrNull(timeObj.thresholdBelowAmount)) {
                      addition_config_payload_object.error_object = "Below threshold amount is required";
                      return;
                    } else {
                      data_object.belowThreshold = timeObj.thresholdBelowAmount;
                    }

                    if (isEmptyOrNull(timeObj.thresholdAboveAmount)) {
                      addition_config_payload_object.error_object = "Above threshold amount is required";
                      return;
                    } else {
                      data_object.aboveThreshold = timeObj.thresholdAboveAmount;
                    }

                    addition_config_payload_object.payload.push(data_object);
                  }
                });
              });
            }
          }
        });
      }
    });
  }
  return addition_config_payload_object;
};

export const _design_update_delivery_fee_state_object = (responseData: any, dlvFeeConfigState: any, shipmentTypeDetails: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    draft.loading = false;
    if (responseData) {
      if (dlvFeeConfigState && dlvFeeConfigState.shipmentLevel && Object.keys(dlvFeeConfigState.shipmentLevel).length === 0) {
        if (shipmentTypeDetails && Array.isArray(shipmentTypeDetails) && shipmentTypeDetails.length > 0) {
          shipmentTypeDetails.forEach((item: any) => {
            if (item.multiShipment) {
              draft.multiShipmentLevel[item.shipment] = {
                shipmentId: item.id,
                multi_shipment: item.multiShipment,
                isEditMode: false, // main action, "Use this value in calculation"
                enabledEdit: true,
                orderThreshold: "",
                deliveryFee: "",
                isPercentage: false,
              };
            } else {
              draft.shipmentLevel[item.shipment] = {
                shipmentId: item.id,
                multi_shipment: item.multiShipment,
                isEditMode: false, // main action, "Use this value in calculation"
                enableBelowThresholdEdit: false, // to use in update screen, change with pencil icon
                enableAboveThresholdEdit: false, // to use in update screen, change with pencil icon
                active: true, // to use in update screen, make configuration active or in-active
                orderThreshold: "",
                greaterOrderThresold: "",
                belowThreshold: "",
                aboveThreshold: "",
                additionalDeliveryFeeConfigDTOList: [],
                deliveryFeeRangeConfigDTOList: [],
                showDrawerConfig: false,
                updateDrawerConfig: true,
                deliveryFeeSlotCapacityConfigDTOList: [],
                isDeliveryFeeSlotCapacityConfigApplicable: false,
              };
            }
          });
        }
      }

      if (responseData.selectedPosServiceTypeZone && Array.isArray(responseData.selectedPosServiceTypeZone) && responseData.selectedPosServiceTypeZone.length > 0) {
        responseData.selectedPosServiceTypeZone.forEach((posZoneConfig: any) => {
          draft.selectedPosServiceTypeZone = [];
          let posZoneObj = prepare_pos_zone_object(posZoneConfig);
          draft.updateConfigPosId = posZoneConfig.posNo;
          draft.updateConfigZoneName = posZoneConfig.zone;
          draft.selectedPosServiceTypeZone.push(posZoneObj);

          if (
            responseData.shipmentLevelDeliveryFeeConfigs &&
            typeof responseData.shipmentLevelDeliveryFeeConfigs === "object" &&
            Object.keys(responseData.shipmentLevelDeliveryFeeConfigs).length !== 0
          ) {
            Object.keys(responseData.shipmentLevelDeliveryFeeConfigs).forEach((dlvFee: any) => {
              if (dlvFee !== undefined && dlvFee !== "") {
                let shipment_type = shipmentTypeDetails.find((shipmentType: any) => shipmentType.id.toString() === dlvFee.toString());
                if (shipment_type && shipment_type.id && shipment_type.id !== "" && shipment_type.shipment && shipment_type.shipment !== "") {
                  if (shipment_type.multiShipment === false) {
                    draft.openShipmentLevel = true;
                    draft.shipmentLevel[shipment_type.shipment].isEditMode = true;
                    draft.shipmentLevel[shipment_type.shipment].enableBelowThresholdEdit = true;
                    draft.shipmentLevel[shipment_type.shipment].enableAboveThresholdEdit = true;
                    draft.shipmentLevel[shipment_type.shipment].active = responseData.shipmentLevelDeliveryFeeConfigs[dlvFee].active;
                    draft.shipmentLevel[shipment_type.shipment].cncReferenceId = responseData.shipmentLevelDeliveryFeeConfigs[dlvFee].cncReferenceId;
                    draft.shipmentLevel[shipment_type.shipment].id = responseData.shipmentLevelDeliveryFeeConfigs[dlvFee].id;

                    draft.shipmentLevel[shipment_type.shipment].orderThreshold = responseData.shipmentLevelDeliveryFeeConfigs[dlvFee].orderThreshold;
                    draft.shipmentLevel[shipment_type.shipment].greaterOrderThresold = responseData.shipmentLevelDeliveryFeeConfigs[dlvFee].orderThreshold;

                    draft.shipmentLevel[shipment_type.shipment].belowThreshold = responseData.shipmentLevelDeliveryFeeConfigs[dlvFee].belowThreshold;
                    draft.shipmentLevel[shipment_type.shipment].aboveThreshold = responseData.shipmentLevelDeliveryFeeConfigs[dlvFee].aboveThreshold;
                    draft.shipmentLevel[shipment_type.shipment].deliveryFeeRangeConfigDTOList = [];

                    if (responseData.shipmentLevelDeliveryFeeConfigs[dlvFee].showDrawerConfig !== undefined) {
                      draft.shipmentLevel[shipment_type.shipment].showDrawerConfig = responseData.shipmentLevelDeliveryFeeConfigs[dlvFee].showDrawerConfig;
                    }

                    // shipmentLevelDeliveryFeeConfigs
                    //
                    if (
                      responseData.shipmentLevelDeliveryFeeConfigs[dlvFee].deliveryFeeRangeConfigDTOList &&
                      Array.isArray(responseData.shipmentLevelDeliveryFeeConfigs[dlvFee].deliveryFeeRangeConfigDTOList) &&
                      responseData.shipmentLevelDeliveryFeeConfigs[dlvFee].deliveryFeeRangeConfigDTOList.length > 0
                    ) {
                      responseData.shipmentLevelDeliveryFeeConfigs[dlvFee].deliveryFeeRangeConfigDTOList.slice().sort((a: any, b: any) => (a.orderThreshold > b.orderThreshold ? 1 : -1));
                      let previousOrderThreshold = "";
                      responseData.shipmentLevelDeliveryFeeConfigs[dlvFee].deliveryFeeRangeConfigDTOList.forEach((rangeObject: any, rangeObjectIndex: any) => {
                        if (rangeObject !== undefined && rangeObject !== "") {
                          if (rangeObjectIndex === 0) {
                            draft.shipmentLevel[shipment_type.shipment].orderThreshold = rangeObject.orderThreshold;
                            draft.shipmentLevel[shipment_type.shipment].belowThreshold = rangeObject.deliveryFee;
                            previousOrderThreshold = rangeObject.orderThreshold;
                          } else {
                            let range_object_data = {
                              enableEdit: true,
                              isValid: true,
                              isErrorText: "",
                              previousOrderThreshold: previousOrderThreshold,
                              newOrderThreshold: rangeObject.orderThreshold,
                              deliveryFee: rangeObject.deliveryFee,
                            } as any;

                            if (rangeObject.id !== undefined && rangeObject.id !== "") {
                              range_object_data.id = rangeObject.id;
                            }

                            previousOrderThreshold = rangeObject.orderThreshold;
                            draft.shipmentLevel[shipment_type.shipment].deliveryFeeRangeConfigDTOList.push(range_object_data);
                          }
                        }
                      });
                      let range_object_data = {
                        enableEdit: true,
                        isValid: true,
                        isErrorText: "",
                        previousOrderThreshold: previousOrderThreshold,
                        newOrderThreshold: responseData.shipmentLevelDeliveryFeeConfigs[dlvFee].orderThreshold,
                        deliveryFee: responseData.shipmentLevelDeliveryFeeConfigs[dlvFee].belowThreshold,
                      } as any;
                      draft.shipmentLevel[shipment_type.shipment].deliveryFeeRangeConfigDTOList.push(range_object_data);
                    }

                    // additionalDeliveryFeeConfigDTOList
                    //
                    draft.shipmentLevel[shipment_type.shipment].additionalDeliveryFeeConfigDTOList = parseAdditionalDeliveryFeeConfigDTOList(
                      responseData.shipmentLevelDeliveryFeeConfigs[dlvFee].additionalDeliveryFeeConfigDTOList
                    );

                    // deliveryFeeSlotCapacityConfigDTOList
                    //
                    draft.shipmentLevel[shipment_type.shipment].deliveryFeeSlotCapacityConfigDTOList = parse_capacity_utilisation_delivery_fee_dto_lits(
                      responseData.shipmentLevelDeliveryFeeConfigs[dlvFee].deliveryFeeSlotCapacityConfigDTOList
                    );
                    draft.shipmentLevel[shipment_type.shipment].isDeliveryFeeSlotCapacityConfigApplicable =
                      responseData.shipmentLevelDeliveryFeeConfigs[dlvFee].isDeliveryFeeSlotCapacityConfigApplicable;
                  }
                }
              }
            });
          }
        });
      }
    }
  });
  return newDlvFeeConfigState;
};

const prepare_pos_zone_object = (posZoneConfig: any) => {
  let posZoneObj = {
    posNo: posZoneConfig.posNo || "",
    serviceType: posZoneConfig.serviceType || "",
    serviceTypeId: posZoneConfig.serviceTypeId || "",
    posName: posZoneConfig.storeName || "",
    zoneName: posZoneConfig.zone || "",
    zoneId: posZoneConfig.zoneId || "",
    cncReferenceId: posZoneConfig.cncReferenceId || "",
  };
  return posZoneObj;
};

const parseAdditionalDeliveryFeeConfigDTOList = (additionalDeliveryFeeConfigDTOList: any) => {
  let returnData = [] as any;
  if (additionalDeliveryFeeConfigDTOList && Array.isArray(additionalDeliveryFeeConfigDTOList) && additionalDeliveryFeeConfigDTOList.length > 0) {
    additionalDeliveryFeeConfigDTOList.forEach((item: any) => {
      if (item !== undefined && item.startDate !== undefined && item.startDate !== "" && item.endDate !== undefined && item.endDate !== "") {
        if (returnData.length === 0) {
          let configuration_Object = create_configuration_object(item);
          returnData.push(configuration_Object);
        } else {
          let combination_present_index = returnData.findIndex((element: any) => element.startDate === item.startDate && element.endDate === item.endDate);
          if (combination_present_index >= 0) {
            let configuration_Object: any = returnData[combination_present_index];
            if (configuration_Object && configuration_Object.daysConfiguration && Array.isArray(configuration_Object.daysConfiguration) && configuration_Object.daysConfiguration.length > 0) {
              let days_Configuration_present_flag = configuration_Object.daysConfiguration.findIndex((element: any) => element.days.includes(createCamelCase(item.day)));
              if (days_Configuration_present_flag >= 0) {
                if (
                  configuration_Object.daysConfiguration &&
                  configuration_Object.daysConfiguration[days_Configuration_present_flag] !== undefined &&
                  configuration_Object.daysConfiguration[days_Configuration_present_flag].timeZoneConfiguration &&
                  Array.isArray(configuration_Object.daysConfiguration[days_Configuration_present_flag].timeZoneConfiguration) &&
                  configuration_Object.daysConfiguration[days_Configuration_present_flag].timeZoneConfiguration.length > 0
                ) {
                  let time_Object = {
                    id: item.id,
                    active: item.active,
                    startTime: moment(item.startTime, "HH:mm:ss").toDate(),
                    endTime: moment(item.endTime, "HH:mm:ss").toDate(),
                    thresholdAmount: item.orderThreshold,
                    thresholdBelowAmount: item.belowThreshold,
                    thresholdAboveAmount: item.aboveThreshold,
                    enableEdit: true,
                  };
                  configuration_Object.daysConfiguration[days_Configuration_present_flag].timeZoneConfiguration.push(time_Object);
                }
              } else {
                let date_object = {
                  availableDays: calculate_available_days(item.startDate, item.endDate),
                  days: [createCamelCase(item.day)],
                  timeZoneConfiguration: [
                    {
                      id: item.id,
                      active: item.active,
                      startTime: moment(item.startTime, "HH:mm:ss").toDate(),
                      endTime: moment(item.endTime, "HH:mm:ss").toDate(),
                      thresholdAmount: item.orderThreshold,
                      thresholdBelowAmount: item.belowThreshold,
                      thresholdAboveAmount: item.aboveThreshold,
                      enableEdit: true,
                    },
                  ],
                };
                configuration_Object.daysConfiguration.push(date_object);
              }
            }
            returnData.splice(combination_present_index, 1);
            returnData.push(configuration_Object);
          } else {
            let configuration_Object = create_configuration_object(item);
            returnData.push(configuration_Object);
          }
        }
      }
    });
  }

  return calculate_remaining_days(returnData);
};

const calculate_remaining_days = (returnData: any) => {
  if (returnData && Array.isArray(returnData) && returnData.length > 0) {
    returnData.forEach((configItem: any, configIndex: any) => {
      let selectDays: any = [];
      configItem.daysConfiguration.forEach((dayConfig: any) => {
        if (dayConfig && dayConfig.days && Array.isArray(dayConfig.days) && dayConfig.days.length > 0) {
          dayConfig.days.forEach((day: any) => selectDays.push(day));
        }
      });

      if (selectDays && selectDays.length >= 0) {
        returnData[configIndex].daysConfiguration.forEach((dayConfig: any, index: any) => {
          let remainingDaysList = returnData[configIndex].allDays.filter((element: any) => dayConfig.days.indexOf(element.id) !== -1 || (selectDays && selectDays.indexOf(element.id) === -1));
          returnData[configIndex].daysConfiguration[index].availableDays = remainingDaysList;
        });
        returnData[configIndex].selectedDays = selectDays;
        returnData[configIndex].startDate = moment(returnData[configIndex].startDate, "YYYY-MM-DD").toDate();
        returnData[configIndex].endDate = moment(returnData[configIndex].endDate, "YYYY-MM-DD").toDate();
        returnData[configIndex].dates = getDates(returnData[configIndex].startDate, returnData[configIndex].endDate);
      }
    });
  }
  return returnData;
};

const calculate_available_days = (startDate: any, endDate: any) => {
  let daysList: any = [];
  let startDateobj: any = moment(startDate, "YYYYY-MM-DD").toDate();
  let endDateobj: any = moment(endDate, "YYYY-MM-DD").toDate();
  startDateobj.setHours(0, 0, 0, 0);
  endDateobj.setHours(0, 0, 0, 0);
  let diff = (endDateobj.getTime() - startDateobj.getTime()) / (24 * 60 * 60 * 1000);
  if (diff < 7) {
    let dayNumber = startDateobj.getDay();
    while (diff >= 0) {
      daysList.push({ id: weekday[dayNumber], name: weekday[dayNumber] });
      dayNumber = (dayNumber + 1) % 7;
      diff--;
    }
  } else {
    weekday.forEach((item) => {
      daysList.push({ id: item, name: item });
    });
  }
  return daysList;
};

const create_configuration_object = (item: any) => {
  let allDaysArray: any = calculate_available_days(item.startDate, item.endDate);
  let configuration_Object = {
    startDate: item.startDate,
    endDate: item.endDate,
    allDays: allDaysArray,
    remainingDays: weekday,
    selectedDays: [createCamelCase(item.day)],
    daysConfiguration: [
      {
        availableDays: allDaysArray,
        days: [createCamelCase(item.day)],
        timeZoneConfiguration: [
          {
            id: item.id,
            active: item.active,
            startTime: moment(item.startTime, "HH:mm:ss").toDate(),
            endTime: moment(item.endTime, "HH:mm:ss").toDate(),
            thresholdAmount: item.orderThreshold,
            thresholdBelowAmount: item.belowThreshold,
            thresholdAboveAmount: item.aboveThreshold,
            enableEdit: true,
          },
        ],
      },
    ],
  };
  return configuration_Object;
};

export const _handle_service_active_toggle_change = (multiShipmentLevelFlag: any, name: any, dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (draft.updateConfigPosId !== "" && draft.updateConfigZoneName !== "") {
      if (multiShipmentLevelFlag) {
        draft.multiShipmentLevel[name].active = !draft.multiShipmentLevel[name].active;
      } else {
        draft.shipmentLevel[name].active = !draft.shipmentLevel[name].active;
      }
    }
  });
  return newDlvFeeConfigState;
};

export const _handle_edit_update_calculation_toggle_change_util = (multiShipmentLevelFlag: any, name: any, fieldName: any, dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (draft.updateConfigPosId !== "" && draft.updateConfigZoneName !== "") {
      if (multiShipmentLevelFlag) {
        draft.multiShipmentLevel[name][fieldName] = !draft.multiShipmentLevel[name][fieldName];
      } else {
        draft.shipmentLevel[name][fieldName] = !draft.shipmentLevel[name][fieldName];
      }
    }
  });
  return newDlvFeeConfigState;
};

export const _handle_remove_time_config_record_row = (multiShipmentLevel: any, shipment_name: any, configIndex: any, daysConfigIndex: any, timeConfigIndex: any, dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (configIndex !== undefined && configIndex >= 0 && daysConfigIndex !== undefined && daysConfigIndex >= 0 && timeConfigIndex !== undefined && timeConfigIndex >= 0) {
      if (multiShipmentLevel !== undefined) {
        if (!multiShipmentLevel) {
          if (shipment_name !== undefined && shipment_name !== "") {
            if (
              draft.shipmentLevel[shipment_name] &&
              draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList &&
              Array.isArray(draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList) &&
              draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList.length > 0 &&
              draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList[configIndex] !== undefined &&
              draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration !== undefined &&
              Array.isArray(draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration) &&
              draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration.length > 0 &&
              draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration[daysConfigIndex] !== undefined &&
              draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration[daysConfigIndex].timeZoneConfiguration !== undefined &&
              Array.isArray(draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration[daysConfigIndex].timeZoneConfiguration) &&
              draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration[daysConfigIndex].timeZoneConfiguration.length > 0 &&
              draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration[daysConfigIndex].timeZoneConfiguration[timeConfigIndex] !== undefined
            ) {
              draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration[daysConfigIndex].timeZoneConfiguration.splice(timeConfigIndex, 1);
            }
          }
        }
      }
    }
  });
  return newDlvFeeConfigState;
};

export const _handle_change_time_config_record_threshould_data = (
  multiShipmentLevel: any,
  shipment_name: any,
  configIndex: any,
  daysConfigIndex: any,
  timeConfigIndex: any,
  textBoxName: any,
  dataValue: any,
  dlvFeeConfigState: any
) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (configIndex !== undefined && configIndex >= 0 && daysConfigIndex !== undefined && daysConfigIndex >= 0 && timeConfigIndex !== undefined && timeConfigIndex >= 0) {
      if (multiShipmentLevel !== undefined) {
        if (!multiShipmentLevel) {
          if (shipment_name !== undefined && shipment_name !== "") {
            if (
              draft.shipmentLevel[shipment_name] &&
              draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList &&
              Array.isArray(draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList) &&
              draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList.length > 0 &&
              draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList[configIndex] !== undefined &&
              draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration !== undefined &&
              Array.isArray(draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration) &&
              draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration.length > 0 &&
              draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration[daysConfigIndex] !== undefined &&
              draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration[daysConfigIndex].timeZoneConfiguration !== undefined &&
              Array.isArray(draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration[daysConfigIndex].timeZoneConfiguration) &&
              draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration[daysConfigIndex].timeZoneConfiguration.length > 0 &&
              draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration[daysConfigIndex].timeZoneConfiguration[timeConfigIndex] !== undefined
            ) {
              draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration[daysConfigIndex].timeZoneConfiguration[timeConfigIndex][textBoxName] = dataValue;
            }
          }
        }
      }
    }
  });
  return newDlvFeeConfigState;
};

export const _handle_edit_toggle_additional_config = (multiShipmentLevel: any, shipment_name: any, configIndex: any, daysConfigIndex: any, timeConfigIndex: any, dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (configIndex !== undefined && configIndex >= 0 && daysConfigIndex !== undefined && daysConfigIndex >= 0 && timeConfigIndex !== undefined && timeConfigIndex >= 0) {
      if (multiShipmentLevel !== undefined) {
        if (!multiShipmentLevel) {
          if (shipment_name !== undefined && shipment_name !== "") {
            if (
              draft.shipmentLevel[shipment_name] &&
              draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList &&
              Array.isArray(draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList) &&
              draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList.length > 0 &&
              draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList[configIndex] !== undefined &&
              draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration !== undefined &&
              Array.isArray(draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration) &&
              draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration.length > 0 &&
              draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration[daysConfigIndex] !== undefined &&
              draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration[daysConfigIndex].timeZoneConfiguration !== undefined &&
              Array.isArray(draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration[daysConfigIndex].timeZoneConfiguration) &&
              draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration[daysConfigIndex].timeZoneConfiguration.length > 0 &&
              draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration[daysConfigIndex].timeZoneConfiguration[timeConfigIndex] !== undefined
            ) {
              draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration[daysConfigIndex].timeZoneConfiguration[timeConfigIndex].enableEdit =
                !draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration[daysConfigIndex].timeZoneConfiguration[timeConfigIndex].enableEdit;
            }
          }
        }
      }
    }
  });
  return newDlvFeeConfigState;
};

export const _handle_edit_threshold_rane_toggle_change_enable_Edit_util = (multiShipmentLevel: any, shipment_name: any, rangeObjectIndex: any, dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (multiShipmentLevel !== undefined) {
      if (!multiShipmentLevel) {
        if (shipment_name !== undefined && shipment_name !== "") {
          if (
            draft.shipmentLevel &&
            draft.shipmentLevel[shipment_name] &&
            draft.shipmentLevel[shipment_name].deliveryFeeRangeConfigDTOList &&
            Array.isArray(draft.shipmentLevel[shipment_name].deliveryFeeRangeConfigDTOList) &&
            draft.shipmentLevel[shipment_name].deliveryFeeRangeConfigDTOList.length > 0
          ) {
            draft.shipmentLevel[shipment_name].deliveryFeeRangeConfigDTOList[rangeObjectIndex].enableEdit =
              !draft.shipmentLevel[shipment_name].deliveryFeeRangeConfigDTOList[rangeObjectIndex].enableEdit;
          }
        }
      }
    }
  });
  return newDlvFeeConfigState;
};

// export const countDecimals = (n: any) => {
//   if (Math.floor(n) === n) return 0;

//   var str = n.toString();
//   if (str.indexOf(".") !== -1 && str.indexOf("-") !== -1) {
//     return str.split("-")[1] || 0;
//   } else if (str.indexOf(".") !== -1) {
//     return str.split(".")[1].length || 0;
//   }
//   return str.split("-")[1] || 0;
// };

// export const validate_number_for_threshould = (n: any) => {
//   const number_of_decimal = countDecimals(n);
//   if (number_of_decimal > 0) {
//     n = Number.parseFloat(n).toFixed(2);
//     return !Number.isNaN(Number.parseFloat(n)) && Number.parseFloat(n) >= 1;
//   }
//   return Number.isInteger(parseInt(n)) && parseInt(n) >= 1;
// };

//
// Listing Screen API
//

export const _design_delivery_fee_listing_screen_object = (responseData: any) => {
  let deliveryFeeHeaderDataList = {
    id: "POS No.",
    posName: "POS Name",
    zoneName: "Zone Name",
    headerRowsValues: [],
    headerInnerRowsValues: [],
    actions: "Actions",
  } as any;
  if (responseData && Array.isArray(responseData) && responseData.length > 0) {
    let headerValues = [] as any;
    // let header_inner_rows_values = ["Threshold", "Fees Above Threshold", "Fees Below Threshold"];
    responseData.forEach((shipmentDetail: any) => {
      if (shipmentDetail) {
        if (shipmentDetail.multiShipment === false) {
          let shipmentObj = {
            shipmentId: shipmentDetail.id,
            shipmentName: shipmentDetail.shipment,
            rowSpan: 1,
            colSpan: 3,
          };
          headerValues.push(shipmentObj);
        }
      }
    });
    deliveryFeeHeaderDataList.headerRowsValues = headerValues;
  }
  return deliveryFeeHeaderDataList;
};

export const _design_delivery_fee_list_data = (responseData: any, deliveryFeeState: any) => {
  let responseObject = {
    responseList: [],
    pageNumber: 0,
    maxRecordPresent: deliveryFeeState.size || 0,
  } as any;

  if (responseData && responseData.content && Array.isArray(responseData.content) && responseData.content.length > 0) {
    responseObject.pageNumber = responseData.number;
    responseObject.maxRecordPresent = responseData.totalElements;
    responseData.content.forEach((config: any) => {
      if (config) {
        if (config.selectedPosServiceTypeZone && Array.isArray(config.selectedPosServiceTypeZone) && config.selectedPosServiceTypeZone.length > 0) {
          config.selectedPosServiceTypeZone.forEach((configIndex: any) => {
            let objData = {
              id: configIndex.posNo,
              storeName: configIndex.storeName,
              zone: configIndex.zone,
              zoneId: configIndex.zoneId,
              shipmentLevelDeliveryFeeConfigs: config.shipmentLevelDeliveryFeeConfigs,
              active: configIndex.active,
            } as any;

            responseObject.responseList.push(objData);
          });
        }
      }
    });
  }
  return responseObject;
};

export const _handle_view_change_configuration_util = (dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    draft.isShipmentLevelConfigurationView = !draft.isShipmentLevelConfigurationView;
  });
  return newDlvFeeConfigState;
};

export const _handle_toggle_change_is_multi_shipment_calculation_util = (dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (draft.multiShipmentLevel && Object.keys(draft.multiShipmentLevel).length > 0) {
      Object.keys(draft.multiShipmentLevel).forEach((multiShipmentLevelName: any) => {
        draft.multiShipmentLevel[multiShipmentLevelName].isPercentage = !draft.multiShipmentLevel[multiShipmentLevelName].isPercentage;
      });
    }
    draft.isMultiShipmentCalculationPercentageBased = !draft.isMultiShipmentCalculationPercentageBased;
  });
  return newDlvFeeConfigState;
};

export const _handle_service_multi_shiment_input_change = (inputName: any, multiShipmentLevel: any, name: any, value: any, dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (value !== "" && (isNaN(Number(value)) || !amountValueRegExp.test(value))) {
      return;
    }
    if (multiShipmentLevel) {
      draft.multiShipmentLevel[inputName][name] = value;
    }
  });
  return newDlvFeeConfigState;
};

export const _handle_edit_multi_shipment_enable_toggle_change_util = (multiShipmentLevel: any, shipment_name: any, toggleName: any, dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (multiShipmentLevel) {
      draft.multiShipmentLevel[shipment_name][toggleName] = !draft.multiShipmentLevel[shipment_name][toggleName];
    }
  });
  return newDlvFeeConfigState;
};

export const _validate_multi_shipment_configuration_object = (dlvFeeConfigState: any) => {
  const payloadObject = {
    multiShipmentConfigList: [],
  } as any;

  let responseValue: any = {};

  if (dlvFeeConfigState.multiShipmentLevel) {
    let emptyCount = 0 as any;

    Object.keys(dlvFeeConfigState.multiShipmentLevel).forEach((supplierType: any, supplierTypeIndex: any) => {
      if (supplierType !== "" && dlvFeeConfigState.multiShipmentLevel[supplierType] && dlvFeeConfigState.multiShipmentLevel[supplierType].isEditMode === true) {
        const { shipmentId, orderThreshold, deliveryFee } = dlvFeeConfigState.multiShipmentLevel[supplierType];

        let shipment_object = {
          shipmentTypeId: shipmentId,
          isPercentage: dlvFeeConfigState.isMultiShipmentCalculationPercentageBased,
        } as any;

        if (isEmptyOrNull(orderThreshold)) {
          responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-orderThreshold`] = "Enter valid Threshold amount";
        } else {
          shipment_object.orderThreshold = Number(orderThreshold);
        }

        if (isEmptyOrNull(deliveryFee)) {
          responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-deliveryFee`] = "Enter valid Above Threshold amount";
        } else {
          shipment_object.deliveryFee = Number(deliveryFee);
        }

        payloadObject.multiShipmentConfigList.push(shipment_object);
      } else {
        emptyCount++;
      }
    });

    if (emptyCount === Object.keys(dlvFeeConfigState.multiShipmentLevel).length) {
      // CustomAlert(ALERT_TYPES.ERROR, "Minimum one configurtion is required."); // No supplier type is made active for calculation
      return payloadObject;
    } else if (responseValue && Object.keys(responseValue).length !== 0) {
      CustomAlert(ALERT_TYPES.ERROR, responseValue[Object.keys(responseValue)[0]]);
      return {};
    }
  }

  return payloadObject;
};

export const _design_update_multi_shipment_delivery_fee_state_object = (data: any, dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    draft.loading = false;
    draft.isMultiShipmentCalculationPercentageBased = false;

    if (data && Array.isArray(data) && data.length > 0) {
      Object.keys(draft.multiShipmentLevel).forEach((supplierType: any, supplierTypeIndex: any) => {
        if (supplierType !== undefined && supplierType !== "") {
          const shipmentIdValue = dlvFeeConfigState.multiShipmentLevel[supplierType].shipmentId;
          if (shipmentIdValue !== undefined && shipmentIdValue !== "") {
            var multi_shipment_record_Index = data.findIndex((item: any) => item.shipmentTypeId === shipmentIdValue);
            let data_object = {} as any;
            if (multi_shipment_record_Index !== -1) {
              data_object = data[multi_shipment_record_Index];
              draft.multiShipmentLevel[supplierType].id = data_object.id;
              draft.isMultiShipmentCalculationPercentageBased = data_object.isPercentage !== undefined ? data_object.isPercentage : false;
            }
            draft.multiShipmentLevel[supplierType].orderThreshold = data_object.orderThreshold !== undefined ? data_object.orderThreshold : "";
            draft.multiShipmentLevel[supplierType].deliveryFee = data_object.deliveryFee !== undefined ? data_object.deliveryFee : "";
            draft.multiShipmentLevel[supplierType].enabledEdit = Object.keys(data_object).length !== 0 ? true : false;
            draft.multiShipmentLevel[supplierType].isEditMode = Object.keys(data_object).length !== 0 ? true : false;
            draft.multiShipmentLevel[supplierType].isPercentage = data_object.isPercentage !== undefined ? data_object.isPercentage : false;
          }
        }
      });
    }
  });
  return newDlvFeeConfigState;
};

export const _handle_service_type_drawer_checkbox_input_change = (inputName: any, multiShipmentLevel: any, objName: any, checked: any, dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (!multiShipmentLevel) {
      draft.shipmentLevel[inputName][objName] = checked;
    }
  });
  return newDlvFeeConfigState;
};

export const _handle_change_capacity_utilisation_field_data = (multiShipmentLevel: any, shipment_name: any, configIndex: any, textBoxName: any, dataValue: any, dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (configIndex !== undefined && configIndex >= 0) {
      if (multiShipmentLevel !== undefined) {
        if (!multiShipmentLevel) {
          if (shipment_name !== undefined && shipment_name !== "") {
            if (
              draft.shipmentLevel[shipment_name] &&
              draft.shipmentLevel[shipment_name].deliveryFeeSlotCapacityConfigDTOList &&
              Array.isArray(draft.shipmentLevel[shipment_name].deliveryFeeSlotCapacityConfigDTOList) &&
              draft.shipmentLevel[shipment_name].deliveryFeeSlotCapacityConfigDTOList.length > 0 &&
              draft.shipmentLevel[shipment_name].deliveryFeeSlotCapacityConfigDTOList[configIndex] !== undefined
            ) {
              draft.shipmentLevel[shipment_name].deliveryFeeSlotCapacityConfigDTOList[configIndex][textBoxName] = dataValue;
            }
          }
        }
      }
    }
  });
  return newDlvFeeConfigState;
};

export const _handle_edit_toggle_change_capacity_utilisation_data = (multiShipmentLevel: any, shipment_name: any, configIndex: any, dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (configIndex !== undefined && configIndex >= 0) {
      if (multiShipmentLevel !== undefined) {
        if (!multiShipmentLevel) {
          if (shipment_name !== undefined && shipment_name !== "") {
            if (
              draft.shipmentLevel[shipment_name] &&
              draft.shipmentLevel[shipment_name].deliveryFeeSlotCapacityConfigDTOList &&
              Array.isArray(draft.shipmentLevel[shipment_name].deliveryFeeSlotCapacityConfigDTOList) &&
              draft.shipmentLevel[shipment_name].deliveryFeeSlotCapacityConfigDTOList.length > 0 &&
              draft.shipmentLevel[shipment_name].deliveryFeeSlotCapacityConfigDTOList[configIndex] !== undefined
            ) {
              draft.shipmentLevel[shipment_name].deliveryFeeSlotCapacityConfigDTOList[configIndex].enableEdit =
                !draft.shipmentLevel[shipment_name].deliveryFeeSlotCapacityConfigDTOList[configIndex].enableEdit;
            }
          }
        }
      }
    }
  });
  return newDlvFeeConfigState;
};

export const prepare_capacity_utilisation_record_payload = (record_list: any, type: any) => {
  let response_payload_object = {
    payload: [],
    error_object: "",
  } as any;
  if (record_list && Array.isArray(record_list) && record_list.length > 0) {
    record_list.forEach((configObj: any) => {
      if (configObj !== undefined) {
        let data_object = {
          capacityLow: "",
          capacityHigh: "",
          deliveryFee: "",
          active: true,
        } as any;

        if (configObj.id !== undefined && configObj.id !== "") {
          data_object.id = configObj.id;
        }

        if (isEmptyOrNull(configObj.capacityLow)) {
          response_payload_object.error_object = "Start Range value is required";
          return;
        } else {
          data_object.capacityLow = configObj.capacityLow;
        }

        if (isEmptyOrNull(configObj.capacityHigh)) {
          response_payload_object.error_object = "Start End value is required";
          return;
        } else {
          data_object.capacityHigh = configObj.capacityHigh;
        }

        if (isEmptyOrNull(configObj.deliveryFee)) {
          response_payload_object.error_object = "Delivery Fee is required";
          return;
        } else {
          data_object.deliveryFee = configObj.deliveryFee;
        }
        response_payload_object.payload.push(data_object);
      }
    });
  }
  return response_payload_object;
};

const parse_capacity_utilisation_delivery_fee_dto_lits = (record_list: any) => {
  let returnData = [] as any;
  if (record_list && Array.isArray(record_list) && record_list.length > 0) {
    record_list.forEach((record_item: any) => {
      const data_object = {
        capacityLow: "",
        capacityHigh: "",
        deliveryFee: "",
        active: true,
        capacity_high_error_message: "",
        capacity_low_error_message: "",
        capacity_fee_error_message: "",
        enableEdit: true,
      } as any;

      if (record_item.id !== undefined) {
        data_object.id = record_item.id;
      }

      if (record_item.capacityLow !== undefined) {
        data_object.capacityLow = record_item.capacityLow;
      }

      if (record_item.capacityHigh !== undefined) {
        data_object.capacityHigh = record_item.capacityHigh;
      }

      if (record_item.deliveryFee !== undefined) {
        data_object.deliveryFee = record_item.deliveryFee;
      }

      if (record_item.active !== undefined) {
        data_object.active = record_item.active;
      }

      returnData.push(data_object);
    });
  }
  return returnData;
};
