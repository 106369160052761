import { Grid, MenuItem, Tab, Tabs } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
// Material UI Components
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Select from "@material-ui/core/Select";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { fetchCountrySettings } from "components/CountrySettings/redux/countrySettingsSlice";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, Link as RouterLink, useHistory, useLocation } from "react-router-dom";
// Constants
import { APP_ROUTES, CURRENT_COUNTRY } from "../../../config/constants";
import { changeCountry } from "../../../config/redux/appConfigSlice";
import { fetchAllAreasOfCountry, fetchAllRegionsOfCountry, selectAllCountries } from "../../../config/redux/configurationsSlice";
// Assets
import AccountCircle from "../../assets/svg/AccountCircle.svg";
import CarrefourLogo from "../../assets/svg/CarrefourLogo.svg";
import DOSLogo from "../../assets/svg/DOSTitle.svg";
import { ReactComponent as CountryDropDownIcon } from "../../assets/svg/DownArrow.svg";
import LineSVG from "../../assets/svg/Line.svg";
import settingsIcon from "../../assets/svg/Settings.svg";
import { canChangeCountrySetting } from "../../CountrySettings/CountryAccessControlUtils";
import { logOutUser } from "../../Login/redux/userSlice";
import { topMenuStyles } from "./TopMenuStyles";
import { getTopBarMenuItems } from "./TopMenuUtil";

function TopMenu({ handleListItemClick }) {
  const topMenuClasses = topMenuStyles();

  const location = useLocation();
  const { user } = useSelector((state) => state);
  const countries = useSelector(selectAllCountries);
  const { currentCountry } = useSelector((state) => state.appConfig, shallowEqual);
  const history = useHistory();

  const [currentTab, setCurrentTab] = React.useState("POS");
  const [country, setCountry] = useState("");

  const [TOP_BAR_MENU_ITEMS, setMenuItems] = useState([]);
  const dispatch = useDispatch();
  const [countryList, setCountryList] = useState([]);
  const [showSetting, setShowSetting] = useState(false);

  useEffect(() => {
    user && user.userDetails && user.userDetails.userProfile && setMenuItems(getTopBarMenuItems(user.userDetails.userProfile));
  }, [user]);

  useEffect(() => {
    if (user && user.userDetails && user.userDetails.userProfile && user.userDetails.userProfile.featureId && user.userDetails.userProfile.featureId.length > 0) {
      let isShowSetting = canChangeCountrySetting(user.userDetails);
      setShowSetting(isShowSetting);
    }
    user && user.userDetails && user.userDetails.userProfile && setMenuItems(getTopBarMenuItems(user.userDetails.userProfile));
  }, [user]);

  const handleClick = (event) => {
    // setUserMenuElement(event.currentTarget);
  };

  const onLogout = () => {
    dispatch(logOutUser());
  };

  const handleCountryChange = (event) => {
    const { value } = event.target;
    setCountry(value);
    let selectedCountryObj = countries.filter((c) => c.countryId === value)[0];
    if (selectedCountryObj !== undefined && selectedCountryObj.countryId !== undefined && selectedCountryObj.countryId !== "") {
      localStorage.setItem(CURRENT_COUNTRY, JSON.stringify(selectedCountryObj));
      dispatch(changeCountry(selectedCountryObj));
      dispatch(fetchCountrySettings(selectedCountryObj));
      dispatch(fetchAllRegionsOfCountry(selectedCountryObj.countryId));
      dispatch(fetchAllAreasOfCountry(selectedCountryObj.countryId));
      handleListItemClick(event, 1, APP_ROUTES.POS_LIST);
      history.push(APP_ROUTES.POS_LIST);
    }
  };

  useEffect(() => {
    TOP_BAR_MENU_ITEMS &&
      TOP_BAR_MENU_ITEMS.length > 0 &&
      setCurrentTab(
        TOP_BAR_MENU_ITEMS.find((tbm) => location.pathname.includes(tbm.to))
          ? TOP_BAR_MENU_ITEMS.find((tbm) => location.pathname.includes(tbm.to)).value
          : TOP_BAR_MENU_ITEMS.find((tbm) => tbm.isDefault).value
      );
    // eslint-disable-next-line
  }, [location.pathname, TOP_BAR_MENU_ITEMS]);

  useEffect(() => {
    if (countries && countries.length > 0 && user && user.userDetails && user.userDetails.userProfile) {
      let storedCountry = localStorage.getItem(CURRENT_COUNTRY) ? JSON.parse(localStorage.getItem(CURRENT_COUNTRY)) : undefined;
      let selectedCountries = countries.filter((item) => user.userDetails.userProfile.countryId.includes(item.countryId));
      if (storedCountry !== undefined && storedCountry !== "" && storedCountry.countryId !== undefined && user.userDetails.userProfile.countryId.includes(storedCountry.countryId)) {
        setCountry(storedCountry.countryId);
      } else {
        setCountry(selectedCountries[0].countryId);
      }
      setCountryList(selectedCountries);
    }
    // eslint-disable-next-line
  }, [countries, user]);

  return (
    <AppBar position="fixed" className={topMenuClasses.appBar}>
      <Toolbar className={topMenuClasses.toolBar}>
        <Grid container>
          <Link to={`${APP_ROUTES.POS_LIST}`} onClick={(e) => handleListItemClick(e, 1, APP_ROUTES.POS_LIST)}>
            <img src={CarrefourLogo} alt="Logo" />
          </Link>
          <img src={LineSVG} className={topMenuClasses.linesvg} alt="line" />
          <Typography variant="h6" noWrap>
            <Link to={`${APP_ROUTES.POS_LIST}`} onClick={(e) => handleListItemClick(e, 1, APP_ROUTES.POS_LIST)}>
              <img src={DOSLogo} alt="DOSText" className={topMenuClasses.logoName} />
            </Link>
          </Typography>
        </Grid>
        {/* <Grid className={topMenuClasses.tabsSection}>
              <Tabs value={currentTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary" classes={{ indicator: topMenuClasses.indicator }}>
                {TOP_BAR_MENU_ITEMS.map((menuItem) => (
                  <Tab key={menuItem.value} label={menuItem.label} className={topMenuClasses.tabLabel} value={menuItem.value} disabled={menuItem.isDisabled} component={RouterLink} to={menuItem.to} />
                ))}
              </Tabs>
            </Grid> */}
        <Grid container className={topMenuClasses.topMenuRightDivStyle}>
          <div className={topMenuClasses.countryDivStyle}>
            <InputLabel className={topMenuClasses.CountryLabel}>Country: </InputLabel>

            {countries !== undefined && countries.length && country && (
              <FormControl>
                <Select
                  disableUnderline={true}
                  id="country"
                  renderValue={() => {
                    return countryList.length ? countryList.find((c) => c.countryId === parseInt(country))?.name : "";
                  }}
                  value={countries.length ? country : ""}
                  onChange={handleCountryChange}
                  label="Country"
                  name="country"
                  className={topMenuClasses.inputRounded}
                  IconComponent={CountryDropDownIcon}
                  MenuProps={{
                    anchorOrigin: { vertical: "bottom", horizontal: "left" },
                    getContentAnchorEl: null,
                    classes: { paper: topMenuClasses.dropdownMenuStyle },
                  }}
                >
                  {countryList.map((item) => (
                    <MenuItem key={item.countryId} value={item.countryId} className={topMenuClasses.countryMenuItemContainer}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={9} className={topMenuClasses.fontSize500}>
                          {item.name}
                        </Grid>
                        {showSetting && (
                          <Grid item xs={3}>
                            <Link to={`${APP_ROUTES.COUNTRY_SETTINGS}/${item.name}`} className={topMenuClasses.countryMenuItem} onClick={(e) => e.stopPropagation()}>
                              <img src={settingsIcon} alt="settings" />
                            </Link>
                          </Grid>
                        )}
                      </Grid>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </div>
          <div className={topMenuClasses.userDetail}>
            {`${user?.userDetails?.firstName || "-"} ${user?.userDetails?.lastName || "-"}`}
            <br />
            <div className={topMenuClasses.logoutButton} onClick={onLogout}>
              Logout
            </div>
          </div>
          <ListItemIcon className={topMenuClasses.accountCircleDiv} onClick={handleClick}>
            <img src={AccountCircle} className={topMenuClasses.accountCircle} alt="accountImg" />
          </ListItemIcon>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default React.memo(TopMenu);
